import React, { useContext, useEffect, useState, useRef, forwardRef} from 'react';
import { Link, useHistory } from 'react-router-dom';
import TabCards from '../../components/TabCards';
import ProjectDetails from './ProjectDetails';
import ProjectFees from './ProjectFees';
import ProjectLogistics from './ProjectLogistics';
import RequiredSpecialisation from './RequiredSpecialisation';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const ProjectSteps = (props) => {	
  
  const globalState = useContext(store);
  const { dispatch } = globalState;
	const [ createNewProject, setCreateNewProject ] = useState(false)
  const [ defaultOptions, setDefaultOptions ] = useState(false)
  const [ findConsultant, setFindConsultant ] = useState(false)
  const [ load, setLoad ] = useState(true)

  const [ therapeuticOthers, setTherapeuticOthers ] = useState([])  

  const [activeId, setActiveId] = useState(1)
  const [projectId, setProjectId] = useState(null)
  const [projectDetails, setProjectDetails] = useState('')
  const [copyProjectDetails, setCopyProjectDetails] = useState({})

  const commonProps = { activeId: activeId, setActiveId: setActiveId, projectId:projectId, setProjectId:setProjectId ,projectDetails: projectDetails, setProjectDetails: setProjectDetails ,create_project: props.create_project,edit_project_id: props.edit_project_id , copyProjectDetails:copyProjectDetails, setCopyProjectDetails:setCopyProjectDetails ,  retriveDataProject:props.retriveDataProject, load:load, setLoad:setLoad }
  
  const tabs = [
    { id: 1, name: 'Project Details', component: ProjectDetails, props: commonProps  },
    { id: 2, name: 'Specialisations Required', component: RequiredSpecialisation, props: commonProps },
    { id: 3, name: 'Project Logistics', component: ProjectLogistics, props: commonProps },
    { id: 4, name: 'Project Fees', component: ProjectFees, props: commonProps },
    
  ]
  
 
  return (    
  	<div className="col-md">
  		<div className="row g-2 text-start">         
				<h4><b>Company Name: {globalState.state.company_name}</b></h4>
				<h5>User Name: {globalState.state.firstName} {globalState.state.lastName}</h5>
      </div>     
       <TabCards
		      tabs={tabs}
		      activeId={activeId}
		      setActiveId={setActiveId}
		      heading={props.headings}
		      projectStep={true}
		    />
      
  	</div>
  );
}

export default ProjectSteps;
