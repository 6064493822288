import React, { useState, useEffect, useRef } from 'react';
import FloatingSelect from '../../components/FloatingSelect'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import { Link } from 'react-router-dom';
import Modal from '../../components/NewModal'


const Signup = (props) => {
	const buttonRef = useRef();
  const [ created, setCreated ] = useState(false)
  const [ show, setShow ] = useState(false)
  const [ newSetModal, setModal ] = useState(null);

  const [countryCodes, setCountryCodes] = useState([])
  const [services, setServices] = useState([])
  const [country, setCountry] = useState('')
  const [service, setService] = useState('')
  const [email, setEmail] = useState('')
  const [email_confirmation, setEmailConfirmation] = useState('')

  const show_signup = new URLSearchParams(window.location.search).get("show_signup");


  useEffect(() => {
    if (newSetModal)
      show ? newSetModal.show() : newSetModal.hide()
  }, [newSetModal, show])

  const fetchData = async () => {
    const response = await fetch('/api/v1/signup_data', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setCountryCodes( responseJson.country_codes )
      const allServices = responseJson.services.map( data => {
        return { value: data.name, text: data.name }
      });
      setServices( allServices )
      setShow(show_signup);
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    Array.prototype.forEach.call(elements, function(el) {
      let message = errors.user[el.name] || errors.consultant_profile[el.name]
      if( message ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = message
      }
    });
  }

  const handleBlur = async (event) =>{

  	if(email != email_confirmation )
  	{
  		let message = "doesn't match Email" ;
  		if( message ){
        event.target.classList.add("is-invalid");
        event.target.parentNode.querySelector('.invalid-feedback').innerText = message
      }
  	}
  	else
  	{
  		event.target.classList.remove("is-invalid");
  	}
  }

  const handleSignupSubmit = async (event) => {

		buttonRef.current.disabled = true;

    if (event) {
      event.preventDefault();
    }
    const formData = {
      user: {
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        email: event.target.email.value,
        email_confirmation: event.target.email_confirmation.value,
        phone_number: event.target.phone_number.value,
        country: event.target.country.value,
        company_name: event.target.company_name.value,
        terms_of_service: event.target.terms_of_service.checked,
        consultant_profile_attributes: {
          service_offering: event.target.service_offering.value,
          web_link: event.target.web_link.value,
          summary: event.target.summary.value,
        }
      }
    }
    const response = await fetch( '/api/v1/signup', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const userData = await response.json()
    if (response.ok) {
      setCreated(true)
    } else {
    	buttonRef.current.disabled = false;
      resetErrors( event.target.elements );
      showErrors( event.target.elements, userData );
    }
  }

  function expandTextarea(id) {
  	var el = document.getElementById(id);
		if(el){
		  el.addEventListener('keyup', function() {
        this.style.overflow = 'hidden';
        this.style.height = 0;
        this.style.height = this.scrollHeight + 'px';
    	}, false);
		}
	}

	expandTextarea('summary');


  if ( created ) {
    return(
      <div className="auth-wrapper">
        <div className="auth-inner signup-success">
          <h3 className="fw-bold">
            Thank you for applying for a Consultant Account with Pharmalancers.
            Approval takes 2 - 3 working days and Login details will be sent to your email.
          </h3>
        </div>
      </div>
    );
  }



  return (
    <div className="auth-wrapper">
      <h4 className="mb-4"><b className="primary-text">Work Smart</b> &#8226; <b className="secondary-text">Work Efficient</b> &#8226; <b className="primary-text">Be Flexible</b></h4>
      <div className="auth-inner signup">
        <form onSubmit={handleSignupSubmit} className="row g-2">
          <h3>Apply for a free Consultant Account</h3>
          <div className="row g-2 m-0">
            <div className="col-md">
              <p className="fs-6 text-center">
                Already have an account <Link className="fw-bold" to="/login">Log in</Link>
              </p>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="first_name" id="first_name" placeholder="John" required />
                <label htmlFor="first_name">First Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="last_name" id="last_name" placeholder="Smith" required />
                <label htmlFor="last_name">Last Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" onChange={(e)=> setEmail(e.target.value)}   required />
                <label htmlFor="email">Email</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="email" className="form-control" name="email_confirmation" id="email_confirmation" placeholder="name@example.com" onChange={(e)=> setEmailConfirmation(e.target.value)} onBlur={handleBlur} required />
                <label htmlFor="email_confirmation">Confirm Email</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md-6">
              <FloatingSelect data={countryCodes} name='country' required={true} onChange={e => setCountry( e.target.value )}
                label='Country Code' value={country} placeholder='Select Code' withError='true'/>
            </div>
            <div className="col-md-6">
              <div className="form-floating">
                <input type="tel" className="form-control" name="phone_number" id="phone_number" placeholder="+123214124412" required />
                <label htmlFor="phone_number">Phone Number</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <FloatingSelect data={services} name='service_offering' required={true}
                onChange={e => setService( e.target.value )}
                label='Service Offering' value={service} placeholder='Select Service' withError='true'/>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="company_name" id="company_name" placeholder="Company Name" />
                <label htmlFor="company_name">Company Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="web_link" id="web_link" placeholder="Profile weblink / LinkedIn" />
                <label htmlFor="web_link">Profile weblink / LinkedIn</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
         		<TextAreaAutoResize Id={"summary"} label_value={"Executive Summary of profile"} />
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-check text-start">
                <input className="form-check-input" name="terms_of_service" type="checkbox" id="terms_of_service" required />
                <label className="form-check-label fw-bold" htmlFor="terms_of_service">
                  I accept the <a href='https://www.pharmalancers.com/pharmalancers-members-terms-conditions' target='_blank'>Terms of Service</a> & <a href='https://www.pharmalancers.com/privacy-policy' target='_blank'>Privacy Policy</a> of the marketplace

                </label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <button type="submit" ref={buttonRef} className="btn btn-primary btn-block w-100">Sign Up</button>
            </div>
          </div>

        </form>
        <Modal setModal={setModal} setShow={setShow} />
      </div>
    </div>
  );
}

export default Signup;
