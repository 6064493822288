import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../store'
import EditEducation from './EditEducation'

const ViewEducation = (props) => {

  const globalState = useContext(store);

  const [ educations, setEducations ] = useState([])
  const [showModal, setShowModal] = useState( false )
  const [modal, setModal] = useState(null);

  const fetchData = async () => {
    const response = await fetch('/api/v1/educations/index', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setEducations( responseJson )
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  useEffect(() => {
    if (modal)
      showModal ? modal.show() : modal.hide()
    fetchData()
  }, [modal, showModal])

  const educationsHTML = educations.map( (education) => {
    return(
      <li key={education.id}>
        {education.qualification}, {education.institution}, {education.start_formatted} - {education.end_formatted}
      </li>
    )

  });

  return (
    <div className="card mt-3">
      <div className="card-header d-flex align-items-center justify-content-between">
        <h4 className='m-0'><b>Education & Qualifications</b></h4>
        {
        	props.display_public_profile == true ?
        		null
        		:
	        	<button type="button"
	          className="btn btn-primary no-print"
	          onClick={ () => setShowModal( !showModal ) }
	        >Edit</button>	       
        }
      </div>
      <div className="card-body">
        <div className="text-end">
          <ul className="text-start">
            {educationsHTML}
          </ul>
          <EditEducation setShow={setShowModal} setModal={setModal}/>
        </div>
      </div>
    </div>


  );
}

export default ViewEducation;
