import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../components/Modal'
import CustomEditor from '../../components/CustomEditor'

const AddMessage = (props) => {

  const globalState = useContext(store);
  const formEl = useRef();
  const formId = 'add-message-form'
  const [ attachment, setAttachment ] = useState('')
  const [ value, setValue] = useState('')


  const handleFormSubmit = async (event) => {
    if (event) event.preventDefault();

    const formData = {
      message: {
        title: event.target.title.value,
        message: value,
      }
    }  

    if (event.target.attachment.files[0]){
      formData.message.attachment = attachment
      formData.message.attachment_name = `${event.target.attachment.files[0].name}`
    }

    if(event.target.attachment.value == '' || event.target.attachment.files[0].size < 1000000)
    {
    	const response = await fetch( '/api/v1/message', {
          headers: {
            'Content-Type': 'application/json',
            'Authorization': globalState.state.Authentication
          },
          method: 'post',
          body: JSON.stringify(formData)
        })
        const message = await response.json()
      
        if (response.ok) {
          props.setShow(false);
          setValue('')
        } else {    	
          resetErrors( event.target.elements );
          showErrors( event.target.elements, message );
        }
      }
  }

  const uploadAttachment = (e) => {  	
  	let message = "File size is too big max 10 MB" ;
		if( e.target.files[0].size > 1000000){
      e.target.classList.add("is-invalid");
      e.target.parentNode.querySelector('.invalid-feedback').innerText = message
      const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }
    else
    {
    	e.target.classList.remove("is-invalid");
    	const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }    
  }

  function handleChange(newValue) {
    setValue(newValue);
  }

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    resetErrors( elements )
    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
    });
  }

  function resetForm() {
    document.getElementById(formId).reset();
  }

  useEffect( () => {
    resetForm()
  }, [props.show]);

  return (
    <Modal setModal={props.setModal} setShow={props.setShow} id="Add-Message-Modal" title='Post New Message' formId={formId} ActionButton={"Post"}>
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">
          <div className="col-md-12">
            <div className="form-floating">
              <input type="text" className="form-control" name="title" id="title" placeholder="Title" required />
              <label htmlFor="title">Title</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="text-start">
              <label htmlFor="message">Message</label>
            </div>
            <div className="text-start">
              <CustomEditor id="message" value={value} onChange={handleChange} />
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="mb-3 text-start">
              <label htmlFor="attachment" className="form-label">Select Attachment (Optional)</label>
              <input className="form-control" name="attachment" type="file" id="attachment" onChange={(e) => uploadAttachment(e)} />
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default AddMessage;
