import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'



const ProjectRFP = (props) => {

	const globalState = useContext(store);
	const { dispatch } = globalState;
	let history = useHistory()


  const [ projectList, setProjectList] = useState([])

	const fetchData = async () => {

	  const response = await fetch('/api/v1/rfps_index', {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  console.log(responseJson)

	  if (response.ok) {
	    setProjectList(responseJson)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	useEffect( () => {
	  fetchData();
	}, []);

	const copyProject = async (project_id) => {
  	dispatch({ type: 'project',project_id: project_id});
  	history.push({pathname:"/new-project", state:{copyProjectState:true}});
  }

	const ProjectList = projectList.map( project => {
		if(project.status == 'posted')
		{
			return (
      	<tr key={project.id}>
		      <td><a className="link" target='_parent' href={"/project-info?project_id=" + project.id}>{project.title}</a></td>
		      <td>{project.company.name}</td>
		      <td>{project.creator}</td>
		      <td>{project.update_date}</td>
		      <td>{project.proposal_due_date_dv}</td>
		    </tr>
    	)
		}

  })

  return (
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="briefcase-medical" /> Project RFPs</h2>
        </div>
    	</div>
    	<div className="col-md mt-4">
    		<table className="table table-bordered table-striped table-sm text-start">
				  <thead>
				    <tr>
				      <th scope="col">Project Title</th>
				      <th scope="col">Company</th>
				      <th scope="col">Posted by</th>
				      <th scope="col">Date Posted</th>
				      <th scope="col">Submission Deadline</th>				      
				    </tr>
				  </thead>
				  <tbody>
			    	{ProjectList}
				  </tbody>
				</table>

    	</div>
    </div>
  );
}

export default ProjectRFP;
