import React, { useState, useEffect, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ServicesDisplay from '../ServicesDisplay';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'



const ProjectInformationPop = (props) => {

const globalState = useContext(store);
const { location } = useLocation();
const { dispatch } = globalState;
const [ projectDetails, setProjectDetails ] = useState({})
const [ consultantData, setConsultantData ] = useState({})
const [ companyData, setCompanyData ] = useState({})
const [ shortlistedConsultant, setShortlistedConsultant ] = useState([])
let history = useHistory()

const [modal, setModal] = useState(null);
const [consultantProfileId, setConsultantProfileId] = useState(null);
const [showModal, setShow] = useState( false )
const [showCompanyProfile, setShowCompanyProfile] = useState( false );
const [modalCompanyProfile, setModalCompanyProfile] = useState(null);
const [fetchEvent, setFetchEvent] = useState(false);
const [showClientModal, setShowClientModal] = useState( false );
const [modalClientPublicProfile, setModalClientPublicProfile] = useState(null);


const fetchData = async () => {

	if(props.show)
	{
		const response = await fetch('/api/v1/project_details/' + props.project_detail_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  if (response.ok) {
	    setProjectDetails(responseJson)
	    setCompanyData(responseJson.company)
	    setShortlistedConsultant(responseJson.shortlisted_consultants)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }

  	const getData = {
      project_detail_id: props.project_detail_id ,
    }

    const specialisation_response = await fetch('/api/v1/show_project_detail_specialisations/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(getData)
    })

    const specialisation_responseJson = await specialisation_response.json()

    if(specialisation_response.ok)
    {
    	setConsultantData(specialisation_responseJson)
    }
	}



	}

	useEffect( () => {
	  fetchData();
	}, [props.show]);





	const Listing = ( props ) => {
	  if(props.list?.length < 1)
	    return <div><ul>{props.noListMsg}</ul></div>;

	  const list = props.list?.map( (name, i) => {
	    return <li key={i}>{name.label}</li>
	  })
	  return <ul>{list}</ul>;
	};


  return (
  	<Modal setModal={props.setModal} setShow={props.setShow} id="project_details" summary={true} title='Project Requirements' project_consultant={true} >
	    <div className="container" id="container_id">
	    	<div className="modal-body">
	        <div className="card">
	          <div className="card-header text-start">
	            <h3><b>{projectDetails.title}</b></h3>
	          </div>
	          <div className="card-body text-start">
	            <div className="card-text">
	              {ReactHtmlParser(projectDetails.description)}
	            </div>
	          </div>
	        </div>

	        <div className="card mt-3">
	          <div className="card-header text-start">
	            <h4><b>Service Offerings</b></h4>
	          </div>
	          <div className="card-body text-start">
	            <div>
	              <p className="text-muted">The main pharmaceutical function(s) in which the project requires specialist services</p>
	            </div>
	            <ul>
	              <p><strong>Primary Service:</strong></p>
	              <strong>{projectDetails.primary_service} <i>({projectDetails.primary_service_exp})</i></strong>
	              <ul>
									<ServicesDisplay services={projectDetails.primary_sub_services}/>
	              </ul>
	            </ul>
	          </div>
	        </div>

	        <div className="card mt-3">
	          <div className="card-header text-start">
	            <h4><b>Specialisations</b></h4>
	          </div>
	          <div className="card-body text-start">

	            <div>
	              <p className="text-muted">The Therapeutic Areas and Product types in which the project requires specific expertise or experience.</p>
	            </div>
	            <ul>
	              <strong>Therapeutic Areas</strong>
	              <Listing list={consultantData.therapeutic} noListMsg="None selected" />
	              <hr/>
	              <strong>Product Specialisations</strong>
	              <Listing list={consultantData.product} noListMsg="None selected" />
	              <hr/>
	              <strong>Service Offering Specialisations</strong>
	              <Listing list={consultantData.service} noListMsg="None selected" />
	              <hr/>
	              <strong>Languages Known (in addition to English)</strong>
	              <Listing list={consultantData.languages} noListMsg="None selected" />
	            </ul>
	          </div>
	        </div>
	        <div className="card mt-3">
	          <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Logistics</b></h4>
	          <div className="card-body text-start">
		          {
		          	projectDetails.consultant_country != '' ?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Consultant Country : </strong>
			            <h6 className="col-md-7">{projectDetails.display_consultant_country}</h6>
			          </div>
			          :
			          <div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Consultant Country : </strong>
			            <h6 className="col-md-7">None</h6>
			          </div>
		          }
		          <div className="row g-0 text-start mt-1">
		            <strong className="col-md-5">Project Location : </strong>
		            <h6 className="col-md-7">{projectDetails.location_type_dv}</h6>
		          </div>
		          {
		          	projectDetails.location_type_dv == "Fully On-site" || projectDetails.location_type == "mix"?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Onsite City : </strong>
			            <h6 className="col-md-7">{projectDetails.onsite_city}</h6>
			          </div>
			          :
			          null
		          }
		          {
		          	projectDetails.location_type_dv == "Fully On-site" || projectDetails.location_type == "mix" ?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Onsite Country : </strong>
			            <h6 className="col-md-7">{projectDetails.display_onsite_country}</h6>
			          </div>
			          :
			          null
		          }
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Receipt of Proposals : </strong>
		            <h6 className="col-md-7">{projectDetails.proposal_due_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Selection of Consultant : </strong>
		            <h6 className="col-md-7">{projectDetails.selection_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Project Start : </strong>
		            <h6 className="col-md-7">{projectDetails.start_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Estimated project duration : </strong>
		            <h6 className="col-md-7">{projectDetails.estimated_duration} {projectDetails.estimated_duration_category}</h6>
		          </div>
		        </div>
	        </div>
	        <div className="card mt-3">
	          <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees</b></h4>
	          <div className="card-body text-start">
		          <div className="row g-0 text-start mt-1">
		            <strong className="col-md-5"> Project fees type : </strong>
		            <h6 className="col-md-7">{projectDetails.fees_type_dv}</h6>
		          </div>

		          {projectDetails.currency == 'Other' ?
		          	<div className="row g-0 text-start mt-2">
			            <strong className="col-md-5">Currency : </strong>
			            <h6 className="col-md-7">{projectDetails.other_currency}</h6>
			          </div>
			          :
			          <div className="row g-0 text-start mt-2">
			            <strong className="col-md-5">Currency : </strong>
			            <h6 className="col-md-7">{projectDetails.currency}</h6>
			          </div>
		          }

		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Rate / Hour Required : </strong>
		            <h6 className="col-md-7">{projectDetails.is_rate_required ? "Yes" : "No"}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Estimated total project hours required? : </strong>
		            <h6 className="col-md-7">{projectDetails.is_estimation_required ? "Yes" : "No"}</h6>
		          </div>
		        </div>
	  	    </div>

	    	</div>
	    </div>
    </Modal>
  );
}

export default ProjectInformationPop;
