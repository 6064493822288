import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'

const InvoiceView = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ invoiceDetails, setInvoiceDetails ] = useState({})
	const [ company, setCompany] = useState({})
	const [ consultantDetails, setConsultantDetails ] = useState({})
	let history = useHistory()


	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	const fetchData = async () => {
	  const response = await fetch('/api/v1/invoice_details/' + my_data.invoice_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  console.log("responseJson")
	  console.log(responseJson)

	  if (response.ok) {
	    setInvoiceDetails(responseJson)		
	    setConsultantDetails(responseJson.consultant_name) 
	    setCompany(responseJson.company) 

	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	useEffect( () => {
	  fetchData();
	}, []);




  return (
    <div className="container ">
    	

     <div className="d-flex align-items-center mt-3">
      	<div className="col-md-3">	      	
	      </div>
	      <div className="text-center col-md-6">
	      	<h2>Invoice Details</h2>
	      </div>      	
    		<div className="text-end col-md-3">
          <button  type="button" className="btn btn-primary btn-block ml-auto no-print" onClick={() => window.print()} >Print</button>
        </div>      	
    	</div>
    	
    	<div className="card mt-4">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Details</b></h4>
        <div className="card-body text-start">
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Title : </strong>
            <h6 className="col-md-9 ">{invoiceDetails.title}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Company Name : </strong>
            <h6 className="col-md-8">{company.name}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Consultant Name : </strong>
            <h6 className="col-md-9">{consultantDetails.first_name} {consultantDetails.last_name}</h6>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Invoice Details</b></h4>
        <div className="card-body text-start">
          <div className="row g-0 mt-2">
            <strong className="col-md-3">Invoice Number	 : </strong>
            <h6 className="col-md-9 ">{invoiceDetails.invoice_number}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Invoice Date (Consultant)	: </strong>
            <h6 className="col-md-8">{invoiceDetails.invoice_date}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Invoice Name : </strong>
            <h6 className="col-md-9">{invoiceDetails.name}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Currency : </strong>
            <h6 className="col-md-9">{invoiceDetails.currency}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Consultant Fees :</strong>
            <h6 className="col-md-9">{invoiceDetails.engagement_fees}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Consultant Fees Tax : </strong>
            <h6 className="col-md-9">{invoiceDetails.engagement_fees_tax}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Pharmalancers Fees : </strong>
            <h6 className="col-md-9">{invoiceDetails.pharmalancers_fees}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Pharmalancers Fees Tax : </strong>
            <h6 className="col-md-9">{invoiceDetails.pharmalancers_fees_tax}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Total Tax : </strong>
            <h6 className="col-md-9">{invoiceDetails.total_tax}</h6>
          </div>
         	<div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Total Fees With Tax : </strong>
            <h6 className="col-md-9">{invoiceDetails.total_fees_with_tax}</h6>
          </div>
        </div>
      </div>
    </div>

  );
}

export default InvoiceView;
