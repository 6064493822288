import React, { useContext, useRef, useState, useEffect } from 'react';
import { store } from '../../store'

import FloatingSelect from '../../components/FloatingSelect';
import Modal from '../../components/Modal';
import CustomEditor from '../../components/CustomEditor'


const EditSpecialisation = (props) => {

  const formEl = useRef();

  const globalState = useContext(store);
  const [ userData, setUserData ] = useState({})
  const [ image, setImage ] = useState('')
  const [ message, setMessage ] = useState('')
  const [value, setValue] = useState('');
  const [ error, setError ] = useState("")



  const formId = 'edit-profile-form'

  const fetchData = async () => {
    const response = await fetch('/api/v1/consultant_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    
    if (response.ok) {
    	setError('')
      setUserData(responseJson)
      setValue(responseJson.summary)
      formEl.current.reset();
      resetErrors(formEl.current)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, [props.show]);

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    resetErrors( elements )
    var error_message = 'consultant_profile.summary'
    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
      else
      {      	
      	var error = "Summary "+errors[error_message]      	
      	setError(error)
      }
    });
  }


  const handleFormSubmit = async (event) => {
    event.preventDefault()

    if(value.replace(/<\/?[^>]+(>|$)/g, "") != "")
    {
  		const formData = {
	      user: {
	        country: event.target.country.value,
	        city: event.target.city.value,
	        consultant_profile_attributes: {
	          id: userData.id,
	          summary: value,
	        }
	      }
	    }

	    if (event.target.image.files[0]){
	      formData.user.image = image
	      formData.user.image_name = `profile_pic.${event.target.image.files[0].type.split('/')[1]}`
	    }

	    const response = await fetch( '/api/v1/users', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()

	   	if (response.ok) {
	   		setError('')
	      props.setShow(false);
	    } else {
	      showErrors( event.target.elements, responseJson );
	    }
    	    		    
	  }
	  else
	  {
	  	setError("Summary can't be blank")
	  }
  }

  const uploadImage = (e) => {
  	e.target.classList.remove("is-invalid");
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setImage( reader.result ) ;
    reader.readAsDataURL(file);
  }

  const handleCountry = (e) => {
    const user = userData
    user.user.country = e.target.value
    setUserData( {...userData } )
  }

  function handleChange(newValue) {  	
  		setValue(newValue);    
  }

  return(
    < Modal setModal={props.setModal} setShow={props.setShow} id="modal-edit-user" title='Edit Details' formId={formId} >
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">

        <div className="row g-2">
          <div className="mb-3">
            <label htmlFor="image" className="form-label">Select profile image</label>
            <input className="form-control" name='image' type="file" id="image" onChange={(e) => uploadImage(e)} />
            <div className="invalid-feedback"></div>
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            < FloatingSelect data={userData?.user?.countries} name='country' label='Country' required={true}
              onChange={e => handleCountry(e)} value={userData?.user?.country} placeholder='Select Country' />
            <div className="invalid-feedback"></div>
          </div>

          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" defaultValue={userData?.user?.city}
                name="city" id="city" placeholder="City" required />
              <label htmlFor="city">City</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>

        </div>

        <div className="text-start">
          <CustomEditor id="summary" value={value} placeholder={"Executive Summary of profile"} onChange={handleChange} /> 
        	{error != "" ?
          	<p className="text-danger text-start">{error}</p>
          	:
          	null
          }
        </div>

      </form>
    </Modal>
  )

}

export default EditSpecialisation;
