import React, { useContext, useEffect, useState, useRef, forwardRef} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TabCards from '../../components/TabCards';
import ProjectPreview from './ProjectPreview';
import Proposals from './Proposals';

import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const ProjectDetailsView = (props) => {

  const globalState = useContext(store);
	const [ createNewProject, setCreateNewProject ] = useState(false)
  const [ defaultOptions, setDefaultOptions ] = useState(false)
  const [ findConsultant, setFindConsultant ] = useState(false)
  const { state, location } = useLocation();
  const [ projectDetails, setProjectDetails ] = useState({})


  const [activeId, setActiveId] = useState(1)
  let history = useHistory()

  if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}  

	const my_data =  JSON.parse(localStorage.getItem('document'));
	var url_token = window.location.href.split("=");

  const commonProps = { activeId: activeId, setActiveId: setActiveId }

  const tabs = [
    { id: 1, name: 'Project Requirements', component: ProjectPreview, props: commonProps },
    { id: 2, name: 'Proposals', component: Proposals, props: commonProps }
    
  ]
  
 
  const formEl = useRef();

  const handleProjectsButton= async () => {
		if(findConsultant)
		{
			setFindConsultant(false)
		}
		else if(createNewProject)
		{
			setCreateNewProject(false)
		}
		else
		{
			setDefaultOptions(false)
		}
	}


	const fetchData = async () => {

		if(url_token[1] != undefined)
		{
			const response = await fetch('/api/v1/project_details/' + url_token[1], {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })
		  const responseJson = await response.json()
		  
		  if (response.ok) {
		    setProjectDetails(responseJson)
		    
		  } else {
		    alert.show("Server is down.")
		    return false
		  }
		}
		else
		{
			const response = await fetch('/api/v1/project_details/' + my_data.projectId, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })
		  const responseJson = await response.json()
		  
		  if (response.ok) {
		    setProjectDetails(responseJson)
		   
		  } else {
		    alert.show("Server is down.")
		    return false
		  }
		}
	}

	useEffect( () => {		
	  fetchData();	  	  
	}, []);

  
 
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <div className="container">      	
      	<div className="d-flex align-items-center justify-content-between">
          <div className="text-center">
          </div>
          <div className="text-center">
            <h2><b>{projectDetails.title}</b></h2>						
          </div>
          <div className="text-end">
            <button onClick={()=> history.goBack()} type="button"
	              className="btn btn-primary"	              
            >Back</button>
          </div>
        </div> 
      	<TabCards
		      tabs={tabs}
		      activeId={activeId}
		      setActiveId={setActiveId}				      
		      projectStep={true}
		    />      
      </div>
    </div>

  );
}

export default ProjectDetailsView;
