import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import { Link, useHistory, useLocation } from 'react-router-dom';

import SearchProfileCard from '../../components/SearchProfileCard'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const SearchForProject = (props) => {


	const { state } = useLocation();

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}

	const my_data =  JSON.parse(localStorage.getItem('document'));

  const [ selectedConsultant, setSelectedConsultant ] = useState([])
  const globalState = useContext(store);
  const [ users, setUsers ] = useState([])
  const [ nameStarts, setNameStarts ] = useState('abcd')
  const [ selecteAll, setSelecteAll ] = useState(false)
  const [ message, setMessage ] = useState('')
  const [ service, setService ] = useState('')
  const [ sub_service, setSub_service ] = useState('')
  const [ therapeutic, setTherapeutic ] = useState('')
  const [ product, setProduct ] = useState('')
  const [ sub_service_with_exp, setSub_service_with_exp ] = useState('')
  const [selectionData, setSelectionData] = useState([]);  
  const [selectedConsultantId, setSelectedConsultantId] = useState(my_data.shortlisted_consultant);


  let history = useHistory()

  console.log("Render again parent component")

  const handleFormSubmit = async (service,sub_service,therapeutic,product, sub_service_with_exp) => {

  	setService(service)
  	setSub_service(sub_service)
  	setTherapeutic(therapeutic)
  	setProduct(product)
  	setSub_service_with_exp(sub_service_with_exp)

    const formData = {
      service: service,
      sub_service_with_exp: sub_service_with_exp,
			sub_service: sub_service,
			therapeutic: therapeutic,
			product:product,
			country:my_data.projectDetails.consultant_country,
			primary_service_exp : my_data.projectDetails.primary_service_exp_code
    }

    const response = await fetch( '/api/v1/search_algo', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })

    const profiles = await response.json()

    if(profiles.length == 0)
    {
    	setMessage("No Records Found")
    }
    if (response.ok) {
    	setUsers(profiles.map(profile => ({ ...profile, selected: false })))
    
    	if(selectedConsultantId.length >= 0)
    	{
    		var profile_array = [];

    		profiles.map((user)=>
    		{
    			console.log(user.id)
    			selectedConsultantId.map((user_id)=>
    			{
    				if(user_id === user.id)
    				{
    					user.selected = true; 
    					profile_array.push(user);   					
    				}    				
    			});

    			if(!user.selected)
    			{
    				profile_array.push(user);
    			}
    		});
    		setUsers(profile_array);
    	}
      setSelectedConsultant(profiles.map(profile => ({ ...profile, selected: true })))
      setSelectionData(profiles.map(profile => ({ ...profile, selected: false })));
    } else {
    }
  }

  const all_ids= users.map((user)=>{
  	return user.id
  })

  useEffect( () => {
  	var main_service = ''
  	var sub_service = []
  	var sub_service_with_exp = []
  	var therapeutic = []
  	var product = []
  	const algorithm_service = my_data.projectDetails.project_detail_services.map((service)=>
  	{
  		if(service.category == "primary" && main_service == '')
  		{
  			main_service = service.service_id
  		}
  		else
  		{
  			var exp_years = [4,9,20,21]
  			var sub_service_exp = Number(service.years_of_experience)

  			exp_years.map(val =>
  			{  				
  				if( val >= sub_service_exp )
  				{
  					sub_service_with_exp.push(service.service_id+'-'+val)
  				}
  			})

  			sub_service.push(service.service_id)  			
  		}
  	})

  	const algorithm_specialisation = my_data.projectDetails.project_detail_specialisations.map((specialisation)=>
  	{
  		if(specialisation.specialisation_id == 1)
  		{
  			therapeutic.push(specialisation.sub_specialisation_id)
  		}
  		else if(specialisation.specialisation_id == 13)
  		{
  			product.push(specialisation.sub_specialisation_id)
  		}
  	})

    handleFormSubmit(main_service,sub_service,therapeutic,product,sub_service_with_exp);
  },[]);



  const updateCheckStatus = async(index,id) => {

  	var array_Id = selectedConsultantId;  	

  	if(array_Id.includes(id))
  	{  		
  		function removeId(array_Id) {
			  return array_Id != id;
			}
			setSelectedConsultantId(array_Id.filter(removeId));
  	}
  	else
  	{
  		array_Id.push(id)
  		setSelectedConsultantId(array_Id);
  	}  	

    setUsers(
      users.map((user, currentIndex) =>
        currentIndex === index
          ? { ...user, selected: !user.selected }
          : user
      )
    )
    setSelecteAll(false);
    var count = 0;   

  }


  const selectAll = async() => {
  	if(selecteAll)
  	{  		
  		setUsers(selectionData)  		
  		setSelecteAll(false);
  		setSelectedConsultantId([])
  	}
  	else if(!selecteAll)
  	{
  		setUsers(selectedConsultant);  		
  		setSelecteAll(true);
  		setSelectedConsultantId(all_ids)
  	} 	
    
  }


  const usersData = users.map( (u,index) => {
    return (
      <SearchProfileCard
        key={u.id}
        name={u.user.first_name + ' ' + u.user.last_name.substring(0, 1)}
        image_url={u.user?.image_url}
        city={u.user.city}
        country={u.user.country_name}
        service={u.primary_service}
        service_exp={u.primary_service_exp}
        education={u.main_education}
        summary={u.summary}
        userData={u}
        index={index}
        selectable={true}
        updateCheckStatus={updateCheckStatus}
        selectedConsultantId={selectedConsultantId}
        project_consultant={true}
      />
    )
  })
 


  const saveSelectedConsultant = async () => {

    const formData = {
      project_detail_id: my_data.projectId,
      consultant_profile_ids: selectedConsultantId
    }
    const response = await fetch( '/api/v1/shortlisted_consultants', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const response_data = await response.json()

    if (response.ok) {
      history.push({pathname:"/project-view", state:{ selected_consultant:true,projectId:my_data.projectId,wip:true}});
    } else {

    }
  }

  return (

    <div>
    	<div className="border-bottom mt-3">
     		<div className="row">
	      	<h2 className="text-center">{my_data.projectDetails.title}</h2>
	      </div>
	      <div className="row align-items-center mb-2 mt-2">
	      	<div className="col-md-4">
	      	</div>
	      	<h4 className="text-center col-md-4">{users.length} consultants matched</h4>
	      	<div className="col-md-4 text-end">
	          <input onChange={()=> selectAll()} checked={selecteAll} className="form-check-input text-end" name="select_all_consultant" type="checkbox" id="select_all_consultant" />
	          <label className="form-check-label ms-1" htmlFor="select_all_consultant">Select All</label>
		      </div>
	      </div>
      </div>
        {
        	(users.length == 0)?
        	<div className="overflow-search-scorll">
        		<h5 className="mt-5">
	        		{message}
	        	</h5>
	        </div>
        	:
        	<div className="row d-flex align-items-center overflow-search-scorll search-with-name">
        		{usersData}
        	</div>
        }
        
      <div className="row-g-2 mt-4 p-2">
      	<div className="row g-2">
        	<div className="col-md g-2 text-start">
            <button  type="button" onClick={()=> history.push({pathname:"/project-view", state:{ selected_consultant:true,projectId:my_data.projectId,wip:true}})} className="btn btn-secondary btn-block ml-auto" >Back</button>
          </div>
          <div className="col-md g-2 text-end">
            <button type="submit" onClick={()=> saveSelectedConsultant()} className="btn btn-primary btn-block ml-auto">Select {selectedConsultantId.length} Consultant</button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchForProject;
