import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../../store'
import Modal from '../../components/Modal';
import EducationComp from './EducationComp';



const EditEducation = (props) => {

  const globalState = useContext(store);
  const formEl = useRef();

  const formId = 'edit-education-form'

	const [ education, setEducation ] = useState([
    {id:0, start_date: null, end_date: null, qualification: '', institution: '' ,category:''}]
  )

  const [ errors, setErrors ] = useState({})

  const fetchData = async () => {

    const response = await fetch('/api/v1/educations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
   
    if (response.ok) {    	
      const education_array = [];
      responseJson.map(data=>
      {
        if (data.start_date != null)
        {
          var new_date = new Date(data.start_date)
          data.start_date = new_date ;
          new_date = new Date(data.end_date)
          data.end_date = new_date;
          education_array.push(data)
        }
        else
        {
          education_array.push(data)
        }
      })
      setErrors({})
    	setEducation(education_array)      
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const handleFormSubmit = async (e) => {
  	event.preventDefault();
    const formData = { educations: education }
    const response = await fetch( '/api/v1/educations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()
    if (response.ok) {
      props.setShow(false)
      setErrors({})
    } else {
      setErrors(responseJson.errors)
    }
  }
  
  let errorsHtml = ''

  Object.entries(errors).forEach(([key, messages]) => {
    errorsHtml = messages.map((message, index) =>{
      return (
        <div key={index} className="alert alert-danger" role="alert">
          {message}
        </div>
      )
    });
  });

  const performSubmit =() => { handleFormSubmit(); }

  return (
    <Modal setModal={props.setModal} setShow={props.setShow} id="modalEditEducations" title='Edit Education & Qualifications' formId={formId} >
      <div className="row g-3">
        <div className="col-md text-start">
          {errorsHtml}
        </div>
      </div>
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <EducationComp value={education} setValue={setEducation}/>
      </form>
    </Modal>
  );
}

export default EditEducation;
