import React, { createContext, useReducer } from 'react';

const getUserData = () => {
  if (localStorage.getItem('user')){
    return JSON.parse(localStorage.getItem('user'));
  } else if (sessionStorage.getItem('user')) {
    return JSON.parse(sessionStorage.getItem('user'));
  }
  return { 
    loggedIn: false, 
    Authentication: "",
    firstName: "",
    lastName: "",
    email: "",
    redirectUrl: ""
  };
}

const clearUserData = () => {
  localStorage.removeItem("user");
  sessionStorage.removeItem("user");
}

const setUserData = (userData) => {
  if (userData.remember){ return localStorage.setItem('user', JSON.stringify(userData)); }
  return sessionStorage.setItem('user', JSON.stringify(userData));
}

const initialState = getUserData()
const store = createContext(initialState);
const { Provider } = store;

const StateProvider = ( { children } ) => {
  const [state, dispatch] = useReducer((state, action) => {
    switch(action.type) {
      case "login":
        const newLoginState = {}
        newLoginState.loggedIn = action.newState.loggedIn
        newLoginState.Authentication = action.newState.Authentication
        newLoginState.firstName = action.newState.firstName
        newLoginState.lastName = action.newState.lastName
        newLoginState.email = action.newState.email
        newLoginState.redirectUrl = state.redirectUrl
        newLoginState.completed = action.newState.completed
        newLoginState.user_type = action.newState.user_type
        newLoginState.remember = action.newState.remember
        newLoginState.access_role = action.newState.access_role
        newLoginState.company_name = action.newState.company_name
        newLoginState.last_login = action.newState.last_login
        setUserData( newLoginState );
        return newLoginState;
      case "logout":
        const newLogoutState = {}
        newLogoutState.loggedIn = false
        newLogoutState.Authentication = ""
        newLogoutState.firstName = ""
        newLogoutState.lastName = ""
        newLogoutState.email = ""
        newLogoutState.redirectUrl = ""
        newLogoutState.completed = ""
        newLogoutState.user_type = ""
        newLogoutState.remember = ""
        newLogoutState.access_role = ""
        newLogoutState.company_name = ""
        clearUserData();
        return newLogoutState;
      case "project":
        const projectState = {...state, project_id:action.project_id}
        setUserData( projectState );
        return projectState;
      case "setRedirectUrl":
        state.redirectUrl = action.newState.redirectUrl
        return state;
      case "completed":
        const newState = {...state, completed: true, user_type:action.user_type}
        setUserData( newState );
        return newState;
      default:
        throw new Error();
    };
  }, initialState);

  return <Provider value={{ state, dispatch }}>{children}</Provider>;
};

export { store, StateProvider }