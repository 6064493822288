import React, { useRef, useEffect } from 'react';
import { Modal } from 'bootstrap';

const ModalCustom = (props) => {
  const modalRef = useRef(null);

  useEffect(() => {
    let bsModal = new Modal(modalRef.current, {
      keyboard: false,
      backdrop: 'static'
    })
    props.setModal( bsModal )
  }, [])

  const submitButton = () => {
    if ( props.formId )
      return <button type="submit" form={props.formId} className="btn btn-primary">{props.ActionButton? props.ActionButton : "Save changes"}</button>

    return <button type="button" className="btn btn-primary" onClick={props.triggerSumbit}>Save</button>
  }

  const cancelModal = () =>
  {  	
  	if(props.id == "changePassword" || props.id == "Setup-User-Modal")
  	{
  		props.onCancel(true)
  	}
  	props.setShow(false)
  	
  }

  return (
    <div ref={modalRef} className="modal fade" id={props.id} tabIndex="-1" aria-labelledby="modal" aria-hidden="true" >
      <div className={"modal-dialog modal-xl modal-dialog-centered " + (props.summary ? "modal-dialog-scrollable" : "")} >
        <div className="modal-content">
          <div className="modal-header">
            <h5 className="modal-title">{props.title}</h5>
            <button type="button" className="btn-close" onClick={ () => cancelModal() } aria-label="Close"></button>
          </div>
          <div className="modal-body">
            { props.children }
          </div>
          {props.summary && !props.project_consultant ?
            <div className="modal-footer">
            	{props.savedConsultant ?
              	<button type="button" className="btn btn-primary" onClick={() => props.handleSaveConsultant('remove')}>Unsave Consultant</button>
              	:
              	<button type="button" className="btn btn-primary" onClick={()=> props.handleSaveConsultant('save')}>Save Consultant</button>

            	}
            </div>
            :!props.project_consultant ?
            <div className="modal-footer">
              <button type="button" className="btn btn-secondary" onClick={ () => cancelModal() } >Cancel</button>
              {submitButton()}
            </div>
            :null
          }
        </div>
      </div>
    </div>
  );
}

export default ModalCustom;
