import React, { useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store'
import { Link } from 'react-router-dom';
import SetupUser from './manageusers/SetupUser';
import BtnCellRenderer from './manageusers/BtnCellRenderer';
import axios from "axios";
//import { agData } from "./agdata";
//var agData = require('./agdata.json');
//const json = require('json-loader!./agdata.json');
//import agdata from './agdata.json';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';

import 'ag-grid-community/dist/styles/ag-grid.css';
import 'ag-grid-community/dist/styles/ag-theme-alpine.css';
import { confirmAlert } from 'react-confirm-alert';


const DataListing = (props) => {

const globalState = useContext(store)
const [gridApi, setGridApi] = useState(null);
const [gridColumnApi, setGridColumnApi] = useState(null);
const [ coldef, setColdef ] = useState([])
const [ rowdata, setRowdata ] = useState({})
const [ selectedRowdata, setSelectedRowdata ] = useState([])
const [ onchangeRowdata, setOnchangeRowdata ] = useState([])
const [isGridReady,setIsGridReady] = useState(false)
const [menu,setMenu]=useState('')
const [showModalSetupUser, setShowModalSetupUser] = useState( false )
const [modalSetupUser, setModalSetupUser] = useState(null)
const formEl = useRef()
const [isEdit, setIsEdit] = useState(false)
const [userData, setUserData] = useState([])
const [title, setTitle] = useState('')
const [country, setCountry] = useState('')
const [userType, setUserType] = useState('')



const fetchData = async () => {

     const users_list = await fetch('/api/v1/index', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const users_response = await users_list.json()

    if(users_list.ok)
    {
    	setUserData(users_response)
    }
    else {
      alert.show("Server is down.")
      return false
    }
  }


  useEffect( () => {
    fetchData();    
  }, []);

  useEffect(() => {
    if (modalSetupUser)
      showModalSetupUser ? modalSetupUser.show() : modalSetupUser.hide()
    	fetchData();
  }, [modalSetupUser, showModalSetupUser])

 
  const delete_user_alert = async (user_id, action) => 
  {

  	if(action == 'inactive')
  	{
  		confirmAlert({
		  	customUI: ({ onClose }) => {
			    return (
			      <div className='p-5 custom-ui border border-primary rounded'>
			        
			        <h5>Deactivate this User?</h5>
			        <div className="row g-2 mt-3">
				        <div className="col-md g-2 text-start">
				        	<button className="btn btn-danger" onClick={onClose}>No</button>
				        </div>
				        <div className="col-md g-2 text-end">
					        <button className="btn btn-primary"
					          onClick={() => {
					            handleDelete(user_id, action);
					            onClose();
					          }}
					        >
					          Yes
					        </button>
					      </div>
					    </div>
			      </div>
			    );
			  }
			});
  	}
  	else
  	{
  		confirmAlert({
		  	customUI: ({ onClose }) => {
			    return (
			      <div className='p-5 custom-ui border border-primary rounded'>
			        
			        <h5>Activate this user?</h5>
			        <div className="row g-2 mt-3">
				        <div className="col-md g-2 text-start">
				        	<button className="btn btn-danger" onClick={onClose}>No</button>
				        </div>
				        <div className="col-md g-2 text-end">
					        <button className="btn btn-primary"
					          onClick={() => {
					            handleDelete(user_id, action);
					            onClose();
					          }}
					        >
					          Yes
					        </button>
					      </div>
					    </div>
			      </div>
			    );
			  }
			});
  	}  
    
  }

  const handleDelete = async (user_id, action) => {
    if (event) {
      event.preventDefault();
    }

    const data = {user_id:  user_id, action_type: action}

    const response = await fetch( '/api/v1/deactivate_user', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
			body: JSON.stringify(data)

    })

    if (response.ok) {
      fetchData();
    } else {
      
    }
  }

  const active_usersList = userData.map( (user, i) =>
  {
  	if(user.status == 'active')
  	{  		
	  	return(
	  		<div className="card mb-3  mt-3" key={user.id}>
		    	<div className="row g-2 m-1 align-items-center justify-content-between">
		      	<div className="col-md text-start ms-4">
							<h6 className="text-start">{user.first_name} {user.last_name}</h6>
						</div>
						<div className="col-md d-flex justify-content-between align-items-center ">
							<h6 className="text-start">{user.access_role.role[0].toUpperCase() + user.access_role.role.slice(1)}</h6>
						</div>
						<div className="col-md d-flex justify-content-between align-items-center ">
							<h6 className="text-start badge rounded-pill bg-success">{user.status[0].toUpperCase() + user.status.slice(1)}</h6>
						</div>
						<div className="col-md mb-2">
							<button
		            className="btn btn-primary btn-block " onClick={ () => {setTitle('Edit User'),setShowModalSetupUser( !showModalSetupUser ), setIsEdit(true), setRowdata(user), setCountry(user.country), setUserType(user.access_role.role) }}           
			          >Edit
			        </button>
			        {
			        	user.status == 'active' ?
			        	<button onClick={() => delete_user_alert(user.id, 'inactive')}
			            className="btn btn-danger btn-block ms-4"	            
				          >Deactivate
				        </button>
				        :
				        <button onClick={() => delete_user_alert(user.id, 'active')}
			            className="btn btn-danger btn-block ms-4"	            
				          >Activate
				        </button>
			        }
			        
						</div>
					</div>
	    	</div>
			);
	  }
  })

  const inactive_usersList = userData.map( (user, i) =>
  {
  	if(user.status != 'active')
  	{  		
	  	return(
	  		<div className="card mb-3  mt-3">
		    	<div className="row g-2 m-1 align-items-center justify-content-between">
		      	<div className="col-md text-start ms-4">
							<h6 className="text-start">{user.first_name} {user.last_name}</h6>
						</div>
						<div className="col-md d-flex justify-content-between align-items-center ">
							<h6 className="text-start">{user.access_role.role[0].toUpperCase() + user.access_role.role.slice(1)}</h6>
						</div>
						<div className="col-md d-flex justify-content-between align-items-center ">
							<h6 className="text-start badge rounded-pill bg-danger">{user.status[0].toUpperCase() + user.status.slice(1)}</h6>
						</div>
						<div className="col-md mb-2">
							<button
		            className="btn btn-primary btn-block " onClick={ () => {setTitle('Edit User'),setShowModalSetupUser( !showModalSetupUser ), setIsEdit(true), setRowdata(user), setCountry(user.country), setUserType(user.access_role.role) }}           
			          >Edit
			        </button>
			        {
			        	user.status == 'active' ?
			        	<button onClick={() => delete_user_alert(user.id, 'inactive')}
			            className="btn btn-danger btn-block ms-4"	            
				          >Deactivate
				        </button>
				        :
				        <button onClick={() => delete_user_alert(user.id, 'active')}
			            className="btn btn-danger btn-block ms-4"	            
				          >Activate
				        </button>
			        }
			        
						</div>
					</div>
	    	</div>
			);
	  }
  })


return(
	<div className="">
		<div className="row g-2">
        <div className="col-md g-2 text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="users-cog" /> Set up and Manage Users </h2>
        </div>
    </div>

    <div className="row g-2">
      <div className="d-flex align-items-center justify-content-between">
        <button type="button"
          className="btn btn-primary font-weight-bold action-button"
          onClick={ () => {setTitle('Set up User Account'),setShowModalSetupUser( !showModalSetupUser ), setRowdata({}), setCountry(''), setUserType(''),  
          setIsEdit(false) }}
        >Set up User Account</button>
      </div>
    </div>


    <div className="row g-2 justify-content-start">
      <SetupUser country={country} userType={userType} setCountry={setCountry} setUserType={setUserType} show={showModalSetupUser} title={title} setShow={setShowModalSetupUser} 
        setModal={setModalSetupUser} isEdit={isEdit} 
        data={rowdata}/>
    </div>
    <div className="mt-4">
    	<div className="text-start">
    		<h4>Manage User Accounts</h4>
    	</div>
	    {active_usersList}
	    {inactive_usersList}
    </div>    
	</div>
	);

}

export default DataListing;
