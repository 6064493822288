import React from 'react';
import NavBar from '../components/NavBar'

function PublicLayout({children}) {

  return (
    <div>
      <NavBar />
      {children}
    </div>
  );
}

export default PublicLayout;
