import React, { useState } from 'react';
import AddJob from '../jobs/AddJob';
import ListJobs from '../jobs/ListJobs';

const JobsStep = (props) => {

  const [fetchEvent, setFetchEvent] = useState(false);
  const [roleCount, setRoleCount] = useState(0)

  return (
    <div>
      <div className="row g-3">
        <div className="col-md text-start">
          <div className="alert alert-info">
            <ul>
              <li>Your CV will be visible to potential clients.</li>
              <li>Add each employment or contracted role that you have held in your career. (We suggest that you include every major role)</li>
              <li>There are no restrictions on the number of roles.</li>
              <li><b>Each field in this section is mandatory.</b></li>
            </ul>
          </div>
        </div>
      </div>
      <AddJob setFetch={setFetchEvent} setActiveId={props.setActiveId} activeId={props.activeId} roleCount={roleCount}/>
      <ListJobs fetch={fetchEvent} setFetch={setFetchEvent} setRoleCount={setRoleCount}/>
    </div>
  );
}

export default JobsStep;
