import React from 'react';

function groupBy(xs, key) {
  return xs.reduce(function(rv, x) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
}

const FloatingSelect = (props) => {
  let groupedData = {}
  if(props.canHaveCategory)
  {

    let groupedData = groupBy(props.data,'category')
    let categories = Object.keys(groupedData)

    if(categories.includes("undefined") || categories.includes("null")){
      return (
        <div className="form-floating">
          <select className="form-select" value={props.value}
            onChange={ props.onChange }
            name={props.name}
            id={props.name}
            aria-label={props.label}
            required={props.required}
            disabled={props.isDisabled ? true : null}>
            <option value=''>{props.placeholder}</option>
            { categories?.map( data =>
                groupedData[data]?.map( grpdata =>
                  <option value={grpdata.value} key={grpdata.value} disabled={props.disabledvalues?.includes(grpdata.value) && grpdata.value != props.value ? true : null} >{grpdata.text}</option>
                )
            ) }
          </select>
          <label htmlFor={props.name}>{props.label}</label>
          {props.withError ? <div className="invalid-feedback"></div> : ''}
        </div>
      );
    } else {

      return (
        <div className="form-floating">
          <select className="form-select" value={props.value}
            onChange={ props.onChange }
            name={props.name}
            id={props.name}
            aria-label={props.label}
            required={props.required}
            disabled={props.isDisabled ? true : null}>
            <option value=''>{props.placeholder}</option>
            { categories?.map( data =>
                  <optgroup label={data}>
                  {
                    groupedData[data]?.map( grpdata =>
                      <option value={grpdata.value} key={grpdata.value} disabled={props.disabledvalues?.includes(grpdata.value) && grpdata.value != props.value ? true : null} >{grpdata.text}</option>
                    )
                  }
                  </optgroup>
            ) }
          </select>
          <label htmlFor={props.name}>{props.label}</label>
          {props.withError ? <div className="invalid-feedback"></div> : ''}
        </div>
      );

    }

  } else {

    return (
      <div className="form-floating">
        <select className="form-select" value={props.value}
          onChange={ props.onChange }
          name={props.name}
          id={props.name}
          aria-label={props.label}
          required={props.required}
          disabled={props.isDisabled ? true : null}>
          <option value=''>{props.placeholder}</option>
          { props.data?.map( data =>
              <option value={data.value} key={data.value} disabled={props.disabledvalues?.includes(data.value) && data.value != props.value ? true : null} >{data.text}</option>
          ) }
        </select>
        <label htmlFor={props.name}>{props.label}</label>
        {props.withError ? <div className="invalid-feedback"></div> : ''}
      </div>
    );
  }

}

export default FloatingSelect;
