import React from 'react';
import FloatingSelect from '../../components/FloatingSelect';

const Service = (props) => {

  const handleServiceSelect = event => {
    props.setValue( { service_id: event.target.value, experience: props.value.experience } )
  }

  const handleExperienceSelect = event => {
    props.setValue( { service_id: props.value.service_id, experience: event.target.value } )
  }

  return (
    <div className="row g-2">
      <div className="col-6">
        <FloatingSelect data={props.services} name='primary_service' required={props.isRequired}
          value={props.value.service_id} placeholder={'Select One'}
          label={props.type + ' Service'} onChange={e => handleServiceSelect(e)} isDisabled={props.isDisabled} />
      </div>
      <div className="col-4">
        <FloatingSelect data={props.experiences} name='primary_experience' required={props.value.service_id != ''}
          value={props.value.experience} placeholder={'Select One'}
          label={props.type + ' Service Experience (Years)'} onChange={e => handleExperienceSelect(e)}/>
      </div>
    </div>

  );
}

export default Service;
