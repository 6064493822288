import React, { useContext, useRef, useState, useEffect } from 'react';
import { store } from '../../store'

import Service from '../steps/Service';
import SubServices from '../steps/SubServices';

import Modal from '../../components/Modal';

const editServices = (props) => {

  const formEl = useRef();

  const experiences = [
    { value: '4', text: '1-4' },
    { value: '9', text: '5-9' },
    { value: '20', text: '10-20' },
    { value: '21', text: '>20' },
  ]

  const globalState = useContext(store);
  const [ allServices, setAllServices ] = useState([])
  const [ errors, setErrors ] = useState({})
  const formId = 'edit-service-form'
  const [ primaryServiceOffering, setPrimaryServiceOffering ] = useState({ service_id: '', experience: '4' })
  const [ primarySubServiceOffering, setPrimarySubServiceOfferings ] = useState([{ id: 1, service_id: '', experience: '4' }])
  const [ secondaryServiceOffering, setSecondaryServiceOffering ] = useState({ service_id: '', experience: '4' })
  const [ secondarySubServiceOffering, setSecondarySubServiceOfferings ] = useState([{ id: 1, service_id: '', experience: '4' }])
  const [ selectedAllSubServices, setSelectedAllSubServices ]  = useState([])

  const fetchData = async () => {
  	setErrors([]);
    const response = await fetch('/api/v1/services', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setAllServices(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }

    const offeringsResponse = await fetch('/api/v1/service_offerings', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const offeringsJson = await offeringsResponse.json()
    if (offeringsResponse.ok) {
      if (offeringsJson.primary_offering){
        setPrimaryServiceOffering(offeringsJson.primary_offering.service)
        setPrimarySubServiceOfferings(offeringsJson.primary_offering.sub_services)
      }
      if (offeringsJson.secondary_offering){
        setSecondaryServiceOffering(offeringsJson.secondary_offering.service)
        setSecondarySubServiceOfferings(offeringsJson.secondary_offering.sub_services)
      }
      updateSelectedServices()
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, [props.show]);

  const services = allServices.map( data => {
    return { value: data.id, text: data.name }
  });

  let primarySubServices = []
  if (primaryServiceOffering.service_id){
    let service = allServices.filter( s => primaryServiceOffering.service_id == s.id )
    primarySubServices = service[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

  let secondarySubServices = []
  if (secondaryServiceOffering.service_id){
    let service = allServices.filter( s => secondaryServiceOffering.service_id == s.id )
    secondarySubServices = service[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

  const handleFormSubmit = async (e) => {
  	event.preventDefault(e);
    const formData = {
      primary_offering: {
        service: primaryServiceOffering,
        sub_services: primarySubServiceOffering
      },
      secondary_offering: {
        service: secondaryServiceOffering,
        sub_services: secondarySubServiceOffering
      }
    }
    const response = await fetch( '/api/v1/service_offerings', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()
    if (response.ok) {
      props.setShow(false)
    } else {
      setErrors(responseJson.errors)
    }
  }

  let errorsMessages = ''

  Object.entries(errors).forEach(([key, messages]) => {
  	if(key > 0)
  	{
  		errorsMessages = messages.map((message, index) =>{
	      return (
	        <div key={index} className="alert alert-danger" role="alert">
	          {message}
	        </div>
	      )
	    });
  	}

  });

  const errorsHtml = () => {
    if (Object.keys(errors).length && errorsMessages != ''){
      return(
        <div className="row g-3">
          <div className="col-md text-start">
            {errorsMessages}
          </div>
        </div>
      )
    }
    else if(Object.keys(errors).length)
    {
    	return(
        <div className="row g-3">
          <div className="col-md text-start">
            <div className="alert alert-danger" role="alert">
		          {errors[0]}
		        </div>
          </div>
        </div>
      )
    }
    return ''
  }

  const performSubmit =() => { handleFormSubmit(); }

  const updateSelectedServices =() => {
    let srvs = []
    srvs = srvs.concat(primarySubServiceOffering.map( item => parseInt(item.service_id) ))
    srvs = srvs.concat(secondarySubServiceOffering.map( item => parseInt(item.service_id) ))
    setSelectedAllSubServices(srvs)

  }

  return(
    <Modal setModal={props.setModal} setShow={props.setShow} id="modalEditServices" title='Edit Service Offering'  formId={formId}>
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        {errorsHtml()}
        < Service services={services} experiences={experiences}
          value={ primaryServiceOffering } setValue={setPrimaryServiceOffering} type='Primary' isDisabled={true} isRequired={true}/>
        <hr/>
        < SubServices services={ primarySubServices } experiences={experiences}
          value={primarySubServiceOffering} setValue={setPrimarySubServiceOfferings} type='Primary' updateSelectedServices={updateSelectedServices} disabledvalues={selectedAllSubServices}/>

        <hr/>
        <hr/>

        < Service services={services} experiences={experiences}
          value={ secondaryServiceOffering } setValue={setSecondaryServiceOffering} type='Secondary' isRequired={false}/>
        <hr/>
        < SubServices services={ secondarySubServices } experiences={experiences}
          value={secondarySubServiceOffering} setValue={setSecondarySubServiceOfferings} type='Secondary' updateSelectedServices={updateSelectedServices} disabledvalues={selectedAllSubServices}/>
      </form>
    </Modal>
  )

}

export default editServices;
