import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";
import ViewJob from '../jobs/ViewJob';
import View from '../projects/View';





const ProposalPopUp = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
	const [ proposalDetails, setProposalDetails ] = useState({})
	const [ userDetails, setUserDetails ] = useState({})
	const [ expRoles, setExpRoles ] = useState([])
	const [ projects, setProjects ] = useState([])
	const [ companyData, setCompanyData ] = useState({})
  const [ projectDescription, setProjectDescription] = useState('')
  const [ milestones, setMilestones] = useState([{id:0,milestone:'',date: null}])
  const [startDate, setStartDate] = useState(null)
  const [ amendMessageValue, setValue] = useState('')
	const [proposalDocuments, setProposalDocuments] = useState([]);

	let history = useHistory()


	function handleChange(newValue) {
    setValue(newValue);
  }

	const fetchData = async () => {
		if(props.show)
		{
			const response1 = await fetch('/api/v1/proposals/'+ props.proposal_id, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })

		  const responseJsonProposal = await response1.json()
		  console.log("responseJsonProposal")
		  console.log(responseJsonProposal)

		  if(response1.ok)
		  {
		  	setUserDetails(responseJsonProposal.user)
		  	setProjectDetails(responseJsonProposal.project_detail)
		  	setProposalDetails(responseJsonProposal)
		  	if(responseJsonProposal.relevant_job_role != null)
		  	{
		  		setExpRoles(responseJsonProposal.relevant_job_role)
		  	}
		  	if(responseJsonProposal.relevant_project != null)
		  	{
		  		setProjects(responseJsonProposal.relevant_project)
		  	}
		  	if(responseJsonProposal.project_milestones != null)
		  	{
		  		setMilestones(responseJsonProposal.project_milestones)
		  	}
		  	setProposalDocuments(responseJsonProposal.proposal_documents)
		  }
		}
	}

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	}, [props.show]);

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const addMilestones = () => {
   	let count = milestones.length;
    let id = milestones[count-1].id + 1;
    setMilestones( [...milestones, {id: id,milestone:'',date: null} ]  )
  }

  const removeMilestones = (id) => {
    const new_milestones =  milestones.filter( data => data.id != id )
    setMilestones( new_milestones )
  }

  const Milestones = milestones.map((data,index)=>
  {
  	return(
			<tr key={index}>
	      <th className="w-10">{index+1}</th>
	      <td className="w-75">{data.name}</td>
	      <td className="w-15">{data.milestone_date}</td>
      </tr>

  	);
  })


  const proposalConfirmation = () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Confirm Post Proposal?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            postProposal();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const deleteConfirmation= () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Delete This Proposal?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            deleteProposal();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const acceptProposal = async () => {

	  const response = await fetch('/api/v1/proposal_accepted/'+ proposalDetails.id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'put',
	  })

	  const responseJson = await response.json()

	  if (response.ok) {
	    props.setShowWorkOrderCreation(true)
	    props.setShow(false);
	  } else {
	    alert.show("Server is down.")
	    return false
	  }

	}

	const rejectProposal = async () => {

	   const response = await fetch('/api/v1/proposal_rejected/'+ proposalDetails.id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'put',
	  })

	  const responseJson = await response.json()

	  if (response.ok) {
	    props.setShow(false);
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	const amend_proposal = async () => {

		const formData = {
			proposal:
			{
				ammendment_requirement_message: amendMessageValue
			}
    }

	  const response = await fetch('/api/v1/amend_proposal/'+ proposalDetails.id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'put',
			body: JSON.stringify(formData)
	  })

	  const responseJson = await response.json()

	  console.log("responseJson")
	  console.log(responseJson)

	  if (response.ok) {
	    props.setShow(false)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }

  }

  const cancel_amendment = async () => {

    const response = await fetch('/api/v1/cancel_amendment/' + proposalDetails.id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',

    })

    const responseJson = await response.json()

    console.log("responseJson")
    console.log(responseJson)

    if (response.ok) {
      props.setShow(false)
    } else {
      alert.show("Server is down.")
      return false
    }

  }

  const proposal_documents_view = proposalDocuments.map((data,index)=>
  {
		return(
			<div key={index} className="col-md-12 text-start mt-3 mb-3">
      	<div className="row mt-4">
  				<div className="col-md-9">
  					<a href={data.attachment} target="_blank" rel="noopener noreferrer" ><b>{data.attachment_name}</b></a>
  				</div>

				</div>
			</div>
		);
	})

	const listItems = expRoles.map((data, index) =>{
    return <ViewJob display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} proposalView={true} />
  });

  const project = projects.map((data, index) =>{
    return <View display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} proposalView={true} />
	});



  return (
  	<Modal setModal={props.setModal} setShow={props.setShow} summary={true} id="proposal_details" title='Proposal View' project_consultant={true} >
	    <div className="container mb-3">
		    <div className="row g-2 mt-2">
	      	<div className="col-md-12">
		      	<div className="row g-0 text-start">
		      		<h4 className="col-md-3"><b>Project Title: </b></h4>
		      		<h4 className="col-md-9  text-start"><b>{projectDetails.title} </b></h4>
		      	</div>
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-3"><b>Posted by: </b></h5>
		      		<h5 className="col-md-9">{projectDetails.creator}</h5>
		      	</div>
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-3"><b>Company Name: </b></h5>
		      		<h5 className="col-md-9">{projectDetails.company_name}</h5>
		      	</div>
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-3"><b>Project Created Date: </b></h5>
		      		<h5 className="col-md-9">{projectDetails.update_date}</h5>
		      	</div>
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-3"><b>Submission deadline: </b></h5>
		      		<h5 className="col-md-9">{projectDetails.proposal_due_date_dv}</h5>
		      	</div>
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-3"><b>Consultant Name: </b></h5>
		      		<h5 className="col-md-9">{userDetails.first_name} {userDetails.last_name}</h5>
		      	</div>

	      	</div>
	    	</div>
	    	<div className="card mt-3">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Scope</b></h4>
	        <div className="card-body text-start">
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Project Objectives : </strong>
	            <h6 className="col-md-9 ">{ReactHtmlParser(proposalDetails.project_objective)}</h6>
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Project In Scope : </strong>
	            <h6 className="col-md-8">{ReactHtmlParser(proposalDetails.project_in_scope)}</h6>
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Project Out Of Scope : </strong>
	            <h6 className="col-md-9">{ReactHtmlParser(proposalDetails.project_out_scope)}</h6>
	          </div>
	        </div>
	      </div>
	      <div className="card mt-3">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Relevant Experience</b></h4>
	        <div className="card-body text-start">
	        	<h5><b>Experience (Roles) : </b></h5>
	        	{
	        		expRoles.length > 0 ?
		          <div className="row g-0 text-start mt-2">
		            {listItems}
		          </div>
		          :
		          null
		        }

	          <h5 className="mt-3"><b>Experience (Projects) : </b></h5>
	          {
	        		projects.length > 0 ?
		          <div className="row g-0 text-start mt-2">
		            {project}
		          </div>
		          :
		          null
		        }

		        <h5 className="mt-3"><b>Summary of Relevant Experience : </b></h5>
	          {
	        		proposalDetails.relevant_exp_summary != '' ?
		          <div className="col-md-12">
		            {ReactHtmlParser(proposalDetails.relevant_exp_summary)}
		          </div>
		          :
		          null
		        }

	        </div>
	      </div>
	      <div className="card mt-3">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Timelines</b></h4>
	        <div className="card-body text-start">
	        	<h5><b>Project Dates : </b></h5>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Proposed Date : </strong>
	            <h6 className="col-md-9">{proposalDetails.proposed_start_date}</h6>
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Comment : </strong>
	            <h6 className="col-md-9">{proposalDetails.comment_start_date ? proposalDetails.comment_start_date : 'None'}</h6>
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Proposed Duration : </strong>
	            <h6 className="col-md-9">{proposalDetails.proposed_estimated_duration} {proposalDetails.proposed_estimated_duration ? proposalDetails.project_detail.estimated_duration_category : ''}</h6>
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Comment : </strong>
	            <h6 className="col-md-9">{proposalDetails.comment_estimated_duration ? proposalDetails.comment_estimated_duration : 'None'}</h6>
	          </div>
	        </div>
					<div className="col-md-10 card-body text-start">
	        	<h5><b>Project Milestones : </b></h5>
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr>
						      <th scope="col-md-1">Sr.No</th>
						      <th scope="col-md-7">Milestone Name</th>
						      <th scope="col-md-4">Date</th>
						    </tr>
						  </thead>
						  <tbody>
					    	{Milestones}
						  </tbody>
						</table>
	        </div>
	        <div className="card-body text-start">
	        	<h5><b>Project Logistics : </b></h5>
	          {
	          	projectDetails.consultant_country != '' ?
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Agree Consultant Country : </strong>
		            <h6 className="col-md-9">{proposalDetails.agree_consultant_country ? 'Yes' : 'No'}</h6>
		          </div>
		          :
		          null
		        }
		        {
	          	projectDetails.consultant_country != '' ?
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Comment : </strong>
		            <h6 className="col-md-9">{proposalDetails.comment_consultant_country ? proposalDetails.comment_consultant_country : 'None'}</h6>
		          </div>
		          :
		          null
		        }
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Agree Location Of Project : </strong>
	            <h6 className="col-md-9">{proposalDetails.agree_location_type ? 'Yes' : 'No'}</h6>
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Comment : </strong>
	            <h6 className="col-md-9">{proposalDetails.comment_location_type ? proposalDetails.comment_location_type : 'None'}</h6>
	          </div>
	          {
	          	projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Agree Onsite Location : </strong>
		            <h6 className="col-md-9">{proposalDetails.agree_onsite_location ? 'Yes' : 'No'}</h6>
		          </div>
		          :
		          null
	          }
	          {
	          	projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Comment : </strong>
		            <h6 className="col-md-9">{proposalDetails.comment_onsite_location ? proposalDetails.comment_onsite_location : 'None'}</h6>
		          </div>
		          :
		          null
		        }

	        </div>
	      </div>
	      <div className="card mt-3">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees</b></h4>
	        <div className="card-body text-start">
		        {
		        	proposalDetails.total_fees != null &&  projectDetails.currency == 'Other' ?
		        	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Fees : </strong>
		            <h6 className="row g-0 col-md-9">{proposalDetails.total_fees} {projectDetails.other_currency}</h6>
		          </div>
		          :proposalDetails.total_fees != null && projectDetails.currency != 'Other'?
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Fees : </strong>
		            <h6 className="row g-0 col-md-9">{proposalDetails.total_fees} {projectDetails.currency} </h6>
		          </div>
		          :
		          null
		        }

		        {
		        	proposalDetails.rate_per_hour != null &&  projectDetails.currency == 'Other' ?
		        	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Rate/Hour : </strong>
		            <h6 className="row g-0 col-md-9">{proposalDetails.rate_per_hour} {projectDetails.other_currency}</h6>
		          </div>
		          :proposalDetails.rate_per_hour != null &&  projectDetails.currency != 'Other' ?
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Rate/Hour : </strong>
		            <h6 className="row g-0 col-md-9">{proposalDetails.rate_per_hour} {projectDetails.currency} </h6>
		          </div>
		          :
		          null
		        }


	          {
	          	proposalDetails.effort_frequency_type != '' ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Effort Frequency Type : </strong>
		            <h6 className="col-md-9">{proposalDetails.effort_frequency_type}</h6>
		          </div>
		          :
		          null
	          }
	          {
	          	proposalDetails.effort_frequency != null ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Effort Frequency : </strong>
		            <h6 className="col-md-9">{proposalDetails.effort_frequency}</h6>
		          </div>
		          :
		          null
	          }

	          {
	          	proposalDetails.total_effort_type != '' ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Effort Type : </strong>
		            <h6 className="col-md-9">{proposalDetails.total_effort_type}</h6>
		          </div>
		          :
		          null

	          }

	          {
	          	proposalDetails.total_effort != null ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Effort : </strong>
		            <h6 className="col-md-9">{proposalDetails.total_effort}</h6>
		          </div>
		          :
		          null
	          }
	        </div>
	      </div>
	      <div className="card mt-3">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Proposal Attachments</b></h4>
	        <div className="card-body text-start">
	        	{proposal_documents_view}
	        </div>
	      </div>
	      {proposalDetails.allow_ammendment ?
          <div className="row g-3 mt-3 mb-3 justify-content-between">
            <div className="col-md text-start">
              <h5><b>Proposal amendment is in progress</b></h5>
            </div>
            <div className="col-md text-end">
              <button type="submit" onClick={() => cancel_amendment()} className="btn btn-danger btn-block ml-auto ">Cancel Amendment</button>
            </div>
		    	</div>
		    	:
		    	null
		    }
	      {
	      	proposalDetails.status == "posted" && proposalDetails.allow_ammendment != true ?
	      	<div className="card mt-3">
			      <div className="accordion-item">
				      <h2 className="accordion-header"  id={"heading1"}>
				        <button className="accordion-button collapsed fw-bold" type="button" data-toggle="collapse" data-bs-toggle="collapse" data-bs-target={"#collapes1" } aria-expanded="false" aria-controls={"collapes1" }>
				          Allow Amendment to Proposal
				        </button>
				      </h2>
				      <div id={"collapes1"} className="accordion-collapse collapse" aria-labelledby={"heading1"}>
				        <div className="accordion-body text-start">
				          <div className="row g-2">
				            <div className="col-md">
				          		<div className="text-start">
					              <CustomEditor id="amend_message" value={amendMessageValue} onChange={handleChange} />
					              <div className="invalid-feedback"></div>
					            </div>
				            </div>
				          </div>
				          <div className="col-md mt-3 text-end">
										<button onClick={()=>amend_proposal()}  type="button"
											className="btn btn-primary btn-block ml-auto"
											>Submit
										</button>
		              </div>
				        </div>
				      </div>
				    </div>
			    </div>
			    :
			    null
	      }

		    {
	    		proposalDetails.status == "posted" && proposalDetails.allow_ammendment != true ?
	    		<div className="row g-3 mt-3 mb-3 justify-content-between">
	      		<div className="col-md text-start">
		          <button  type="button" onClick={()=>rejectProposal()} className="btn btn-danger btn-block ml-auto" >Reject</button>
		        </div>
	      		<div className="col-md text-end">
		          <button  type="submit" onClick={()=>acceptProposal()} className="btn btn-primary btn-block ml-auto ">Accept</button>
		        </div>
		      </div>
			    :
			    null
		    }
		    {
	    		proposalDetails.status == "rejected" && proposalDetails.allow_ammendment != true ?
	    		<div className="row g-3 mt-3 mb-3 justify-content-between">
	      		<div className="col-md text-start">
		          <button  type="button" onClick={()=>rejectProposal()} className="btn btn-danger btn-block ml-auto" >Un-reject</button>
		        </div>
		      </div>
			    :
			    null
		    }

	    </div>
	  </Modal>
  );
}

export default ProposalPopUp;
