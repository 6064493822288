import React, { useContext, useState, useEffect } from 'react';
import { store } from '../../store';
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import Edit from './Edit';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const View = (props) => {
  const globalState = useContext(store);

  const [showModal, setShowModal] = useState( false )
  const [modal, setModal] = useState(null);

  const headingId = "heading-project" + props.data.id
  const modalId = "modal-project" + props.data.id

  useEffect(() => {
    if (modal)
      showModal ? modal.show() : modal.hide()
  }, [modal, showModal]) 


   const submit = () => {
    confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>
		        
		        <h5>Delete this entry?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            handleDelete();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
  };

  const handleDelete = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const response = await fetch( '/api/v1/projects/' + props.data.id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'delete'
    })
    if (response.ok) {
      props.setFetch(true)
    } else {
      
    }
  }

  return (
    <div className="accordion-item">
      <h2 className="accordion-header" id={headingId}>
        <button className="accordion-button collapsed fw-bold" type="button"
          data-bs-toggle="collapse" data-bs-target={"#" + modalId }
          aria-expanded="false" aria-controls={modalId}>
          {props.data.title} at {props.data.client_name}
        </button>
      </h2>
      <div id={modalId} className="accordion-collapse collapse"
        aria-labelledby={headingId}>
        <div className="accordion-body text-start">
          <div className="row g-2">
            <div className="col-md">
              <div className="row g-2">
                <div className="col-md">
                  <div className="col-md">
                    <h5><b>{props.data.title}</b></h5>
                  </div>
                  <div className="col-md">
                    <h6>{props.data.client_name}</h6>
                  </div>
                  <div className="col-md text-muted">
                    <span>{props.data.start_date_view} - {props.data.end_date_view}</span>
                  </div>
                </div>
                {props.display_public_profile == true || props.proposalView ?
                	null
                	:
                	<div className="col-md-auto">
                    <button type="button"
                      className="btn btn-primary"
                      onClick={ () => setShowModal( !showModal ) }
                    >Edit</button>
                    <button type="button" className="btn btn-danger ms-2" onClick={submit}>
                      Delete
                    </button>
                  </div>
                }
              </div>
              <div className="row-g2">
                <div className="col-md mt-3">
                  {ReactHtmlParser(props.data.description)}
                </div>
              </div>              
            	<div className="row-g2">
                <div className="col-md mt-2">
                    <div>Service Offering:  {props.data.primary_service.name}</div>
                    <div>Sub-service:  {props.data.secondary_service?.name}</div>
                </div>
              </div>
	              
            </div>
          </div>
          {
          	!props.proposalView ?
          	<Edit data={props.data} show={showModal} setFetch={props.setFetch} setShow={setShowModal} setModal={setModal}/>
      			:
      			null
      		}
      	</div>
      </div>
    </div>
  );
}

export default View;
