import React, { useState } from 'react';
import AddEducation from '../education/AddEducation';
import ListEducation from '../education/ListEducation';

const EducationStep = (props) => {

  const [fetchEvent, setFetchEvent] = useState(false);

  return (

    <AddEducation setActiveId={props.setActiveId} activeId={props.activeId}/>

  );
}

export default EducationStep;
