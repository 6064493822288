import React, { useState } from 'react';

import Add from '../projects/Add';
import List from '../projects/List';

const ProjectStep = (props) => {

  const [fetchEvent, setFetchEvent] = useState(false);
  const [projectCount, setProjectCount] = useState(0)

  return (
    <div>
      <div className="row g-3">
        <div className="col-md text-start">
          <div className="alert alert-info">
            <ul>
              <li>You can describe the Projects that you have undertaken in your Primary and Secondary Services.</li>
              <li>Describe as many projects as will be useful for your credentials.</li>
              <li>You will be able to link to your listed past projects in your future proposals.</li>
            </ul>
          </div>
        </div>
      </div>
      <Add setFetch={setFetchEvent} setActiveId={props.setActiveId} activeId={props.activeId} projectCount={projectCount}/>
      <List fetch={fetchEvent} setFetch={setFetchEvent} setProjectCount={setProjectCount}/>
    </div>
  );
}

export default ProjectStep;
