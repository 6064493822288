import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'



const ArchivedProjectList = (props) => {

	const globalState = useContext(store);
	const { dispatch } = globalState;
	let history = useHistory()


  const [ workOrderList, setWorkOrderList] = useState([])

	const fetchData = async () => {

	  const response = await fetch('/api/v1/client_archived_projects', {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  console.log(responseJson)
	  if (response.ok) {
	    setWorkOrderList(responseJson)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	useEffect( () => {
	  fetchData();
	}, []);



	const copyProject = async (project_id) => {
  	dispatch({ type: 'project',project_id: project_id});
  	history.push({pathname:"/new-project", state:{copyProjectState:true}});
  }

	const work_Order = workOrderList.map( work_order => {
		return (
    	<tr key={work_order.id}>
	      <td><Link to={{ pathname: "/complete-project-view", state: { work_order_id: work_order.id, workOrder: work_order, }}}>{work_order.project_detail.title}</Link></td>
	      <td>{work_order.project_detail.creator}</td>
	      <td>{work_order.user.first_name} {work_order.user.last_name}</td>
	      <td>{work_order.update_date}</td>
	      <td>{work_order.status.charAt(0).toUpperCase() + work_order.status.slice(1)}</td>
	    </tr>
  	)

  })

  return (
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="box" /> Archived Projects </h2>
        </div>
    	</div>
    	<div className="col-md mt-4">
    		<table className="table table-bordered table-striped table-sm text-start">
				  <thead>
				    <tr>
				      <th scope="col">Project Title/ Work Order</th>
				      <th scope="col">Project Posted by</th>
				      <th scope="col">Consultant Selected</th>
				      <th scope="col">Project Completion Date</th>
				      <th scope="col">Project Status</th>
				    </tr>
				  </thead>
				  <tbody>
			    	{work_Order}
				  </tbody>
				</table>

    	</div>
    </div>
  );
}

export default ArchivedProjectList;
