import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const TermAndConditions = (props) => {
	const term_and_conditions = [
	"This Work Order constitutes an Agreement between the Client and the Consultant (each a 'Party', together 'Parties').",
	
	"The Client hereby agrees to engage the Consultant to provide the Client with the services pertaining to the project set out in this Work Order (‘Services’).",
	
	"The Consultant hereby agrees to provide such Services to the Client.",
	
	"In providing the Services under this Work Order it is expressly agreed that the Consultant is acting as an independent contractor and not an employee of the Client.",
	
	"The term of this Agreement (‘Term’) will begin on the Start Date specified in the Work Order and will remain in full force and effect until the completion of the Services, subject to early termination as specified in this Agreement. The term may be extended with the written consent of the Parties.",
	
	"In the event that either Party wishes to terminate this Agreement prior to completion of the Services, that Party will be required to give 30 days’ written notice to the other Party.",
	
	"In the event that the Agreement is terminated early by either Party in accordance with clause 6, the Consultant may invoice the Client on a pro rata basis for work done up to and including the termination date.",
	
	"Consultant will invoice fees to the Client as per the invoicing schedule in the Work Order. All invoices will be submitted through Pharmalancers Ltd.",
	
	"The Consultant will be responsible for all income tax liabilities, national insurance, social security or similar contributions relating to the payments for the Services and the Consultant will indemnify the Client in respect of any such payments required to be made by the Client.",
	
	"Confidential Information (the “Confidential Information”) refers to any data or information relating to the Client, whether business or personal, which would reasonably be considered private or proprietary to the Client an that is not generally known and where the release of that Confidential Information could reasonably be expected to cause harm to the Client.",
	
	"The Consultant agrees that they will not disclose, divulge, reveal, report or use, for any purpose, any Confidential Information that the Consultant has obtained, except as authorised by the Client or as required by law. The obligations of confidentiality will apply during the term and will survive indefinitely upon termination of this Agreement.",
	
	"All intellectual property and related material, including any trade secrets, moral rights, goodwill, relevant registrations or applications for registrations, and rights in any patent, copyright, trade mark, trade dress, industrial design and trade name (“Intellectual Property”) that is developed or produced under this Agreement, will be the sole property of the Client. The use of the Intellectual Property by the Client will not be restricted in any manner.",
	
	"The Parties acknowledge that this Agreement is non-exclusive and that either Party will be free, during and after the Term to engage and contract with third parties for the provision of services similar to the Services.",
	
	];

	const term_conditions = term_and_conditions.map( (terms,index) => {
		return (
    	<li key={index}>{terms}</li>      
  	)

  })
	
	return (    
    <div className="col-md">
    	<ol>
      	{term_conditions}
    	</ol>
    </div>
        
  );
}

export default TermAndConditions;