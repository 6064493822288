import React, { useContext, useEffect, useState, useRef, forwardRef} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TabCards from '../../components/TabCards';
import ProjectDetails from './ProjectDetails';
import ProjectFees from './ProjectFees';
import ProjectSteps from './ProjectSteps';
import ProjectLogistics from './ProjectLogistics';
import RequiredSpecialisation from './RequiredSpecialisation';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';


const EditProject = (props) => {	 

  const globalState = useContext(store);
	const [ projectDetails, setProjectDetails ] = useState({})
	const { state } = useLocation();

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}  

	const my_data =  JSON.parse(localStorage.getItem('document'));  

	// useEffect( () => {
	//   fetchData();
	// }, []);
  
 
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <div className="container">       
      	<ProjectSteps retriveDataProject={true} projectDetails={my_data.projectDetails} edit_project_id={my_data.projectDetails.id}  headings={"Edit Project"}/>       
      </div>
    </div>

  );
}

export default EditProject;
