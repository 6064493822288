import React from 'react';
import FloatingSelect from '../../components/FloatingSelect';

const SubServices = (props) => {

  const addService = () => {
    if ((props.type == 'Primary' && props.value.length >= 6) || (props.type == 'Secondary' && props.value.length >= 3)) { return }
    let id = props.value.length + 1
    props.setValue( [...props.value, { id: id, service_id: '', experience: '' } ]  )
  }

  const removeService = (id) => {
    const newServices =  props.value.filter( data => data.id != id )
    props.setValue( newServices )
  }

  const handleServiceChange = (event, id )=> {
    const newData = props.value.map( data => {
      if (data.id == id)
        data.service_id = event.target.value
      return data
    });
    props.setValue( newData )
    props.updateSelectedServices()
  }

  const handleExperienceChange = (event, id )=> {
    const newData = props.value.map( data => {
      if (data.id == id)
        data.experience = event.target.value
      return data
    });
    props.setValue( newData )
  }

  const subServices = props.value.map( data => {
    return(
      <div className="row g-2" key={data.id}>
        <div className="col-6">
          <FloatingSelect data={props.services} name='subServices' value={data.service_id} required={true}
            placeholder={'Select One'} disabledvalues={props.disabledvalues} canHaveCategory={true}
            label={props.type + ' Sub Service'} onChange={(e) => handleServiceChange( e, data.id )}/>
        </div>
        <div className="col-4">
          <FloatingSelect data={props.experiences} name='experience' value={data.experience} required={true}
            placeholder={'Select One'}
            label={props.type + ' Sub Service Experience (Years)'} onChange={(e) => handleExperienceChange( e, data.id )} />
        </div>
        <div className="col d-flex align-items-center justify-content-center">
          <button type="button" onClick={() => removeService(data.id)}
            className="btn btn-danger btn-block">Remove</button>
        </div>
      </div>
    )
  });

  const addButton = () => {
    if ((props.page == "project" && props.value.length >= 4) || (props.type == 'Primary' && props.value.length >= 6) || (props.type == 'Secondary' && props.value.length >= 3)) { return '' }
    return(
      <div className="col d-flex">
        <button type="button" onClick={addService} className="btn btn-primary btn-block">Add</button>
      </div>
    );
  }

  return (
    <>
      {subServices}
      <div className="row g-2">
        {addButton()}
      </div>
    </>

  );
}

export default SubServices;
