import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store';
import { Link } from 'react-router-dom';

import SearchProfileCard from '../components/SearchProfileCard'

const SearchWithName = (props) => {

  const globalState = useContext(store);
  const [ users, setUsers ] = useState([])
  const [ nameStarts, setNameStarts ] = useState('abcd')


  const handleFormSubmit = async (value) => {
    setNameStarts(value)
    const formData = {
      name_starts: value,
    }
    const response = await fetch( '/api/v1/search_with_name', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const profiles = await response.json()

    if (response.ok) {
      setUsers(profiles)
    } else {
    }
  }


  useEffect( () => {
    handleFormSubmit('abcd');
  }, []);

  const usersData = users.map( u => {
    return (
      <SearchProfileCard
        key={u.id}
        name={u.user.first_name + ' ' + u.user.last_name.substring(0, 1)}
        image_url={u.user?.image_url}
        city={u.user.city}
        country={u.user.country_name}
        service={u.primary_service}
        service_exp={u.primary_service_exp}
        education={u.main_education}
        summary={u.summary}
        userData={u}
      />
    )
  })

  const totalCount = () => {
    return `${users.length} consultant${ users.length > 1 ? 's' : '' } found`
  }
  return (
    <div className="">
      <form onSubmit={handleFormSubmit} className="row g-2 border-bottom">
        <div className="row g-2">
          <div className="col-md g-2 text-start">
            <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="address-book" /> Consultants - By Name</h2>
          </div>
        </div>

        <div className="d-flex align-items-center justify-content-between">
          <div className='name-grouping-container'>
            <a onClick={() => handleFormSubmit('abcd')} className={nameStarts == 'abcd' ? 'active' : ''}
              role="button">ABCD</a>
            <a onClick={() => handleFormSubmit('efgh')} className={nameStarts == 'efgh' ? 'active' : ''}
              role="button">EFGH</a>
            <a onClick={() => handleFormSubmit('ijkl')} className={nameStarts == 'ijkl' ? 'active' : ''}
              role="button">IJKL</a>
            <a onClick={() => handleFormSubmit('mnop')} className={nameStarts == 'mnop' ? 'active' : ''}
              role="button">MNOP</a>
            <a onClick={() => handleFormSubmit('qrst')} className={nameStarts == 'qrst' ? 'active' : ''}
              role="button">QRST</a>
            <a onClick={() => handleFormSubmit('uvw')} className={nameStarts == 'uvw' ? 'active' : ''}
              role="button">UVW</a>
            <a onClick={() => handleFormSubmit('xyz')} className={nameStarts == 'xyz' ? 'active' : ''}
              role="button">XYZ</a>
          </div>
          <div>
            <Link to={"/search"}>Click here to search by filter</Link>
          </div>

        </div>
        <div className="row g-2">
          <div className="text-center m-0 mb-2">
            <h5 className="fw-bolder">{totalCount()}</h5>
          </div>
        </div>
      </form>

      <div className="row d-flex align-items-center overflow-search-scorll search-with-name">
        {usersData}
      </div>

    </div>
  );
}

export default SearchWithName;
