import React, { useState, useEffect, useContext, forwardRef, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";




const ProjectScope = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();	
 	const formEl = useRef();
 	const formId = `proposal_create`
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
	const [ companyData, setCompanyData ] = useState({})
  const [ project_objective, setProjectObjective] = useState('')
  const [ project_out_scope, setProjectOutScope] = useState('')
  const [ project_in_scope, setProjectInScope] = useState('')
  const [ milestones, setMilestones] = useState([{id:0,milestone:'',date: null}])
  const [startDate, setStartDate] = useState(null)



	let history = useHistory()

	const [modal, setModal] = useState(null);

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	const fetchData = async () => {	
		console.log("props.proposalId")
		console.log(props.proposalId)
		if(props.edit_proposal)
		{
			const response = await fetch('/api/v1/proposals/'+props.edit_proposal_id, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })

		  const responseJson = await response.json()

		  if(response.ok)
		  {
		  	setProjectObjective(responseJson.project_objective)
		  	setProjectInScope(responseJson.project_in_scope)
		  	setProjectOutScope(responseJson.project_out_scope)
		  	props.setProposalId(responseJson.id)
		  	props.setProposalDetails(responseJson)
		  }
		  else {
		    alert.show("Server is down.")
		    return false
		  }	
		}
		else if(!props.edit_proposal && props.proposalId != null)
		{
			const response = await fetch('/api/v1/proposals/'+props.proposalId, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })

		  const responseJson = await response.json()

		  if(response.ok)
		  {
		  	setProjectObjective(responseJson.project_objective)
		  	setProjectInScope(responseJson.project_in_scope)
		  	setProjectOutScope(responseJson.project_out_scope)
		  	props.setProposalId(responseJson.id)
		  	props.setProposalDetails(responseJson)
		  }
		  else {
		    alert.show("Server is down.")
		    return false
		  }
		}
		
	  
	}

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	  scrollToUp();
	}, []);

	function handleChange(newValue) {
    setProjectObjective(newValue);
  }
  function handleChangeInScope(newValue) {
    setProjectInScope(newValue);
  }
  function handleChangeOutScope(newValue) {
    setProjectOutScope(newValue);
  } 

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  ); 

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    if(props.proposalId == null )
    {
			const formData = {      
				proposal:
				{
					project_detail_id: props.projectDetails.id,
					project_objective: project_objective,
					project_in_scope: project_in_scope,
					project_out_scope: project_out_scope,
				}     
	    }	    
	    
				const response = await fetch( '/api/v1/proposals', {
		      headers: {
		        'Content-Type': 'application/json',
		        'Authorization': globalState.state.Authentication
		      },
		      method: 'post',
		      body: JSON.stringify(formData)
		    })
		    const responseJson = await response.json()
		    console.log("responseJson")  
		    console.log(responseJson)     

		    if (response.ok) {	    	
		    	props.setProposalId(responseJson.id)
		    	props.setProposalDetails(responseJson)
		    	props.setActiveId(props.activeId + 1)
		    } 
    	} 
    
    else if(props.edit_proposal)
    { 
	    const formData = {      
				proposal:
				{
					project_detail_id: props.projectDetails.id,
					project_objective: project_objective,
					project_in_scope: project_in_scope,
					project_out_scope: project_out_scope,
				}     
	    }	    
	    
			const response = await fetch( '/api/v1/proposals/' + props.edit_proposal_id, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()  

	    console.log("responseJson")  
	    console.log(responseJson)  

	    if (response.ok) {
	    	props.setActiveId(props.activeId + 1)
	    	props.setProposalId(responseJson.id)
	    	props.setProposalDetails(responseJson)
	    }
		}
		else
    { 
	    const formData = {      
				proposal:
				{
					project_detail_id: props.projectDetails.id,
					project_objective: project_objective,
					project_in_scope: project_in_scope,
					project_out_scope: project_out_scope,
				}     
	    }	    
	    
			const response = await fetch( '/api/v1/proposals/' + props.proposalId, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()  

	    console.log("responseJson")  
	    console.log(responseJson)  

	    if (response.ok) {
	    	props.setActiveId(props.activeId + 1)
	    	props.setProposalId(responseJson.id)
	    	props.setProposalDetails(responseJson)
	    }
		} 
  }	
  
  
  return (
    <div className="container">      
	    <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center mt-3 mb-3">	    	
        <div className="col-md-12">
          <div className="text-start form-group required">
            <label className="control-label" htmlFor="projectDescription">Project Objectives</label>
	        </div>
		      <div className="text-start">
		      	<CustomEditor placeholder={"Project Objectives"} className="form-control" type="text" id="project_objective" value={project_objective} onChange={handleChange} required/>
		      </div>        
	      </div>
	    	<div className="col-md-12">
          <div className="text-start form-group required">
            <label className="control-label" htmlFor="project_in_scope">Project Scope: In Scope</label>
	        </div>
		      <div className="text-start">
		      	<CustomEditor placeholder={"In Scope"} className="form-control" type="text" id="project_in_scope" value={project_in_scope} onChange={handleChangeInScope} required/>
		      </div>        
	      </div>
	      <div className="col-md-12">
          <div className="text-start form-group required">
            <label className="control-label" htmlFor="project_out_scope">Project Scope: Out of Scope</label>
	        </div>
		      <div className="text-start">
		      	<CustomEditor placeholder={"Out of Scope"} className="form-control" type="text" id="project_out_scope" value={project_out_scope} onChange={handleChangeOutScope} required/>
		      </div>        
	      </div>
	      <div className="row g-2">
	        <div className="col-md text-end">
	          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
	        </div>
	      </div>				  
    	</form>
      	
    </div>
  );
}

export default ProjectScope;
