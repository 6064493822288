import React, { useContext, useEffect, useState, useRef, forwardRef} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TabCards from '../../components/TabCards';
import ProjectPreview from './ProjectPreview';
import ProjectFees from './ProjectFees';
import ProjectLogistics from './ProjectLogistics';
import ProposalPopUp from './ProposalPopUp';
import MessagePopup from './MessagePopup';
import RequiredSpecialisation from './RequiredSpecialisation';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ConsultantProfile from '../../components/ConsultantProfile';
import { confirmAlert } from 'react-confirm-alert';




const Proposals = (props) => {  
  
 const globalState = useContext(store);
	const { dispatch } = globalState;
	let history = useHistory()
	const { state , location} = useLocation();
	const [modalProfile, setModalProfile] = useState(null);
	const [consultantProfileId, setConsultantProfileId] = useState(null);
	const [proposalId, setProposalId] = useState(null);
	const [conversationId, setConversationId] = useState(null);
	const [showModal, setShow] = useState( false )
	const [proposalModal, setProposalModal] = useState(null);
	const [messageModal, setMessageModal] = useState(null);
	const [showProposal, setShowProposal] = useState( false )
	const [messageConsultant, setMessageConsultant] = useState( false )
	const [singleProposal, setSingleProposal] = useState( false )
	const [showWorkOrderCreation, setShowWorkOrderCreation] = useState( false )



	useEffect(() => {
	  if (modalProfile)
	    showModal ? modalProfile.show() : modalProfile.hide()
	}, [modalProfile, showModal])

	useEffect(() => {
	  if (proposalModal)
	    showProposal ? proposalModal.show() : proposalModal.hide()
	  fetchData()
	}, [proposalModal, showProposal])

	useEffect(() => {
	  if (messageModal)
	    messageConsultant ? messageModal.show() : messageModal.hide()
	  fetchData()
	}, [messageModal, messageConsultant])

  const [ proposalList, setProposalList] = useState([])
  if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}

	const my_data =  JSON.parse(localStorage.getItem('document'));
	
	var url_token = window.location.href.split("=");

	const fetchData = async () => {
		if(url_token[1] != undefined)
		{
			const formData = {			
				project_detail_id: url_token[1]					   
	    }

		  const response = await fetch('/api/v1/proposal_received', {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'post',
	      body: JSON.stringify(formData)
		  })
		  const responseJson = await response.json()
		  console.log("responseJson1111111111111111111111111111")
		  console.log(responseJson)

		  if (response.ok) {
		    setProposalList(responseJson) 
		    if(showWorkOrderCreation)
		    {
		    	WorkOrderCreationAlert();
		    } 

		  } else {
		    alert.show("Server is down.")
		    return false
		  }	
	 	}
	 	else
	 	{
	 		const formData = {			
				project_detail_id: my_data.projectId					   
	    }

		  const response = await fetch('/api/v1/proposal_received', {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'post',
	      body: JSON.stringify(formData)
		  })
		  const responseJson = await response.json()
		  console.log("responseJson@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@@")
		  console.log(responseJson)

		  if (response.ok) {
		    setProposalList(responseJson)
		    if(showWorkOrderCreation)
		    {
		    	WorkOrderCreationAlert();
		    }
		  } else {
		    alert.show("Server is down.")
		    return false
		  }		
	 	}
	}

	useEffect( () => {
	  fetchData();
	},[]);


	const WorkOrderCreationAlert = async () => {		
		confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui-error border border-primary rounded'>

		        <h5>The Work Order for this project is being created. Please review and submit the Work Order which is saved in the Projects/Work Orders section.</h5>
		        <div className="row g-2 mt-3">

			        <div className="col-md g-2 text-center">
				        <button className="btn btn-primary"
				          onClick={() => {
				          	setShowWorkOrderCreation(false);
				            onClose();
				          }}
				        >
				          OK
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});	

	}
	

	const proposal_list = proposalList.map( propsal => {		
		return (
    	<tr key={propsal.id}>
	      <td><button className="btn btn-link p-0" onClick={()=> {setConsultantProfileId(propsal.user.consultant_id), setShow(!showModal)}} >{propsal.user.first_name} {propsal.user.last_name}</button></td>
	      <td><button className="btn btn-link p-0" onClick={()=> {setProposalId(propsal.id), setShowProposal(!showProposal)}}>View Proposal</button></td>
	      <td>{propsal.update_date}</td>
	      <td>{propsal.status.charAt(0).toUpperCase() + propsal.status.slice(1)}</td>
	      <td><button onClick={()=> {setProposalId(propsal.id),setSingleProposal(propsal), setConversationId(propsal.conversation_id), setMessageConsultant(!messageConsultant)}} className="btn btn-link p-0">Message Consultant</button></td>
	    </tr>
  	)		

  })

  return (
    <div className="container">      
    	<div className="col-md mt-4">
    		<table className="table table-bordered table-striped table-sm text-start">
				  <thead>
				    <tr>
				      <th scope="col">Consultant Name</th>
				      <th scope="col">Proposal Link</th>
				      <th scope="col">Date Received</th>
				      <th scope="col">Status</th>
				      <th scope="col">Message</th>				      				      
				    </tr>
				  </thead>
				  <tbody>
			    	{proposal_list}
				  </tbody>
				</table>

    	</div>

    	<ConsultantProfile project_consultant={true} consultant_id={consultantProfileId} show={showModal} setShow={setShow} setModal={setModalProfile}  />
    	<ProposalPopUp setShowWorkOrderCreation={setShowWorkOrderCreation} project_consultant={true} proposal_id={proposalId} show={showProposal} setShow={setShowProposal} setModal={setProposalModal}  />
    	<MessagePopup project_consultant={true} proposal={singleProposal} proposal_id={proposalId} conversation_id={conversationId} show={messageConsultant} setShow={setMessageConsultant} setModal={setMessageModal}  />

    </div>
  );
}

export default Proposals;
