import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../store'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const RequiredSpecialisation = (props) => {

  const globalState = useContext(store);
  const [ therapeutic, setTherapeutic ] = useState([])
  const [ allowTherapeuticOthers, setAllowTherapeuticOthers ] = useState(false)
  const [ therapeuticOthers, setTherapeuticOthers ] = useState([])
  const [ product, setProduct ] = useState([])
  const [languages, setLanguages ] = useState([])
  const [ allowProductOthers, setAllowProductOthers ] = useState(false)
  const [ productOthers, setProductOthers ] = useState([])
  const [ service, setService ] = useState([])
  const [ options, setOptions ] = useState({
    therapeutic: [],
    product: [],
    service: []
  })

  const fetchData = async () => {
  	
    const response = await fetch('/api/v1/specialisations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
   
    if (response.ok) {
      setOptions(responseJson)		

    } else {
      alert.show("Server is down.")
      return false
    }

    if(!props.create_project)
 		{
 			const getData = {
	      project_detail_id: props.projectId ,      
	    }

	    const response1 = await fetch('/api/v1/show_project_detail_specialisations/', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'post',
	      body: JSON.stringify(getData)
	    })

	    const responseJson1 = await response1.json()
	 			 			
 			if(responseJson1.therapeutic.length != 0)
 			{
 				setTherapeutic(responseJson1.therapeutic)      		
 			} 
 			if(responseJson1.product.length != 0)
 			{ 					 				
    		setProduct(responseJson1.product)
 			} 
 			if(responseJson1.languages.length != 0)
 			{
    		setLanguages(responseJson1.languages)    		
 			} 
 			if(responseJson1.service.length != 0)
 			{ 			
    		setService(responseJson1.service)
 			} 				 			

 		}
 		else if(props.create_project && props.retriveDataProject )
 		{
 			
 			if(props.projectDetails.project_detail_specialisations.length <= 0 )
 			{
 				const getData = {
		      project_detail_id: globalState.state.project_id ,      
		    }

		    const response1 = await fetch('/api/v1/show_project_detail_specialisations/', {
		      headers: {
		        'Content-Type': 'application/json',
		        'Authorization': globalState.state.Authentication
		      },
		      method: 'post',
		      body: JSON.stringify(getData)
		    })

	     	const responseJson1 = await response1.json()

	 			if(responseJson1.therapeutic.length != 0)
	 			{
	 				setTherapeutic(responseJson1.therapeutic)      		
	 			} 
	 			if(responseJson1.product.length != 0)
	 			{ 				
	    		setProduct(responseJson1.product)
	 			} 
	 			if(responseJson1.languages.length != 0)
	 			{ 				
	    		setLanguages(responseJson1.languages)    		
	 			} 
	 			if(responseJson1.service.length != 0)
	 			{ 			
	    		setService(responseJson1.service)
	 			}
 			}
 			else{
 				if(props.projectDetails.id != undefined)
 				{
 					const getData = {
			      project_detail_id: props.projectDetails.id ,      
			    }

			    const response1 = await fetch('/api/v1/show_project_detail_specialisations/', {
			      headers: {
			        'Content-Type': 'application/json',
			        'Authorization': globalState.state.Authentication
			      },
			      method: 'post',
			      body: JSON.stringify(getData)
			    })

		     	const responseJson1 = await response1.json()

		 			if(responseJson1.therapeutic.length != 0)
		 			{
		 				setTherapeutic(responseJson1.therapeutic)      		
		 			} 
		 			if(responseJson1.product.length != 0)
		 			{ 				
		    		setProduct(responseJson1.product)
		 			} 
		 			if(responseJson1.languages.length != 0)
		 			{ 				
		    		setLanguages(responseJson1.languages)    		
		 			} 
		 			if(responseJson1.service.length != 0)
		 			{ 			
		    		setService(responseJson1.service)
		 			}
 				}

 				
 			} 			
 		}

 		else
 		{
 			if(props.projectId != null)
 			{
 				const getData = {
		      project_detail_id: props.projectId ,      
		    }

		    const response1 = await fetch('/api/v1/show_project_detail_specialisations/', {
		      headers: {
		        'Content-Type': 'application/json',
		        'Authorization': globalState.state.Authentication
		      },
		      method: 'post',
		      body: JSON.stringify(getData)
		    })

		    const responseJson1 = await response1.json()
		 			 			
	 			if(responseJson1.therapeutic.length != 0)
	 			{
	 				setTherapeutic(responseJson1.therapeutic)      		
	 			} 
	 			if(responseJson1.product.length != 0)
	 			{ 					 				
	    		setProduct(responseJson1.product)
	 			} 
	 			if(responseJson1.languages.length != 0)
	 			{
	    		setLanguages(responseJson1.languages)    		
	 			} 
	 			if(responseJson1.service.length != 0)
	 			{ 			
	    		setService(responseJson1.service)
	 			}
 			}
 			
 		}
   
  }

  useEffect( () => {
    fetchData();
  }, []);

  const components = {
    DropdownIndicator: null,
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const therapeuticValues = therapeutic.map( data => data.value )
    const productValues = product.map( data => data.value )
    const serviceValues = service.map( data => data.value )
    const languageValue = languages.map(data=>data.value)
    const formData = {
      project_detail_id: props.projectId ,
      project_detail_specialisations:{
        therapeutic: therapeuticValues,
        product: productValues,
        languages: languageValue,
        service: serviceValues,        
      }
    }


    const response = await fetch( '/api/v1/project_detail_specialisations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()

    if (response.ok) {
      const response_project = await fetch('/api/v1/project_details/' + props.projectDetails.id, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })

		  const responseJson = await response_project.json()
		  props.setProjectDetails(responseJson)
		  props.setActiveId(props.activeId + 1)

    } else {

    }
  }

  const check_from_array =(value,array,key)=>
  {
  	var num = value.length
  	if(value.length == 0)
  	{
  		if(key == 'therapeutic')
  		{
  			setTherapeuticOthers([]);
  		}
  		else
  		{
  			setProductOthers([]);
  		}
  	}
  	else
  	{
  		var array_value = [];
	  	array.map((single)=>
	  	{
	  		array_value.push(single.label.toLowerCase()) ;
	  	});

			var lower_case_value = value[num-1].label.toLowerCase();

	  	if(array_value.includes(lower_case_value))
	  	{
	  		if(key == 'therapeutic')
	  		{
	  			alert('This specialisation already present in above dropdown,Add any specialisation not listed above')
	  		}
	  		else
	  		{
	  			alert('This product specialisations already present in above dropdown,Add any specialisation not listed above.')
	  		}
	  	}
	  	else
	  	{
	  		if(key == 'therapeutic')
	  		{
	  			setTherapeuticOthers(value);
	  		}
	  		else
	  		{
	  			setProductOthers(value);
	  		}
	  	}
  	}
  }


  const therapeuticOthersHTML = () => {
    if (allowTherapeuticOthers){
      return(
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <CreatableSelect
              components={components}
              value={therapeuticOthers}
              isClearable
              isMulti
              onChange={ newValue => check_from_array( newValue,options.therapeutic,"therapeutic")}
              id='therapeuticOthers'
              placeholder='Add any specialisation not listed above'
            />
          </div>
        </div>
      )
    }
    return ''
  }

  const productOthersHTML = () => {
    if (allowProductOthers){
      return(
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <CreatableSelect
              components={components}
              value={productOthers}
              isClearable
              isMulti
              onChange={ newValue => check_from_array( newValue, options.product, 'product')}
              id='productOthers'
              placeholder='Add any specialisation not listed above'
            />
          </div>
        </div>
      )
    }
    return ''
  }

  return (
    <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
      <fieldset>
	        <label className="card-title "><b>Therapeutic Areas</b></label>
	        <div className="mb-3 row">
	          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
	          <div className="">
	            <Select
	              value={therapeutic}
	              options={options.therapeutic}
	              isClearable
	              isMulti
	              onChange={ newValue => setTherapeutic( newValue )}
	              id='therapeutic'
	            />
	          </div>			          
	        </div>			        
	      </fieldset>
	      <fieldset>
	        <label className="card-title "><b>Product Specialisations</b></label>
	        <div className="mb-3 row">
	          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
	          <div className="">
	            <Select
	              value={product}
	              options={options.product}
	              isClearable
	              isMulti
	              onChange={ newValue => setProduct( newValue )}
	              id='product'
	            />
	          </div>			          
	        </div>			        
	      </fieldset>
	      
        <fieldset>
        	<label className="card-title "><b>Any Special Skill Required</b></label>
	        <div className="mb-3 row">
	          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
	          <div className="">
	            <CreatableSelect
	              components={components}
	              value={service}
	              isClearable
	              isMulti
	              onChange={ newValue => setService( newValue )}
	              id='service'
	              placeholder='Add any other specialisation specific to your Service offering'
	            />
	          </div>
	        </div>
	      </fieldset>
	      <fieldset>
	        <label className="card-title "><b>Languages Known (in addition to English)</b></label>
	        <div className="mb-3 row">
	          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
	          <div className="">
	            <Select
	              value={languages}
	              options={options.languages}
	              isClearable
	              isMulti
	              onChange={newValue => setLanguages(newValue)}
	              id='language'
	            />
	          </div>
	         
	        </div>
	      </fieldset>
      <div className="row g-2">
        <div className="col-md text-end">
          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
        </div>
      </div>
    </form>
  );
}

export default RequiredSpecialisation;
