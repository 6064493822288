import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";
import FloatingSelect from '../../components/FloatingSelect';





const ProjectFees = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
	const [ effort_frequency_type, setEffort_frequency_type ] = useState('')
	const [ totalEffortType, setTotalEffortType ] = useState('')
	const [ totalFees, setTotalFees ] = useState('')
	const [ ratePerHour, setRatePerHour ] = useState('')
	const [ errorMessage, setErrorMessage ] = useState('')

  const effort_frequency = [
    { value: 'hours/week', text: 'Hours/Week' },
    { value: 'days/month', text: 'Days/Month' },

  ]

  const effort_type = [
    { value: 'total-hours', text: 'Total Hours' },
    { value: 'total-days', text: 'Total Days' },

  ]

	let history = useHistory()

	const [modal, setModal] = useState(null);

	// if(state != undefined)
	// {
	// 	localStorage.setItem('document',JSON.stringify(state));
	// }
	// const my_data =  JSON.parse(localStorage.getItem('document'));

	const fetchData = async () => {
		console.log(props.proposalDetails)
    setProjectDetails(props.projectDetails)
    setTotalEffortType(props.proposalDetails.total_effort_type)
    setEffort_frequency_type(props.proposalDetails.effort_frequency_type)
    if(props.proposalDetails.total_fees != null)
    {
    	setTotalFees(props.proposalDetails.total_fees)
    }
    if(props.proposalDetails.rate_per_hour != null)
    {
    	setRatePerHour(props.proposalDetails.rate_per_hour)
    }
	}

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	  scrollToUp();
	}, []);

	function handleChange(newValue) {
    setProjectDescription(newValue);
  }

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
			proposal:
			{
				total_fees: totalFees,
				rate_per_hour: ratePerHour,
				effort_frequency_type: event.target.effort_frequency_type.value,
				effort_frequency: event.target.effort_frequency.value,
				total_effort_type: event.target.total_effort_type.value,
				total_effort: event.target.total_effort.value,
			}
    }


		const response = await fetch( '/api/v1/proposals/' + props.proposalId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()

    if (response.ok) {
    	// history.push("/proposal-preview");
    	props.setActiveId(props.activeId + 1)
    	// history.push({pathname:"/proposal-preview", state:{ proposal_id:props.proposalId, project_details_id: props.projectDetails.id }});
    	props.setProposalId(responseJson.id)
    }
    else
    {
    	console.log(responseJson)
    	setErrorMessage(responseJson.effort_frequency[0])

    }
  }

  console.log(errorMessage)



  return (
    <div className="container">

	    <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center mt-3 mb-3">
				
				{
					projectDetails.fees_type_dv == "Fixed cost" && projectDetails.is_rate_required ?
					<div className="row align-items-center mt-3">
						<div className="col-md-3 text-center">
							<div className="text-start">
			          <label className="control-label" htmlFor="projectDescription"><b>Type of project</b></label>
			        </div>
							<div className="form-floating">
		            <input type="text" defaultValue={projectDetails.fees_type_dv} className="form-control" name="title" id="title" placeholder="John" disabled />
		            <label htmlFor="title">Type of project</label>
		          </div>
						</div>						
						<div className="col-md-3 text-center form-check ">
							<div className="text-start form-group required">
			          <label className="control-label" htmlFor="projectDescription"><b>Total Fees</b></label>
			        </div>
					  	<div className="form-floating">
		            <input  type="number" step="0.01" className="form-control" defaultValue={(Math.round(totalFees * 100) / 100).toFixed(2)} onChange={(e)=>setTotalFees(e.target.value)} name="total_fees" id="total_fees" placeholder="John" required />
		            <label htmlFor="title">Total Fees</label>
		          </div>
					  </div>
					  
					  <div className="col-md-3 text-center form-check ">
					  	<div className="text-start form-group required">
			          <label className="control-label" htmlFor="projectDescription"><b>Fee Rate/ hour</b></label>
			        </div>
					  	<div className="form-floating">
		            <input  type="number" step="0.01" className="form-control" defaultValue={(Math.round(ratePerHour * 100) / 100).toFixed(2)} onChange={(e)=>setRatePerHour(e.target.value)} name="rate_per_hour" id="rate_per_hour" placeholder="John" required />
		            <label htmlFor="title">Fee Rate/ hour</label>
		          </div>
					  </div>
						<div className="col-md-3 text-center">
							<div className="text-start">
			          <label className="control-label" htmlFor="projectDescription"><b>Currency</b></label>
			        </div>
							{
								projectDetails.currency == "Other" ?
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.other_currency} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Currency</label>
			          </div>
			          :
			          <div className="form-floating">
			            <input type="text" defaultValue={projectDetails.currency} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Currency</label>
			          </div>
							}

						</div>
					</div>
					:
					projectDetails.fees_type_dv == "Fixed cost" && projectDetails.is_rate_required == false ?
					<div className="row align-items-center mt-3">
						<div className="col-md-4 text-center">
							<div className="text-start">
			          <label className="control-label" htmlFor="projectDescription"><b>Type of project</b></label>
			        </div>
							<div className="form-floating">
		            <input type="text" defaultValue={projectDetails.fees_type_dv} className="form-control" name="title" id="title" placeholder="John" disabled />
		            <label htmlFor="title">Type of project</label>
		          </div>
						</div>						
						<div className="col-md-4 text-center form-check ">
							<div className="text-start form-group required">
			          <label className="control-label" htmlFor="projectDescription"><b>Total Fees</b></label>
			        </div>
					  	<div className="form-floating">
		            <input  type="number" step="1" className="form-control" defaultValue={(Math.round(totalFees * 100) / 100).toFixed(2)} onChange={(e)=>setTotalFees(e.target.value)} name="total_fees" id="total_fees" placeholder="John" required />
		            <label htmlFor="title">Total Fees</label>
		          </div>
					  </div>					 
						<div className="col-md-4 text-center">
							<div className="text-start">
			          <label className="control-label" htmlFor="projectDescription"><b>Currency</b></label>
			        </div>
							{
								projectDetails.currency == "Other" ?
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.other_currency} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Currency</label>
			          </div>
			          :
			          <div className="form-floating">
			            <input type="text" defaultValue={projectDetails.currency} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Currency</label>
			          </div>
							}

						</div>
					</div>
					:projectDetails.fees_type == "time-based" ?
					<div className="row align-items-center mt-3">
						<div className="col-md-4 text-center">
							<div className="text-start">
			          <label className="control-label" htmlFor="projectDescription"><b>Type of project</b></label>
			        </div>
							<div className="form-floating">
		            <input type="text" defaultValue={projectDetails.fees_type_dv} className="form-control" name="title" id="title" placeholder="John" disabled />
		            <label htmlFor="title">Type of project</label>
		          </div>
						</div>						
						<div className="col-md-4 text-center form-check ">
					  	<div className="text-start form-group required">
			          <label className="control-label" htmlFor="projectDescription"><b>Fee Rate/ hour</b></label>
			        </div>
					  	<div className="form-floating">
		            <input  type="number" step="1" className="form-control" defaultValue={(Math.round(ratePerHour * 100) / 100).toFixed(2)} onChange={(e)=>setRatePerHour(e.target.value)} name="rate_per_hour" id="rate_per_hour" placeholder="John" required />
		            <label htmlFor="title">Fee Rate/ hour</label>
		          </div>
					  </div>					 
						<div className="col-md-4 text-center">
							<div className="text-start">
			          <label className="control-label" htmlFor="projectDescription"><b>Currency</b></label>
			        </div>
							{
								projectDetails.currency == "Other" ?
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.other_currency} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Currency</label>
			          </div>
			          :
			          <div className="form-floating">
			            <input type="text" defaultValue={projectDetails.currency} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Currency</label>
			          </div>
							}

						</div>
					</div>
					:
					null


				}
					
				<div className="row align-items-center mt-3 mb-3">
					<div className="col-md-4 text-center">
						<div className={"text-start form-group "+( projectDetails.is_estimation_required ? "required" : "")}>
		          <label className="control-label" htmlFor="projectDescription"><b>Effort Frequency Type</b></label>
		        </div>
						<div className="form-floating">
	            <label htmlFor="title" className="card-title ">Estimated Effort</label>
	            <FloatingSelect  data={effort_frequency} value={effort_frequency_type} onChange={e => setEffort_frequency_type( e.target.value )} name={'effort_frequency_type' }
					     	placeholder={'Estimated Effort'}
						    label={'Select One'} required={projectDetails.is_estimation_required}/>
	          </div>
					</div>
					<div className="col-md-4 text-center form-check ">
						<div className={"text-start form-group "+( projectDetails.is_estimation_required ? "required" : "")}>
		          <label className="control-label" htmlFor="projectDescription"><b>Effort Frequency Value</b></label>
		        </div>
				  	<div className="form-floating">
	            <input type="number" step="1" className="form-control" defaultValue={props.proposalDetails.effort_frequency} name="effort_frequency" id="effort_frequency" placeholder="John" required={projectDetails.is_estimation_required} />
	            <label htmlFor="title">Enter Value</label>
	          </div>
				  </div>
				  {
				  	errorMessage != ''?
				  	<p className="text-danger text-start">{errorMessage}</p>
				  	:
				  	null
				  }

				</div>
				<div className="row align-items-center mt-3 mb-3">
					<div className="col-md-4 text-center">
						<div className={"text-start form-group "+( projectDetails.is_estimation_required ? "required" : "")}>
		          <label className="control-label" htmlFor="projectDescription"><b>Estimated Effort Type</b></label>
		        </div>
						<div className="form-floating">
	            <label htmlFor="title" className="card-title ">Estimated Effort</label>
	            <FloatingSelect data={effort_type} value={totalEffortType} onChange={e => setTotalEffortType( e.target.value )} name={'total_effort_type' }
					     	placeholder={'Effort Type'}
						    label={'Select One'} required={projectDetails.is_estimation_required}/>
	          </div>
					</div>
					<div className="col-md-4 text-center form-check ">
						<div className={"text-start form-group "+( projectDetails.is_estimation_required ? "required" : "")}>
		          <label className="control-label" htmlFor="projectDescription"><b>Estimated Effort Value</b></label>
		        </div>
				  	<div className="form-floating">
	            <input type="number" step="1" className="form-control" defaultValue={props.proposalDetails.total_effort} name="total_effort" id="total_effort" placeholder="John" required={projectDetails.is_estimation_required} />
	            <label htmlFor="title">Enter Value</label>
	          </div>
				  </div>
				</div>
				<div className="row g-2">
	        <div className="col-md text-end">
	          <button  type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
	        </div>
	      </div>

    	</form>

    </div>
  );
}

export default ProjectFees;
