import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../store'
import { useHistory } from 'react-router-dom';
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import CustomEditor from '../../components/CustomEditor'


const ProfileSummaryStep = (props) => {

  const globalState = useContext(store);
  let history = useHistory();
  const [ profileData, setProfileData ] = useState( {} )
  const [ image, setImage ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ value, setValue] = useState('')
  const [ error, setError ] = useState("")



  const fetchData = async () => {
    const response = await fetch('/api/v1/consultant_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

    if (response.ok) {
    	if(responseJson.completed)
    	{
    		history.push('/view-consultant-profile');
    	}
    	else
    	{
      	setProfileData(responseJson)
      	setValue(responseJson.summary)
    	}
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    resetErrors( elements )

    var error_message = 'consultant_profile.summary'

    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
      else
      {
      	var error_mess = "Summary "+errors[error_message]      	
      	setError(error_mess)
      }
    });
  }

  const handleFormSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if(value.replace(/<\/?[^>]+(>|$)/g, "") != "")
    {    	
    	const formData = {
	      user: {
	        consultant_profile_attributes: {
	          id: profileData.id,
	          web_link: event.target.web_link.value,
	          summary: value,
	        }
	      }
	    }
	    if (image){
	      formData.user.image = image
	      formData.user.image_name = `profile_pic.${event.target.image.files[0].type.split('/')[1]}`
	    }
	      
	    const response = await fetch( '/api/v1/users', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()
	    if (response.ok) {
	    	setError("")
	      setProfileData(responseJson)
	      props.setActiveId(props.activeId + 1)
	    } else {
	      showErrors( event.target.elements, responseJson );
	    }  

    }
    else
    {
    	setError("Summary can't be blank")
    }
    
  }

  const uploadImage = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setImage( reader.result ) ;
    reader.readAsDataURL(file);
  }

  function handleChange(newValue) {	    
		setValue(newValue);		 	
  }

  return (
    <div>
      <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">
          <div className="col-md">
            <p>{profileData.user?.first_name}</p>
          </div>
          <div className="col-md">
            <p>{profileData.user?.last_name}</p>
          </div>
          <div className="col-md">
            <p>{profileData.user?.company_name}</p>
          </div>
        </div>

        <div className="row g-2">
          <div className="mb-3">
            <label htmlFor="image" className="form-label">Select profile image</label>
            <input className="form-control" name='image' type="file" id="image" onChange={(e) => uploadImage(e)} />
            <div className="invalid-feedback"></div>
          </div>
        </div>

        <div className="row g-2">
          <div className="form-floating">
            <input
              type="text" className="form-control" defaultValue={profileData.web_link}
              name="web_link" id="web_link" placeholder="web_link" />
            <label htmlFor="web_link">Profile weblink / LinkedIn</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>

        <div className="text-start">
          <CustomEditor id="summary" value={value} placeholder={"Executive Summary of profile"} onChange={handleChange} /> 
          {error != "" ?
          	<p className="text-danger text-start">{error}</p>
          	:
          	null
          }       
        </div>

        <div className="row g-2 mt-3">
          <div className="col-md g-2 text-end">
            <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
          </div>
        </div>
      </form>
    </div>
  );
}

export default ProfileSummaryStep;
