import React, { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { store } from '../store.js';

const ImageComp= (props) =>  {

  const [ userData, setUserData ] = useState( {} )


	const globalState = useContext(store);
  const { dispatch } = globalState;

	const fetchData = async () => {
    const response = await fetch('/api/v1/consultant_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {    	
      setUserData(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }
  }	

	useEffect(() => {    
    fetchData()
  },[])

  return (
    <div className="col-md-1">
      {/*<img src={userData.user?.image_url} alt="BlankProfileImg" className="border border-3 rounded-circle img-responsive" style={{width:"60px",height:"60px"}}/>*/}
    </div>
);
}

export default ImageComp;
