import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import FloatingSelect from '../../components/FloatingSelect'
import  ResetPasswordModalView from '../authentication/ResetPasswordModalView'
import  ClientPublicProfile from './ClientPublicProfile'



const MyProfile = (props) => {

	const globalState = useContext(store);
  const [country, setCountry] = useState('')
  const [userData, setUserData] = useState( {} )
  const [showModal, setShowModal] = useState( false )
  const [showClientModal, setShowClientModal] = useState( false );
  const [modalChangePassword, setModalChangePassword] = useState(null);
  const [modalClientPublicProfile, setModalClientPublicProfile] = useState(null);
  const [fetchEvent, setFetchEvent] = useState(false);
  const [imageField, setImageField] = useState(false);
	const [ image, setImage ] = useState('')
	const [ userId, setUserId ] = useState('')




  const fetchData = async () => {	

    const response = await fetch('/api/v1/users', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
   
    if (response.ok) {
      setUserData(responseJson)
      setUserId(responseJson.id)
      setCountry(responseJson.country_code)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  useEffect(() => {
    if (modalChangePassword)
      showModal ? modalChangePassword.show() : modalChangePassword.hide()
  }, [modalChangePassword, showModal])

  useEffect(() => {
    if (modalClientPublicProfile)
      showClientModal ? modalClientPublicProfile.show() : modalClientPublicProfile.hide()
  }, [modalClientPublicProfile, showClientModal])

  const handleFormSubmit = async (event) => {
    if (event)
      event.preventDefault();

    const formData = {
      user: {
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        country: event.target.country.value,
        job_title: event.target.job_title.value,
        job_role_description: event.target.job_role_description.value,
        phone_number: event.target.phone_number.value,
      }
    }

    if (image){
      formData.user.image = image
      formData.user.image_name = `profile_pic.${event.target.image.files[0].type.split('/')[1]}`
    }

    const response = await fetch( '/api/v1/client_users', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })

    const responseJson = await response.json()

    if (response.ok) {      
       fetchData();
       setImageField(false)     
    } else {
      showErrors( event.target.elements, responseJson );
    }
  }


  const handleCountry = (e) => {
    const user = userData
    user.country = e.target.value
    setUserData( {...userData, country: e.target.value} )
  }

  const uploadpic = (e) => {
  	e.target.classList.remove("is-invalid");
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setImage( reader.result ) ;
    reader.readAsDataURL(file);
  }


  return (
    <div className="container mb-5">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="user-tie" /> My Profile</h2>
        </div>
    	</div>
    	<div className="row g-0">
	    	<div className="col-md-3 text-start mb-3">
	        <img src={userData.image_url}  className="img-responsive" style={{width:"100%"}}/>
	        <button onClick={()=>setImageField(true)}  className="btn btn-primary btn-block ml-auto mt-3">Edit Pictures</button>
	      </div>
	    </div>
    	<div className="">
    		<form onSubmit={handleFormSubmit} className="row g-2">
    			{imageField?
    				<div className="row g-2">
		          <div className="mb-3">
		            <label htmlFor="image" className="form-label">Profile Pic</label>
		            <input className="form-control" name='image' type="file" id="image" onChange={(e) => uploadpic(e)} />
		            <div className="invalid-feedback"></div>
		          </div>
		        </div>
		        :
		        null
		      }
    			<div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="first_name" defaultValue={userData.first_name} id="first_name" placeholder="John" required />
                <label htmlFor="first_name">First Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="last_name" defaultValue={userData.last_name} id="last_name" placeholder="Smith" required />
                <label htmlFor="last_name">Last Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="email" className="form-control" name="email" disabled={true} value={userData.email} id="email" placeholder="name@example.com" onChange={(e)=> setEmail(e.target.value)}   required />
                <label htmlFor="email">Admin Email</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" defaultValue={userData.job_title} name="job_title" id="job_title" placeholder="name@example.com"   required />
                <label htmlFor="job_title">Job Title</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" defaultValue={userData.job_role_description} name="job_role_description" id="job_role_description" placeholder="name@example.com"   required />
                <label htmlFor="job_role_description">Job Role Description</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md-6">
              <FloatingSelect  name='country' data={userData.country_codes} required={true} onChange={e => handleCountry( e )}
                label='Country Code' value={userData.country} placeholder='Select Code' withError='true'/>
            </div>
            <div className="col-md-6">
              <div className="form-floating">
                <input type="tel" className="form-control" name="phone_number" defaultValue={userData.phone_number} id="phone_number" placeholder="+123214124412" required />
                <label htmlFor="phone_number">Phone Number</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
	        	<div className={`col-md g-2 text-start`}>
	            <button onClick={ () => setShowModal( !showModal ) }  type="button" className="btn btn-primary btn-block ml-auto" >Reset Password</button>
	          </div>
	          <div className="col-md g-2 text-end">
	            <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
	          </div>
	        </div>
    		</form>
	      <ResetPasswordModalView show={showModal} setShow={setShowModal} setModal={setModalChangePassword} setFetch={setFetchEvent}/>
	      <div className="col-md g-2 text-start mt-3">
          <button onClick={()=> setShowClientModal(!showClientModal)} className="btn btn-primary font-weight-bold">Display Public Profile</button>
        </div>
	      <ClientPublicProfile clientDataId={userId} show={showClientModal} setShow={setShowClientModal} setModal={setModalClientPublicProfile} setFetch={setFetchEvent}/>

    	</div>
    </div>
  );
}

export default MyProfile;
