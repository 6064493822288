import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../store';
import Modal from './Modal'
import ConsultantProfile from './ConsultantProfile'
import PublicProfile from '../views/PublicProfile';
import ViewJob from '../views/jobs/ViewJob';
import View from '../views/projects/View';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import { useReactToPrint } from 'react-to-print';
import ConsultantProfileForPrint from './ConsultantProfileForPrint';


const SearchProfileCard = (props) => {

	const globalState = useContext(store);
	const [showModal, setShow] = useState( false )
  const [modal, setModal] = useState(null);

  const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  useEffect(() => {
    if (modal)
      showModal ? modal.show() : modal.hide()
  }, [modal, showModal])


  const handleClick = async(index,id) =>
  {
  	props.updateCheckStatus(index, id);
  }

  return (
    <div className="card d-flex flex-row search-profile m-3">
      <div className='w-100'>
      	<div className="card-header d-flex align-items-center">
      		<div className="col-md-1">
      		</div>
        	<h5 className="col-md-10 text-center"><b>{props.name}</b></h5>
        	{props.selectable?
            <div className="col-md-1">
              <input id={`checkbox-${props.userData.id}`} onChange={() => handleClick(props.index,props.userData.id)} checked={props.userData.selected} className="form-check-input text-end" name="select_consultant" type="checkbox" required />
              <label className="form-check-label ms-1" htmlFor={`checkbox-${props.userData.id}`} >Select</label>
            </div>
	        	:
	        	null
	        }
        </div>
        <div className="card-body">
          <div className="row">
            <div className='col-md-5'>
              <img src={props.image_url} alt="User Photo" className='search profile-image mb-3'/>
              {props.userData.promoted_consultant?
               <h6><span className="badge" >Recommended</span></h6>
               :
               null
             }
              <h5 className="card-title mb-1">{props.service}, <b>({props.service_exp})</b></h5>
              <p className="card-text  mb-1">{props.education}.</p>
              <p className="card-text mb-1">{props.city}, {props.country}.</p>
            </div>
            <div className='col-md text-start'>
              {ReactHtmlParser(props.summary)}
            </div>
          </div>        
          
          <div className="row">
          	{
            	globalState.state.user_type == 'client' ?
            	<div className=" text-end">
					      {globalState.state.company_name == 'Pharmalancers Ltd' ?
					      	<button
  					        type="button"
  					        className="btn btn-primary m-2"
  					        onClick={handlePrint}
  					      	>Print Profile
  					      </button>
  					      :
  					      null
  					    }
					      <ConsultantProfileForPrint consultant_id={props.userData.id} ref={componentRef} />
					    	<button type="button" onClick={ () => setShow( !showModal ) }
		              className="btn btn-primary"

			            >View Profile</button>
						    </div>
            	
	            :
	            null
            }
          </div>
        </div>
      </div>
      <ConsultantProfile deleteRefresh={props.deleteFresh} consultant_id={props.userData.id} show={showModal} setModal={setModal} setShow={setShow}  title='Consultant Profile' summary={true} project_consultant={props.project_consultant} savedConsultant={props.savedConsultant} />

    </div>
  );
}

export default SearchProfileCard;
