import React, { useState, useEffect, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ServicesDisplay from '../ServicesDisplay';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'



const ProjectInformation = (props) => {

const globalState = useContext(store);
const { location } = useLocation();
const { dispatch } = globalState;
const [ projectDetails, setProjectDetails ] = useState({})
const [ consultantData, setConsultantData ] = useState({})
const [ companyData, setCompanyData ] = useState({})
const [ shortlistedConsultant, setShortlistedConsultant ] = useState([])
let history = useHistory()

const [modal, setModal] = useState(null);
const [consultantProfileId, setConsultantProfileId] = useState(null);
const [showModal, setShow] = useState( false )
const [showCompanyProfile, setShowCompanyProfile] = useState( false );
const [modalCompanyProfile, setModalCompanyProfile] = useState(null);
const [fetchEvent, setFetchEvent] = useState(false);
const [showClientModal, setShowClientModal] = useState( false );
const [modalClientPublicProfile, setModalClientPublicProfile] = useState(null);

var url_token = window.location.href.split("=");

const fetchData = async () => {

  const response = await fetch('/api/v1/project_details/' +url_token[1], {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': globalState.state.Authentication
    },
    method: 'get'
  })
  const responseJson = await response.json()
  console.log("responseJson")
  console.log(responseJson)
  if (response.ok) {
    setProjectDetails(responseJson)
    setCompanyData(responseJson.company)
    setShortlistedConsultant(responseJson.shortlisted_consultants)
    selectionData
  } else {
    alert.show("Server is down.")
    return false
  }

  const getData = {
      project_detail_id: url_token[1] ,
    }

    const specialisation_response = await fetch('/api/v1/show_project_detail_specialisations/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(getData)
    })

    const specialisation_responseJson = await specialisation_response.json()

    if(specialisation_response.ok)
    {
    	setConsultantData(specialisation_responseJson)
    }

}

const saveProject = async () => {

	const formData = {
    project_detail_id: projectDetails.id,
  }

  const response = await fetch('/api/v1/save_project_details' , {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': globalState.state.Authentication
    },
    method: 'post',
    body: JSON.stringify(formData)
  })
  const responseJson = await response.json()

  if (response.ok) {
    history.push('/project-rfp')
  } else {
    alert.show("Server is down.")
    return false
  }

}

const ProposalCreated = (proposal) => {
  confirmAlert({
  	customUI: ({ onClose }) => {
	    return (
	      <div className='p-5 custom-ui border border-primary rounded '>
	      	{
	      		proposal.status != "posted" ?
	      		<h5>Proposal already created in Project WIP. Do you want to view?</h5>
	      		:
	      		<h5>Proposal already submitted. Do you want to view?</h5>
	      	}
	        <div className="row g-2 mt-3">
		        <div className="col-md g-2 text-start">
		        	<button className="btn btn-danger" onClick={onClose}>No</button>
		        </div>
		        <div className="col-md g-2 text-end">
			        <button className="btn btn-primary"
			          onClick={() => {
			            history.push({pathname:"/proposal-preview", state:{ project_details_id: proposal.project_detail.id, proposal_id:proposal.id}});
			            onClose();
			          }}
			        >
			          Yes
			        </button>
			      </div>
			    </div>
	      </div>
	    );
	  }
	});
};

const checkProposal = async (value) => {

  const response = await fetch('/api/v1/check_proposal/' + projectDetails.id , {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': globalState.state.Authentication
    },
    method: 'get',

  })
  const responseJson = await response.json()

  if (response.ok) {
  	if(responseJson.length > 0)
  	{
			ProposalCreated(responseJson[0])
  	}
  	else{
  		if(value == 'createProposal')
  		{
  			history.push({pathname:"/new-proposal", state:{ project_details_id: projectDetails.id}})
  		}
  		else
  		{
  			saveProject();
  		}

  	}
  } else {
    alert.show("Server is down.")
    return false
  }

}

const selectionData = shortlistedConsultant.map( u => {
  return u.consultant_profile_id ;
})


	useEffect( () => {
	  fetchData();
	}, []);

	useEffect(() => {
    if (modalCompanyProfile)
      showCompanyProfile ? modalCompanyProfile.show() : modalCompanyProfile.hide()
  }, [showCompanyProfile, modalCompanyProfile])

  useEffect(() => {
    if (modalClientPublicProfile)
      showClientModal ? modalClientPublicProfile.show() : modalClientPublicProfile.hide()
  }, [modalClientPublicProfile, showClientModal])

	const Listing = ( props ) => {
	  if(props.list?.length < 1)
	    return <div><ul>{props.noListMsg}</ul></div>;

	  const list = props.list?.map( (name, i) => {
	    return <li key={i}>{name.label}</li>
	  })
	  return <ul>{list}</ul>;
	};


  return (
    <div className="container" id="container_id">
      <div className="d-flex align-items-center justify-content-between mt-2">
      	<div className="text-center">
        </div>
        <div className="text-center">
        	<h2>Project Requirements</h2>
        </div>
        <div className="text-end">
        	<button  type="button" onClick={()=> history.goBack()}
		          className="btn btn-primary font-weight-bold text-end"
		        >Back
		      </button>
        </div>
      </div>
      <div className="d-flex align-items-center justify-content-between mt-2">
      	<div className="col-md-10 ms-3">
	      	<div className="row g-0 text-start mt-2">
	        	<h5 className="col-md-4"><b>Company Name: </b></h5>
	        	<button type="button" className="col-md-7 text-start btn btn-link p-0" onClick={() => setShowCompanyProfile(!showCompanyProfile)} ><h5>  {companyData.name} </h5></button>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-4"><b>Posted by: </b></h5>
	      		<button type="button" className="col-md-7 text-start btn btn-link p-0" onClick={() => setShowClientModal(!showClientModal)} ><h5>  {projectDetails.creator}</h5></button>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-4"><b>Date Posted:</b></h5>
	      		<h5 className="col-md-7"><b>{projectDetails.update_date}</b></h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-4 text-danger"><b>Submission deadline: </b></h5>
	      		<h5 className="col-md-7 text-danger"><b>{projectDetails.proposal_due_date_dv}</b></h5>
	      	</div>
      	</div>
      	<div className="col-md-2">
      	</div>
    	</div>
    	<div className="modal-body">
	        <div className="card">
	          <div className="card-header text-start">
	            <h3><b>{projectDetails.title}</b></h3>
	          </div>
	          <div className="card-body text-start">
	            <div className="card-text">
	              {ReactHtmlParser(projectDetails.description)}
	            </div>
	          </div>
	        </div>

	        <div className="card mt-3">
	          <div className="card-header text-start">
	            <h4><b>Service Offerings</b></h4>
	          </div>
	          <div className="card-body text-start">
	            <div>
	              <p className="text-muted">The main pharmaceutical function(s) in which the project requires specialist services</p>
	            </div>
	            <ul>
	              <p><strong>Primary Service:</strong></p>
	              <strong>{projectDetails.primary_service} <i>({projectDetails.primary_service_exp})</i></strong>
	              <ul>
                  <ServicesDisplay services={projectDetails.primary_sub_services}/>
	              </ul>
	            </ul>
	          </div>
	        </div>

	        <div className="card mt-3">
	          <div className="card-header text-start">
	            <h4><b>Specialisations</b></h4>
	          </div>
	          <div className="card-body text-start">

	            <div>
	              <p className="text-muted">The Therapeutic Areas and Product types in which the project requires specific expertise or experience.</p>
	            </div>
	            <ul>
	              <strong>Therapeutic Areas</strong>
	              <Listing list={consultantData.therapeutic} noListMsg="None selected" />
	              <hr/>
	              <strong>Product Specialisations</strong>
	              <Listing list={consultantData.product} noListMsg="None selected" />
	              <hr/>
	              <strong>Service Offering Specialisations</strong>
	              <Listing list={consultantData.service} noListMsg="None selected" />
	              <hr/>
	              <strong>Languages Known (in addition to English)</strong>
	              <Listing list={consultantData.languages} noListMsg="None selected" />
	            </ul>
	          </div>
	        </div>
	        <div className="card mt-3">
	          <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Logistics</b></h4>
	          <div className="card-body text-start">
		          {
		          	projectDetails.consultant_country != '' ?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Consultant Country : </strong>
			            <h6 className="col-md-7">{projectDetails.display_consultant_country}</h6>
			          </div>
			          :
			          <div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Consultant Country : </strong>
			            <h6 className="col-md-7">None</h6>
			          </div>
		          }
		          <div className="row g-0 text-start mt-1">
		            <strong className="col-md-5">Project Location : </strong>
		            <h6 className="col-md-7">{projectDetails.location_type_dv}</h6>
		          </div>
		          {
		          	projectDetails.location_type_dv == "Fully On-site" || projectDetails.location_type == "mix"?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Onsite City : </strong>
			            <h6 className="col-md-7">{projectDetails.onsite_city}</h6>
			          </div>
			          :
			          null
		          }
		          {
		          	projectDetails.location_type_dv == "Fully On-site" || projectDetails.location_type == "mix" ?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Onsite Country : </strong>
			            <h6 className="col-md-7">{projectDetails.display_onsite_country}</h6>
			          </div>
			          :
			          null
		          }
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Receipt of Proposals : </strong>
		            <h6 className="col-md-7">{projectDetails.proposal_due_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Selection of Consultant : </strong>
		            <h6 className="col-md-7">{projectDetails.selection_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Project Start : </strong>
		            <h6 className="col-md-7">{projectDetails.start_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Estimated project duration : </strong>
		            <h6 className="col-md-7">{projectDetails.estimated_duration} {projectDetails.estimated_duration_category}</h6>
		          </div>
		        </div>
	        </div>
	        <div className="card mt-3">
	          <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees</b></h4>
	          <div className="card-body text-start">
		          <div className="row g-0 text-start mt-1">
		            <strong className="col-md-5"> Project fees type : </strong>
		            <h6 className="col-md-7">{projectDetails.fees_type_dv}</h6>
		          </div>

		          {projectDetails.currency == 'Other' ?
		          	<div className="row g-0 text-start mt-2">
			            <strong className="col-md-5">Currency : </strong>
			            <h6 className="col-md-7">{projectDetails.other_currency}</h6>
			          </div>
		          	:
			          <div className="row g-0 text-start mt-2">
			            <strong className="col-md-5">Currency : </strong>
			            <h6 className="col-md-7">{projectDetails.currency}</h6>
			          </div>
		          }

		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Rate / Hour Required : </strong>
		            <h6 className="col-md-7">{projectDetails.is_rate_required ? "Yes" : "No"}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Estimated total project hours required? : </strong>
		            <h6 className="col-md-7">{projectDetails.is_estimation_required ? "Yes" : "No"}</h6>
		          </div>
		        </div>
    	      <ClientPublicProfile clientDataId={projectDetails.creator_id} show={showClientModal} setShow={setShowClientModal} setModal={setModalClientPublicProfile} setFetch={setFetchEvent}/>
    	      <CompanyPublicProfile companyDataId={companyData.id} show={showCompanyProfile} setShow={setShowCompanyProfile} setModal={setModalCompanyProfile} setFetch={setFetchEvent}/>
	        </div>
	        <div className="row g-3 mt-3 mb-3 justify-content-between">
	        	<div className="col-md-4 text-start">
	            <button   type="button" onClick={()=> history.goBack()} className="btn btn-secondary btn-block ml-auto" >Close</button>
	          </div>
	          <div className="col-md-4 text-center">
	            <button  type="button" onClick={()=> checkProposal('saveRequirements')} className="btn btn-primary btn-block ml-auto" >Save</button>
	          </div>
          	<div className="col-md-4 text-end">
	            <button  type="submit" onClick={()=> checkProposal('createProposal')} className="btn btn-primary btn-block ml-auto ">Create Proposal</button>
	          </div>
	        </div>
      	</div>

    </div>
  );
}

export default ProjectInformation;
