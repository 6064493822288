import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import FloatingSelect from '../../components/FloatingSelect'
import  ResetPasswordModalView from '../authentication/ResetPasswordModalView'
import Modal from '../../components/Modal';


const ClientPublicProfile = (props) => {

	const globalState = useContext(store);
  const [country, setCountry] = useState('')
  const [userData, setUserData] = useState( {} )
  const [showModal, setShowModal] = useState( false )
  const [modalChangePassword, setModalChangePassword] = useState(null);
  const [fetchEvent, setFetchEvent] = useState(false);
  const [imageField, setImageField] = useState(false);
	const [ image, setImage ] = useState('')


  const fetchData = async () => {	
  	if(props.show)
  	{
  		const response = await fetch('/api/v1/user_public_profile/' + props.clientDataId, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'get'
	    })
	    const responseJson = await response.json()
	   	
	  
	    if (response.ok) {
	      setUserData(responseJson)
	      setCountry(responseJson.country_code)
	    } else {
	      alert.show("Server is down.")
	      return false
	    }
  	}
    
  }

  useEffect( () => {
    fetchData();
  },[props.show]);

  

  const handleFormSubmit = async (event) => {
    if (event)
      event.preventDefault();

    const formData = {
      user: {
        country: event.target.country.value,
        job_title: event.target.job_title.value,
        phone_number: event.target.phone_number.value,
      }
    }

    if (image){
      formData.user.image = image
      formData.user.image_name = `profile_pic.${event.target.image.files[0].type.split('/')[1]}`
    }

    const response = await fetch( '/api/v1/client_users', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })

    const responseJson = await response.json()

    if (response.ok) {      
       fetchData();
       setImageField(false)     
    } else {
      showErrors( event.target.elements, responseJson );
    }
  }


  const handleCountry = (e) => {
    const user = userData
    user.country = e.target.value
    setUserData( {...userData, country: e.target.value} )
  }

  const uploadpic = (e) => {
  	e.target.classList.remove("is-invalid");
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setImage( reader.result ) ;
    reader.readAsDataURL(file);
  }




  return (
  	<Modal setModal={props.setModal} setShow={props.setShow} id="profile" title='Profile' project_consultant={true} >
	    <div className="container mb-5">         	
	    	<div className="">
	    		<div className="card mt-1">
	          <div className="row g-0">
	            <div className="col-md-3 text-start">
								<img src={userData.image_url} alt="BlankProfileImg" className="img-responsive" style={{width:"100%"}}/>            </div>
	            <div className="col-md-8 text-start ms-2">
	              <div className="card-body">                
	                <h3 className="card-title"><b>  {userData.first_name} {userData.last_name} </b></h3>                
	                <h5 className="card-title">  {userData.job_title} </h5>                
	                <h5 className="card-title">  {userData.job_role_description} </h5>                
	                <hr/>
	                <h5 className="card-title">  {userData.company_name}, {userData.country_name} </h5>                
	              </div>
	            </div>
	          </div>
	        </div>    		
	      </div>	     
	    </div>
    </Modal>
  );
}

export default ClientPublicProfile;
