import React, { useRef, useEffect, useState } from 'react';
import ReactQuill from 'react-quill';

const CustomEditor = (props) => {

  function handleChange(val) {
    props.onChange(val);
  }

  return(
      <ReactQuill
        id={props.id}
        name={props.id}
        value={props.value}
        placeholder={props.placeholder}
        onChange={handleChange}>
      </ReactQuill>
  );
}

export default CustomEditor;
