import React, { useState, useEffect, useContext } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import ServicesDisplay from '../ServicesDisplay';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';



const ProjectPreview = (props) => {

const globalState = useContext(store);
const { state , location} = useLocation();
const { dispatch } = globalState;
const [ projectDetails, setProjectDetails ] = useState({})
const [ consultantData, setConsultantData ] = useState({})
const [ shortlistedConsultant, setShortlistedConsultant ] = useState([])
let history = useHistory()

const [modal, setModal] = useState(null);
const [consultantProfileId, setConsultantProfileId] = useState(null);
const [showModal, setShow] = useState( false )
const [firstRender, setFirstRender] = useState( true )
var first_time = 'yes';

useEffect(() => {
  if (modal)
    showModal ? modal.show() : modal.hide()
}, [modal, showModal])

if(state != undefined)
{
	localStorage.setItem('document',JSON.stringify(state));
}

const my_data =  JSON.parse(localStorage.getItem('document'));
console.log("my_data")
console.log(my_data)
var url_token = window.location.href.split("=");

  console.log("url_token@@@@@@@@@@@@@@@@@@")
  console.log(projectDetails)

const fetchData = async () => {

	if(url_token[1] != undefined)
	{
		const response = await fetch('/api/v1/project_details/' + url_token[1], {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  if (response.ok) {
	    setProjectDetails(responseJson)
	    setShortlistedConsultant(responseJson.shortlisted_consultants)
	    selectionData
	  } else {
	    alert.show("Server is down.")
	    return false
	  }

	  const getData = {
      project_detail_id: url_token[1] ,
    }

    const specialisation_response = await fetch('/api/v1/show_project_detail_specialisations/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(getData)
    })

    const specialisation_responseJson = await specialisation_response.json()

    if(specialisation_response.ok)
    {
    	setConsultantData(specialisation_responseJson)
    	if(my_data.selected_consultant == true || firstRender == false)
    	{
    		window.scrollTo(0, document.body.scrollHeight);
    	}
    	else if(first_time == 'yes')
    	{
    		window.scrollTo(0, 0);
    	}
    }
	}
	else
	{
		const response = await fetch('/api/v1/project_details/' + my_data.projectId, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  if (response.ok) {
	    setProjectDetails(responseJson)
	    setShortlistedConsultant(responseJson.shortlisted_consultants)
	    selectionData
	  } else {
	    alert.show("Server is down.")
	    return false
	  }

	  const getData = {
      project_detail_id: my_data.projectId ,
    }

    const specialisation_response = await fetch('/api/v1/show_project_detail_specialisations/', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(getData)
    })

    const specialisation_responseJson = await specialisation_response.json()

    if(specialisation_response.ok)
    {
    	setConsultantData(specialisation_responseJson)
    	if(my_data.selected_consultant == true || firstRender == false)
    	{
    		window.scrollTo(0, document.body.scrollHeight);
    	}
    	else if(first_time == 'yes')
    	{
    		window.scrollTo(0, 0);
    	}
    }
	}

}

const selectionData = shortlistedConsultant.map( u => {
  return u.consultant_profile_id ;
})

const removeConsultant = async (value) => {
	setFirstRender(false)
	first_time = 'no'
	const formData = {
    project_detail_id: my_data.projectId,
    consultant_profile_ids: [value]
  }

  const response = await fetch('/api/v1/shortlisted_consultants/destroy', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': globalState.state.Authentication
    },
    method: 'delete',
    body: JSON.stringify(formData)
  })
  const responseJson = await response.json()

  if (response.ok) {
    fetchData()
  } else {
    alert.show("Server is down.")
    return false
  }

}

const postProject = async (value) => {
	const formData = {
    project_detail_id: my_data.projectId,
  }

  const response = await fetch('/api/v1/post_project_details', {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': globalState.state.Authentication
    },
    method: 'put',
    body: JSON.stringify(formData)
  })

  const responseJson = await response.json()
  if (response.ok) {
    history.push("/project-posted");
  } else {
    postProjectError(responseJson);
    return false
  }

}

const deleteProject = async () => {
	const formData = {
    project_detail_id: my_data.projectId,
    status: 'projectPosted'
  }

  const response = await fetch('/api/v1/project_details/'+ my_data.projectId, {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': globalState.state.Authentication
    },
    method: 'delete',
  })

  const responseJson = await response.json()

  if (response.ok) {
    history.push("/project-saved");
  } else {
    alert.show("Server is down.")
    return false
  }

}

const consultantRemove = (consultant__id) => {
  confirmAlert({
  	customUI: ({ onClose }) => {
	    return (
	      <div className='p-5 custom-ui border border-primary rounded'>

	        <h5>Remove this consultant?</h5>
	        <div className="row g-2 mt-3">
		        <div className="col-md g-2 text-start">
		        	<button className="btn btn-danger" onClick={onClose}>No</button>
		        </div>
		        <div className="col-md g-2 text-end">
			        <button className="btn btn-primary"
			          onClick={() => {
			            removeConsultant(consultant__id);
			            onClose();
			          }}
			        >
			          Yes
			        </button>
			      </div>
			    </div>
	      </div>
	    );
	  }
	});
};

const postProjectError = (error) => {
  confirmAlert({
  	customUI: ({ onClose }) => {
	    return (
	      <div className='p-5 custom-ui-error border border-primary rounded '>

	        <h5>{error.proposal_due_date[0]}</h5>
	        <div className="row g-2 mt-3">
		        <div className="col-md g-2 text-start">
		        	<button className="btn btn-danger" onClick={onClose}>No</button>
		        </div>
		        <div className="col-md g-2 text-end">
			        <button className="btn btn-primary"
			          onClick={() => {
			            history.push({pathname:"/edit-project", state:{ projectDetails: projectDetails, date_error:error}});
			            onClose();
			          }}
			        >
			          Yes
			        </button>
			      </div>
			    </div>
	      </div>
	    );
	  }
	});
};

// const postProjectError = () => {
//     confirmAlert({
//       title: '',
//       message: 'Receipt of proposals date should be greater than selection of consultant date consultant date should be greater than project start date.',
//       buttons: [
//         {
//           label: 'Yes',
//           onClick: () => alert('Click Yes')
//         },
//         {
//           label: 'No',
//           onClick: () => alert('Click No')
//         }
//       ]
//     });
//   };

const ProjectConfirmation = (consultant__id) => {
  confirmAlert({
  	customUI: ({ onClose }) => {
	    return (
	      <div className='p-5 custom-ui border border-primary rounded '>

	        <h5>Confirm Post Project?</h5>
	        <div className="row g-2 mt-3">
		        <div className="col-md g-2 text-start">
		        	<button className="btn btn-danger" onClick={onClose}>No</button>
		        </div>
		        <div className="col-md g-2 text-end">
			        <button className="btn btn-primary"
			          onClick={() => {
			            postProject();
			            onClose();
			          }}
			        >
			          Yes
			        </button>
			      </div>
			    </div>
	      </div>
	    );
	  }
	});
};

const deleteProjectAlert = () => {
  confirmAlert({
  	customUI: ({ onClose }) => {
	    return (
	      <div className='p-5 custom-ui border border-primary rounded'>

	        <h5>Delete this project?</h5>
	        <div className="row g-2 mt-3">
		        <div className="col-md g-2 text-start">
		        	<button className="btn btn-danger" onClick={onClose}>No</button>
		        </div>
		        <div className="col-md g-2 text-end">
			        <button className="btn btn-primary"
			          onClick={() => {
			            deleteProject();
			            onClose();
			          }}
			        >
			          Yes
			        </button>
			      </div>
			    </div>
	      </div>
	    );
	  }
	});
};

	useEffect( () => {
	  fetchData();
	}, []);

	const Listing = ( props ) => {
	  if(props.list?.length < 1)
	    return <div><ul>{props.noListMsg}</ul></div>;

	  const list = props.list?.map( (name, i) => {
	    return <li key={i}>{name.label}</li>
	  })
	  return <ul>{list}</ul>;
	};

	// useEffect( () => {
 //    var element = document.getElementById("container_id");
 //    window.scrollTo(0,element.scrollHeight);
 //  },[]);

  const findConsultant = async () => {
  	setFirstRender(false)
  	history.push({pathname:"/search-consultant-project", state:{ projectId:my_data.projectId,shortlisted_consultant:selectionData, projectDetails: projectDetails}});
  }

  const findConsultantByName = async () => {
  	setFirstRender(false)
  	history.push({pathname:"/search-by-name", state:{ projectId:my_data.projectId,shortlisted_consultant:selectionData, project_title: projectDetails.title}});
  }


  const onCloseClick = async () => {
  	history.push("/project-saved");
  }

  const EditProject = async () => {
  	history.push({pathname:"/edit-project", state:{ projectDetails: projectDetails}});
  }

  const copyProject = async () => {
  	dispatch({ type: 'project',project_id: my_data.projectId});
  	history.push({pathname:"/new-project", state:{copyProjectState:true}});
  }



  return (
    <div className="container" id="scrollingContainer">
      <div className="d-flex align-items-center justify-content-between">
        <div className="text-center">
        </div>
        <div className="text-center">
          {my_data.wip?
          	<h2>Project Requirements</h2>
          	:
          	<h2>Project Preview</h2>
          }
        </div>
        {
        	projectDetails.status == 'posted' && globalState.state.access_role != "accountant"  ?
        	 <div className="text-end">
	          <button onClick={()=>copyProject()}  type="submit"  className="btn btn-primary btn-block ml-auto ">Copy</button>
	        </div>
	        :
	        <div className="text-end">
	        </div>
        }


    	</div>
    	<div className="container modal-body">
	        <div className="card">
	          <div className="card-header text-start">
	            <h3><b>{projectDetails.title}</b></h3>
	          </div>
	          <div className="card-body text-start">
	            <div className="card-text">
	              {ReactHtmlParser(projectDetails.description)}
	            </div>
	          </div>
	        </div>

	        <div className="card mt-3">
	          <div className="card-header text-start">
	            <h4><b>Service Offerings</b></h4>
	          </div>
	          <div className="card-body text-start">
	            <div>
	              <p className="text-muted">The main pharmaceutical function(s) in which the project requires specialist services</p>
	            </div>
	            <ul>
	              <p><strong>Primary Service:</strong></p>
	              <strong>{projectDetails.primary_service} <i>({projectDetails.primary_service_exp})</i></strong>
	              <ul>
                  <ServicesDisplay services={projectDetails.primary_sub_services}/>
	              </ul>
	            </ul>
	          </div>
	        </div>

	        <div className="card mt-3">
	          <div className="card-header text-start">
	            <h4><b>Specialisations</b></h4>
	          </div>
	          <div className="card-body text-start">

	            <div>
	              <p className="text-muted">The Therapeutic Areas and Product types in which the project requires specific expertise or experience.</p>
	            </div>
	            <ul>
	              <strong>Therapeutic Areas</strong>
	              <Listing list={consultantData.therapeutic} noListMsg="None selected" />
	              <hr/>
	              <strong>Product Specialisations</strong>
	              <Listing list={consultantData.product} noListMsg="None selected" />
	              <hr/>
	              <strong>Service Offering Specialisations</strong>
	              <Listing list={consultantData.service} noListMsg="None selected" />
	              <hr/>
	              <strong>Languages Known (in addition to English)</strong>
	              <Listing list={consultantData.languages} noListMsg="None selected" />
	            </ul>
	          </div>
	        </div>
	        <div className="card mt-3">
	          <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Logistics</b></h4>
	          <div className="card-body text-start">
		          {
		          	projectDetails.consultant_country != '' ?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Consultant Country : </strong>
			            <h6 className="col-md-7">{projectDetails.display_consultant_country}</h6>
			          </div>
			          :
			          <div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Consultant Country : </strong>
			            <h6 className="col-md-7">None</h6>
			          </div>
		          }
		          <div className="row g-0 text-start mt-1">
		            <strong className="col-md-5">Project Location : </strong>
		            <h6 className="col-md-7">{projectDetails.location_type_dv}</h6>
		          </div>
		          {
		          	projectDetails.location_type_dv == "Fully On-site" || projectDetails.location_type == "mix"?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Onsite City : </strong>
			            <h6 className="col-md-7">{projectDetails.onsite_city}</h6>
			          </div>
			          :
			          null
		          }
		          {
		          	projectDetails.location_type_dv == "Fully On-site" || projectDetails.location_type == "mix" ?
		          	<div className="row g-0 text-start mt-1">
			            <strong className="col-md-5">Onsite Country : </strong>
			            <h6 className="col-md-7">{projectDetails.display_onsite_country}</h6>
			          </div>
			          :
			          null
		          }
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Receipt of Proposals : </strong>
		            <h6 className="col-md-7">{projectDetails.proposal_due_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Selection of Consultant : </strong>
		            <h6 className="col-md-7">{projectDetails.selection_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Date for Project Start : </strong>
		            <h6 className="col-md-7">{projectDetails.start_date}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Estimated project duration : </strong>
		            <h6 className="col-md-7">{projectDetails.estimated_duration} {projectDetails.estimated_duration_category}</h6>
		          </div>
		        </div>
	        </div>
	        <div className="card mt-3">
	          <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees</b></h4>
	          <div className="card-body text-start">
		          <div className="row g-0 text-start mt-1">
		            <strong className="col-md-5"> Project fees type : </strong>
		            <h6 className="col-md-7">{projectDetails.fees_type_dv}</h6>
		          </div>

		          {projectDetails.currency == 'Other' ?
		          	<div className="row g-0 text-start mt-2">
			            <strong className="col-md-5">Currency : </strong>
			            <h6 className="col-md-7">{projectDetails.other_currency}</h6>
			          </div>
			          :
			          <div className="row g-0 text-start mt-2">
			            <strong className="col-md-5">Currency : </strong>
			            <h6 className="col-md-7">{projectDetails.currency}</h6>
			          </div>
		          }

		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Rate / Hour Required : </strong>
		            <h6 className="col-md-7">{projectDetails.is_rate_required ? "Yes" : "No"}</h6>
		          </div>
		          <div className="row g-0 text-start mt-2">
		            <strong className="col-md-5">Estimated total project hours required? : </strong>
		            <h6 className="col-md-7">{projectDetails.is_estimation_required ? "Yes" : "No"}</h6>
		          </div>
		        </div>
	        </div>
	        <div className="card mt-3">
	          <h4 className="card-header d-flex align-items-center justify-content-between"><b>Selected Consultants</b></h4>
	          {
	          	shortlistedConsultant.map( (consultant) =>
	          	{
	          		return(
				          <div key={consultant.id} className="card mb-3 m-3">
							    	<div className="row g-2 m-1 align-items-center justify-content-between">
							      	<div className="col-md text-start ms-4">
												<h6 className="text-start">{consultant.consultant_name}</h6>
											</div>
											<div className="col-md mb-2 text-end">
												<button  onClick={ () => {setConsultantProfileId(consultant.consultant_profile_id),setShow( !showModal )} }
							            className="btn btn-primary btn-block "
								          >View
								        </button>

												{projectDetails.status != 'posted' ?
													<button onClick={()=> consultantRemove(consultant.consultant_profile_id)}
								            className="btn btn-danger btn-block ms-4"
									          >Unselect
									        </button>
													:
													null
												}
											</div>


										</div>
						    	</div>

	          		)
	          	})
	          }
          {projectDetails.status != 'posted' ?
	          <div className="row g-2 m-3">
	          	<div className="col-md g-2 text-start">
		            <button onClick={()=>findConsultantByName()} type="button" className="btn btn-primary btn-block ml-auto" >Find Consultants By Name</button>
		          </div>
		          <div className="col-md g-2 text-end">
		            <button onClick={()=>findConsultant()}  type="button" className="btn btn-primary btn-block ml-auto" >Find Consultants By Algorithm</button>
		          </div>
	          </div>
	          :null
	        }
	        </div>
	        {projectDetails.status != 'posted' ?
		        <div className="row g-3 mt-3 mb-3 justify-content-between">
		        	<div className="col-md-4 text-start">
		            <button onClick={()=>deleteProjectAlert()}  type="button" className="btn btn-danger" >Delete</button>
		          </div>
		          <div className="col-md-4 text-center">
		            <button  onClick={()=>onCloseClick()} type="button" className="btn btn-secondary btn-block ml-auto" >Close</button>
		          </div>
		          {shortlistedConsultant.length > 0 ?
		          	<div className="col-md-4 text-end">
	  	            <button  type="submit" onClick={()=>ProjectConfirmation()} className="btn btn-primary btn-block ml-auto ">Post Project</button>
	  	          </div>
	  	          :
	  	          <div className="col-md-4 text-end">
	  	          </div>
	  	        }

		        </div>
		        :null
	        }

        	<ConsultantProfile project_consultant={true} consultant_id={consultantProfileId} show={showModal} setShow={setShow} setModal={setModal}  />

        	<div >
      		</div>

      	</div>

    </div>
  );
}

export default ProjectPreview;
