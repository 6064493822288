import React, { useContext, useEffect, useState,useRef } from 'react';


function groupByArr(data) {
  return data.reduce(function(rv, x) {
    (rv[x[1]] = rv[x[1]] || []).push(x[0]);
    return rv;
  }, {});
}


const ServicesDisplay = (props) => {
  let subdata = ( props.services ? groupByArr(props.services) : {} )
  let categories = Object.keys(subdata)
  // console.log("Service Display Cat");
  // console.log(subdata);
  console.log(categories);

  const withcategorialservices = categories?.map( data =>
    <li>{data}
      <ul>
      {
        subdata[data]?.map( grpdata =>
          <li>{grpdata}</li>
        )
      }
      </ul>
    </li>
  )

  const services = categories?.map( data =>
      subdata[data]?.map( grpdata =>
        <li>{grpdata}</li>
      )
  )

  return (
    <div>{ categories.includes("null") ? services : withcategorialservices }</div>
  )
}

export default ServicesDisplay;
