import React, { useRef, useEffect } from 'react';
import { Modal } from 'bootstrap';
import { Link, useHistory,useLocation } from 'react-router-dom';

const NewModal = (props) => {
  const modalRef = useRef(null);

	const location = useLocation();
  const base_url = window.location.origin

  useEffect(() => {
    let bsModal = new Modal(modalRef.current, {
      keyboard: false,
      backdrop: 'static'
    })
    props.setModal( bsModal )
  }, [])

  const signupOption = async(option) =>
  {
  	if(option == 'consultant')
  	{
  		window.location.replace(base_url+"/signup");
  	}
  	else if(option == 'company')
  	{
  		window.location.replace(base_url+"/client-signup"); 		
  	}
  	props.setShow(false)
  }

 
  const cancelModal = () =>
  {
  	props.setShow(false) ; 	
  	window.location.reload();
  }

  return (
    <div ref={modalRef} className="modal fade" aria-labelledby="modal" aria-hidden="true" >
      <div className="modal-dialog modal-xl modal-dialog-centered">
        <div className="modal-content">
					<div className="modal-header">
            <h5 className="modal-title">Sign Up</h5>
            <button type="button" className="btn-close" onClick={ () => cancelModal() } aria-label="Close"></button>
          </div>
          <div className="modal-body">
            <div className="d-flex justify-content-between p-5">
				    	<div className="text-center col-md-5">
				    		<h5>If you are a consultant or a freelancer, sign up for a free account below</h5>
		        		<button className="btn btn-primary btn-block btn-lg" onClick={()=>signupOption('consultant')}>         Consultant Sign Up        </button>
		        	</div>
		        	<div className="text-center col-md-1 border-end">		        				        	
		        	</div>
		        	<div className="text-center col-md-1">		        				        	
		        	</div>		        	
		        	<div className="text-center col-md-5">
		        		<h5>If you represent a life sciences company, open a free company account below</h5>
		        		<button className="btn btn-primary btn-block btn-lg" onClick={()=>signupOption('company')}>         Company Sign Up         </button>
							</div>
						</div>
          </div>                   
        </div>
      </div>
    </div>
  );
}

export default NewModal;
