import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import { Link, useHistory, useLocation } from 'react-router-dom';

import SearchProfileCard from '../../components/SearchProfileCard'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const SearchByName = (props) => {


	const { state } = useLocation();

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}

	const my_data =  JSON.parse(localStorage.getItem('document'));
  const [ selectedConsultant, setSelectedConsultant ] = useState([])
  const globalState = useContext(store);
  const [ users, setUsers ] = useState([])
  const [ firstName, setFirstName ] = useState('')
  const [ message, setMessage ] = useState('')
  const [ selecteAll, setSelecteAll ] = useState(false)
  const [selectionData, setSelectionData] = useState([]);  
  const [selectedConsultantId, setSelectedConsultantId] = useState(my_data.shortlisted_consultant);

  let history = useHistory()


  const handleFormSubmit = async () => {
    event.preventDefault();

    const formData = {
      first_name: firstName
    }

    const response = await fetch( '/api/v1/search_by_name', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })

    const profiles = await response.json()
    if(profiles.length == 0)
    {
    	setMessage("No Records Found")
    }
    if (response.ok) {
      setUsers(profiles.map(profile => ({ ...profile, selected: false })))
    
    	if(selectedConsultantId.length >= 0)
    	{
    		var profile_array = [];

    		profiles.map((user)=>
    		{
    			console.log(user.id)
    			selectedConsultantId.map((user_id)=>
    			{
    				if(user_id === user.id)
    				{
    					user.selected = true; 
    					profile_array.push(user);   					
    				}    				
    			});

    			if(!user.selected)
    			{
    				profile_array.push(user);
    			}
    		});

    		console.log("profile_array")
    		console.log(profile_array)
    		setUsers(profile_array);
    	}
      setSelectedConsultant(profiles.map(profile => ({ ...profile, selected: true })))
      setSelectionData(profiles.map(profile => ({ ...profile, selected: false })));
    } else {
    }
  }

  const all_ids= users.map((user)=>{
  	return user.id
  })


  const updateCheckStatus = async(index,id) => {

  	var array_Id = selectedConsultantId;  	

  	if(array_Id.includes(id))
  	{  		
  		function removeId(array_Id) {
			  return array_Id != id;
			}
			setSelectedConsultantId(array_Id.filter(removeId));
  	}
  	else
  	{
  		array_Id.push(id)
  		setSelectedConsultantId(array_Id);
  	}  	

    setUsers(
      users.map((user, currentIndex) =>
        currentIndex === index
          ? { ...user, selected: !user.selected }
          : user
      )
    )
    setSelecteAll(false);
       

  }


  const selectAll = async() => {
  	if(selecteAll)
  	{  		
  		setUsers(selectionData)  		
  		setSelecteAll(false);
  		setSelectedConsultantId([])
  	}
  	else if(!selecteAll)
  	{
  		setUsers(selectedConsultant);  		
  		setSelecteAll(true);
  		setSelectedConsultantId(all_ids)
  	} 	
    
  }


  const usersData = users.map( (u,index) => {
    return (
      <SearchProfileCard
        key={u.id}
        name={u.user.first_name + ' ' + u.user.last_name.substring(0, 1)}
        image_url={u.user?.image_url}
        city={u.user.city}
        country={u.user.country_name}
        service={u.primary_service}
        service_exp={u.primary_service_exp}
        education={u.main_education}
        summary={u.summary}
        userData={u}
        index={index}
        selectable={true}
        updateCheckStatus={updateCheckStatus}
        selectedConsultantId={selectedConsultantId}
        project_consultant={true}
      />
    )
  })

  const totalCount = () => {
    return `${users.length} consultant${ users.length > 1 ? 's' : '' } found`
  }  

  const saveSelectedConsultant = async () => {

    const formData = {
      project_detail_id: my_data.projectId,
      consultant_profile_ids: selectedConsultantId
    }
    const response = await fetch( '/api/v1/shortlisted_consultants', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const response_data = await response.json()

    if (response.ok) {
      history.push({pathname:"/project-view", state:{ selected_consultant:true,projectId:my_data.projectId,wip:true}});
    } else {

    }
  }


  return (
    <div >
    	<div className="row border-bottom">
	    	<div className="d-flex align-items-center">
		      <div className="col-md-4">
		      </div>
		       <div className="col-md-4 text-center">
		      	<h2>{my_data.project_title}</h2>
		      </div>
		      <div className="col-md-4 text-end">
            <input autoComplete="off" onChange={()=> selectAll()} checked={selecteAll} className="form-check-input text-end" name="select_all_consultant" type="checkbox" id="select_all_consultant" />
            <label className="form-check-label ms-1" htmlFor="select_all_consultant">Select All</label>
		      </div>
	      </div>
	      <form onSubmit={handleFormSubmit}  className="row g-2 justify-content-center align-items-center mb-2">
	      	<div className="col-md-1">
		      </div>
	      	<div className="col-md-8 text-end">
		    		<div className="form-floating">
		          <input type="text" className="form-control" onChange={(e)=>setFirstName(e.target.value)} name="first_name" id="first_name" placeholder="John" />
		          <label htmlFor="title">Enter Name (first name only)</label>
		        </div>
	        </div>
	        <div className="col-md-2 text-start">
	        	<button  type="submit" className="btn btn-primary btn-block ml-auto" >Search</button>
	        </div>
	      </form>
	    </div>
      <div className="overflow-search-scorll">
        {
        	(users.length == 0 && firstName != '')?
        	<h2 className="mt-5">
        		{message}
        	</h2>
        	:
        	usersData
        }
      </div>
    	<div className="row mt-5">
      	<div className="col-md text-start">
          <button  type="button" onClick={()=> history.push({pathname:"/project-view", state:{ selected_consultant:true,projectId:my_data.projectId,wip:true}})} className="btn btn-secondary btn-block ml-auto" >Back</button>
        </div>
        <div className="col-md text-end">
          <button type="submit" onClick={()=> saveSelectedConsultant()} className="btn btn-primary btn-block ml-auto"><b>Select {selectedConsultantId.length} Consultant</b></button>
        </div>
      </div>

    </div>
  );
}

export default SearchByName;
