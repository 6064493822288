import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import { confirmAlert } from 'react-confirm-alert';
import MessagePopup from '../postProject/MessagePopup';




const ReceiveWorkOrderList = (props) => {

	const globalState = useContext(store);
	const { dispatch } = globalState;
	let history = useHistory()
	const [messageModal, setMessageModal] = useState(null);
	const [showMessagePopup, setShowMessagePopup] = useState( false )


  const [ workOrderList, setWorkOrderList] = useState([])
  const [ work_Order, setWork_Order] = useState({})

	const fetchData = async () => {

	  const response = await fetch('/api/v1/work_orders', {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()
	  console.log(responseJson)

	  if (response.ok) {
	    setWorkOrderList(responseJson)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	useEffect(() => {
	  if (messageModal)
	    showMessagePopup ? messageModal.show() : messageModal.hide()
	  fetchData()
	}, [messageModal, showMessagePopup])


	useEffect( () => {
	  fetchData();
	}, []);

	const workOrderStatus= () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5> Work Order creation in progress by client</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-center">
			        	<button className="btn btn-primary" onClick={onClose}>Ok</button>
			        </div>

				    </div>
		      </div>
		    );
		  }
		});
	};


	const checkStatus= (work_Order) => {
	  if(work_Order.status == 'wip'){
	  	workOrderStatus();
	  }
	  else{
	  	history.push({pathname:"/work-order-view", state:{ work_order_id: work_Order.id, workOrder:work_Order, }})
	  }
	};





	const workOrder_list = workOrderList.map( workOrder => {
		return (
    	<tr key={workOrder.id}>
	      <td><button className="btn btn-link p-0" onClick={()=> checkStatus(workOrder)}>{workOrder.project_detail.title}</button></td>
	      <td>{workOrder.project_detail.company_name}</td>
	      <td>{workOrder.project_detail.creator}</td>
	      <td>{workOrder.update_date}</td>
	      {workOrder.status == 'wip' ?
		      	<td>{workOrder.status.toUpperCase()}</td>
		      	:
		      	<td>{workOrder.status.charAt(0).toUpperCase() + workOrder.status.slice(1)}</td>
		      }
	      <td><button className="btn btn-link p-0" onClick={()=> {setWork_Order(workOrder), setShowMessagePopup(!showMessagePopup)}}>Message Client</button></td>
	    </tr>
  	)

  })

  console.log("work_Order")
  console.log(work_Order)

  return (
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="file-contract" /> Work Order</h2>
        </div>
    	</div>
    	<div className="col-md mt-4">
    		<table className="table table-bordered table-striped table-sm text-start">
				  <thead>
				    <tr>
				      <th scope="col">Project Title/ Work Order</th>
				      <th scope="col">Company</th>
				      <th scope="col">Project Posted by</th>
				      <th scope="col">Date of Work Order</th>
				      <th scope="col">Work Order Status</th>
				      <th scope="col"></th>
				    </tr>
				  </thead>
				  <tbody>
			    	{workOrder_list}
				  </tbody>
				</table>

    	</div>
    	<MessagePopup project_consultant={true} conversation_id={work_Order.conversation_id} work_Order={work_Order} work_order_id={work_Order.id}  show={showMessagePopup} setShow={setShowMessagePopup} setModal={setMessageModal}  />
    </div>
  );
}

export default ReceiveWorkOrderList;
