import React, { useContext, useState, useEffect, useRef, forwardRef } from 'react';

import { store } from '../../store'
import FloatingSelect from '../../components/FloatingSelect';
import DatePicker from "react-datepicker";

import Modal from '../../components/Modal';
import CustomEditor from '../../components/CustomEditor'


const AddProject = (props) => {

  const globalState = useContext(store);
  const [allServices, setAllServices] = useState([])
  const [service, setService] = useState({ service: '', sub_service: '' })
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const formEl = useRef();
  const formId = 'add-project-form'

  const fetchData = async () => {
    const response = await fetch('/api/v1/services', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setAllServices(responseJson)
      formEl.current.reset();
      resetErrors(formEl.current.elements);
      setStartDate(null)
      setValue('')
      setEndDate(null)
      setDescriptionError('')
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, [props.show]);

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;
    if(errors.description != undefined)
    {
	    setDescriptionError(errors.description[0]);
    }
    resetErrors( elements );
    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
    });
  }

  const handleFormSubmit = async (event) => {

    if (event) event.preventDefault();
    if(value.replace(/<\/?[^>]+(>|$)/g, "") != "")
    {
    	const formData = {
	      project: {
	        start_date: event.target.start_date.value,
	        end_date: event.target.end_date.value,
	        title: event.target.title.value,
	        client_name: event.target.client_name.value,
	        description: value,
	        primary_service_id: event.target.primary_service_id.value,
	        secondary_service_id: event.target.secondary_service_id.value,
	        is_public: event.target.is_public.checked,
	      }
	    }
	    const response = await fetch( '/api/v1/projects', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'post',
	      body: JSON.stringify(formData)
	    })
	    const jobRole = await response.json()
	    if (response.ok) {
	      props.setShow(false);
	      props.setFetch(true);
	      formEl.current.reset();
	      setValue('')
	      setService({ service: '', sub_service: '' })
	    } else {

	      showErrors( event.target.elements, jobRole );
	    }
    }
    else{
    	setDescriptionError("can't be blank")
    }

  }

  const services = allServices.map( data => {
    return { value: data.id, text: data.name }
  });

  let sub_services = []
  if (service.service){
    let all_sub_services = allServices.filter( s => service.service == s.id )
    sub_services = all_sub_services[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

  const nextButton = () => {
    if (props.projectCount){
      return(
              <button type="button" className="btn btn-primary btn-block ms-3"
              onClick={() => props.setActiveId(props.activeId + 1) }>Next</button>
      );
    }
    return ''
  }


  function handleChange(newValue) {
    setValue(newValue);
  }

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text" defaultValue={value} onClick={onClick} ref={ref}
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  return (
    <Modal setModal={props.setModal} setShow={props.setShow} id="Add-Project-Modal" title='Add Project' formId={formId}>
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" name="title" id="title" placeholder="Title" required />
              <label htmlFor="title">Title</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" name="client_name" id="client_name"
                placeholder="Client name or description" required />
              <label htmlFor="client_name">Client name or description</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>

        </div>

        <div className="row g-2">
          <div className="col-md">
            < DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              autoComplete='nope'
              customInput={<CustomInput identify='start_date' label='From'/>}
            />
          </div>
          <div className="col-md">
            < DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              autoComplete='nope'
              customInput={<CustomInput identify='end_date' label='To'/>}
            />
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            <FloatingSelect data={services} name='primary_service_id' required={true}
              onChange={e => setService( { service: e.target.value, sub_service: service.sub_service } )}
              label='Service Offering' value={service.service} placeholder='Select Service' withError='true'/>
          </div>
          <div className="col-md">
            <FloatingSelect data={sub_services} name='secondary_service_id' canHaveCategory={true} 
              onChange={e => setService( { service: service.service, sub_service: e.target.value } )}
              label='Sub Service Offering' value={service.sub_service} placeholder='Select Sub Service' withError='true'/>
          </div>
        </div>

        {/*<div className="row g-2">
          <div className="form-floating">
            <textarea
              className="form-control"
              maxLength='500'
              name="description"
              id="description"
              placeholder="Project Description"
              required
            ></textarea>
            <label htmlFor="description">Project Description</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>*/}

        <div className="text-start">
		      {/* <textarea className="form-control" name="message" id="message" placeholder="Message" required /> */}
		      <CustomEditor id="project" placeholder={"Project Description...."} value={value} onChange={handleChange} />
		      {descriptionError != '' ?
		      	<div className="text-danger text-start"> Description {descriptionError}</div>
		      	:
		      	null
		      }
		    </div>

        <div className="row g-2">
          <div className="col-md">
            <div className="form-check text-start">
              <input className="form-check-input" name="is_public" type="checkbox" id="is_public" />
              <label className="form-check-label fw-bold" htmlFor="is_public">
                Show this project in public profile
              </label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>

      </form>
    </Modal>
  );
}

export default AddProject;
