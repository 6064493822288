import React from 'react';

const Errors = (props) => {

  if ( !props.message )
    return ''

  return (
    <div className="alert alert-danger" role="alert">
      { props.message }
    </div>
  );
}

export default Errors;
