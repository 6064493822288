import React, { useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CommentList from './CommentList';
import ImageComp from '../../components/ImageComp'
import SearchProfileCard from '../../components/SearchProfileCard'
import Modal from '../../components/Modal'
import { Link, useHistory } from 'react-router-dom';
import TextAreaAutoResize from '../../components/TextAreaAutoResize'


const MessageCard = (props) => {

  const [ comments, setComments ] = useState(props.comments)
  const [showModalSummary, setShowModalSummary] = useState(false)
  const [ModalSummary, setModalSummary] = useState(null)
  const globalState = useContext(store);
  const formEl = useRef();
  const formId = 'add-project-form'


  useEffect(() => {
    if (ModalSummary)
      showModalSummary ? ModalSummary.show() : ModalSummary.hide()
  }, [ModalSummary, showModalSummary])

  const addComment = async (event) => {
    if (event) {
      event.preventDefault();
    }
    
    const formData = {
      comment: event.target.summary.value,
      message_id: props.message_id
    }
    if(event.target.summary.value != "")
    {
    	const response = await fetch( '/api/v1/comment', {
			headers: {
			  'Content-Type': 'application/json',
			  'Authorization': globalState.state.Authentication
			},
			method: 'post',
			body: JSON.stringify(formData)
			})
			const comn = await response.json()
	    if (response.ok) {
	      setComments(comn)
	      formEl.current.reset();
	    }
    }
  }

  const DeleteComment = async(id) =>
  {
  	const formData = {
      comment_id: id,
    }
    const response = await fetch( '/api/v1/delete_comment', {
			headers: {
			  'Content-Type': 'application/json',
			  'Authorization': globalState.state.Authentication
			},
			method: 'post',
			body: JSON.stringify(formData)
			})
			const comn = await response.json()
	    if (response.ok) {
	      setComments(comn)
	    }
  }

  const DeleteMessage = (id) =>
  {
  	props.DeleteMessage(id)
  }
  

  const usersData = () => {
    return (
      <SearchProfileCard
        name={props.consultant_profile.display_name}
        image_url={props.consultant_profile.image_url}
        city={props.consultant_profile.city}
        country={props.consultant_profile.country_name}
        service={props.consultant_profile.primary_service}
        service_exp={props.consultant_profile.primary_service_exp}
        education={props.consultant_profile.main_education}
        summary={props.consultant_profile.summary}
      />
    );
  } 
 
  return (
  	props.from_admin != true ?
	    <div className="card mb-3">
	      <div className="card-body">
	        <div className="row g-2">
	          <div className="col-md-1">
				      <img src={props.image_url} alt="BlankProfileImg" className="border border-3 rounded-circle img-responsive" style={{width:"60px",height:"60px"}}/>
				    </div>
	          <div className="col-md mt-3">
	            <button onClick={() => setShowModalSummary(!showModalSummary)} className="btn bg-transparent shadow-none text-primary fw-bold"><u>{props.display_name}</u></button>
	            <div className="text-muted">{props.primary_service} &#8226; {props.posted_on}</div>
	          </div>
	          {props.allow_delete ?
	          	<div className="col-md-1 text-end align-items-center justify-content-center">
	  	        	<button onClick={()=>DeleteMessage(props.message_id)} className="btn bg-transparent shadow-none"><FontAwesomeIcon icon="trash" /></button>
	  	        </div>
	  	        :
	  	        false
	  	      }
	          {props.promoted_at != null ?
	          	<div className="col-md-1 mt-3 text-end align-items-center justify-content-center ">
	  	        	<span className="badge" >Promoted</span>
	  	        </div>
	  	        :
	  	        false
	  	      }
	          
	        </div>
	        <div className="mt-3">
	          <h6 className="card-title"><b>{props.title}</b></h6>
	        </div>
	        <div className="mt-2">
	          {ReactHtmlParser(props.message)}
	        </div>
					{props.attachment_title?
						<div className="text-end">
								<a className="btn btn-primary" href={props.attachment_url} target="_blank" rel="noopener noreferrer" >Download Attachment</a>
						</div>
						:
						false
					}
	      </div>
	      <div className="card-footer bg-light">
	        <div className="mt-2">
	          <form id={formId} ref={formEl} onSubmit={addComment} className="row g-2">
	            <div className="row g-2 text-start align-items-center">
	              <ImageComp/>
	              <div className="col-md-10">
	                <TextAreaAutoResize Id={props.title}  label_value={"Reply to this post"} />
	              </div>
	              <div className="col-md-1 text-end">
	               <button  type="submit"
			              className="btn button-custom-style font-weight-bold"
			            >Reply
			          </button>
	              </div>
	            </div>
	          </form>
	        </div>
	        <CommentList user_id={props.user_id} deleteComment={DeleteComment} comments={comments}/>
	        <Modal setModal={setModalSummary} setShow={setShowModalSummary} title='Profile Summary' summary={true} project_consultant={true} >
	          <div className="card d-flex flex-row search-profile m-3">
	            <div className='w-100'>
	              <div className="card-header text-center"><h5><b>{props.consultant_profile.display_name}</b></h5></div>
	              <div className="card-body">
	                <div className="row">
	                  <div className='col-md-5 text-center'>
	                    <img src={props.consultant_profile.image_url} alt="User Photo" className='search profile-image mb-3' />
	                    {props.consultant_profile.completed ?
	                    	<h5 className="card-title mb-1">{props.consultant_profile.primary_service}, <b>({props.consultant_profile.primary_service_exp})</b></h5>
	                    	:
	                    	null
	                    }
	                    {props.consultant_profile.completed ?
		                    <p className="card-text  mb-1">{props.consultant_profile.main_education}.</p>
		                    :
		                    null
		                  }
	                    <p className="card-text mb-1">{props.consultant_profile.city}, {props.consultant_profile.country_name}.</p>
	                  </div> 
	                  <div className='col-md card-text text-start mt-2'>
	                    {ReactHtmlParser(props.consultant_profile.summary)}
	                  </div>
	                </div>
	              </div>
	            </div>
	          </div>
	        </Modal>      
	      </div>     
	    </div>
    :
    	<div className="card mb-3">
	      <div className="card-body">
	        <div className="row g-2">
	          <div className="col-md-1">
				      <img src={props.image_url} alt="BlankProfileImg" className="border border-3 rounded-circle img-responsive" style={{width:"60px",height:"60px"}}/>
				    </div>
	          <div className="col-md mt-3">
	            <button onClick={() => setShowModalSummary(!showModalSummary)} className="btn bg-transparent shadow-none text-primary fw-bold"><u>{props.display_name}</u></button>
	            <div className="text-muted">{props.posted_on}</div>
	          </div>
	          {props.allow_delete ?
	          	<div className="col-md-1 text-end align-items-center justify-content-center">
	  	        	<button onClick={()=>DeleteMessage(props.message_id)} className="btn bg-transparent shadow-none"><FontAwesomeIcon icon="trash" /></button>
	  	        </div>
	  	        :
	  	        false
	  	      }
	          {props.promoted_at != null ?
	          	<div className="col-md-1 text-end align-items-center justify-content-center">
	  	        	<span className="badge">Promoted</span>
	  	        </div>
	  	        :
	  	        false
	  	      }
	          
	        </div>
	        <div className="mt-3">
	          <h6 className="card-title"><b>{props.title}</b></h6>
	        </div>
	        <div className="mt-2">
	          {ReactHtmlParser(props.message)}
	        </div>
					{props.attachment_title?
						<div className="text-end">
								<a className="btn btn-primary" href={props.attachment_url} target="_blank" rel="noopener noreferrer" >Download Attachment</a>
						</div>
						:
						false
					}
	      </div>
	      <div className="card-footer bg-light">
	        <div className="mt-2">
	          <form id={formId} ref={formEl} onSubmit={addComment} className="row g-2">
	            <div className="row g-2 text-start align-items-center">
	              <ImageComp/>
	              <div className="col-md-10">
	                <TextAreaAutoResize Id={props.title}  label_value={"Reply to this post"} />
	              </div>
	              <div className="col-md-1 text-end">
	               <button  type="submit"
			              className="btn button-custom-style font-weight-bold"
			            >Reply
			          </button>
	              </div>
	            </div>
	          </form>
	        </div>
	        <CommentList user_id={props.user_id} deleteComment={DeleteComment} comments={comments}/>	             
	      </div>
	    </div>	

  );
}

export default MessageCard;
