import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../store'

import Service from './Service';
import SubServices from './SubServices';

const ServiceOfferingStep = (props) => {

  const experiences = [
    { value: '4', text: '1-4' },
    { value: '9', text: '5-9' },
    { value: '20', text: '10-20' },
    { value: '21', text: '>20' },
  ]

  const globalState = useContext(store);
  const [ allServices, setAllServices ] = useState([])
  const [ errors, setErrors ] = useState({})

  const [ primaryServiceOffering, setPrimaryServiceOffering ] = useState({ service_id: '', experience: '' })
  const [ primarySubServiceOffering, setPrimarySubServiceOfferings ] = useState([{ id: 1, service_id: '', experience: '' }])
  const [ secondaryServiceOffering, setSecondaryServiceOffering ] = useState({ service_id: '', experience: '' })
  const [ secondarySubServiceOffering, setSecondarySubServiceOfferings ] = useState([{ id: 1, service_id: '', experience: '' }])
  const [ selectedAllSubServices, setSelectedAllSubServices ]  = useState([])


  const fetchData = async () => {

  	var existing_offerings = {}

  	if(globalState.state.user_type == 'consultant')
  	{
  		const existing_service = await fetch('/api/v1/consultant_profiles', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'get'
	    })
	    existing_offerings = await existing_service.json()
		}


    const response = await fetch('/api/v1/services', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

    responseJson.map((service)=>
    {
    	if(existing_offerings.service_offering == service.name)
    	{
    		setPrimaryServiceOffering({service_id:service.id.toString() , experience: "" })
    	}

    })

    if (response.ok) {
      setAllServices(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }

    const offeringsResponse = await fetch('/api/v1/service_offerings', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const offeringsJson = await offeringsResponse.json()

    if (offeringsResponse.ok) {
      if (offeringsJson.primary_offering){
        setPrimaryServiceOffering(offeringsJson.primary_offering.service)
        setPrimarySubServiceOfferings(offeringsJson.primary_offering.sub_services)
      }
      if (offeringsJson.secondary_offering){
        setSecondaryServiceOffering(offeringsJson.secondary_offering.service)
        setSecondarySubServiceOfferings(offeringsJson.secondary_offering.sub_services)
      }
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const services = allServices.map( data => {
    return { value: data.id, text: data.name }
  });

  let primarySubServices = []
  if (primaryServiceOffering.service_id){
    let service = allServices.filter( s => primaryServiceOffering.service_id == s.id )
    if(service.length != 0)
    {
    	primarySubServices = service[0].secondary_services.map( data => {
	      return { value: data.id, text: data.name, category: data.category }
	    });
    }
  }

  let secondarySubServices = []
  if (secondaryServiceOffering.service_id){
    let service = allServices.filter( s => secondaryServiceOffering.service_id == s.id )
    secondarySubServices = service[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      primary_offering: {
        service: primaryServiceOffering,
        sub_services: primarySubServiceOffering
      },
      secondary_offering: {
        service: secondaryServiceOffering,
        sub_services: secondarySubServiceOffering
      }
    }

    const response = await fetch( '/api/v1/service_offerings', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()
    if (response.ok) {
      props.setActiveId(props.activeId + 1)
    } else {
      setErrors(responseJson.errors)
    }
  }

  let errorsHtml = ''

  Object.entries(errors).forEach(([key, messages]) => {
    errorsHtml = messages.map((message, index) =>{
      return (
        <div key={index} className="alert alert-danger" role="alert">
          {message}
        </div>
      )
    });
  });

  const updateSelectedServices =() => {
    let srvs = []
    srvs = srvs.concat(primarySubServiceOffering.map( item => parseInt(item.service_id) ))
    srvs = srvs.concat(secondarySubServiceOffering.map( item => parseInt(item.service_id) ))
    setSelectedAllSubServices(srvs)

  }


  return (
    <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center">

      <div className="row g-3">
        <div className="col-md text-start">
          {errorsHtml}
        </div>
      </div>

      <div className="row g-3">
        <div className="col-md text-start">
          <div className="alert alert-info">
            <ul>
              <li>Your Primary Service offering is the main function in which you offer your services. This field is mandatory.</li>
              <li>Within the Primary Service offering, you can select from 1 to 6 sub-services to detail the services that you offer.</li>
              <li><b>Please select carefully as these fields will be used to match you to Projects posted by clients.</b></li>
            </ul>
          </div>
        </div>
      </div>

      < Service services={services} experiences={experiences}
        value={ primaryServiceOffering } setValue={setPrimaryServiceOffering} type='Primary' isRequired={true}/>
      <hr/>
      < SubServices services={ primarySubServices } experiences={experiences}
        value={primarySubServiceOffering} setValue={setPrimarySubServiceOfferings} type='Primary' updateSelectedServices={updateSelectedServices} disabledvalues={selectedAllSubServices}/>

      <hr/>

      <div className="row g-3">
        <div className="col-md text-start">
          <div className="alert alert-info">
            <ul>
              <li>You may want to select a Secondary Service offering in addition to your Primary Service. This field is optional.</li>
              <li>For the Secondary Service, you can select from 1 to 3 sub-services to detail the services that you offer.</li>
            </ul>
          </div>
        </div>
      </div>

      < Service services={services} experiences={experiences}
        value={ secondaryServiceOffering } setValue={setSecondaryServiceOffering} type='Secondary' isRequired={false}/>
      <hr/>
      < SubServices services={ secondarySubServices } experiences={experiences}
        value={secondarySubServiceOffering} setValue={setSecondarySubServiceOfferings} type='Secondary' updateSelectedServices={updateSelectedServices} disabledvalues={selectedAllSubServices}/>

      <div className="row g-2">
        <div className="col-md text-end">
          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
        </div>
      </div>
    </form>
  );
}

export default ServiceOfferingStep;
