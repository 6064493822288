import React, { useContext, useState ,useRef,useEffect} from 'react';
import { Link, useHistory,useLocation } from 'react-router-dom';
import Errors from '../../components/Errors';
import { store } from '../../store';
import Modal from '../../components/NewModal'

const Login = (props) => {

  const [ message, setMessage ] = useState('');
  const [ showmodal, setShow ] = useState(false);
  const [ newSetModal, setModal ] = useState(null);
  const globalState = useContext(store);
  const { dispatch } = globalState;
  let history = useHistory();


  useEffect(() => {
    if (newSetModal)
      showmodal ? newSetModal.show() : newSetModal.hide()
  }, [newSetModal, showmodal])


  const handleLoginSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const data = {
      email: event.target.email.value,
      password: event.target.password.value
    }
    const response = await fetch('/api/v1/authenticate', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(data)
    })
    const userData = await response.json()
    console.log("userData")
    console.log(userData)

    if (response.ok) {
    	if(userData.current_user.user_type == 'client')
    	{
    		const newState = {
	        loggedIn: true,
	        Authentication: userData.auth_token,
	        firstName: userData.current_user.first_name,
	        lastName: userData.current_user.last_name,
	        email: userData.current_user.email,
	        completed: userData.current_user.completed,
	        user_type: userData.current_user.user_type,
	        remember: event.target.remember.checked,
	        access_role: userData.current_user.access_role,
	        company_name: userData.current_user.company_name.name,
	        last_login: userData.current_user.last_login,
	      }
	      dispatch({ type: 'login', newState: newState });
    	}
    	else
    	{
    		const newState = {
	        loggedIn: true,
	        Authentication: userData.auth_token,
	        firstName: userData.current_user.first_name,
	        lastName: userData.current_user.last_name,
	        email: userData.current_user.email,
	        completed: userData.current_user.completed,
	        user_type: userData.current_user.user_type,
	        remember: event.target.remember.checked,
	        last_login: userData.current_user.last_login,
	      }
	      dispatch({ type: 'login', newState: newState });
    	}
      
      if ( userData.current_user.system_password )
        return history.push('/password-update');
      if ( userData.current_user.completed && (userData.current_user.user_type == "client"))
        return history.push('/dashboard');
      if ( userData.current_user.completed && (userData.current_user.user_type == "consultant"))
        return history.push('/consultant-dashboard');
      if ( userData.current_user.completed || userData.current_user.user_type == "consultant_demo")
        return history.push('/view-consultant-profile');
      history.push('/');
    } else {
      if (userData.error)
        setMessage( userData.errors )
    }
  }


  return (
    <div className="auth-wrapper">
      <h4 className="mb-4"><b className="primary-text">Work Smart</b> &#8226; <b className="secondary-text">Work Efficient</b> &#8226; <b className="primary-text">Be Flexible</b></h4>
      <div className="auth-inner">
        <form onSubmit={handleLoginSubmit} className="row g-2">
          <h3>Log in</h3>
          <div className="row g-2">
          <Errors message={message} />
            <div className="col-md">
              <div className="form-floating">
                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required />
                <label htmlFor="email">Email</label>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="password" className="form-control" name="password" id="password" placeholder="Password" required />
                <label htmlFor="password">Password</label>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-check text-start">
                <input className="form-check-input" name="remember" type="checkbox" value="" id="remember" />
                <label className="form-check-label" htmlFor="remember">
                  Remember Me
                </label>
              </div>
            </div>
            <div className="col-md">
              <div className="form-group text-end">
                <Link className="forgot-password" to={"/forgot-password"}> Forgot Password?</Link>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <button type="submit" className="btn btn-primary btn-block w-100">Log in</button>
            </div>
          </div>

          <div className="row g-2 m-0">
            <div className="col-md">
              <p className="signup-link fw-bold text-end">
                Don’t have an account? <Link onClick={()=>setShow(true)} >Sign up</Link>
              </p>
            </div>
          </div>
        </form>
		    <Modal setModal={setModal} setShow={setShow} />

      </div>
    </div>
  );
}

export default Login;
