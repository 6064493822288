import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";




const ProjectTimelines = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
  const [ milestones, setMilestones] = useState([{id:0, name:'', milestone_date: null}])
  const [startDate, setStartDate] = useState(null)
  const [agreeConsultantCountry, setAgreeConsultantCountry] = useState(true)
  const [agreeLocation, setAgreeLocation] = useState(true)
  const [agreeOnLocation, setAgreeOnLocation] = useState(true)
  const [ProposedStartDate, setProposedStartDate] = useState(null)
  const [onsiteLocationComment, setOnsiteLocationComment] = useState('')
  const [consultant_country_comment, setconsultant_country_comment] = useState('')
  const [proposedStartDateError, setProposedStartDateError] = useState('')
  const [milestoneDateError, setMilestoneDateError] = useState('')




	let history = useHistory()

	const [modal, setModal] = useState(null);

	// if(state != undefined)
	// {
	// 	localStorage.setItem('document',JSON.stringify(state));
	// }
	// const my_data =  JSON.parse(localStorage.getItem('document'));

	const fetchData = async () => {
		setProjectDetails(props.projectDetails)
    setStartDate(new Date(props.projectDetails.start_date))

  	const response = await fetch('/api/v1/proposals/'+ props.proposalId, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })

	  const responseJson = await response.json()
  	console.log("responseJson")
  	console.log(responseJson)

  	if(response.ok)
  	{
  		props.setProposalDetails(responseJson)
  		if(responseJson.project_milestones.length > 0)
    	{
    		setMilestones(responseJson.project_milestones)
    	}

    	if(responseJson.proposed_start_date != null)
    	{
    		setProposedStartDate(new Date(responseJson.proposed_start_date))
    	}
    	if(responseJson.agree_consultant_country != null)
    	{
    		setAgreeConsultantCountry(responseJson.agree_consultant_country)
    	}
    	if(responseJson.agree_location_type != null)
    	{
    		setAgreeLocation(responseJson.agree_location_type)
    	}
    	if(responseJson.agree_onsite_location != null)
    	{
    		setAgreeOnLocation(responseJson.agree_onsite_location)
    	}
    	if(responseJson.comment_consultant_country != null)
    	{
    		setconsultant_country_comment(responseJson.comment_consultant_country)
    	}
    	if(responseJson.comment_onsite_location != null)
    	{
    		setOnsiteLocationComment(responseJson.comment_onsite_location)
    	}
  	}

	}


	useEffect( () => {
	  fetchData();
	}, []);


  const CustomInput = forwardRef(
    ({ value, onClick, label, identify, disable }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} disabled={disable} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const addMilestones = () => {
   	let count = milestones.length;
    let id = milestones[count-1].id + 1;
    setMilestones( [...milestones, {id:id, name:'',milestone_date: null} ]  )
  }

  const removeMilestones = (id) => {
    const new_milestones =  milestones.filter( data => data.id != id )
    setMilestones( new_milestones )
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setMilestoneDateError('');

    const formData = {
			proposal:
			{
				proposed_start_date: event.target.proposed_start_date.value,
				comment_start_date: event.target.comment_start_date.value,
				proposed_estimated_duration: event.target.proposed_estimated_duration.value,
				comment_estimated_duration: event.target.comment_estimated_duration.value,
				agree_consultant_country: agreeConsultantCountry,
				comment_consultant_country: consultant_country_comment,
				agree_location_type: agreeLocation,
				comment_location_type: event.target.comment_location_type.value,
				agree_onsite_location: agreeOnLocation,
				comment_onsite_location: onsiteLocationComment,
				project_milestones_attributes:milestones
			}
    }


		const response = await fetch( '/api/v1/proposals/' + props.proposalId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()

    if (response.ok) {
    	props.setActiveId(props.activeId + 1)
    	props.setProposalId(responseJson.id)
    	props.setProposalDetails(responseJson)
    }
    else
    {
    	if(responseJson.proposed_start_date != undefined)
    	{
    		setProposedStartDateError(responseJson.proposed_start_date)
    	}
    	if(responseJson.project_milestones != undefined)
    	{
    		setMilestoneDateError(responseJson.project_milestones)
    	}

    }
  }

  const Milestones = milestones.map((data,index)=>
  {
  	var milestone_date = null;

  	if(data.milestone_date != null)
  	{
  		milestone_date = new Date(data.milestone_date)
  	}

		return(
			<div key={index} className="col-md-12 text-start mt-3 mb-3">
				<div className="row">
					<div className="col-md-6 text-center">
						<div className="form-floating">
		          <input type="text" className="form-control" value={data.name} onChange={(event)=>handleMilestoneChange(event, data.id)} name="milestone_name" id="milestone_name" placeholder="Milestone" required />
		          <label htmlFor="title">Milestone</label>
		        </div>
					</div>
					<div className="col-md-4 text-center">
						< DatePicker
		          selected={milestone_date}
		          onChange={date => handleDateChange(date, data.id)}
		          dateFormat="dd/MM/yyyy"
		          minDate={new Date()}
		          customInput={<CustomInput identify='milestone_date' label='Date'/>}
		        />
					</div>
					{
		      	index > 0 ?
		      	<div className="col d-flex align-items-center justify-content-center">
		          <button type="button" onClick={() => removeMilestones(data.id)}
		            className="btn btn-danger btn-block"><FontAwesomeIcon icon="times" /></button>
		        </div>
		        :
		        null

		      }
				</div>
			</div>
		);
  })

  const handleMilestoneChange = (event, id )=> {

    const newData = milestones.map( data => {
      if (id == data.id)
        data.name = event.target.value
      return data
    });
    setMilestones( newData )
  }

  const handleDateChange = (date, id )=> {

  	let formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

  	if(ProposedStartDate != null && ProposedStartDate > date)
  	{
  		setMilestoneDateError('Milestone date should be later than project start date.')
  	}
  	else{
  		setMilestoneDateError('')
  	}

    const newData = milestones.map( data => {
      if (id == data.id)
        data.milestone_date = formattedDate
      return data
    });
    setMilestones( newData )
  }

  return (
    <div className="container">

	    <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center mt-3 mb-3">
				<h5><b>Project Dates</b></h5>
				<div className="col-md-12 text-start mt-3 mb-3">
					<div className="row">
						<div className="col-md-3 text-center">
							<label className="card-title  "><b>Client Requirement</b></label>
						</div>
						<div className="col-md-3 text-center form-group required">
							<label className="card-title control-label "><b>Consultant Proposal</b></label>
						</div>
						<div className="col-md-6 text-center">
							<label className="card-title  "><b>Comments</b></label>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-3 text-center">
							< DatePicker
		            selected={startDate}
		            // onChange={date}
		            dateFormat="dd/MM/yyyy"
		            minDate={new Date()}

		            customInput={<CustomInput disable={true} identify='start_date' label='Date for Project Start'/>}
		          />
						</div>
						<div className="col-md-3 text-center">
							< DatePicker
		            selected={ProposedStartDate}
		            onChange={date => setProposedStartDate(date)}
		            dateFormat="dd/MM/yyyy"
		            minDate={new Date()}

		            customInput={<CustomInput identify='proposed_start_date' label='Proposed Start Date'/>}
		          />
						</div>
						<div className="col-md-6 text-center">
							<div className="form-floating">
		            <input type="text" defaultValue={props.proposalDetails.comment_start_date} className="form-control" name="comment_start_date" id="comment_start_date" placeholder="abc"  />
		            <label htmlFor="title">Comment</label>
		          </div>
						</div>
					</div>
					<div className="row mt-3">
						<div className="col-md-3 text-center">
							<div className="form-floating">
		            <input type="text" defaultValue={projectDetails.estimated_duration_display} className="form-control" name="title" id="title" placeholder="John" disabled={true} />
		            <label htmlFor="title">Estimated duration</label>
		          </div>
						</div>
						<div className="col-md-3 text-center">
							<div className="form-floating">
		            <input type="number" step="1"  className="form-control" defaultValue={props.proposalDetails.proposed_estimated_duration} name="proposed_estimated_duration" id="proposed_estimated_duration" placeholder="John" required />
		            <label htmlFor="proposed_estimated_duration">Proposed duration</label>
		          </div>
						</div>
						<div className="col-md-6 text-center">
							<div className="form-floating">
		            <input type="text" className="form-control" defaultValue={props.proposalDetails.comment_estimated_duration} name="comment_estimated_duration" id="comment_estimated_duration" placeholder="John"  />
		            <label htmlFor="comment_estimated_duration">Comment</label>
		          </div>
						</div>
					</div>

				</div>
				<hr/>

				<div className="col-md form-group required">
	    		<h5><b className="control-label">Project Milestones </b></h5>
	    		<h6>( List Milestones or Review Points with Dates )</h6>
	    	</div>

	    	{
			  	milestoneDateError != ''?
			  	<p className="text-danger text-start">{milestoneDateError}</p>
			  	:
			  	null
			  }
			  {
			  	proposedStartDateError != ''?
			  	<p className="text-danger text-start">{proposedStartDateError}</p>
			  	:
			  	null
			  }
    		{Milestones}
	    	<div className="text-start ms-3">
	        <button type="button" onClick={addMilestones} className="btn btn-primary btn-block"><FontAwesomeIcon icon="plus" /></button>
	      </div>
	      <hr/>
	      <div className="col-md form-group required">
					<h5><b className="control-label">Project Logistics</b></h5>
				</div>

					{
						projectDetails.consultant_country != '' ?
						<div className="row align-items-center mt-3">
							<div className="col-md-5 text-center">
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.display_consultant_country} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Required Location Country of Consultant</label>
			          </div>
							</div>
							<div className="col-md-1 text-center form-check ">
						  	<input className="form-check-input" type="checkbox" onChange={()=>setAgreeConsultantCountry(!agreeConsultantCountry)} checked={agreeConsultantCountry} id="agree_consultant_country"/>
						  	<label htmlFor="title">{agreeConsultantCountry ? 'Yes' : 'No'}</label>
						  </div>
							<div className="col-md-6 text-center">
								<div className="form-floating">
			            <input type="text" className="form-control" defaultValue={consultant_country_comment} onChange={(e)=>setconsultant_country_comment(e.target.value)} name="comment_consultant_country" id="comment_consultant_country" placeholder="John" />
			            <label htmlFor="title">Comment</label>
			          </div>
							</div>
						</div>
						:
						null
					}

					<div className="row align-items-center mt-3">
						<div className="col-md-5 text-center">
							<div className="form-floating">
		            <input type="text" defaultValue={projectDetails.location_type_dv} className="form-control" name="title" id="title" placeholder="John" disabled />
		            <label htmlFor="title">Location of project</label>
		          </div>
						</div>
						<div className="col-md-1 text-center form-check ">
					  	<input className="form-check-input" type="checkbox" onChange={()=>setAgreeLocation(!agreeLocation)}  checked={agreeLocation} id="agree_location_type"/>
					  	<label htmlFor="title">{agreeLocation ? 'Yes' : 'No'}</label>
					  </div>
						<div className="col-md-6 text-center">
							<div className="form-floating">
		            <input type="text" className="form-control" defaultValue={props.proposalDetails.comment_location_type} name="comment_location_type" id="comment_location_type" placeholder="John" />
		            <label htmlFor="title">Comment</label>
		          </div>
						</div>
					</div>
					{
						projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
						<div className="row align-items-center mt-3">
							<div className="col-md-5 text-center">
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.onsite_city} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Onsite City</label>
			          </div>
							</div>
							<div className="col-md-1 text-center form-check ">
						  	<input className="form-check-input" type="checkbox" onChange={()=>setAgreeOnLocation(!agreeOnLocation)} checked={agreeOnLocation} id="agree_onsite_location"/>
						  	<label htmlFor="title">{agreeOnLocation ? 'Yes' : 'No'}</label>
						  </div>
							<div className="col-md-6 text-center">
								<div className="form-floating">
			            <input type="text" className="form-control" onChange={(e)=>setOnsiteLocationComment(e.target.value)} defaultValue={props.proposalDetails.comment_onsite_location} name="comment_onsite_location" id="comment_onsite_location" placeholder="John" />
			            <label htmlFor="title">Comment</label>
			          </div>
							</div>
						</div>
						:
						null
					}
					{
						projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
						<div className="row mt-3 mb-2">
							<div className="col-md-5 text-center">
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.display_onsite_country } className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Onsite Country</label>
			          </div>
							</div>
						</div>
						:
						null
					}
					<div className="row g-2">
		        <div className="col-md text-end">
		          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
		        </div>
		      </div>

    	</form>

    </div>
  );
}

export default ProjectTimelines;
