import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'


const CommentList = (props) => {

	const [ showAll, setShowAll ] = useState(false)
	const globalState = useContext(store);



	const delete_comment =  (id) => {
    props.deleteComment(id);      
  }

  const comments = props.comments.map( (m,i) => {
  	if(showAll)
  	{
  		return (
	      <div key={i} className="row g-2 text-start align-items-center mt-3 comment">	        
         	<div className="col-md-1">
			      <img src={m.image_url} alt="BlankProfileImg" className="border border-3 rounded-circle img-responsive" style={{width:"40px",height:"40px"}}/>
			    </div>
	        <div className="col-md-10">
	          {m.content}
	          <div className="text-muted m-2">
	            {m.display_name} &#8226; {m.commented_on}
	          </div>
	        </div>
	        {m.allow_delete ?
	        	<div className="col-md-1 text-end align-items-center justify-content-center">
  	        	<button onClick={()=>delete_comment(m.id)} className="btn bg-transparent shadow-none"><FontAwesomeIcon icon="trash" /></button>
  	        </div>
  	        :
  	        false
  	      }
	      </div>
	    )
  	}
  	else if(i == 0)
  	{
  		return (
	      <div key={i} className="row g-2 text-start align-items-center mt-3 comment">
					<div className="col-md-1">
			      <img src={m.image_url} alt="BlankProfileImg" className="border border-3 rounded-circle img-responsive" style={{width:"40px",height:"40px"}}/>
			    </div>
	        <div className="col-md-10">
	          {m.content}
	          <div className="text-muted m-2">
	            {m.display_name} &#8226; {m.commented_on}
	          </div>
	        </div>
	        {m.allow_delete ?
	        	<div className="col-md-1 text-end align-items-center justify-content-center">
  	        	<button onClick={()=>delete_comment(m.id)} className="btn bg-transparent shadow-none"><FontAwesomeIcon icon="trash" /></button>
  	        </div>
  	        :
  	        false
  	      }
	      </div>
	    )
  	}
    
  })

  const handleStateChange= (event) =>
	{		
		setShowAll(event)
	}
  return(
    <div className="mt-2">
      {comments}
      <div className={`text-end mt-2 ${props.comments.length>1 ? '' : 'visually-hidden'}`}>
        {showAll == false ? 
        	<button className="btn bg-transparent shadow-none text-primary"  onClick={() => setShowAll(true)}><u>Show all {props.comments.length} Comments </u></button>
        	:
        	<button className="btn bg-transparent shadow-none text-primary" onClick={() => setShowAll(false)}><u>Show only latest Comment </u></button>
        }
      </div>
    </div>
  );
}

export default CommentList;
