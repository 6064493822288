import React,{ useContext, useState, useEffect,useRef } from 'react';
import { store } from '../../store'
import Modal from '../../components/Modal';
import Errors from '../../components/Errors';



const ResetPasswordModalView = (props) => {

  const globalState = useContext(store);
  const { dispatch } = globalState;
  
  const [ message, setMessage ] = useState('');
  const [ successMessage, setSuccessMessage ] = useState('');
  const formId = 'change-password'
  const formEl = useRef(); 	

  const handleFormSubmit = async (event) => {

    if (event) {
      event.preventDefault();  }
   
    if(event.target.new_password.value != event.target.confirm_password.value)
    {
    	setMessage( 'Passwords did not match' )
    }
    else
    {
    	const data = {
	      user:
	      	{
	      		
	      		password: event.target.new_password.value,
  	      	password_confirmation: event.target.confirm_password.value,
  	      },
  	      old_password:event.target.old_password.value,
	      
	    }

	    const response = await fetch('/api/v1/change-password', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'post',
	      body: JSON.stringify(data)
	    })
	    const userData = await response.json()
	    if (response.ok) {
	    	event.target.reset();
	    	setMessage('')
	    	setSuccessMessage(userData.message)
		    //props.setShow(false);
	      //props.setFetch(true);	      
	    } else {
	      if (userData.error)	      	
	      setSuccessMessage('')
        setMessage( userData.errors )
	    }
    }    
  }

  const Oncancel = (current_state) =>
  {
  	if(current_state == true)
  	{
  		setMessage('')
  		setSuccessMessage('')
  		formEl.current.reset();
  	}
  }

  

  return (

    <Modal setModal={props.setModal} setShow={props.setShow} id="changePassword" title='Change Password' formId={formId} onCancel={Oncancel} >
      
				<form id={formId} ref={formEl} onSubmit={handleFormSubmit}  className="row g-2 d-flex justify-content-center">			 
				  <div className="col-md-6">
					  <div className="row g-2">
					  {
					  	successMessage != '' ?
					  	<div className="alert alert-success alert-dismissible fade show">
						    {successMessage}
							</div>
							:
							null
					  }
			  			<Errors message={message} />							  			  
					    <div className="col-md">
					      <div className="form-floating">
					        <input type="password" className="form-control" name="password" id="old_password" placeholder="old_password" required />
					        <label htmlFor="password">Old Password</label>
					      </div>
					    </div>
					  </div>
					  <div className="row g-2 mt-2">					  	  
					    <div className="col-md">
					      <div className="form-floating">
					        <input type="password" className="form-control" name="new_password" id="new_password" placeholder="new_password" required />
					        <label htmlFor="password">New Password</label>
					      </div>
					    </div>
					  </div>
					  <div className="row g-2 mt-2">
					    <div className="col-md">
					      <div className="form-floating">
					        <input type="password" className="form-control" name="confirm_password" id="confirm_password" placeholder="Confirm Password" required />
					        <label htmlFor="password">Confirm Password</label>
					      </div>
					    </div>
					  </div>			           
				  </div>			           
				</form> 
				
    </Modal>
  );
}

export default ResetPasswordModalView;
