require('/node_modules/better-dateinput-polyfill/dist/better-dateinput-polyfill.js');
import React from 'react';
import Routes from './Routes';
import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { fas } from '@fortawesome/free-solid-svg-icons'

function App() {

  library.add(fab, fas)

  return (
    <div className="App">
      <Routes />
    </div>
  );
}

export default App;
