import React, { useContext, useEffect, useState, useRef, forwardRef} from 'react';
import { Link, useHistory } from 'react-router-dom';
import TabCards from '../../components/TabCards';
import ProjectFees from './ProjectFees';
import ProjectScope from './ProjectScope';
import RelevantExperience from './RelevantExperience';
import ProjectTimelines from './ProjectTimelines';
import ProposalAttachments from './ProposalAttachments';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';



const ProposalSteps = (props) => {	
  
  const globalState = useContext(store);
  const { dispatch } = globalState;
	const [ createNewProject, setCreateNewProject ] = useState(false)
  const [ defaultOptions, setDefaultOptions ] = useState(false)
  const [ findConsultant, setFindConsultant ] = useState(false)
  const [ load, setLoad ] = useState(true)

  const [ therapeuticOthers, setTherapeuticOthers ] = useState([])  

  const [activeId, setActiveId] = useState(1)
  const [proposalId, setProposalId] = useState(null)
  const [proposalDetails, setProposalDetails] = useState({})

  const commonProps = { activeId: activeId, setActiveId: setActiveId, proposalId:proposalId, setProposalId:setProposalId ,projectDetails: props.projectDetails, proposalDetails:proposalDetails, setProposalDetails:setProposalDetails, edit_proposal_id:props.edit_proposal_id, edit_proposal:props.edit_proposal  }
  
  const tabs = [
    { id: 1, name: 'Project Scope', component: ProjectScope, props: commonProps  },
    { id: 2, name: 'Relevant Experience', component: RelevantExperience, props: commonProps },
    { id: 3, name: 'Project Timelines', component: ProjectTimelines, props: commonProps },
    { id: 4, name: 'Project Fees', component: ProjectFees, props: commonProps },
    { id: 5, name: 'Attachments', component: ProposalAttachments, props: commonProps },
    
  ]
  
 
  return (    
  	<div className="col-md">  		   
       <TabCards
		      tabs={tabs}
		      activeId={activeId}
		      setActiveId={setActiveId}
		      heading={props.headings}
		      projectStep={true}
		    />
      
  	</div>
  );
}

export default ProposalSteps;
