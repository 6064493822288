import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../components/Modal'
import CustomEditor from '../../components/CustomEditor'
import Select from 'react-select';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const MessagePopup = (props	) => {

  const globalState = useContext(store);
  const formEl = useRef();
  const { state } = useLocation(); 

  if(state != undefined)
  {
  	localStorage.setItem('document',JSON.stringify(state));
  }  

	const my_data =  JSON.parse(localStorage.getItem('document'));

  const formId = 'message-pop'
  const [ messageBody, setMessageBody] = useState('')
  const [ messages, setMessages] = useState([])
  const [ senderDetails, setSenderDetails] = useState({})
  const [ error, setError ] = useState(false)    
  let history = useHistory();
	const [conversationId,setConversationId] = useState(my_data.conversation_id)
  const [ attachment, setAttachment ] = useState('')
  const [ conSubject, setConSubject ] = useState('')
  const [ messageAttachment, setMessageAttachment ] = useState('')
  const [ subject, setSubject ] = useState('')
  const [value, setValue] = useState('checking value...');


 
  const fetchData = async () => {
  	
  	if(globalState.state.user_type == 'client')
  	{
  		var subject_name = props.proposal.project_detail.title+" disscussion with "+props.proposal.user.first_name+" "+props.proposal.user.last_name

  		setConSubject(subject_name);
  	}
  	else{
  		var subject_name = props.work_Order.project_detail.title+" project disscussion with "+props.work_Order.project_detail.creator

  		setConSubject(subject_name);
  	}  	

  	if(props.show && props.conversation_id != null)
  	{
  		const response = await fetch('/api/v1/conversation/'+ props.conversation_id +'/chat_messages', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'get'
	    })
	    const responseJson = await response.json()

	    if (response.ok) {    	
	      setMessages(responseJson)
	      setSenderDetails(responseJson[0].sender)
	      setMessageAttachment(responseJson[0].conversation.attachment_url)
	      props.show(false)
	    } else {
	      alert.show("Server is down.")
	      return false
	    }	    
  	}    
  }


  useEffect( () => {
    fetchData();
  }, [props.show]);


  const handleFormSubmit = async (event) => {
    if (event) event.preventDefault();

    if(globalState.state.user_type != 'client')
    {
    	const formData = {
	      conversation: {
	      	subject: conSubject,
	        work_order_id: props.work_order_id,
	        body: messageBody.replace(/\<br\>/g,''),
	      }
	    }

	    console.log("formData")
	    console.log(formData)
	    
	    if(messageBody.replace(/<\/?[^>]+(>|$)/g, "") != "" )
	    {
	    	const response = await fetch( '/api/v1/message_client', {
	        headers: {
	          'Content-Type': 'application/json',
	          'Authorization': globalState.state.Authentication
	        },
	        method: 'put',
	        body: JSON.stringify(formData)
	      })
	      const new_response = await response.json()
	     
	      if (response.ok) {
	      	formEl.current.reset();
	      	fetchData();
	      	setMessageBody('')
	      	setAttachment('') 
	      	setError(false)  
	      	props.setShow(false)   
	      } else {
	        resetErrors( event.target.elements );
	        showErrors( event.target.elements, message );
	      }
	    }
	    else
	    {
	    	setError(true);
	    }
    }
    else{

    	const formData = {
	      conversation: {
	      	subject: conSubject,
	        proposal_id: props.proposal_id,
	        body: messageBody.replace(/\<br\>/g,''),
	      }
	    }
	    
	    if(messageBody.replace(/<\/?[^>]+(>|$)/g, "") != "" )
	    {
	    	const response = await fetch( '/api/v1/proposal_message_consultant', {
	        headers: {
	          'Content-Type': 'application/json',
	          'Authorization': globalState.state.Authentication
	        },
	        method: 'put',
	        body: JSON.stringify(formData)
	      })
	      const new_response = await response.json()
	     
	      if (response.ok) {
	      	formEl.current.reset();
	      	fetchData();
	      	setMessageBody('')
	      	setAttachment('') 
	      	setError(false)  
	      	props.setShow(false)   
	      } else {
	        resetErrors( event.target.elements );
	        showErrors( event.target.elements, message );
	      }
	    }
	    else
	    {
	    	setError(true);
	    }
    }    

  } 


  function handleChange(newValue) {
    setMessageBody(newValue);
  }
  
  const OneTOnemessages_list = messages.map(m=>
  {
  	return(
  		<div key={m.id} className="row g-2 text-start align-items-center mt-3 ms-1 comment">        	
        <div className="col-md-10">	          
            {m.display_name} &#8226; {m.replied_on}
          <div >
          	{ReactHtmlParser(m.body)}
          </div>          
        </div>
        {m.attachment_url != '' ?
        	<div className="text-end">
  					<a className="btn btn-primary" href={m.attachment_url} target="_blank" rel="noopener noreferrer" >Download Attachment</a>
  				</div>
  				:
  				null
  			}		        
      </div>
  	);
  })

  return (
    <Modal project_consultant={true} setModal={props.setModal} setShow={props.setShow}    title='Message Consultant' summary={true}  >
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">       
        	<div className="card mt-4 ">
        		<div className="d-flex align-items-center">
		          <div className="col-md-11 text-start  m-2">
		            <h4 className="card-title">Subject : </h4>
		            <h5 className="">{conSubject}</h5>
		          </div>		          
		        </div>	        
        	</div>        	
        	       	
		      <div className="card mb-3">
		      	<div className="card-body row g-2">	      	
		      		<div className="col-md-12">
								<div className="text-start">
									<label htmlFor="message">Message</label>
								</div>
								<div className="text-start form-floating">
									<CustomEditor placeholder={"Type message here"} className="form-control" id="message" value={messageBody} onChange={handleChange} />
									<div className="invalid-feedback"></div>
								</div>
								{error == true && messageBody.replace(/<\/?[^>]+(>|$)/g, "") == "" ?
			          	<p className="text-danger text-start">Message can't be blank</p>
			          	:
			          	null
			          }
			          
								<div className="col-md-12 text-end mt-2 ">
									<button  type="submit"
				              className="btn btn-primary font-weight-bold text-end"
				            >Send
				          </button>
		            </div>
							</div>		      		
							{OneTOnemessages_list}
		      	</div>
						
		      </div>          
        </div>
				
      </form>
    </Modal>
  );
}

export default MessagePopup;
