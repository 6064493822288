import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { store } from '../store'
import ListJobs from './jobs/ListJobs';
import ListProjects from './projects/List';
import EditServices from './profile/editServices';
import EditSpecialisation from './profile/editSpecialisation';
import AddJob from './profile/AddJob';
import AddProject from './profile/AddProject';
import EditProfile from './profile/EditProfile';
import ViewEducation from './education/ViewEducation';
import ServicesDisplay from './ServicesDisplay';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useReactToPrint } from 'react-to-print';
import BlankProfileImg from './blank-profile.png';
import ConsultantProfileForPrint from '../components/ConsultantProfileForPrint';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const PublicProfile = (props) => {

  const [showModal, setShowModal] = useState( false )
  const [showModalSpecialisation, setShowModalSpecialisation] = useState( false )
  const [showModalAddJob, setShowModalAddJob] = useState( false )
  const [showModalAddProject, setShowModalAddProject] = useState( false )
  const [showModalEditProfile, setShowModalEditProfile] = useState( false )
  const [modal, setModal] = useState(null);
  const [modalSpecialisation, setModalSpecialisation] = useState(null);
  const [modalAddJob, setModalAddJob] = useState(null);
  const [modalAddProject, setModalAddProject] = useState(null);
  const [modalEditProfile, setModalEditProfile] = useState(null);

  const globalState = useContext(store);

 	const componentRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => componentRef.current
  });

  const [ userData, setUserData ] = useState( {} )
  const [fetchEvent, setFetchEvent] = useState(false);

  const fetchData = async () => {
    const response = await fetch('/api/v1/consultant_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setUserData(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  function scrollToUp() {
		window.scrollTo(0, 0);
	}

  useEffect(() => {
    if (modal)
      showModal ? modal.show() : modal.hide()
    fetchData()
    scrollToUp()
  }, [modal, showModal])

  useEffect(() => {
    if (modalSpecialisation)
      showModalSpecialisation ? modalSpecialisation.show() : modalSpecialisation.hide()
    fetchData()
  }, [modalSpecialisation, showModalSpecialisation])

  useEffect(() => {
    if (modalAddJob)
      showModalAddJob ? modalAddJob.show() : modalAddJob.hide()
  }, [modalAddJob, showModalAddJob])

  useEffect(() => {
    if (modalAddProject)
      showModalAddProject ? modalAddProject.show() : modalAddProject.hide()
  }, [modalAddProject, showModalAddProject])

  useEffect(() => {
    if (modalEditProfile)
      showModalEditProfile ? modalEditProfile.show() : modalEditProfile.hide()
    fetchData()
  }, [modalEditProfile, showModalEditProfile])

  const Listing = ( props ) => {
    if(props.list?.length < 1)
      return <div><ul>{props.noListMsg}</ul></div>;

    const list = props.list?.map( (name , i) => {
      return <li key={i}>{name}</li>
    })
    return <ul>{list}</ul>;
  };

  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div className="container">
        <div className="row g-2 align-items-center">
          <div className="col-md g-2 text-start">
            <h2 className="card-title my-3"><FontAwesomeIcon icon="address-card" /> My Profile</h2>
          </div>
          <div className="col-md text-end">
	          <button
			        type="button"
			        className="btn btn-primary m-2"
			        onClick={handlePrint}
			      	>Print Profile
			      </button>
	        </div>
        </div>
        <div className="card mt-1">
          <div className="row g-0">
            <div className="col-md-3 text-start">
              <img src={userData.user?.image_url} alt="BlankProfileImg" className="img-responsive" style={{width:"100%"}}/>
            </div>
            <div className="col-md-9 text-start">
              <div className="card-body">
                <h3 className="card-title"><b>{userData.user?.first_name} {userData.user?.last_name}</b></h3>
                {userData.promoted_consultant?
	               	<h6><span className="badge" >Recommended</span></h6>
	               	:
	               	null
	             	}
                <h5 className="card-title">{userData.primary_service} <i>({userData.total_experience})</i></h5>
                <h5 className="card-title">{userData.main_education}</h5>
                <h6 className="card-title"><i> {userData.user?.city}, {userData.user?.country_name}. </i></h6>
                <hr/>
                <p className="card-text">{ReactHtmlParser(userData.summary)}</p>
              </div>
            </div>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header text-start">
            <h4><b>Service Offerings</b></h4>
          </div>
          <div className="card-body text-start">
            <div>
              <p className="text-muted">The main pharmaceutical function(s) in which the consultant provides specialist services</p>
            </div>
            <ul>
              <p><strong>Primary Service:</strong></p>
              <strong>{userData.primary_service} <i>({userData.primary_service_exp})</i></strong>
              <ul>
                <ServicesDisplay services={userData.primary_sub_services}/>
              </ul>
              <hr/>
              <p><strong>Secondary Service:</strong></p>
              {
              	userData.secondary_service != null ?
              		<strong>{userData.secondary_service} <i>({userData.secondary_service_exp})</i></strong>
              		:
              		<strong>Not selected</strong>
              }

              <ul>
                <ServicesDisplay services={userData.secondary_sub_services}/>
              </ul>
            </ul>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header text-start">
            <h4><b>Specialisations</b></h4>
          </div>
          <div className="card-body text-start">

            <div>
              <p className="text-muted">The Therapeutic Areas and Product types in which the Consultant has specific expertise or experience.</p>
            </div>
            <ul>
              <strong>Therapeutic Areas</strong>
              <Listing list={userData.therapeutics} noListMsg="None selected" />
              <hr/>
              <strong>Product Specialisations</strong>
              <Listing list={userData.products} noListMsg="None selected" />
              <hr/>
              <strong>Service Offering Specialisations</strong>
              <Listing list={userData.services} noListMsg="None selected" />
              <hr/>
              <strong>Languages Known (in addition to English)</strong>
              <Listing list={userData.languages} noListMsg="None selected" />
            </ul>
          </div>
        </div>

        <div className="card mt-3">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h4 className='m-0'><b>Experience (Roles)</b></h4>

          </div>
          <div className="card-body">
            <div>
              <p className="text-muted"></p>
            </div>
            <ListJobs display_public_profile={true} fetch={fetchEvent} setFetch={setFetchEvent}/>
          </div>
          <AddJob show={showModalAddJob} setShow={setShowModalAddJob} setModal={setModalAddJob} setFetch={setFetchEvent}/>
        </div>

        <div className="card mt-3">
          <div className="card-header d-flex align-items-center justify-content-between">
            <h4 className='m-0'><b>Projects</b></h4>
          </div>
          <div className="card-body">
            <div className="text-start">
              <p className="text-muted">A selection of projects worked on by the Consultant</p>
            </div>
            <ListProjects display_public_profile={true} fetch={fetchEvent} setFetch={setFetchEvent}/>
          </div>
          <AddProject show={showModalAddProject} setShow={setShowModalAddProject} setModal={setModalAddProject} setFetch={setFetchEvent}/>
        </div>
        <ViewEducation display_public_profile={true} />
        <div className="card mt-3 col-md-2">
        	<Link className="btn btn-primary font-weight-bold" to={"/view-consultant-profile"}>Show My Profile</Link>
        </div>

      </div>
      <ConsultantProfileForPrint ref={componentRef} print_from={'consultant'}/>
    </div>

  );
}

export default PublicProfile;
