import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import CustomEditor from '../../components/CustomEditor'
import CompanyPublicProfile from '../CompanyPublicProfile'
import FloatingSelect from '../../components/FloatingSelect';


const CompanyProfileView = (props) => {

	let history = useHistory();
  const [ created, setCreated ] = useState(false)
  const [countryCodes, setCountryCodes] = useState([])
  const [services, setServices] = useState([])
  const [country, setCountry] = useState('')
  const [turnover, setTurnover] = useState('')
  const [employee_count, setEmloyeeCount] = useState('')
  const [service, setService] = useState('')
  const [email, setEmail] = useState('')
  const [email_confirmation, setEmailConfirmation] = useState('')
  const [service_locations, setServiceLocations] = useState([{id:0,city:'',country:''}])
  const [ logo, setLogo ] = useState('')
  const [ company_data, setCompanyData ] = useState({})
  const globalState = useContext(store);
  const [value, setValue] = useState('');
  const [imageField, setImageField] = useState(false);
  const [showCompanyProfile, setShowCompanyProfile] = useState( false );
  const [modalCompanyProfile, setModalCompanyProfile] = useState(null);
  const [fetchEvent, setFetchEvent] = useState(false);



 	function scrollToUp() {
		window.scrollTo(0, 0);
	} 


  const company_turnover = [
    { value: '< $10 M', text: '< $10 M' },
    { value: '$10 – 100 M', text: '$10 – 100 M' },
    { value: '$100 M – 1 B', text: '$100 M – 1 B' },
    { value: '> $1 B', text: '> $1 B' },
  ]

  const employees_number = [
    { value: '< 25', text: '< 25' },
    { value: '25 – 100', text: '25 – 100' },
    { value: '100 – 1000', text: '100 – 1000' },
    { value: '> 1000', text: '> 1000' },
  ]

  const fetchData = async () => {

    const response_company = await fetch('/api/v1/companies', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson_company = await response_company.json()

    if(response_company.ok)
    {
    	setCompanyData(responseJson_company)
    	setTurnover(responseJson_company.turnover)
    	setServiceLocations(responseJson_company.locations)
    	setEmloyeeCount(responseJson_company.no_of_employees)
    	setValue(responseJson_company.profile)
    }

    const response = await fetch('/api/v1/signup_data', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get'
    })
    const responseJson = await response.json()

    if (response.ok) {
      setCountryCodes( responseJson.country_codes )
      const allServices = responseJson.services.map( data => {
        return { value: data.name, text: data.name }
      });
      setServices( allServices )
    } else {
      alert.show("Server is down.")
      return false
    }
  }


  useEffect( () => {
    fetchData();
    scrollToUp();
  }, []);

  useEffect(() => {
    if (modalCompanyProfile)
      showCompanyProfile ? modalCompanyProfile.show() : modalCompanyProfile.hide()
  }, [showCompanyProfile, modalCompanyProfile])



  const handleCompanyProfileSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const formData = {
      company: {
        website: event.target.website.value,
        turnover: event.target.turnover.value,
        no_of_employees: event.target.no_of_employees.value,
        profile: value,
        department_name: event.target.department_name.value,
        locations_attributes: service_locations
      }
    }

    if (logo){
      formData.company.logo = logo
      formData.company.logo_name = `profile_pic.${event.target.logo.files[0].type.split('/')[1]}`
    }

    const response = await fetch( '/api/v1/companies', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const company_data = await response.json()
    
    if (response.ok) {
    	fetchData();
    	setImageField(false)
    } else {
      resetErrors( event.target.elements );
      showErrors( event.target.elements, userData );
    }
  }


	const locations_of_service = service_locations.map((data,index)=>
  {
  	return(
  		<div className="row g-2 mb-2">
        <div className="col-md-5">
          <div className="form-floating">
            <input type="text" className="form-control" name="service_city" value={data.city} id="service_city" placeholder="City" required onInput={e =>handleCityChange(e, data.id)} />
            <label htmlFor="service_city">City</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>
        <div className="col-md-5">
          <div className="col-md">
            <FloatingSelect data={countryCodes} name='service_country' value={data.country} required={true} onChange={e => handleCountryChange( e.target.value, data.id )}
              label='Country' placeholder='Select Country' withError='true'/>
          </div>
        </div>
        {
        	index > 0 ?
        	<div className="col d-flex align-items-center justify-content-center">
	          <button type="button" onClick={() => removeLocation(data.id)}
	            className="btn btn-danger btn-block"><FontAwesomeIcon icon="times" /></button>
	        </div>
	        :
	        null

        }

      </div>
  	)
  });

	const addLocation = () => {
   	let count = service_locations.length;
    let id = service_locations[count-1].id + 1;
    setServiceLocations( [...service_locations, {id:id, city:'',country:''} ]  )

  }

  const removeLocation = (id) => {
    const new_service_locations =  service_locations.filter( data => data.id != id )
    setServiceLocations( new_service_locations )
  }

  const uploadLogo = (e) => {
  	e.target.classList.remove("is-invalid");
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setLogo( reader.result ) ;
    reader.readAsDataURL(file);
  }

  const handleCityChange = (event, id )=> {

    const newData = service_locations.map( data => {
      if (id == data.id)
        data.city = event.target.value
      return data
    });
    setServiceLocations( newData )
  }

  const handleCountryChange = (country, id )=> {

    const newData = service_locations.map( data => {
      if (id == data.id)
        data.country = country
      return data
    });
    setServiceLocations( newData )
  }

  function handleChange(newValue) {
    setValue(newValue);
  }

  return (
    <div className="">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="building" /> Company Profile</h2>
        </div>
    	</div>
    	<div className="row g-0">
	    	<div className="col-md-2 text-start mb-3">
	        <img src={company_data.image_url}  className="img-responsive" style={{width:"150px"}}/>
	        <button onClick={()=>setImageField(true)}  className="btn btn-primary btn-block ml-auto mt-3">Edit Logo</button>
	      </div>
	    </div>
    	<div className="mb-4">
    		<form onSubmit={handleCompanyProfileSubmit}  className="row g-2">        	
        	{imageField?
        		<div className="row g-2">
		          <div className="mb-3">
		            <label htmlFor="logo" className="form-label">Insert Company Logo</label>
		            <input className="form-control" name='logo' type="file" id="logo" onChange={(e) => uploadLogo(e)} />
		            <div className="invalid-feedback"></div>
		          </div>
		        </div>
		        :null
		      }
        	<div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" value={company_data.name} className="form-control" disabled={true} name="company_name" id="company_name" placeholder="Company Name" />
                <label htmlFor="company_name">Company Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>


          <div className="row g-2 mb-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" defaultValue={company_data.department_name} name="department_name" id="department_name" placeholder="John" />
                <label htmlFor="department_name">Department (Optional)</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>            
          </div>
          <p className="text-start fw-bold">About the Company</p>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" defaultValue={company_data.website} name="website" id="website" placeholder="Company Name" required={true}  />
                <label htmlFor="website">Website</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2 ">
          	<div className="text-start">
		      		<CustomEditor id="project" placeholder={"Company Profile"} value={value} onChange={handleChange} />
          	</div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="col-md">
		            <FloatingSelect data={company_turnover} name='turnover' required={true} onChange={e => setTurnover( e.target.value )}
		              label='Company Turnover' value={turnover} placeholder='Select Turnover' withError='true'/>
		          </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="col-md">
		            <FloatingSelect data={employees_number} value={employee_count} name='no_of_employees' required={true} onChange={e => setEmloyeeCount( e.target.value )}
		              label='Number Of Employee' placeholder='Select' withError='true'/>
		          </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <label className="text-start fw-bold">Company locations where Pharmalancers services will be required</label>
            </div>
          </div>
         	{locations_of_service}
         	{
         		service_locations.length < 10 ?
         		<div className="text-start ms-3">
			        <button type="button" onClick={addLocation} className="btn btn-primary btn-block"><FontAwesomeIcon icon="plus" /></button>
			      </div>
			      :
			      null
         	}

          <div className="row g-2">          	
	          <div className="col-md g-2 text-end">
	            <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
	          </div>	          
	        </div>
	        <div className="row g-2">
		        <div className="col-md g-2 text-end">
	            <button onClick={()=>setShowCompanyProfile(!showCompanyProfile)} className="btn btn-primary font-weight-bold">Display Public Profile</button>
	          </div>
          </div>          
        </form>
	      <CompanyPublicProfile companyDataId={company_data.id} show={showCompanyProfile} setShow={setShowCompanyProfile} setModal={setModalCompanyProfile} setFetch={setFetchEvent}/>

      </div>
    </div>
  );
}

export default CompanyProfileView;
