import React, { useContext, useEffect, useState, useRef, forwardRef} from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import TabCards from '../../components/TabCards';
import ProjectDetails from './ProjectDetails';
import ProjectFees from './ProjectFees';
import ProjectSteps from './ProjectSteps';
import ProjectLogistics from './ProjectLogistics';
import RequiredSpecialisation from './RequiredSpecialisation';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';







const NewProject = (props) => {

	const experiences = [
    { value: '4', text: '1-4' },
    { value: '9', text: '5-9' },
    { value: '20', text: '10-20' },
    { value: '21', text: '>20' },
  ]

  const project_location = [
    { value: '1', text: 'Fully On-site' },
    { value: '2', text: 'Mix of on-site/remote' },
    { value: '3', text: 'Fully Remote' },
    { value: '4', text: 'No preference' },
  ]

  const { state } = useLocation();

  if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}  

	const my_data =  JSON.parse(localStorage.getItem('document'));

  const globalState = useContext(store);
  const { dispatch } = globalState;
	const [ createNewProject, setCreateNewProject ] = useState(false)
  const [ defaultOptions, setDefaultOptions ] = useState(false)
  const [ findConsultant, setFindConsultant ] = useState(false)
  const [ therapeuticOthers, setTherapeuticOthers ] = useState([])  
  const [ projectDetails, setProjectDetails ] = useState({})  
  const [ projectID, setProjectID ] = useState(null)  

  const [activeId, setActiveId] = useState(1)
  
  const commonProps = { activeId: activeId, setActiveId: setActiveId }
  
 
  const formEl = useRef();

  const handleProjectsButton= async () => {
		if(findConsultant)
		{
			setFindConsultant(false)
		}
		else if(createNewProject)
		{
			setCreateNewProject(false)
		}
		else
		{
			setDefaultOptions(false)
		}
	}

  
 
  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <div className="container">
      	<div className="row g-2 d-flex align-items-center">
          <div className="col-md g-2 text-start">
            <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="briefcase-medical" /> Post Project</h2>
          </div>
          {(defaultOptions || createNewProject)&&(!findConsultant) ?
          	<div className="col-md g-2 text-end align-items-center">
            	<button type="button"
  	              className="btn btn-primary"	onClick={()=> handleProjectsButton()}               
              >Back</button>
            </div>
            : findConsultant ?
            <div className="col-md g-2 text-end align-items-center">
            	<button type="button"
  	              className="btn btn-primary"	onClick={()=> handleProjectsButton()}               
              >Back To Requirements</button>
            </div>
            :
            null
          }
        </div>
        {
        	(!defaultOptions && my_data.copyProjectState == false) ?
        	<div className="row g-2 mt-4">
	      		<div className="col-md text-center">
	      			<button type="button" onClick={()=>setDefaultOptions(true)} className="btn btn-primary btn-lg btn-block col-md-10">Create a custom project</button>
	      		</div>
	      		<div className="col-md text-center">
	      			<Link to={"/send-requirements"} type="button" className="btn btn-secondary btn-lg btn-block col-md-10">Ask Pharmalancers to create a project</Link>
	        	</div>
	        </div>
	        : (!createNewProject && my_data.copyProjectState == false)?
	        <div className="row g-2 mt-4">
	      		<div className="col-md text-center">
	      			<button type="button" onClick={()=>setCreateNewProject(true)} className="btn btn-primary btn-lg btn-block col-md-10">Create a new project</button>
	      		</div>	      		
	        	<div className="col-md text-center">
	      			<Link to={"/project-posted"} type="button" className="btn btn-secondary btn-lg btn-block col-md-10">Copy from a previous project</Link>
	        	</div>
	        </div>
	        :
	        null

        }  
           
      	
        {
        	createNewProject ?
        	<ProjectSteps retriveDataProject={false} projectDetails={projectDetails} create_project={true} headings={"Create Project"}/>
        	:
        	null

        }

        {
        	my_data.copyProjectState ?
        	<ProjectSteps retriveDataProject={true} projectDetails={projectDetails} create_project={true} headings={"Create Project"}/>
        	:
        	null

        }

        
      </div>
    </div>

  );
}

export default NewProject;
