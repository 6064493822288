import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";
import ProposalSteps from './ProposalSteps';
import ProjectInformationPop from './ProjectInformationPop'
import ViewJob from '../jobs/ViewJob';
import View from '../projects/View';






const PropsalPreview = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
	const [ proposalDetails, setProposalDetails ] = useState({})
	const [ expRoles, setExpRoles ] = useState([])
	const [ projects, setProjects ] = useState([])
	const [ companyData, setCompanyData ] = useState({})
  const [ projectDescription, setProjectDescription] = useState('')
  const [ milestones, setMilestones] = useState([{id:0,milestone:'',date: null}])
  const [startDate, setStartDate] = useState(null)
  const [showCompanyProfile, setShowCompanyProfile] = useState( false );
	const [modalCompanyProfile, setModalCompanyProfile] = useState(null);
	const [fetchEvent, setFetchEvent] = useState(false);
	const [showClientModal, setShowClientModal] = useState( false );
	const [modalClientPublicProfile, setModalClientPublicProfile] = useState(null);
	const [showProjectDetails, setShowProjectDetails] = useState( false );
	const [modalProjectDetails, setProjectDetailsModal] = useState(null);
	const [proposalDocuments, setProposalDocuments] = useState([]);


	let history = useHistory()

	const [modal, setModal] = useState(null);

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	const fetchData = async () => {

	  const response = await fetch('/api/v1/project_details/' + my_data.project_details_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()


	  if (response.ok) {
	    setProjectDetails(responseJson)
	    setCompanyData(responseJson.company)
	    setStartDate(new Date(responseJson.start_date))
	  } else {
	    alert.show("Server is down.")
	    return false
	  }

	  const response1 = await fetch('/api/v1/proposals/'+ my_data.proposal_id, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })

		  const responseJsonProposal = await response1.json()

		  console.log("responseJsonProposal")
		  console.log(responseJsonProposal)

		  if(response1.ok)
		  {
		  	setProposalDetails(responseJsonProposal)
		  	if(responseJsonProposal.relevant_job_role != null)
		  	{
		  		setExpRoles(responseJsonProposal.relevant_job_role)
		  	}
		  	if(responseJsonProposal.relevant_project != null)
		  	{
		  		setProjects(responseJsonProposal.relevant_project)
		  	}
		  	if(responseJsonProposal.project_milestones != null)
		  	{
		  		setMilestones(responseJsonProposal.project_milestones)
		  	}

		  	setProposalDocuments(responseJsonProposal.proposal_documents)
		  }



	}

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	  scrollToUp();
	}, []);

	useEffect(() => {
    if (modalCompanyProfile)
      showCompanyProfile ? modalCompanyProfile.show() : modalCompanyProfile.hide()
  }, [showCompanyProfile, modalCompanyProfile])

  useEffect(() => {
    if (modalClientPublicProfile)
      showClientModal ? modalClientPublicProfile.show() : modalClientPublicProfile.hide()
  }, [modalClientPublicProfile, showClientModal])

  useEffect(() => {
    if (modalProjectDetails)
      showProjectDetails ? modalProjectDetails.show() : modalProjectDetails.hide()
  }, [modalProjectDetails, showProjectDetails])

	function handleChange(newValue) {
    setProjectDescription(newValue);
  }

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const addMilestones = () => {
   	let count = milestones.length;
    let id = milestones[count-1].id + 1;
    setMilestones( [...milestones, {id: id,milestone:'',date: null} ]  )
  }

  const removeMilestones = (id) => {
    const new_milestones =  milestones.filter( data => data.id != id )
    setMilestones( new_milestones )
  }

  const Milestones = milestones.map((data,index)=>
  {
  	return(
			<tr key={index}>
	      <th>{index+1}</th>
	      <td>{data.name}</td>
	      <td>{data.milestone_date}</td>
      </tr>

  	);
  })


  const listItems = expRoles.map((data, index) =>{
    return <ViewJob display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} proposalView={true} />
  });

  const project = projects.map((data, index) =>{
    return <View display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} proposalView={true} />
	});

  const proposalConfirmation = () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Confirm submit proposal?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            postProposal();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const deleteConfirmation= () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Delete This Proposal?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            deleteProposal();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const showErrorPopup= () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>The submmision deadline has passed. Proposal cannot be submitted.</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-center">
				        <button className="btn btn-danger"
				          onClick={() => {
				            onClose();
				          }}
				        >
				          Ok
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const checkProposalDeadline = async () => {
		var check_date = new Date(projectDetails.proposal_due_date);
		check_date.setDate(check_date.getDate() + 3)
		if(proposalDetails.version == 1 && check_date <  new Date())
		{
			showErrorPopup();
		}
		else
		{
			proposalConfirmation();
		}
	}

	const postProposal = async () => {
		const response = await fetch('/api/v1/post_proposals/'+ my_data.proposal_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'put',
	  })

	  const responseJson = await response.json()

	  console.log("responseJson")
	  console.log(responseJson)

	  if (response.ok) {
	    history.push("/proposal-submited");
	  } else {
	    postProposalError();
	    return false
	  }

	}

	const deleteProposal = async () => {

	  const response = await fetch('/api/v1/proposals/'+ my_data.proposal_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'delete',
	  })

	  const responseJson = await response.json()

	  console.log("responseJson")
	  console.log(responseJson)

	  if (response.ok) {
	    history.push("/proposal-wip");
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	const postProposalError = () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui-error border border-primary rounded '>

		        <h5>Some data is missing, please check all fields completed correctly or not?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            history.push({pathname:"/edit-proposal", state:{ project_details_id: projectDetails.id, proposal_id:proposalDetails.id}});
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const proposal_documents_view = proposalDocuments.map((data,index)=>
  {
		return(
			<div key={index} className="col-md-12 text-start mt-3 mb-3">
      	<div className="row mt-4">
  				<div className="col-md-9">
  					<a href={data.attachment} target="_blank" rel="noopener noreferrer" ><b>{data.attachment_name}</b></a>
  				</div>

				</div>
			</div>
		);
	})


  return (
    <div className="container mb-3">
    	<div className="text-center mt-2">
      	<h2>Proposal Preview</h2>
      </div>
      <div className="row g-2 mt-2">
      	<div className="col-md-12">
	      	<div className="row g-0 text-start mt-2">
	        	<h5 className="col-md-3"><b>Company Name: </b></h5>
	        	<button type="button" className="col-md-9  text-start btn btn-link p-0" onClick={() => setShowCompanyProfile(!showCompanyProfile)} ><h5>{companyData.name}</h5></button>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Posted by: </b></h5>
	      		<button type="button" className="col-md-9  text-start btn btn-link p-0" onClick={() => setShowClientModal(!showClientModal)} ><h5>{projectDetails.creator}</h5></button>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Project Title: </b></h5>
	      		<h5 className="col-md-9  text-start">{projectDetails.title}    [ <button type="button" className="text-start btn btn-link p-0 mt-1" onClick={() => setShowProjectDetails(!showProjectDetails)} ><h6>Show Requirements</h6></button> ]</h5>
	      	</div>
      	</div>
    	</div>
    	<div className="card mt-3">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Scope</b></h4>
        <div className="card-body text-start">
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Objectives : </strong>
            <h6 className="col-md-9 ">{ReactHtmlParser(proposalDetails.project_objective)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project In Scope : </strong>
            <h6 className="col-md-8">{ReactHtmlParser(proposalDetails.project_in_scope)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Out Of Scope : </strong>
            <h6 className="col-md-9">{ReactHtmlParser(proposalDetails.project_out_scope)}</h6>
          </div>
        </div>
      </div>
      <div className="card mt-3">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Relevant Experience</b></h4>
        <div className="card-body text-start">
        	<h5><b>Experience (Roles) : </b></h5>
        	{
        		expRoles.length > 0 ?
	          <div className="row g-0 text-start mt-2">
	            {listItems}
	          </div>
	          :
	          null
	        }

          <h5 className="mt-3"><b>Experience (Projects) : </b></h5>
          {
        		projects.length > 0 ?
	          <div className="row g-0 text-start mt-2">
	            {project}
	          </div>
	          :
	          null
	        }
	        <h5 className="mt-3"><b>Summary of Relevant Experience : </b></h5>
          {
        		proposalDetails.relevant_exp_summary != '' ?
	          <div className="col-md-12">
	            {ReactHtmlParser(proposalDetails.relevant_exp_summary)}
	          </div>
	          :
	          null
	        }

        </div>
      </div>
      <div className="card mt-3">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Timelines</b></h4>
        <div className="card-body text-start">
        	<h5><b>Project Dates : </b></h5>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Proposed Date : </strong>
            <h6 className="col-md-9">{proposalDetails.proposed_start_date}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Comment : </strong>
            <h6 className="col-md-9">{proposalDetails.comment_start_date ? proposalDetails.comment_start_date : 'None'}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Proposed Duration : </strong>
            <h6 className="col-md-9">{proposalDetails.proposed_estimated_duration} {proposalDetails.proposed_estimated_duration ? projectDetails.estimated_duration_category : ''}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Comment : </strong>
            <h6 className="col-md-9">{proposalDetails.comment_estimated_duration ? proposalDetails.comment_estimated_duration : 'None'}</h6>
          </div>
        </div>
				<div className="col-md-10 card-body text-start">
        	<h5><b>Project Milestones : </b></h5>
        	<table className="table table-bordered table-striped table-sm text-start">
					  <thead>
					    <tr>
					      <th scope="col">Sr.No</th>
					      <th scope="col">Milestone Name</th>
					      <th scope="col">Date</th>
					    </tr>
					  </thead>
					  <tbody>
				    	{Milestones}
					  </tbody>
					</table>
        </div>
        <div className="card-body text-start">
        	<h5><b>Project Logistics : </b></h5>
        	{
          	projectDetails.consultant_country != '' ?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Agree Consultant Country : </strong>
	            <h6 className="col-md-9">{proposalDetails.agree_consultant_country ? 'Yes' : 'No'}</h6>
	          </div>
	          :
	          null
	        }
	        {
          	projectDetails.consultant_country != '' ?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Comment : </strong>
	            <h6 className="col-md-9">{proposalDetails.comment_consultant_country ? proposalDetails.comment_consultant_country : 'None'}</h6>
	          </div>
	          :
	          null
	        }
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Agree Location Of Project : </strong>
            <h6 className="col-md-9">{proposalDetails.agree_location_type ? 'Yes' : 'No'}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Comment : </strong>
            <h6 className="col-md-9">{proposalDetails.comment_location_type ? proposalDetails.comment_location_type : 'None'}</h6>
          </div>
          {
          	projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
          	<div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Agree Onsite Location : </strong>
	            <h6 className="col-md-9">{proposalDetails.agree_onsite_location ? 'Yes' : 'No'}</h6>
	          </div>
	          :
	          null
          }
          {
          	projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Comment : </strong>
	            <h6 className="col-md-9">{proposalDetails.comment_onsite_location ? proposalDetails.comment_onsite_location : 'None'}</h6>
	          </div>
	          :
	          null
	        }

        </div>
      </div>
      <div className="card mt-3">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees</b></h4>
        <div className="card-body text-start">
	        {
	        	proposalDetails.total_fees != null &&  projectDetails.currency == 'Other' ?
	        	<div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Total Fees : </strong>
	            <h6 className="row g-0 col-md-9">{(Math.round(proposalDetails.total_fees * 100) / 100).toFixed(2)} {projectDetails.other_currency}</h6>
	          </div>
	          :proposalDetails.total_fees != null && projectDetails.currency != 'Other'?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Total Fees : </strong>
	            <h6 className="row g-0 col-md-9">{(Math.round(proposalDetails.total_fees * 100) / 100).toFixed(2)} {projectDetails.currency} </h6>
	          </div>
	          :
	          null
	        }

	        {
	        	proposalDetails.rate_per_hour != null &&  projectDetails.currency == 'Other' ?
	        	<div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Rate/Hour : </strong>
	            <h6 className="row g-0 col-md-9">{(Math.round(proposalDetails.rate_per_hour * 100) / 100).toFixed(2)} {projectDetails.other_currency}</h6>
	          </div>
	          :proposalDetails.rate_per_hour != null &&  projectDetails.currency != 'Other' ?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Rate/Hour : </strong>
	            <h6 className="row g-0 col-md-9">{(Math.round(proposalDetails.rate_per_hour * 100) / 100).toFixed(2)} {projectDetails.currency} </h6>
	          </div>
	          :
	          null
	        }

          {
	          	proposalDetails.effort_frequency_type != '' ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Effort Frequency Type : </strong>
		            <h6 className="col-md-9">{proposalDetails.effort_frequency_type}</h6>
		          </div>
		          :
		          null
	          }
	          {
	          	proposalDetails.effort_frequency != null ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Effort Frequency : </strong>
		            <h6 className="col-md-9">{proposalDetails.effort_frequency}</h6>
		          </div>
		          :
		          null
	          }

	          {
	          	proposalDetails.total_effort_type != '' ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Effort Type : </strong>
		            <h6 className="col-md-9">{proposalDetails.total_effort_type}</h6>
		          </div>
		          :
		          null

	          }

	          {
	          	proposalDetails.total_effort != null ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Effort : </strong>
		            <h6 className="col-md-9">{proposalDetails.total_effort}</h6>
		          </div>
		          :
		          null
	          }
        </div>
      </div>
      <div className="card mt-3">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Proposal Attachments</b></h4>
        <div className="card-body text-start">
        	{proposal_documents_view}
        </div>
      </div>
      {
      	proposalDetails.ammendment_requirement_message != null ?
      	<div className="card mt-3">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Proposal Amendment Details</b></h4>
	        <div className="card-body text-start">
	        	{ReactHtmlParser(proposalDetails.ammendment_requirement_message)}
        	</div>
	      </div>
	      :null
      }

      <div className="row g-3 mt-3 mb-3 justify-content-between">
      	{
      		proposalDetails.status == "wip" ?
	      	<div className="col-md-4 text-start">
	          <button onClick={()=> history.push("/proposal-wip")}  type="button" className="btn btn-secondary" >Close</button>
	        </div>
	        :
	        <div className="col-md-4 text-start">
	          <button onClick={()=> history.push("/proposal-submited")}  type="button" className="btn btn-secondary" >Close</button>
	        </div>
	      }


      	{
      		proposalDetails.status != "posted" && proposalDetails.status != 'accepted' && proposalDetails.status != 'rejected' ?
      		<div className="col-md-4 text-center">
	          <button  type="button" onClick={()=>deleteConfirmation()} className="btn btn-danger btn-block ml-auto" >Delete</button>
	        </div>
	        :
	        null
      	}

      	{
      		proposalDetails.status != "posted" && proposalDetails.status != 'accepted' && proposalDetails.status != 'rejected' ?
      		<div className="col-md-4 text-end">
	          <button onClick={()=>checkProposalDeadline()} type="submit" className="btn btn-primary btn-block ml-auto ">Submit Proposal</button>
	        </div>
	        :
	        null
      	}


      </div>
      <ClientPublicProfile clientDataId={projectDetails.creator_id} show={showClientModal} setShow={setShowClientModal} setModal={setModalClientPublicProfile} setFetch={setFetchEvent}/>
      <CompanyPublicProfile companyDataId={companyData.id} show={showCompanyProfile} setShow={setShowCompanyProfile} setModal={setModalCompanyProfile} setFetch={setFetchEvent}/>
    	<ProjectInformationPop project_detail_id={projectDetails.id} show={showProjectDetails} setShow={setShowProjectDetails} setModal={setProjectDetailsModal} setFetch={setFetchEvent}/>
 		</div>
  );
}

export default PropsalPreview;
