import React, { useState, useEffect, useContext, forwardRef, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";
import ViewJob from '../jobs/ViewJob';
import WorkOrderView from './WorkOrderView';
import FloatingSelect from '../../components/FloatingSelect';


const CompletedProjectsView = (props) => {

	const globalState = useContext(store);
	const formEl = useRef();
	const formId = `projectUpdate`
	const { state } = useLocation();
  const { dispatch } = globalState;
	const [ userDetails, setUserDetails ] = useState({})	
  const [ milestones, setMilestones] = useState([{id:0,milestone:'',date: null}])
  const [ timeBasedMilestone, setTimeBasedMilestone] = useState([{id:0,description:'',date: null, rateperhour:'', currency:''}])
  const [ invoice_Milestones, setInvoice_Milestones] = useState([{id:0,milestone:'',date: null, percentage:'',amount:''}])
  const [projectDetails, setProjectDetails] = useState({})
  const [workOrder, setWorkOrder] = useState({})
  const [projectUpdates, setProjectUpdates] = useState([{ id: 0, date: null, postedBy: '', updateText: '', uplaodFile: null }])
  const [showModal, setShowModal] = useState( false );
	const [workOrderModal, setWorkOrderModal] = useState(null);
	const [showComplate, setShowComplate] = useState( false );
	const [completeWorkModal, setCompleteWork] = useState(null);
	const [completion_comment, setCompletionComment] = useState('');
	const [invoiceModalId, setInvoiceModalId] = useState('');
	const [projectUpdate, setProjectUpdate] = useState('');
	const [showMilestoneUpdate, setShowInvoiceMilestone] = useState( false );
	const [invoiceModal, setInvoiceModal] = useState(null);
	const [showMilestoneEdit, setShowMilestoneEdit] = useState( false );
	const [milestoneModal, setMilestoneModal] = useState(null);
	const [actualDate, setActualDate] = useState(null);
	const [showTimeLine, setShowTimeLine] = useState(false);
	const [timeLineModal, setTimeLinesModal] = useState(null);
	const [actualAmount, setActualAmount] = useState(null);
	const [ milestoneData, setMilestoneData ] = useState({})
	const [ invoicemilestoneData, setInvoiceMilestoneData ] = useState({})
	const [ mileActualDateComment, setMileActualDateComment ] = useState({})
	const [ billableHours, setBillableHours ] = useState(null)
	const [ actualStartDate, setActualStartDate ] = useState(null)
	const [ actualStartDateComment, setActualStartDateComment ] = useState('')
	const [ actualDuration, setActualDuration ] = useState('')
	const [ actualDurationComment, setActualDurationComment] = useState('')
  const [ attachment, setAttachment ] = useState('')
  const [ work_order_updates, setWork_order_updates ] = useState([])
  const [ IncompleteMilestone, setIncompleteMilestone ] = useState(false)



	const invoicingfrequency = [
    { value: 'Weekly', text: 'Weekly' },
    { value: 'Fortnightly', text: 'Fortnightly' },
    { value: 'Monthly', text: 'Monthly' },

  ]
  
	let history = useHistory()

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	useEffect(() => {
    if (workOrderModal)
      showModal ? workOrderModal.show() : workOrderModal.hide()
  }, [showModal, workOrderModal])



  const fetchData = async () => {
    const response = await fetch('/api/v1/work_orders/' + my_data.work_order_id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

    console.log("responseJson")
    console.log(responseJson)
   
    if (response.ok) {
    	setIncompleteMilestone(false)
      setProjectDetails(responseJson.project_detail)
      setWorkOrder(responseJson)
      setUserDetails(responseJson.user)
      setMilestones(responseJson.work_order_milestones)
      setInvoice_Milestones(responseJson.work_order_invoice_schedules)
      setCompletionComment(responseJson.completion_comment)
      setWork_order_updates(responseJson.work_order_updates)

      responseJson.work_order_invoice_schedules.map((data,index)=>
	  	{  
	  		if(data.status != '' && data.status != 'accept')
	  		{
	  			setIncompleteMilestone(true);
	  		} 
	  	})

    } else {
      alert.show("Server is down.")
      return false
    }
  }

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	}, [props.show]);

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify, disable }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} disabled={disable} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const Milestones = milestones.map((data,index)=>
  {
  	return(
			<tr key={index}>
	      <th>{index+1}</th>
	      <td>{data.name}</td>
	      <td>{data.milestone_date}</td>
	      <td>{data.actual_milestone_date}</td>
	      <td>{data.comment_actual_milestone_date}</td>
      </tr>

  	);
  })  

  const ProjectUpdates = projectUpdates.map((data,index)=>
  {  	
		return(
			<tr key={index}>
	      <th>{index+1}</th>
	      <td>{data.date}</td>
	      <td>{data.postedBy}</td>
	      <td>{data.updateText}</td>
	      <td><Link className="link">{data.uplaodFile}</Link></td>	      
      </tr>

  	);
  })

  const InvoiceMilestones = invoice_Milestones.map((data,index)=>
  {
  	
  	if(data.status != 'accept' && data.status != null)
  	{
  		return(
				<tr key={index}>
		      <th>{index+1}</th>
		      <td>{data.name}</td>	     
		      <td>{data.milestone_date}</td>
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td>{workOrder.rate_per_hour}</td>:<td>{data.amount_percent}</td>
		      }
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td>{data.billable_hours}</td>:<td>{(Math.round(data.amount * 100) / 100).toFixed(2)} {projectDetails.currency}</td>
		      }
		      
		      <td>{data.actual_amount != null ? data.actual_amount+" "+projectDetails.currency : ''}</td>
		      <td>
      			<h6 className={data.status_indicator}><b>{data.status != null ? data.status.charAt(0).toUpperCase() + data.status.slice(1) : ''}</b></h6>
    			</td>
	      </tr>

	  	);
  	}
  	else
  	{
  		return(
				<tr key={index}>
		      <th>{index+1}</th>
		      <td>{data.name}</td>	     
		      <td>{data.milestone_date}</td>
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td>{workOrder.rate_per_hour}</td>:<td>{data.amount_percent}</td>
		      }
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td>{data.billable_hours}</td>:<td>{(Math.round(data.amount * 100) / 100).toFixed(2)} {projectDetails.currency}</td>
		      }
		      
		      <td>{data.actual_amount != null ? data.actual_amount+" "+projectDetails.currency : ''}</td>
		    	<td>
      			<h6 className={data.status_indicator}><b>{data.status != null ? data.status.charAt(0).toUpperCase() + data.status.slice(1) : ''}</b></h6>
    			</td> 
		      
	      </tr>

	  	);
  	}
  	
  })


  const workOrderUpdatesList = work_order_updates.map(m=>
  {
  	return(
  		<div key={m.id} className="row g-2 text-start align-items-center mt-2 m-4 comment">        	
        <div className="col-md-8">	          
            {m.display_name} &#8226; {m.created_at}
          <div >
          	{ReactHtmlParser(m.update_details)}
          </div>          
        </div>
        {m.attachment_url != '' ?
        	<div className="text-end">
  					<a className="btn btn-primary" href={m.attachment_url} target="_blank" rel="noopener noreferrer" >Download Attachment</a>
  				</div>
  				:
  				null
  			}		        
      </div>
  	);
  })



  return (
    <div className="container mb-5">
    	<div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="box" /> Project Completed</h2>
        </div>
    	</div>
	    <div className="row g-2 mt-2">
      	<div className="col-md-12">
      		<div className="row g-0 text-start">
	      		<h4 className="col-md-3"><b>Project Title: </b></h4>
	      		<h4 className="col-md-9  text-start"><b>{projectDetails.title} </b></h4>
	      	</div>
      		<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Company Name: </b></h5>
	      		<h5 className="col-md-9">{projectDetails.company_name}</h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Consultant Name: </b></h5>
	      		<h5 className="col-md-9">{userDetails.first_name} {userDetails.last_name}</h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Client Name: </b></h5>
            <h5 className="col-md-9">{projectDetails.creator}</h5>
	      	</div>	      	      	
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Work Order Date: </b></h5>
            <h5 className="col-md-9">{workOrder.update_date} [ <button type="button" className="text-start btn btn-link p-0 mt-1" onClick={()=> setShowModal(!showModal)}><h6>Work Order Details</h6></button> ]</h5>
	      	</div>	      	
      	</div>
    	</div>    	
    	<div className="card mt-4">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Scope</b></h4>
        <div className="card-body text-start">
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Objectives : </strong>
            <h6 className="col-md-9 ">{ReactHtmlParser(workOrder.project_objective)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project In Scope : </strong>
            <h6 className="col-md-8">{ReactHtmlParser(workOrder.project_in_scope)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Out Of Scope : </strong>
            <h6 className="col-md-9">{ReactHtmlParser(workOrder.project_out_scope)}</h6>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees</b></h4>
        <div className="card-body text-start">
        	<div className="text-start">
		        <div className="row g-0 text-start mt-2">
		          <strong className="col-md-3">Type of project : </strong>
		          <h6 className="col-md-9 ">{projectDetails.fees_type_dv}</h6>
		        </div>
		        {
		        	projectDetails.fees_type_dv == 'Time-based' ? 
		        	<div className="row g-0 text-start mt-2">
			          <strong className="col-md-3">Fee Rate/ hour : </strong>
		          	<h6 className="col-md-9">{workOrder.rate_per_hour}</h6>
			        </div>
			        :
	         		<div className="row g-0 text-start mt-2">
			          <strong className="col-md-3">Total Fees : </strong>
		          	<h6 className="col-md-8">{workOrder.total_fees}</h6>
			        </div>
		        }	       
		        
		        <div className="row g-0 text-start mt-2">
		          <strong className="col-md-3">Currency : </strong>
		          <h6 className="col-md-9">{projectDetails.currency}</h6>
		        </div>	        
		      </div>
        </div>
      </div>
      <div className="card mt-4">
      	<div className="card-header d-flex align-items-center justify-content-between">
	      	<h4 className='m-0' ><b>Project Timeline</b></h4>		      
	      </div>
	      <div className="card-body text-start">
		      <div className="row mt-3 align-items-center">
		      	<div className="col-md-3 text-start">
			      	<h5 className="text-start"><b>Timeline</b></h5>
			    	</div>
			    	<div className="col-md-2 text-start">
			      	<h5 className="text-start"><b>Plan</b></h5>
			    	</div>
			    	<div className="col-md-2 text-start">
			      	<h5 className="text-start"><b>Actual</b></h5>
			    	</div>
			    	<div className="col-md-5 text-start">
			      	<h5 className="text-start"><b>Comment</b></h5>
			    	</div>
		    	</div>
		    	<div className="row mt-3 align-items-center">
						<div className="col-md-3 text-start">
							<h6>Date of Project Start : </h6>
						</div>
						<div className="col-md-2 text-start">
		          <h6>{projectDetails.start_date_dv}</h6>
						</div>
						<div className="col-md-2 text-start">
							<h6>{workOrder.actual_start_date}</h6>
						</div>
						
						<div className="col-md-5 text-start">
							<h6>{workOrder.comment_actual_start_date}</h6>
						</div>
					</div>
					<div className="row mt-3 align-items-center">
						
						<div className="col-md-3 text-start">
							<h6>Estimated project duration :</h6>
						</div>
						<div className="col-md-2 text-start">
		          <h6>{projectDetails.estimated_duration_display}</h6>
						</div>
						<div className="col-md-2 text-start">
							<h6>{workOrder.actual_duration}</h6>
						</div>
						
						<div className="col-md-5 text-start">
							<h6>{workOrder.comment_actual_duration}</h6>
						</div>
					</div>
				</div>
			</div>
			<div className="card mt-4">
      	<h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Milestones</b></h4>    	
	      <div className="card-body text-start">
	      	<div className="col-md-12 card-body text-start">	        	
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr>
						      <th scope="col">Sr.No</th>
						      <th scope="col">Milestone Name</th>
						      <th scope="col">Date</th>
						      <th scope="col">Actual Date</th>
						      <th scope="col">Comment</th>
						    </tr>
						  </thead>
						  <tbody>
					    	{Milestones}
						  </tbody>
						</table>
	        </div>		    	
	      </div>
      </div>
			{
				projectDetails.fees_type_dv == "Time-based" ?				
				<div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Invoicing Schedule</b></h4>
	        <div className="card-body text-start">
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr>
						      <th scope="col">Sr.No</th>
						      <th scope="col">Invoice</th>
						      <th scope="col">Date</th>
						      <th scope="col">Rate/Hour</th>
						      <th scope="col">Hours</th>
						      <th scope="col">Actual Amount</th>
						      <th scope="col">Client Approval</th>						      
						    </tr>
						  </thead>
						  <tbody>
						  {InvoiceMilestones}
						  </tbody>
						</table>
	        </div>
	      </div>
				
        :
        <div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Invoicing Schedule</b></h4>
	        <div className="card-body text-start">
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr>
						      <th scope="col">Sr.No</th>
						      <th scope="col">Invoice</th>
						      <th scope="col">Date</th>
						      <th scope="col">% of total</th>
						      <th scope="col">Plan Amount</th>
						      <th scope="col">Actual Amount</th>
						      <th scope="col">Client Approval</th>						     
						    </tr>
						  </thead>
						  <tbody>
						  {InvoiceMilestones}
						  </tbody>
						</table>
	        </div>
	      </div>					
			}

			<div className="card mt-3 pagebreak">				
				<div className="card-header d-flex align-items-center justify-content-between">
	      	<h4 className='m-0' ><b>Project Updates</b></h4>		     
	      </div>	        
				{workOrderUpdatesList}
      </div>
      
    	<div className="card mt-3 pagebreak">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Complete Projects</b></h4>
        <div className="p-3">
        	<div className="text-start">
            <strong htmlFor="message">Consultant Comment :</strong>
          </div>
          <div className="text-start">
      			<h6>{ReactHtmlParser(workOrder.completion_comment)}</h6>
    			</div>
    			<div className="text-start mt-3">
            <strong htmlFor="message">Client Comment :</strong>
          </div>
          <div className="text-start">
      			<h6>{ReactHtmlParser(workOrder.client_completion_comment)}</h6>
    			</div>			     
      	</div>      	
      </div>
      <Modal setModal={setWorkOrderModal} setShow={setShowModal} id="work_order_details" summary={true} title='Work Order Details' project_consultant={true} >
      	<WorkOrderView work_order_id={workOrder.id} workOrderPopup={true}/>
      </Modal>
	        
				  	
    </div>

  );
}

export default CompletedProjectsView;
