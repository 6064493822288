import React, { useContext, useRef, useState, useEffect, forwardRef } from 'react';
import { store } from '../../store'
import FloatingSelect from '../../components/FloatingSelect';
import DatePicker from "react-datepicker";
import CustomEditor from '../../components/CustomEditor'


import Modal from '../../components/Modal'

const EditJob = (props) => {
  const formEl = useRef();
  const globalState = useContext(store);
  const formId = `edit-job-form-${props.data.id}`

  const [countries, setCountries] = useState([])
  const [allServices, setAllServices] = useState([])
  const [country, setCountry] = useState(props.data.country)
  const [service, setService] = useState({ service: props.data.primary_service.id, sub_service: props.data.secondary_service?.id })
  const [startDate, setStartDate] = useState(new Date(props.data.start_date));
  const [endDate, setEndDate] = useState(new Date(props.data.end_date));
	const [value, setValue] = useState('');
	const [descriptionError, setDescriptionError] = useState('');


  const fetchData = async () => {
    const response = await fetch('/api/v1/services', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setAllServices(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }

    const countriesResponse = await fetch('/api/v1/countries', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const countries = await countriesResponse.json()
    if (countriesResponse.ok) {
      setCountries( countries )
      formEl.current.reset();
      setCountry(props.data.country)
      setService({ service: props.data.primary_service.id, sub_service: props.data.secondary_service?.id })
      resetErrors(formEl.current.elements);
      setStartDate(new Date(props.data.start_date));
      setEndDate(new Date(props.data.end_date));
      setValue(props.data.description)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, [props.show]);

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
    });
  }

  const handleFormSubmit = async (event) => {
    if (event) event.preventDefault();

    if(value.replace(/<\/?[^>]+(>|$)/g, "") != "")
    {
    	const formData = {
	      job_role: {
	        start_date: event.target.start_date.value,
	        end_date: event.target.end_date.value,
	        company: event.target.company.value,
	        city: event.target.city.value,
	        country: event.target.country.value,
	        title: event.target.title.value,
	        description: value,
	        primary_service_id: event.target.primary_service_id.value,
	        secondary_service_id: event.target.secondary_service_id.value,
	      }
	    }
	    const response = await fetch( '/api/v1/job_roles/' + props.data.id, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })
	    const jobRole = await response.json()
	    if (response.ok) {
	      props.setShow(false)
	      props.setFetch(true)
	      setDescriptionError('')
	    } else {
	      resetErrors( event.target.elements );
	      showErrors( event.target.elements, jobRole );
	    }
    }
    else
    {
    	setDescriptionError("can't be blank")
    }

  }

  const services = allServices.map( data => {
    return { value: data.id, text: data.name }
  });

  let sub_services = []
  if (service.service && allServices.length){
    let all_sub_services = allServices.filter( s => service.service == s.id )
    sub_services = all_sub_services[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

  function handleChange(newValue) {
    setValue(newValue);
  }

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text" defaultValue={value} onClick={onClick} ref={ref}
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  return (
    <Modal setModal={props.setModal} setShow={props.setShow} id={"job-modal" + props.data.id} title='Edit Job Role' formId={formId}>
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" defaultValue={props.data.title} name="title" id="title" placeholder="Job Title" required />
              <label htmlFor="title">Job Title</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md">
            <div className="form-floating">
              <input type="text" defaultValue={props.data.company} className="form-control" name="company" id="company" placeholder="title" required />
              <label htmlFor="company">Company Name</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            < DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              customInput={<CustomInput identify='start_date' label='From'/>}
            />
          </div>
          <div className="col-md">
            < DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              customInput={<CustomInput identify='end_date' label='To'/>}
            />
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="text" defaultValue={props.data.city} className="form-control" name="city" id="city" placeholder="title" required />
              <label htmlFor="city">City</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md">
            <FloatingSelect data={countries} name='country' required={true} onChange={e => setCountry( e.target.value )}
              label='Country' value={country} placeholder='Select Country' withError='true'/>
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            <FloatingSelect data={services} name='primary_service_id' required={true}
              onChange={e => setService( { service: e.target.value, sub_service: service.sub_service } )}
              label='Service Offering' value={service.service} placeholder='Select Service' withError='true'/>
          </div>
          <div className="col-md">
            <FloatingSelect data={sub_services} name='secondary_service_id' canHaveCategory={true}
              onChange={e => setService( { service: service.service, sub_service: e.target.value } )}
              label='Sub Service Offering' value={service.sub_service} placeholder='Select Sub Service' withError='true'/>
          </div>
        </div>

        {/*<div className="row g-2">
          <div className="form-floating">
            <textarea
              className="form-control"
              maxLength='500'
              name="description"
              id="description"
              placeholder="Role Description"
              defaultValue={props.data.description}
              required
            ></textarea>
            <label htmlFor="description">Role Description</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>*/}
        <div className="text-start">
				  {/* <textarea className="form-control" name="message" id="message" placeholder="Message" required /> */}
				  <CustomEditor id="project" placeholder={"Role Description...."} value={value} onChange={handleChange} />
				  {descriptionError != '' ?
		      	<div className="text-danger text-start"> Description can't be blank</div>
		      	:
		      	null
		      }
				</div>

      </form>
    </Modal>
  );
}

export default EditJob;
