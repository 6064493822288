import React, { useState, useEffect, useContext } from 'react';
import FloatingSelect from '../components/FloatingSelect'
import TextAreaAutoResize from '../components/TextAreaAutoResize'
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store'



const ConsultantDashboard = (props) => {

	const globalState = useContext(store);
	const [userData, setUserData] = useState( {} )
	const [user, setUser] = useState( {} )
	const [count, setCount] = useState('')


	const fetchData = async () => {
    const response = await fetch('/api/v1/consultant_profiles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })

    const responseJson = await response.json()
    console.log("responseJson")
    console.log(responseJson)
    if (response.ok) {    	
      setUserData(responseJson)
      setUser(responseJson.user)
    } else {
      alert.show("Server is down.")
      return false
    }

    const conversation_response = await fetch('/api/v1/conversations_unread_count', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const convResponseJson = await conversation_response.json()

    if (conversation_response.ok) {
      setCount(convResponseJson)    
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);


  return (
    <div>
      <div className="vh-100">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="tachometer-alt" /> My Dashboard</h2>
        </div>
        <div className="row text-center  mt-5">
			    <div className="col-md-1">
			    </div>
			    <div className="text-center col-md-10">
		    		<table className="table table-bordered  table-lg text-start">					    					  
						  <tbody>
					    	<tr className="d-flex">
						      <td className="col-md-4"><b>Name:</b></td>
						      <td className="col-md-8">{user.first_name} {user.last_name}</td>					      					      				      
						    </tr>
						    <tr className="d-flex">
						      <td className="col-md-4"><b>Last Logged In:</b></td>
						      <td className="col-md-8">{globalState.state.last_login}</td>					      					      				      
						    </tr>					    
						    <tr className="d-flex">
						      <td className="col-md-4"><b>Profile Status:</b></td>
						      <td className="col-md-8">{userData.completed ? 'Completed' : 'Incomplete'}</td>					      					      				      
						    </tr>						   
						    <tr className="d-flex">
						      <td className="col-md-4"><b>No of unread messages:</b></td>
						      <td className="col-md-8"><Link className="link" to={{pathname:"/email-message"}}  >{count}</Link></td>					      					      				      
						    </tr>
						  </tbody>
						</table>
		    	</div>
	    	</div>
    	</div>

    </div>
  );
}

export default ConsultantDashboard;
