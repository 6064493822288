import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store';
import { Link } from 'react-router-dom';

import SearchProfileCard from '../components/SearchProfileCard'

const SavedConsultants = (props) => {

  const globalState = useContext(store);
  const [ users, setUsers ] = useState([])
  const [ nameStarts, setNameStarts ] = useState()


  const fetchData = async (value) => {

    const response = await fetch( '/api/v1/saved_consultants', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get',
    })
    const profiles = await response.json()

    if (response.ok) {
      setUsers(profiles)
    } else {
    }
  }

  const handleFormSubmit=()=>
  {

  }


  useEffect( () => {
    fetchData();
  }, []);

  const usersData = users.map( u => {
    return (
      <SearchProfileCard
        key={u.id}
        name={u.display_name}
        image_url={u?.image_url}
        city={u.city}
        country={u.country_name}
        service={u.primary_service}
        service_exp={u.primary_service_exp}
        education={u.main_education}
        summary={u.summary}
        userData={u}
        savedConsultant={true}
        deleteFresh={fetchData}
      />
    )
  })

  const totalCount = () => {
    return `${users.length} consultant${ users.length > 1 ? 's' : '' } found`
  }
  return (
    <div className="">
      <form onSubmit={handleFormSubmit} className="row g-2 border-bottom">
        <div className="row g-2">
          <div className="col-md g-2 text-start">
            <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="users" /> Saved Consultants</h2>
          </div>
        </div>
        <div className="row g-2">
          <div className="text-center m-0 mb-2">
            <h5 className="fw-bolder">{totalCount()}</h5>
          </div>
        </div>
      </form>

      <div className="row d-flex align-items-center overflow-search-scorll search-with-name">
        {usersData}
      </div>

    </div>
  );
}

export default SavedConsultants;
