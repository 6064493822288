import React, { useState, useEffect, useContext, forwardRef, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";
import ViewJob from '../jobs/ViewJob';
import WorkOrderView from './WorkOrderView';
import FloatingSelect from '../../components/FloatingSelect';


const ProjectDeliveryPage = (props) => {

	const globalState = useContext(store);
	const formEl = useRef();
	const formId = `projectUpdate`
	const { state } = useLocation();
  const { dispatch } = globalState;
	const [ userDetails, setUserDetails ] = useState({})
  const [ milestones, setMilestones] = useState([{id:0,milestone:'',date: null}])
  const [ timeBasedMilestone, setTimeBasedMilestone] = useState([{id:0,description:'',date: null, rateperhour:'', currency:''}])
  const [ invoice_Milestones, setInvoice_Milestones] = useState([{id:0,milestone:'',date: null, percentage:'',amount:''}])
  const [projectDetails, setProjectDetails] = useState({})
  const [workOrder, setWorkOrder] = useState({})
  const [projectUpdates, setProjectUpdates] = useState([{ id: 0, date: null, postedBy: '', updateText: '', uplaodFile: null }])
  const [showModal, setShowModal] = useState( false );
	const [workOrderModal, setWorkOrderModal] = useState(null);
	const [showComplate, setShowComplate] = useState( false );
	const [completeWorkModal, setCompleteWork] = useState(null);
	const [completion_comment, setCompletionComment] = useState('');
	const [client_completion_comment, setClient_completion_comment] = useState('');
	const [invoiceModalId, setInvoiceModalId] = useState('');
	const [projectUpdate, setProjectUpdate] = useState('');
	const [showMilestoneUpdate, setShowInvoiceMilestone] = useState( false );
	const [invoiceModal, setInvoiceModal] = useState(null);
	const [showMilestoneEdit, setShowMilestoneEdit] = useState( false );
	const [milestoneModal, setMilestoneModal] = useState(null);
	const [actualDate, setActualDate] = useState(null);
	const [showTimeLine, setShowTimeLine] = useState(false);
	const [timeLineModal, setTimeLinesModal] = useState(null);
	const [actualAmount, setActualAmount] = useState(null);
	const [ milestoneData, setMilestoneData ] = useState({})
	const [ invoicemilestoneData, setInvoiceMilestoneData ] = useState({})
	const [ mileActualDateComment, setMileActualDateComment ] = useState({})
	const [ billableHours, setBillableHours ] = useState(null)
	const [ actualStartDate, setActualStartDate ] = useState(null)
	const [ actual_invoice_date, setActualInvoiceDate ] = useState(null)
	const [ actualStartDateComment, setActualStartDateComment ] = useState('')
	const [ actualDuration, setActualDuration ] = useState('')
	const [ actualDurationComment, setActualDurationComment] = useState('')
  const [ attachment, setAttachment ] = useState('')
  const [ work_order_updates, setWork_order_updates ] = useState([])
  const [ IncompleteMilestone, setIncompleteMilestone ] = useState(false)
  const [ errorMessage, setErrorMessage ] = useState(false)



	const invoicingfrequency = [
    { value: 'Weekly', text: 'Weekly' },
    { value: 'Fortnightly', text: 'Fortnightly' },
    { value: 'Monthly', text: 'Monthly' },

  ]

	let history = useHistory()

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	useEffect(() => {
    if (workOrderModal)
      showModal ? workOrderModal.show() : workOrderModal.hide()
  }, [showModal, workOrderModal])

  useEffect(() => {
    if (invoiceModal)
      showMilestoneUpdate ? invoiceModal.show() : invoiceModal.hide()
  }, [showMilestoneUpdate, invoiceModal])

  useEffect(() => {
    if (milestoneModal)
      showMilestoneEdit ? milestoneModal.show() : milestoneModal.hide()
  }, [showMilestoneEdit, milestoneModal])

  useEffect(() => {
    if (timeLineModal)
      showTimeLine ? timeLineModal.show() : timeLineModal.hide()
  }, [showTimeLine, timeLineModal])

  const fetchData = async () => {
    const response = await fetch('/api/v1/work_orders/' + my_data.work_order_id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

    console.log("responseJson")
    console.log(responseJson)

    if (response.ok) {
    	setIncompleteMilestone(false)
      setProjectDetails(responseJson.project_detail)
      setWorkOrder(responseJson)
      setUserDetails(responseJson.user)
      setMilestones(responseJson.work_order_milestones)
      setInvoice_Milestones(responseJson.work_order_invoice_schedules)
      setCompletionComment(responseJson.completion_comment)
      setWork_order_updates(responseJson.work_order_updates)
      setActualStartDate(responseJson.actual_start_date)
      setActualStartDateComment(responseJson.comment_actual_start_date)
      setActualDuration(responseJson.actual_duration)
      setActualDurationComment(responseJson.comment_actual_duration)

      responseJson.work_order_invoice_schedules.map((data,index)=>
	  	{
	  		if(data.status != '' && data.status != 'accepted')
	  		{
	  			setIncompleteMilestone(true);
	  		}
	  	})

    } else {
      alert.show("Server is down.")
      return false
    }
  }

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	}, [props.show]);

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify, disable }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} onKeyDown={(e)=>onkeyChangeFunction(identify)} ref={ref} disabled={disable} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const addMilestones = () => {
   	let count = milestones.length;
    let id = milestones[count-1].id + 1;
    setMilestones( [...milestones, {id: id,milestone:'',date: null} ]  )
  }

  const addInMilestones = () => {
  	if(projectDetails.fees_type_dv != "Time-based")
  	{
  		let count = invoice_Milestones.length;
	    let id = invoice_Milestones[count-1].id + 1;
	    setTimeBasedMilestone( [...invoice_Milestones, {id: id,milestone:'',date: null, rateperhour:'', currency:''} ]  )
  	}
  	else
  	{
  		let count = invoice_Milestones.length;
	    let id = invoice_Milestones[count-1].id + 1;
	    setInvoice_Milestones( [...invoice_Milestones, {id: id,milestone:'',date: null, percentage:'',amount:''} ]  )
  	}

  }

  const removeMilestones = (id) => {
    const new_milestones =  milestones.filter( data => data.id != id )
    setMilestones( new_milestones )
  }

  const removeInMilestones = (id) => {
    const new_milestones =  invoice_Milestones.filter( data => data.id != id )
    setInvoice_Milestones( new_milestones )
  }

  const removeTimeMilestones = (id) => {
    const new_milestones =  timeBasedMilestone.filter( data => data.id != id )
    setTimeBasedMilestone( new_milestones )
  }



  const handleMilestonePercentage = (event, id )=> {

    const newData = invoice_Milestones.map( data => {
      if (id == data.id){
      	var value = event.target.value
      	const amount = (value/100)*totalFees
        data.percentage = event.target.value
        data.amount = amount+" "+projectDetails.currency
      }
      return data

    });
    setMilestones( newData )
  }

  const Milestones = milestones.map((data,index)=>
  {
  	return(
			<tr className="d-flex" key={index}>
	      <td className="col-md-1">{index+1}</td>
	      <td className="col-md-3">{data.name}</td>
	      <td className="col-md-2">{data.milestone_date}</td>
	      <td className="col-md-2">{data.actual_milestone_date}</td>
	      <td className="col-md-3">{data.comment_actual_milestone_date}</td>
	      <td className="col-md-1"><button type="button" className="text-start btn btn-link p-0 mt-1" onClick={()=> {setActualDate(data.actual_milestone_date),setMilestoneData(data),setShowMilestoneEdit(!showMilestoneEdit)}}><h6>Edit</h6></button></td>
      </tr>

  	);
  })

  const TimeBasedMilestone = timeBasedMilestone.map((data,index)=>
  {
  	var milestone_date = null;

  	if(data.milestone_date != null)
  	{
  		milestone_date = new Date(data.milestone_date)
  	}

		return(
			<div key={index} className="col-md-12 text-start mt-3 mb-3">
				<div className="row">
					<div className="col-md-3 text-start">
						<h6>Description</h6>
					</div>
					<div className="col-md-2 text-start">
						<h6>Date</h6>
					</div>
					<div className="col-md-2 text-start">
						<h6>Rate/Hour</h6>
					</div>
					<div className="col-md-1 text-start">
						<h6>Hours</h6>
					</div>
					<div className="col-md-2 text-start">
						<h6>Actual Amount</h6>
					</div>
					<div className="col-md-2 text-start">
						<h6>Approval</h6>
					</div>
				</div>
			</div>
		);
  })

  const ProjectUpdates = projectUpdates.map((data,index)=>
  {
		return(
			<tr key={index}>
	      <th>{index+1}</th>
	      <td>{data.date}</td>
	      <td>{data.postedBy}</td>
	      <td>{data.updateText}</td>
	      <td><Link className="link">{data.uplaodFile}</Link></td>
      </tr>

  	);
  })

  const InvoiceMilestones = invoice_Milestones.map((data,index)=>
  {

  	if(globalState.state.user_type == "client")
  	{
  		return(
				<tr className="d-flex" key={index}>
		      <th className="col-md-1">{index+1}</th>
		      <td className="col-md-3">{data.name}</td>
		      <td className="col-md-2">{data.milestone_date}</td>
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td className="col-md-1">{data.billable_hours}</td>
		      	:
		      	<td className="col-md-1">{data.amount_percent}</td>
		      }
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td className="col-md-1">{workOrder.rate_per_hour} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</td>
		      	:
		      	<td className="col-md-1">{(Math.round(data.amount * 100) / 100).toFixed(2)} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</td>
		      }

		      <td className="col-md-1">{data.actual_amount != null ? ( projectDetails.currency == 'Other' ? data.actual_amount+" "+projectDetails.other_currency : data.actual_amount+" "+projectDetails.currency ) : ''}</td>
		      {data.status == 'review'  ?
		      	<td className="col-md-3">
		      		<button type="button" className="btn btn-danger btn-block ml-auto btn-sm " onClick={()=>handleInvoiceMilestoneUpdate(data,'rejected')}>Reject</button>
		      		<button type="button"className="btn btn-primary btn-block ml-auto btn-sm ms-2" onClick={()=>handleInvoiceMilestoneUpdate(data,'accepted')}>Accept</button>
	      		</td>
	      		:
	      		<td className="col-md-3">
	      			<h6 className={data.status_indicator}><b>{data.status != null ? data.status.charAt(0).toUpperCase() + data.status.slice(1) : ''}</b></h6>
      			</td>
      		}

	      </tr>
	  	);
  	}
  	else
  	{
			return(
				<tr className="d-flex" key={index}>
		      <th className="col-md-1">{index+1}</th>
		      <td className="col-md-3">{data.name}</td>
		      <td className="col-md-2">{data.milestone_date}</td>
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td className="col-md-1">{data.billable_hours}</td>
		      	:
		      	<td className="col-md-1">{data.amount_percent}</td>
		      }
		      {projectDetails.fees_type_dv == "Time-based" ?
		      	<td className="col-md-1">{workOrder.rate_per_hour} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</td>
		      	:
		      	<td className="col-md-1">{(Math.round(data.amount * 100) / 100).toFixed(2)} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</td>
		      }

		      <td className="col-md-1">{data.actual_amount != null ? ( projectDetails.currency == 'Other' ? data.actual_amount+" "+projectDetails.other_currency : data.actual_amount+" "+projectDetails.currency ) : ''}</td>

      		<td className="col-md-2">
      			<h6 className={data.status_indicator}><b>{data.status != null ? data.status.charAt(0).toUpperCase() + data.status.slice(1) : ''}</b></h6>
    			</td>
    			<td className="col-md-1">
    				{data.status != 'accepted' ?
    					<button type="button" className="text-start btn btn-link p-0 mt-1" onClick={()=> {setActualInvoiceDate(data.milestone_date) , setActualAmount(data.actual_amount), setInvoiceMilestoneData(data),setShowInvoiceMilestone(!showMilestoneUpdate)}}>
    						<h6>Edit</h6>
  						</button>
  						:
  						null
  					}
    			</td>
	      </tr>
	  	);
  	}
  })


	function handleChange(newValue) {
    setCompletionComment(newValue);
  }

  function handleClientChange(newValue) {
    setClient_completion_comment(newValue);
  }

  function handleProjectUpdate(newValue) {
    setProjectUpdate(newValue);
  }

  const handleMilestoneUpdate = async (data) => {
    const formData = {
			work_order_milestone:
			{
				actual_milestone_date: actualDate,
				comment_actual_milestone_date: mileActualDateComment
			}
    }

		const response = await fetch( '/api/v1/update_milestone/' + data.id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })

    const responseJson = await response.json()

    console.log(responseJson)

    if (response.ok) {
    	setMilestoneData({});
    	document.getElementById("milestoneForm").reset();
    	setShowMilestoneEdit(false);
    	fetchData();
    }
    else
    {

    }
  }

  console.log("actualAmount")
  console.log(actualAmount)

  const handleInvoiceMilestoneUpdate = async (data, status) => {

  	if(status == 'review')
  	{
  		const formData = {
				work_order_invoice_schedule:
				{
					milestone_date: actual_invoice_date,
					actual_amount: actualAmount,
					status: status,
					billable_hours: billableHours
				}
	    }

	    const response = await fetch( '/api/v1/update_invoice_schedule/' + data.id, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })

	    const responseJson = await response.json()

	    console.log(responseJson)

	    if (response.ok) {
	    	setInvoiceMilestoneData({});
	    	document.getElementById("invoiceMilestone").reset();
	    	setActualInvoiceDate(null)
	    	setBillableHours(null)
	    	setShowInvoiceMilestone(!setShowInvoiceMilestone);
	    	fetchData();
	    }
	    else
	    {

	    }
  	}
  	else
  	{
  		const formData = {
				work_order_invoice_schedule:
				{
					status: status,
				}
	    }

	    const response = await fetch( '/api/v1/update_invoice_schedule/' + data.id, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })

	    const responseJson = await response.json()

	    console.log(responseJson)

	    if (response.ok) {
	    	setInvoiceMilestoneData({});
	    	document.getElementById("invoiceMilestone").reset();

	    	setShowInvoiceMilestone(!setShowInvoiceMilestone);
	    	fetchData();
	    }
	    else
	    {

	    }
  	}

  }

  const handleWorkOrderUpdates = async () => {
    const formData = {
			work_order:
			{
				actual_start_date:actualStartDate,
				comment_actual_start_date:actualStartDateComment,
				actual_duration: actualDuration,
				comment_actual_duration: actualDurationComment
			}
    }

		const response = await fetch( '/api/v1/update_work_order/' + workOrder.id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })

    const responseJson = await response.json()

    console.log(responseJson)

    if (response.ok) {
    	setShowTimeLine(false);
    	fetchData();
    }
    else
    {

    }
  }

  const handleCompleteWork = async () => {
    const formData = {
			work_order:
			{
				completion_comment: completion_comment
			}
    }

		const response = await fetch( '/api/v1/complete_work_order/' + workOrder.id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })

    const responseJson = await response.json()


    if (response.ok) {
    	history.push("/receive-active-project-list");
    }
    else
    {

    }
  }

  const handleCompleteWorkConfirm = async () => {
    const formData = {
			work_order:
			{
				client_completion_comment: client_completion_comment
			}
    }

		const response = await fetch( '/api/v1/confirm_complete_work_order/' + workOrder.id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })

    const responseJson = await response.json()

    if (response.ok) {
    	history.push("/archived-project-list");
    }
    else
    {

    }
  }

  const handleProjectUpdates = async (event) => {
    if (event) event.preventDefault();

    const formData = {
      work_order_update: {
        update_details: projectUpdate,
        work_order_id: workOrder.id,
      }
    }

    if (event.target.attachment.files[0]){
      formData.work_order_update.attachment = attachment
      formData.work_order_update.attachment_name = `${event.target.attachment.files[0].name}`
    }

    if(projectUpdate.replace(/<\/?[^>]+(>|$)/g, "") == "")
    {
    	setErrorMessage(true);    	
    }
    else
    {
    	const response = await fetch( '/api/v1/create_work_order_update', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'post',
	      body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()


	    if (response.ok) {
	    	setProjectUpdate('')
	    	document.getElementById("attachment").value = "";
	    	setErrorMessage(false)
	      fetchData();
	    } else {

	    }
    }  	

  }

  const checkInvoiceMilestoneCompleted = async () => {
  	if(IncompleteMilestone)
  	{
  		showAlertInvoice();
  	}
  	else
  	{
  		if(globalState.state.user_type == 'client')
  		{
  			confirmAlert({
			  	customUI: ({ onClose }) => {
				    return (
				      <div className='p-5 custom-ui border border-primary rounded'>

				        <h5>Confirm Complete Project?</h5>
				        <div className="row g-2 mt-3">
					        <div className="col-md g-2 text-start">
					        	<button className="btn btn-danger" onClick={onClose}>No</button>
					        </div>
					        <div className="col-md g-2 text-end">
						        <button className="btn btn-primary"
						          onClick={() => {
						            handleCompleteWorkConfirm();
						            onClose();
						          }}
						        >
						          Yes
						        </button>
						      </div>
						    </div>
				      </div>
				    );
				  }
				});  			
  		}
  		else
  		{
  			confirmAlert({
			  	customUI: ({ onClose }) => {
				    return (
				      <div className='p-5 custom-ui border border-primary rounded'>

				        <h5>Confirm Complete Project?</h5>
				        <div className="row g-2 mt-3">
					        <div className="col-md g-2 text-start">
					        	<button className="btn btn-danger" onClick={onClose}>No</button>
					        </div>
					        <div className="col-md g-2 text-end">
						        <button className="btn btn-primary"
						          onClick={() => {
						            handleCompleteWork();
						            onClose();
						          }}
						        >
						          Yes
						        </button>
						      </div>
						    </div>
				      </div>
				    );
				  }
				});  			
  		}
  	}
  }

  const onkeyChangeFunction = async (identify) => {
  	if(identify == 'actaul_date')
  	{
  		setActualDate(null)
  	}
  	if(identify == 'actual_start_date')
  	{
  		setActualStartDate(null)
  	}
  }

  const showAlertInvoice = () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Please complete all invoices before completing project.</h5>
		        <div className="row g-2 mt-3">

			        <div className="col-md g-2 text-center">
				        <button className="btn btn-primary"
				          onClick={() => {
				            onClose();
				          }}
				        >
				          Ok
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

  const uploadAttachment = (e) => {
  	let message = "File size is too big max 10 MB" ;
		if( e.target.files[0].size > 1000000){
      e.target.classList.add("is-invalid");
      e.target.parentNode.querySelector('.invalid-feedback').innerText = message
      const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }
    else
    {
    	e.target.classList.remove("is-invalid");
    	const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }
  }

  const workOrderUpdatesList = work_order_updates.map(m=>
  {
  	return(
  		<div key={m.id} className="row g-2 text-start align-items-center mt-2 m-4 comment">
        <div className="col-md-8">
            {m.created_at} &#8226; Posted by {m.display_name}
          <div >
          	{ReactHtmlParser(m.update_details)}
          </div>
        </div>
        {m.attachment_url != '' ?
        	<div className="text-end">
  					<label className="text-primary"><b>{m.attachment_file_name}</b></label><a className="btn btn-primary ms-2" href={m.attachment_url} target="_blank" rel="noopener noreferrer" >Download Attachment</a>
  				</div>
  				:
  				null
  			}
      </div>
  	);
  })

  const handleTimeLineDateChange = async (date) => 
  {
  	let formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    setActualStartDate(formattedDate)
  }

  const handleMilestoneDateChange = async (date) => 
  {
  	let formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    setActualDate(formattedDate)
  }

  const handleInvoiceDateChange = async (date) => 
  {
  	let formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    setActualInvoiceDate(formattedDate)
  }

  const handleValueChange = async (value) => 
  {
  	setMileActualDateComment(value);
  }

  const handleStartValueChange = async (value) => 
  {
  	setActualStartDateComment(value);
  }
  

  const handleHoursChange = async (value) => 
  {
  	setBillableHours(value);
  	var actualAmount = value * workOrder.rate_per_hour
  	console.log("actualAmount")
  	setActualAmount(actualAmount)
  }

  return (
    <div className="container mb-5">
    	<div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="file-contract" /> Project Delivery</h2>
        </div>
    	</div>
	    <div className="row g-2 mt-2">
      	<div className="col-md-12">
      		<div className="row g-0 text-start">
	      		<h4 className="col-md-3"><b>Project Title: </b></h4>
	      		<h4 className="col-md-9  text-start"><b>{projectDetails.title} </b></h4>
	      	</div>
      		<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Company Name: </b></h5>
	      		<h5 className="col-md-9">{projectDetails.company_name}</h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Consultant Name: </b></h5>
	      		<h5 className="col-md-9">{userDetails.first_name} {userDetails.last_name}</h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Client Name: </b></h5>
            <h5 className="col-md-9">{projectDetails.creator}</h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-3"><b>Work Order Date: </b></h5>
            <h5 className="col-md-9">{workOrder.update_date} [ <button type="button" className="text-start btn btn-link p-0 mt-1" onClick={()=> setShowModal(!showModal)}><h6>Work Order Details</h6></button> ]</h5>
	      	</div>
      	</div>
    	</div>
    	<div className="card mt-4">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Scope</b></h4>
        <div className="card-body text-start">
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Objectives : </strong>
            <h6 className="col-md-9 ">{ReactHtmlParser(workOrder.project_objective)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project In Scope : </strong>
            <h6 className="col-md-8">{ReactHtmlParser(workOrder.project_in_scope)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Out Of Scope : </strong>
            <h6 className="col-md-9">{ReactHtmlParser(workOrder.project_out_scope)}</h6>
          </div>
        </div>
      </div>
      <div className="card mt-4">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees</b></h4>
        <div className="card-body text-start">
        	<div className="text-start">
		        <div className="row g-0 text-start mt-2">
		          <strong className="col-md-3">Type of project : </strong>
		          <h6 className="col-md-9 ">{projectDetails.fees_type_dv}</h6>
		        </div>
		        {
		        	projectDetails.fees_type_dv == 'Time-based' ?
		        	<div className="row g-0 text-start mt-2">
			          <strong className="col-md-3">Fee Rate/ hour : </strong>
		          	<h6 className="col-md-9">{workOrder.rate_per_hour} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</h6>
			        </div>
			        :
	         		<div className="row g-0 text-start mt-2">
			          <strong className="col-md-3">Total Fees : </strong>
		          	<h6 className="col-md-8">{workOrder.total_fees} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</h6>
			        </div>
		        }

		        <div className="row g-0 text-start mt-2">
		          <strong className="col-md-3">Currency : </strong>
		          <h6 className="col-md-9">{projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</h6>
		        </div>
		      </div>
        </div>
      </div>
      <div className="card mt-4">
      	<div className="card-header d-flex align-items-center justify-content-between">
	      	<h4 className='m-0' ><b>Project Timeline</b></h4>
		      <button type="button"
		          className="btn btn-primary"	onClick={()=>{setActualStartDate(workOrder.actual_start_date),setShowTimeLine(!showTimeLine)}}
	        >Edit</button>
	      </div>
	      <div className="card-body text-start">
		      <div className="row mt-3 align-items-center">
		      	<div className="col-md-3 text-start">
			      	<h5 className="text-start"><b>Timeline</b></h5>
			    	</div>
			    	<div className="col-md-2 text-start">
			      	<h5 className="text-start"><b>Plan</b></h5>
			    	</div>
			    	<div className="col-md-2 text-start">
			      	<h5 className="text-start"><b>Actual</b></h5>
			    	</div>
			    	<div className="col-md-5 text-start">
			      	<h5 className="text-start"><b>Comment</b></h5>
			    	</div>
		    	</div>
		    	<div className="row mt-3">
						<div className="col-md-3 text-start">
							<h6>Date of Project Start : </h6>
						</div>
						<div className="col-md-2 text-start">
		          <h6>{workOrder.proposed_start_date}</h6>
						</div>
						<div className="col-md-2 text-start">
							<h6>{workOrder.actual_start_date}</h6>
						</div>

						<div className="col-md-5 text-start">
							<h6>{workOrder.comment_actual_start_date}</h6>
						</div>
					</div>
					<div className="row mt-3">

						<div className="col-md-3 text-start">
							<h6>Estimated project duration :</h6>
						</div>
						<div className="col-md-2 text-start">
		          <h6>{workOrder.proposed_estimated_duration} {projectDetails.estimated_duration_category}</h6>
						</div>
						<div className="col-md-2 text-start">
							<h6>{workOrder.actual_duration} {workOrder.actual_duration != null ? projectDetails.estimated_duration_category : null}</h6>
						</div>

						<div className="col-md-5 text-start">
							<h6>{workOrder.comment_actual_duration} </h6>
						</div>
					</div>
				</div>
			</div>
			<div className="card mt-4">
      	<h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Milestones</b></h4>
	      <div className="card-body text-start">
	      	<div className="col-md card-body text-start">
	        	<table className="table table-bordered table-striped text-start">
						  <thead>
						    <tr className="d-flex">
						      <th className="col-1">Sr.No</th>
						      <th className="col-3">Milestone Name</th>
						      <th className="col-2">Date</th>
						      <th className="col-2">Actual Date</th>
						      <th className="col-3">Comment</th>
						      <th className="col-1"></th>
						    </tr>
						  </thead>
						  <tbody>
					    	{Milestones}
						  </tbody>
						</table>
	        </div>
	      </div>
      </div>
			{
				projectDetails.fees_type_dv == "Time-based" ?
				<div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Invoicing Schedule</b></h4>
	        <div className="card-body text-start">
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr className="d-flex">
						      <th className="col-md-1">Sr.No</th>
						      <th className="col-md-3">Invoice</th>
						      <th className="col-md-2">Date</th>
						      <th className="col-md-1">Hours</th>
						      <th className="col-md-1">Rate / Hour</th>						      
						      <th className="col-md-1">Actual Amount</th>
						      <th className={globalState.state.user_type != "client" ? "col-md-2" : "col-md-3"}>Client Approval</th>
						      {globalState.state.user_type != "client" ? <th className="col-md-1"></th> : null }
						    </tr>
						  </thead>
						  <tbody>
						  {InvoiceMilestones}
						  </tbody>
						</table>
						<h6 className="text-center">All invoices will be raised off-line and sent to the client by email.</h6>
	        </div>	        
	      </div>

        :
        <div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Invoicing Schedule</b></h4>
	        <div className="card-body text-start">
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr className="d-flex">
						      <th className="col-md-1">Sr.No</th>
						      <th className="col-md-3">Invoice</th>
						      <th className="col-md-2">Date</th>
						      <th className="col-md-1">% of total</th>
						      <th className="col-md-1">Plan Amount</th>
						      <th className="col-md-1">Actual Amount</th>
						      <th className={globalState.state.user_type != "client" ? "col-md-2" : "col-md-3"}>Client Approval</th>
						      {globalState.state.user_type != "client" ? <th className="col-md-1"></th> : null }
						    </tr>
						  </thead>
						  <tbody>
						  {InvoiceMilestones}
						  </tbody>
						</table>
						<h6 className="text-center">All invoices will be raised off-line and sent to the client by email.</h6>
	        </div>
	      </div>
			}

			<div className="card mt-3 pagebreak">
				<form id={formId} ref={formEl} onSubmit={handleProjectUpdates}>
					<div className="card-header d-flex align-items-center justify-content-between">
		      	<h4 className='m-0' ><b>Project Updates</b></h4>
		      </div>
		      <div className="text-start ms-4">Refresh page for latest updates</div>
	        <div className="col-md-12 p-4">
						<div className="text-start">
							<label htmlFor="message">Project Update Description</label>
						</div>
						<div className="text-start form-floating">
							<CustomEditor placeholder={"Type Description"} className="form-control" id="project_update_details" value={projectUpdate} onChange={handleProjectUpdate} />
							{errorMessage ? 
								<p className="text-danger text-start">Project update description can't be blank</p>
								:
								null
							}
						</div>
	          <div className="col-md-12 mt-1">
	            <div className="mb-3 text-start">
	              <label htmlFor="attachment" className="form-label">Attachment</label>
	              <input className="form-control" name="attachment" type="file" id="attachment" onChange={(e) => uploadAttachment(e)}  />
	              <div className="invalid-feedback"></div>
	            </div>
	          </div>
						<div className="col-md-12 text-end mt-2 ">
							<button  type="submit"
		              className="btn btn-primary font-weight-bold text-end"
		            >Save
		          </button>
	          </div>
					</div>
				</form>
				{workOrderUpdatesList}
      </div>
      {
      	(globalState.state.user_type != "client") ?
      	<div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Complete Project</b></h4>
	        <div className="p-4">
	        	<div className="text-start">
	            <label htmlFor="message">Consultant Completion Comment</label>
	          </div>
	      		<div className="text-start">
			      	<CustomEditor placeholder={"Completion comments"} className="form-control" type="text" id="completion_comments" value={completion_comment} onChange={handleChange} required/>
			      </div>
			      <div className="text-end mt-3">
			      	<button type="button" onClick={()=>checkInvoiceMilestoneCompleted()}  className="btn btn-primary btn-block ml-auto">Project Complete</button>
			      </div>
	      	</div>
	      </div>
	      :
	      <div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Complete Projects</b></h4>
	        <div className="p-4">
	        	{workOrder.completion_comment != null ?
	        		<div className="row col-md">
		        		<strong className="text-start col-md-3">Consultant Comment:</strong>
		        		<h6 className="text-start col-md-8">{ReactHtmlParser(workOrder.completion_comment)}</h6>
		        	</div>
		        	:
		        	null
		        }
	        	<div className="text-start mt-3">
	            <label htmlFor="message">Client Completion Comment </label>
	          </div>
	      		<div className="text-start">
			      	<CustomEditor placeholder={"Completion comments"} className="form-control" type="text" id="completion_comments" value={client_completion_comment} onChange={handleClientChange} required/>
			      </div>
	      	</div>
	      	<div className="text-end m-3">
		      	<button type="button" onClick={()=>checkInvoiceMilestoneCompleted()} disabled={workOrder.status != "work-done"}  className="btn btn-primary btn-block ml-auto">Accept Project Complete</button>
		      </div>
	      </div>

      }



      {/*<div className="row g-2 mt-3">
        <div className="col-md text-end">
          <button type="button" onClick={()=>setShowComplate(!showComplate)}  className="btn btn-primary btn-block ml-auto">Complete Project</button>
        </div>
      </div>*/}
      <Modal setModal={setWorkOrderModal} setShow={setShowModal} id="work_order_details" summary={true} title='Work Order Details' project_consultant={true} >
      	<WorkOrderView work_order_id={workOrder.id} workOrderPopup={true}/>
      </Modal>
      <Modal setModal={setInvoiceModal} setShow={setShowInvoiceMilestone} summary={true} title='Edit Invoice Schedule' project_consultant={true} >
      	<form id="invoiceMilestone" className="row justify-content-center mb-5 mt-3">      		
      		<div className="col-md-8 text-end">
						<div className="form-floating">
		          <input type="text" className="form-control" defaultValue={invoicemilestoneData.name}   name="milestone_name" id="milestone_name" placeholder="Milestone" disabled />
		          <label htmlFor="milestone_name">Invoice</label>
		        </div>
					</div>					
					<div className="col-md-8 text-center mt-3">
						<div>
							< DatePicker
		            selected={actual_invoice_date != null ? new Date(actual_invoice_date) : actual_invoice_date}
		            onChange={date => handleInvoiceDateChange(date)}
		            dateFormat="dd/MM/yyyy"
		            popperPlacement="bottom"
						    popperModifiers={{
						        flip: {
						            behavior: ["top"] // don't allow it to flip to be above
						        },
						        preventOverflow: {
						            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
						        },
						        hide: {
						            enabled: false // turn off since needs preventOverflow to be enabled
						        }
						    }}
		            customInput={<CustomInput disable={workOrder.actual_start_date == null} identify='actaul_date' label='Actual Date'/>}
		          />
						</div>
					</div>
	      	
	      	{
	      		projectDetails.fees_type_dv == "Time-based" ?

	      		<div className="col-md-8 text-center">
		      		<div className="col-md  mt-3">
								<div className="form-floating">
				          <input type="text" className="form-control" defaultValue={workOrder.rate_per_hour}   name="rateperhour" id="rateperhour" placeholder="Milestone" disabled />
				          <label htmlFor="milestone_name">Rate/Hour</label>
				        </div>
							</div>
							<div className="col-md  mt-3">
								<div className="form-floating">
				          <input type="Number" className="form-control" defaultValue={invoicemilestoneData.billable_hours} name="billable_hours" id="billable_hours" onChange={(e)=>handleHoursChange(e.target.value)} disabled={invoicemilestoneData.status == 'accepted'} required/>
				          <label htmlFor="billable_hours">Billable Hours</label>
				          <div className="invalid-feedback"></div>
				        </div>
							</div>
							<div className="col-md mt-3">
								<div className="form-floating">
			            <input type="Number"  className="form-control" defaultValue={actualAmount} name="actual_amount" id="actual_amount"  disabled />
			            <label htmlFor="actual_amount">Actual amount</label>
			            <div className="invalid-feedback"></div>
			          </div>
		          </div>
		      	</div>

	      		:
	      		<div className="col-md-8 text-center">
		      		<div className="col-md mt-3">
								<div className="form-floating">
				          <input type="text" className="form-control" defaultValue={invoicemilestoneData.amount_percent}   name="milestone_name" id="milestone_name" placeholder="Milestone" disabled />
				          <label htmlFor="milestone_name">Percentage</label>
				        </div>
							</div>
							<div className="col-md mt-3">
								<div className="form-floating">
				          <input type="text" className="form-control" defaultValue={invoicemilestoneData.amount}   name="milestone_name" id="milestone_name" placeholder="Milestone" disabled />
				          <label htmlFor="milestone_name">Plan Amount</label>
				        </div>
							</div>
							<div className="col-md mt-3">
								<div className="form-floating">
			            <input type="Number"  className="form-control" defaultValue={invoicemilestoneData.actual_amount} onChange={(e)=>setActualAmount(e.target.value)} disabled={invoicemilestoneData.status == 'accepted'} required />
			            <label htmlFor="title">Actual amount</label>
			            <div className="invalid-feedback"></div>
			          </div>
		          </div>
		      	</div>
	      	}


		      <div className="col-md-8 text-end mt-3">
		      	<button type="button" onClick={()=>handleInvoiceMilestoneUpdate(invoicemilestoneData, 'review')}   className="btn btn-primary btn-block ml-auto">Save</button>
		      </div>
      	</form>
      </Modal>
      <Modal setModal={setMilestoneModal} setShow={setShowMilestoneEdit} summary={true} title='Edit Milestones' project_consultant={true} >
      	<form id="milestoneForm" className="row justify-content-center mb-5">
      		{
      			workOrder.actual_start_date == null ?
      			<p className="text-danger text-center">Please set actual project start date from Project Timelines section.</p>
      			:
      			null
      		}
      		<div className="col-md-4 text-center mt-4">
						<div className="form-floating">
		          <input type="text" className="form-control" defaultValue={milestoneData.name}   name="milestone_name" id="milestone_name" placeholder="Milestone" disabled />
		          <label htmlFor="milestone_name">Milestone</label>
		        </div>
					</div>
	      	<div className="col-md-4 text-center mt-4">
						<div className="form-floating">
		          <input type="text" className="form-control" defaultValue={milestoneData.milestone_date}   name="milestone_name" id="milestone_name" placeholder="Milestone" disabled />
		          <label htmlFor="milestone_name">Milestone Date</label>
		        </div>
					</div>
	      	<div className="col-md-8 text-end mt-4">
	      		<div>
							< DatePicker
		            selected={actualDate != null ? new Date(actualDate) : actualDate}
		            onChange={date => handleMilestoneDateChange(date)}
		            dateFormat="dd/MM/yyyy"
		            minDate={new Date(workOrder.actual_start_date)}
		            popperPlacement="bottom"
						    popperModifiers={{
						        flip: {
						            behavior: ["top"] // don't allow it to flip to be above
						        },
						        preventOverflow: {
						            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
						        },
						        hide: {
						            enabled: false // turn off since needs preventOverflow to be enabled
						        }
						    }}
		            customInput={<CustomInput disable={workOrder.actual_start_date == null} identify='actaul_date' label='Actual Date'/>}
		          />
						</div>
	      	</div>
	      	{/*<div className="col-md-8 mt-4">
            <div className="form-floating">
              <input type="text"  className="form-control" defaultValue={milestoneData.comment_actual_milestone_date} onChange={(e)=> setMileActualDateComment(e.target.value)} name="actual_date_comment" id="actual_date_comment"  placeholder="Comment" required />
              <label htmlFor="estimated_duration">Comment</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>*/}
          <div className="col-md-8 mt-4">
         		<TextAreaAutoResize Id={"comment"} handleValueChange={handleValueChange} value={milestoneData.comment_actual_milestone_date} label_value={"Milestone Comment"} />
          </div>

       		<div className="col-md-8 text-end mt-4 mb-5">
		      	<button type="button" onClick={()=>handleMilestoneUpdate(milestoneData)}   className="btn btn-primary btn-block ml-auto">Save</button>
		      </div>
      	</form>
      </Modal>
      <Modal setModal={setTimeLinesModal} setShow={setShowTimeLine} summary={true} title='Timelines Update' project_consultant={true} >
      	<div className="p-4">
	      	<div className="row align-items-center mt-2">
	      		<div className="col-md-3 text-start">
							<h6>Date of Project Start : </h6>
						</div>
						<div className="col-md-3 text-start">
		          <h6>{workOrder.proposed_start_date}</h6>
						</div>
	      		<div className="col-md-6 text-center">
							< DatePicker
		            selected={actualStartDate != null ? new Date(actualStartDate) : actualStartDate}
		            onChange={date => handleTimeLineDateChange(date)}
		            dateFormat="dd/MM/yyyy"
		            minDate={new Date(workOrder.proposed_start_date) > new Date() ? new Date() : new Date(workOrder.proposed_start_date) }
		            popperPlacement="bottom"
						    popperModifiers={{
						        flip: {
						            behavior: ["bottom"] // don't allow it to flip to be above
						        },
						        preventOverflow: {
						            enabled: false // tell it not to try to stay within the view (this prevents the popper from covering the element you clicked)
						        },
						        hide: {
						            enabled: false // turn off since needs preventOverflow to be enabled
						        }
						    }}
		            customInput={<CustomInput identify='actual_start_date' label='Actual Start Date'/>}
		          />
						</div>	          
	      	</div>
	      	<div className="row align-items-center mt-2">
            <div className="col-md-3">
            </div>
            <div className="col-md-9">
	            <div className="form-floating">
	              <input type="text"  className="form-control" name="actual_start_date_comment" defaultValue={workOrder.comment_actual_start_date} onChange={(e)=>setActualStartDateComment(e.target.value)} id="actual_start_date_comment"  placeholder="Duration" required />
	              <label htmlFor="actual_start_date_comment">Actual Start Date Comment</label>
	              <div className="invalid-feedback"></div>
	            </div>
            </div>
          </div>
	      	<div className="row align-items-center mt-5">
	      		<div className="col-md-3 text-start">
							<h6>Estimated project duration :</h6>
						</div>
						<div className="col-md-3 text-start">
		          <h6>{projectDetails.estimated_duration_display}</h6>
						</div>
						<div className="col-md-6 text-start">
		      		<div className="form-floating">
		            <input type="Number"  className="form-control" defaultValue={workOrder.actual_duration} name="actual_duration" onChange={(e)=>setActualDuration(e.target.value)} id="actual_duration" placeholder="John" required />
		            <label htmlFor="title">Actual Duration</label>
		            <div className="invalid-feedback"></div>
		          </div>
	          </div>
						
	      	</div>
	      	<div className="row align-items-center mt-2">
            <div className="col-md-3">
            </div>
            <div className="col-md-9">
	            <div className="form-floating">
	              <input type="text"  className="form-control" name="actual_start_date_comment" defaultValue={workOrder.comment_actual_start_date} onChange={(e)=>setActualDurationComment(e.target.value)} id="actual_start_date_comment"  placeholder="Duration" required />
	              <label htmlFor="actual_start_date_comment">Actual Duration Comment</label>
	              <div className="invalid-feedback"></div>
	            </div>
            </div>
          </div>

       		<div className="text-end mt-4 mb-5">
		      	<button type="button" onClick={()=>handleWorkOrderUpdates()}   className="btn btn-primary btn-block ml-auto">Save</button>
		      </div>
      	</div>
      </Modal>

    </div>

  );
}

export default ProjectDeliveryPage;
