import React from 'react';
import ContactDetailStep from './steps/ContactDetailStep';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const AccountSetting = (props) => {

  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div className="container">
        <div className="row g-2">
          <div className="col-md g-2 text-start">
            <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="address-card" /> Account settings</h2>
          </div>
        </div>
        <ContactDetailStep showPassReset={true}/>
      </div>
    </div>
  )
}

export default AccountSetting;
