import React,{ useContext, useState, useEffect } from 'react';
import { store } from '../../store'
import ViewJob from './ViewJob';

const ListJobs = (props) => {

  const globalState = useContext(store);
  
  const [jobRoles, setJobRoles] = useState([]);

  const fetchData = async () => {
    const response = await fetch('/api/v1/job_roles', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setJobRoles(responseJson)
      props.setFetch( false )
      if (props.setRoleCount) { props.setRoleCount( responseJson.length ) }
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  if (props.fetch)
    fetchData();

  const listItems = jobRoles.map((data, index) =>{
    return <ViewJob display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} />
  });

  return (
    <div className="accordion step-form my-4 mx-1" id="accordionJobs">
      {listItems}
    </div>
  );
}

export default ListJobs;
