import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { store } from '../store.js';

function ClientSidebar() {

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const logoutHandler = () =>{
    dispatch({ type: 'logout', newState: null });
    return false
  }

  return (

    <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse overflow-auto vh-100">
      <div className="position-sticky pt-3">
        <ul className="nav flex-column mt-4">
          <li className="nav-item">
          	<NavLink activeClassName="nav-link active" className="nav-link" to={"/dashboard"}>
              <FontAwesomeIcon icon="tachometer-alt" />
              &nbsp;&nbsp;My Dashboard
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/search"}>
              <FontAwesomeIcon icon="address-book" />
              &nbsp;&nbsp;Search Consultants
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/search-with-name"}>
              <FontAwesomeIcon icon="address-book" />
              &nbsp;&nbsp;Consultants By Name
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/email-message"}>
              <FontAwesomeIcon icon="comments" />
              &nbsp;&nbsp;Messages
            </NavLink>
          </li>
          {/*<li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" >
              <FontAwesomeIcon icon="briefcase" />
              &nbsp;&nbsp;Projects
            </NavLink>
          </li>*/}
          <li className="nav-item">
            <a role="button" href="#"  className="nav-link btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#project-collapse" aria-expanded="false"><FontAwesomeIcon icon="business-time" />&nbsp;&nbsp;Project RFPs </a>
          	<div className="collapse" id="project-collapse">
						  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
						    {
			          	globalState.state.access_role != "accountant" ?
							    <li className="nav-item">
				            <NavLink activeClassName="nav-link active" className="nav-link" to={{pathname:"/new-project", state:{ copyProjectState: false }}}>
				              <FontAwesomeIcon icon="briefcase-medical" />
				              &nbsp;&nbsp;Post Project
				            </NavLink>
				          </li>
				          :
				          null
				        }

				        {
			          	globalState.state.access_role != "accountant" ?
							    <li className="nav-item">
							    	<NavLink activeClassName="nav-link active" className="nav-link" to={"/project-saved"}>
				              <FontAwesomeIcon icon="bookmark" />
				              &nbsp;&nbsp;Project RFPs WIP
				            </NavLink>
							    </li>
							    :
							    null
							  }

			          <li className="nav-item">
			            <NavLink activeClassName="nav-link active" className="nav-link" to={"/project-posted"}>
			              <FontAwesomeIcon icon="thumbs-up" />
			              &nbsp;&nbsp;Projects Posted
			            </NavLink>
			          </li>
						  </ul>
						</div>
          </li>
          <li className="nav-item">
            <a role="button" href="#"  className="nav-link btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#commission-collapse" aria-expanded="false"><FontAwesomeIcon icon="briefcase" />&nbsp;&nbsp;Projects </a>
          	<div className="collapse" id="commission-collapse">
						  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
						    
						    <li className="nav-item">
			            <NavLink activeClassName="nav-link active" className="nav-link" to={{pathname:"/work-order-list", state:{ copyProjectState: false }}}>
			              <FontAwesomeIcon icon="file-contract" />
			              &nbsp;&nbsp;Work Orders
			            </NavLink>
			          </li>
  					    <li className="nav-item">
						    	<NavLink activeClassName="nav-link active" className="nav-link" to={"/active-project-list"}>
			              <FontAwesomeIcon icon="list" />
			              &nbsp;&nbsp;Active Projects
			            </NavLink>
						    </li>
							    
			          <li className="nav-item">
			            <NavLink activeClassName="nav-link active" className="nav-link" to={"/archived-project-list"}>
			              <FontAwesomeIcon icon="box" />
			              &nbsp;&nbsp;Archived Projects
			            </NavLink>
			          </li>
						  </ul>
						</div>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/invoice-list"}>
              <FontAwesomeIcon icon="clipboard-list" />
              &nbsp;&nbsp;Invoices
            </NavLink>
          </li>
          {/*<li className="nav-item">
            <a className="nav-link disabled">
              <FontAwesomeIcon icon="clipboard-list" />
              &nbsp;&nbsp;Invoices
            </a>
          </li>*/}
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/saved-consultant"}>
              <FontAwesomeIcon icon="users" />
              &nbsp;&nbsp;Saved Consultants
            </NavLink>
          </li>
          <li className="nav-item">
            <a role="button" href="#"  className="nav-link btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#home-collapse" aria-expanded="false"><FontAwesomeIcon icon="user-tie" />&nbsp;&nbsp;Profile </a>
          	<div className="collapse" id="home-collapse">
						  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
						    <li className="nav-item">
						    	<NavLink activeClassName="nav-link active" className="nav-link" to={"/my-profile"}>
			              <FontAwesomeIcon icon="user-tie" />
			              &nbsp;&nbsp;My Profile
			            </NavLink>
						    </li>
						    {
			          	globalState.state.access_role == "admin" ?
				          <li className="nav-item">
				            <NavLink activeClassName="nav-link active" className="nav-link" to={"/company-profile-view"}>
				              <FontAwesomeIcon icon="building" />
				              &nbsp;&nbsp;Company Profile
				            </NavLink>
				          </li>
				          :
				          null
				        }
						    {
			          	globalState.state.access_role == "admin" ?
			          	<li className="nav-item">
				            <NavLink activeClassName="nav-link active" className="nav-link" to={"/manage-users"}>
				              <FontAwesomeIcon icon="users-cog" />
				              &nbsp;&nbsp;Manage Users
				            </NavLink>
				          </li>
				          :
				          null
			          }
						  </ul>
						</div>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/resources"}>
              <FontAwesomeIcon icon="book" />
              &nbsp;&nbsp;Resources
            </NavLink>
          </li>
          <li className="nav-item">
            <a className="nav-link" onClick={logoutHandler}>
              <FontAwesomeIcon icon="power-off" />
              &nbsp;&nbsp;Log out
            </a>
          </li>
        </ul>
      </div>
    </nav>

  );
}

export default ClientSidebar;
