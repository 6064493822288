import React, { useContext, useRef, useState, useEffect } from 'react';

const TabCards = (props) => {

	useEffect( () => {
    scrollToUp();
  }, []);


  function scrollToUp() {
		window.scrollTo(0, 0);
	}

  const headers = props.tabs.map((data, index) =>{
    return (
      <li className="nav-item" key={data.id}>
        <a className={ props.activeId == data.id ? "nav-link active" : "nav-link" } type="button"
           role="tab" onClick={() => props.setActiveId(data.id)} >
          {data.name}
        </a>
      </li>
    )
  });

  const contents = props.tabs.map((data, index) =>{
    const TagName = data.component
    if (props.activeId == data.id) {
			scrollToUp();
      return (
        <div
          className={props.activeId == data.id ? "tab-pane active" : "tab-pane" }
          key={data.id} role="tabpanel">

          <TagName {...data.props} />
        </div>
      )
    }
  });



  return (
 
    <div id="myDIV" >
      <div className="text-center mt-3">
        <h3>{props.heading}</h3>
        {
        	props.heading == "Create Project" || props.heading == "Edit Project" ?
        	<h6>All mandatory fields are marked with a red asterisk *</h6>
        	:
        	null
        }
      </div>
      <div className={"card step-form " + (props.projectStep  ? 'sidespace-remove' : '')} >
        <div className="card-header">
          <ul className="nav nav-tabs card-header-tabs nav-fill">
            {headers}
          </ul>
        </div>
        <div className="card-body text-start">
          <div className="tab-content">
            {contents}
          </div>
        </div>
      </div>
    </div>

  );
}

export default TabCards;
