import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../store'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FloatingSelect from '../../components/FloatingSelect';
import { Link, useHistory } from 'react-router-dom';



const ProjectFees = (props) => {

  const globalState = useContext(store);
  const { dispatch } = globalState;
  
  const [ rateRequired, setRateRequired ] = useState(true)
  const [ estimationRequired, setEstimationRequired ] = useState(true)
  const [ feesType, setFeesType ] = useState('')
  const [ currency_value, setCurrency_value ] = useState('')
  const [ otherCurrency, setOtherCurrency ] = useState('')
 
  let history = useHistory();

  const fetchData = async () => {
  	console.log(props.projectDetails)
  	if(!props.create_project)
 		{
	  	setFeesType(props.projectDetails.fees_type)
	  	setCurrency_value(props.projectDetails.currency)	  	
	  	setOtherCurrency(props.projectDetails.other_currency)
	  	if(props.projectDetails.is_rate_required != null){
	  		setRateRequired(props.projectDetails.is_rate_required)
	  	}
	  	if(props.projectDetails.is_estimation_required != null){
	  		setEstimationRequired(props.projectDetails.is_estimation_required)
	  	}
	  }
	  else if(props.create_project && globalState.state.project_id != undefined)
 		{
 			setFeesType(props.copyProjectDetails.fees_type)
	  	setCurrency_value(props.copyProjectDetails.currency)	  	
	  	if(props.projectDetails.is_rate_required != null){
	  		setRateRequired(props.copyProjectDetails.is_rate_required)
	  	}
	  	if(props.projectDetails.is_estimation_required != null){
	  		setEstimationRequired(props.copyProjectDetails.is_estimation_required)
	  	}
	  	setOtherCurrency(props.copyProjectDetails.other_currency)
 		}
  }

  useEffect( () => {
    fetchData();
  }, []);

  
  const fees_types = [
    { value: 'fixed-cost', text: 'Fixed cost' },
    { value: 'time-based', text: 'Time-based' },
    
  ]

  const currency = [
    { value: 'GBP', text: 'GBP' },
    { value: 'Euro', text: 'Euro' },
    { value: 'USD', text: 'USD' },
    { value: 'Other', text: 'Other' },
  ]
 

  const components = {
    DropdownIndicator: null,
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
      project_detail:
			{
				fees_type: feesType,
				currency: event.target.currency.value,
				other_currency: otherCurrency,
				is_rate_required: rateRequired,
				is_estimation_required: estimationRequired,
				
			}
    }

    const response = await fetch( '/api/v1/project_details/' + props.projectId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()
   
    if (response.ok) {
    	dispatch({ type: 'project',project_id: null});
      history.push({pathname:"/project-view", state:{ projectId:props.projectId}});
    } else {

    }
  }
  


  return (
    <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
			<div>
				<div className="form-group required">
					<label className="card-title control-label"><b>Project fees type</b></label>
				  <FloatingSelect value={feesType} data={fees_types} name={'fees_type' } onChange={e => setFeesType( e.target.value )}
				     placeholder={'Project fees type'}
				    label={'Select One'} required/>
				</div>
			</div>
			<div>
				<div className="form-group required">
					<label className="card-title control-label"><b>Currency</b></label>
				  <FloatingSelect value={currency_value} data={currency} name={'currency' } onChange={e => setCurrency_value( e.target.value )}
				     placeholder={'Currency'}
				    label={'Select One'} required/>
				</div>
			</div>
			{currency_value == 'Other' ?
				<div className="col-md">
          <div className="form-floating">
            <input className="form-control" defaultValue={otherCurrency} name="other_currency" id="other_currency" onChange={e => setOtherCurrency( e.target.value )} placeholder="City" required />
            <label htmlFor="other_currency">Other Currency</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>
        :
        null
			}
			
			<div className="d-flex mt-3">
			  <label className="card-title me-2" htmlFor="is_rate_required"><b>Rate/ hour required?</b></label>
			  <div className="form-check form-switch">
			  	<input className="form-check-input" type="checkbox" id="is_rate_required" onChange={()=>setRateRequired(!rateRequired)} checked={rateRequired}/>
			  </div>
			</div>
			<div className="d-flex mt-3">
			  <label className="card-title me-2" htmlFor="is_estimation_required"><b>Estimated total project hours required?</b></label>
			  <div className="form-check form-switch">
			  	<input className="form-check-input" type="checkbox" id="is_estimation_required" onChange={()=>setEstimationRequired(!estimationRequired)} checked={estimationRequired} />
			  </div>
			</div>
			{/*<div className="form-check form-switch mt-2">
			  <input className="form-check-input" type="checkbox" id="is_estimation_required" onChange={()=>setEstimationRequired(!estimationRequired)} checked={estimationRequired}/>
			  <label className="card-title" for="flexSwitchCheckChecked"><b>Estimated total project hours required?</b></label>
			</div>*/}
			
      <div className="row g-2">
        <div className="col-md text-end">
          <button type="submit"  className="btn btn-primary">Save</button>
        </div>
      </div>
    </form>
  );
}

export default ProjectFees;
