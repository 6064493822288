import React, { useState, useEffect ,useRef} from 'react';
import FloatingSelect from '../../components/FloatingSelect'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import { Link } from 'react-router-dom';

const ClientSignup = (props) => {
	const buttonRef = useRef();
  const [created, setCreated ] = useState(false);
  const [countryCodes, setCountryCodes] = useState([]);
  const [country, setCountry] = useState('')
  const [service, setService] = useState('')
  const [email, setEmail] = useState('')
  const [email_confirmation, setEmailConfirmation] = useState('')

  const fetchData = async () => {
    const response = await fetch('/api/v1/signup_data', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setCountryCodes( responseJson.country_codes )
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    Array.prototype.forEach.call(elements, function(el) {
      let message = errors.user[el.name]
      if( message ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = message
      }
    });
  }

  const handleBlur = async (event) =>{

  	if(email != email_confirmation )
  	{
  		let message = "doesn't match Email" ;
  		if( message ){
        event.target.classList.add("is-invalid");
        event.target.parentNode.querySelector('.invalid-feedback').innerText = message
      }
  	}
  	else
  	{
  		event.target.classList.remove("is-invalid");
  	}
  }

  const handleSignupSubmit = async (event) => {
		buttonRef.current.disabled = true;

    if (event) {
      event.preventDefault();
    }
    const formData = {
      user: {
        first_name: event.target.first_name.value,
        last_name: event.target.last_name.value,
        email: event.target.email.value,
        email_confirmation: event.target.email_confirmation.value,
        phone_number: event.target.phone_number.value,
        country: event.target.country.value,
        company_name: event.target.company_name.value,
        department: event.target.department.value,
        job_title: event.target.job_title.value,
        job_role_description: event.target.job_role_description.value,
        company_authorisation: event.target.company_authorisation.checked,
        terms_of_service: event.target.terms_of_service.checked,
      }
    }
    const response = await fetch( '/api/v1/client_signup', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const userData = await response.json()
    if (response.ok) {
      setCreated(true)
    } else {
    	buttonRef.current.disabled = false;
      resetErrors( event.target.elements );
      showErrors( event.target.elements, userData );
    }
  }

  function expandTextarea(id) {
  	var el = document.getElementById(id);
		if(el){
		  el.addEventListener('keyup', function() {
        this.style.overflow = 'hidden';
        this.style.height = 0;
        this.style.height = this.scrollHeight + 'px';
    	}, false);
		}
	}

	expandTextarea('summary');


  if ( created ) {
    return(
      <div className="auth-wrapper">
        <div className="auth-inner signup-success">
          <h3 className="fw-bold">
            Thank you for applying for a Client Account with Pharmalancers.
            Approval takes 2 - 3 working days and Login details will be sent to your email.
          </h3>
        </div>
      </div>
    );
  }



  return (
    <div className="auth-wrapper">
      <h4 className="mb-4"><b className="primary-text">Work Smart</b> &#8226; <b className="secondary-text">Work Efficient</b> &#8226; <b className="primary-text">Be Flexible</b></h4>
      <div className="auth-inner signup">
        <form onSubmit={handleSignupSubmit} className="row g-2">
          <h3>Apply for a free Company Account</h3>
          <div className="row g-2 m-0">
            <div className="col-md">
              <p className="fs-6 text-center">
                Already have an account <Link className="fw-bold" to={"/login"}>Log in</Link>
              </p>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="company_name" id="company_name" placeholder="Company Name" required />
                <label htmlFor="company_name">Company Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2 mb-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="department" id="department" placeholder="Department" />
                <label htmlFor="department">Department (Optional)</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>

          </div>
          <p className="text-start fw-bold">Account Administrator</p>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="first_name" id="first_name" placeholder="First Name" required />
                <label htmlFor="first_name">First Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="last_name" id="last_name" placeholder="Last Name" required />
                <label htmlFor="last_name">Last Name</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control" name="job_title" id="job_title" placeholder="Job Title"  required />
                <label htmlFor="job_title">Job Title</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="text" className="form-control"  name="job_role_description" id="job_role_description" placeholder="name@example.com"   required />
                <label htmlFor="job_role_description">Job Role Description</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" onChange={(e)=> setEmail(e.target.value)}   required />
                <label htmlFor="email">Email</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="email" className="form-control" name="email_confirmation" id="email_confirmation" placeholder="name@example.com" onChange={(e)=> setEmailConfirmation(e.target.value)} onBlur={handleBlur} required />
                <label htmlFor="email_confirmation">Confirm Email</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md-6">
              <FloatingSelect data={countryCodes} name='country' required={true} onChange={e => setCountry( e.target.value )}
                label='Country Code' value={country} placeholder='Select Code' withError='true'/>
            </div>
            <div className="col-md-6">
              <div className="form-floating">
                <input type="tel" className="form-control" name="phone_number" id="phone_number" placeholder="+123214124412" required />
                <label htmlFor="phone_number">Phone Number</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-check text-start">
                <input className="form-check-input" name="company_authorisation" type="checkbox" id="company_authorisation" required />
                <label className="form-check-label fw-bold" htmlFor="company_authorisation">
                  I confirm I am authorised to open this account on behalf of my Company
                </label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <div className="form-check text-start">
                <input className="form-check-input" name="terms_of_service" type="checkbox" id="terms_of_service" required />
                <label className="form-check-label fw-bold" htmlFor="terms_of_service">
                  I accept the <a href='https://www.pharmalancers.com/pharmalancers-members-terms-conditions' target='_blank'>Terms of Service</a> & <a href='https://www.pharmalancers.com/privacy-policy' target='_blank'>Privacy Policy</a> of the marketplace
                </label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <button type="submit" ref={buttonRef} className="btn btn-primary btn-block w-100">Apply for Client Account</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
}

export default ClientSignup;
