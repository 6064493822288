import React, { useContext, useEffect, useState } from 'react';
import FloatingSelect from '../../components/FloatingSelect';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import { store } from '../../store'
import  ResetPasswordModalView from '../authentication/ResetPasswordModalView'

const ContactDetailStep = (props) => {

  const globalState = useContext(store);
  const { dispatch } = globalState;
  let history = useHistory();

  const [ userData, setUserData ] = useState( {} )
  const [ completed, setCompleted ] = useState( false )
  const [showModal, setShowModal] = useState( false )
  const [modalChangePassword, setModalChangePassword] = useState(null);
  const [fetchEvent, setFetchEvent] = useState(false);

  const fetchData = async () => {

    const response = await fetch('/api/v1/users', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setUserData(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);


  useEffect(() => {
    if (modalChangePassword)
      showModal ? modalChangePassword.show() : modalChangePassword.hide()
  }, [modalChangePassword, showModal])

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    resetErrors( elements )
    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
    });
  }

  const handleFormSubmit = async (event) => {
    if (event)
      event.preventDefault();

    const formData = {
      user: {
        company_name: event.target.company_name.value,
        reporting_email: event.target.reporting_email.value,
        country: event.target.country.value,
        city: event.target.city.value,
        email: event.target.email.value,
        backup_email: event.target.backup_email.value,
        phone_number: event.target.phone_number.value,
      }
    }
    const response = await fetch( '/api/v1/users', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()

    if (response.ok) {
      if (props.setActiveId){
        setUserData(responseJson)
        setCompleted(true)
        dispatch({ type: 'completed',user_type: responseJson.user_type});
      } else {
        history.push('/view-consultant-profile');
      }
    } else {
      showErrors( event.target.elements, responseJson );
    }
  }

  const handleCountry = (e) => {
    const user = userData
    user.country = e.target.value
    setUserData( {...userData, country: e.target.value} )
  }

  function ReportingNotice(props) {
    if (props.email) {
      return <p className="fw-bold text-danger"> All Project related communication will be copied to : <b>{props.email}</b> </p>;
    }
    return <p className=""></p>;
  }

  if (completed)
    return <Redirect to="/view-consultant-profile" />

  return (
    <div>
      <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">
          <div className="col-md text-start">
            <p className="fw-bold">{userData.first_name} {userData.last_name}</p>
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md text-start">
            <ReportingNotice email={userData.reporting_email} />
          </div>
        </div>

        <div className="row g-2">
          <div className="form-floating">
            <input type="text" className="form-control" defaultValue={userData.company_name}
              name="company_name" id="company_name" placeholder="Company Name" required />
            <label htmlFor="company_name">Company Name</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>

        <div className="row g-2">
          <FloatingSelect data={userData.countries} name='country' onChange={e => handleCountry(e)}
            label='Country' value={userData.country} placeholder='Select Country' required={true} />
        </div>

        <div className="row g-2">
          <div className="form-floating">
            <input type="text" className="form-control" defaultValue={userData.city}
              name="city" id="city" placeholder="City" required />
            <label htmlFor="city">City</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>

        <div className="row g-2">
          <div className="form-floating">
            <input
              type="email" className="form-control" defaultValue={userData.email}
              name="email" id="email" placeholder="Email" required />
            <label htmlFor="email">Email</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>

        <div className="row g-2">
          <div className="form-floating">
            <input
              type="email" className="form-control" defaultValue={userData.backup_email}
              name="backup_email" id="backup_email" placeholder="backup_email" />
            <label htmlFor="email">Backup Email</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md-4">
            <FloatingSelect data={userData.country_codes} name='country_code' label='Country Code'
              value={userData.country} placeholder='Select country code' required={true}/>
          </div>
          <div className="col-md">
            <div className="form-floating">
              <input type="tel" defaultValue={userData.phone_number}
                className="form-control" name="phone_number" id="phone_number"
                placeholder="+123214124412" required />
              <label htmlFor="phone_number">Phone Number</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>

        <div className="row g-2">
        	<div className={`col-md g-2 text-start ${props.showPassReset ? '' : 'visually-hidden'}`}>
            <button onClick={ () => setShowModal( !showModal ) } type="button" className="btn btn-primary btn-block ml-auto" >Reset Password</button>
          </div>
          <div className="col-md g-2 text-end">
            <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
          </div>
        </div>
      </form>
      <ResetPasswordModalView show={showModal} setShow={setShowModal} setModal={setModalChangePassword} setFetch={setFetchEvent}/>
    </div>
  );
}

export default ContactDetailStep;
