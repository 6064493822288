import React, { useContext, useEffect, useState, useRef } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../components/Modal'
import CustomEditor from '../../components/CustomEditor'
import Select from 'react-select';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const ReplyMessage = (props	) => {

  const globalState = useContext(store);
  const formEl = useRef();
  const { state } = useLocation(); 

  if(state != undefined)
  {
  	localStorage.setItem('document',JSON.stringify(state));
  }  

	const my_data =  JSON.parse(localStorage.getItem('document'));

  const formId = 'reply-message'
  const [ messageBody, setMessageBody] = useState('')
  const [ messages, setMessages] = useState([])
  const [ senderDetails, setSenderDetails] = useState({})
  const [ error, setError ] = useState(false)    
  let history = useHistory();
	const [conversationId,setConversationId] = useState(my_data.conversation_id)
  const [ attachment, setAttachment ] = useState('')
  const [ messageAttachment, setMessageAttachment ] = useState('')
  const [value, setValue] = useState('checking value...');


 
  const fetchData = async () => {

    const response = await fetch('/api/v1//conversation/'+ conversationId +'/chat_messages', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

   	console.log(responseJson)
   	console.log("my_data.conversation")
   	console.log(my_data.conversation)

    if (response.ok) {    	
      setMessages(responseJson)
      if(my_data.conversation.conversation_with_admin && responseJson[0].admin_sender != null)
      {
      	setSenderDetails(responseJson[0].admin_sender)
      }
      else
      {
      	setSenderDetails(responseJson[0].sender)
      }      
      setMessageAttachment(responseJson[0].conversation.attachment_url)
    } else {
      alert.show("Server is down.")
      return false
    }

    if(responseJson[0].conversation.unread)
   	{
   	 	const formData ={
       	conversation_id:conversationId
       }
   
				const response_new = await fetch( '/api/v1/update_conversation', {
         headers: {
           'Content-Type': 'application/json',
           'Authorization': globalState.state.Authentication
         },
         method: 'post',
         body: JSON.stringify(formData)
       })
   
       const newResponse = await response_new.json()       
   	}
  }


  useEffect( () => {
    let isMounted = true;
		fetchData().then(() => {
      if(isMounted ){
      setValue("done!"); // no more error
      } 
    });
   	return () => {
    isMounted = false;
    };
  }, []);

  const handleFormSubmit = async (event) => {
    if (event) event.preventDefault();

    const formData = {
      chat_message: {
        conversation_id: conversationId,
        body: messageBody.replace(/\<br\>/g,''),
      }
    }

    if (event.target.attachment.files[0]){
      formData.chat_message.attachment = attachment
      formData.chat_message.attachment_name = `${event.target.attachment.files[0].name}`
    }
    if(messageBody.replace(/<\/?[^>]+(>|$)/g, "") != "" && (event.target.attachment.value == '' || event.target.attachment.files[0].size < 1000000))
    {
    	const response = await fetch( '/api/v1/chat_messages', {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': globalState.state.Authentication
        },
        method: 'post',
        body: JSON.stringify(formData)
      })
      const new_response = await response.json()
      if (response.ok) {
      	formEl.current.reset();
      	fetchData();
      	setMessageBody('')
      	setAttachment('') 
      	setError(false)     
      } else {
        resetErrors( event.target.elements );
        showErrors( event.target.elements, message );
      }
    }
    else
    {
    	setError(true);
    }

  }

  const uploadAttachment = (e) => {  	
  	let message = "File size is too big max 10 MB" ;
		if( e.target.files[0].size > 1000000){
      e.target.classList.add("is-invalid");
      e.target.parentNode.querySelector('.invalid-feedback').innerText = message
      const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }
    else
    {
    	e.target.classList.remove("is-invalid");
    	const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }    
  }


  function handleChange(newValue) {
    setMessageBody(newValue);
  }
  
  const OneTOnemessages_list = messages.map(m=>
  {
  	return(
  		<div key={m.id} className="row g-2 text-start align-items-center mt-3 ms-1 comment">        	
        <div className="col-md-10">	          
            {m.display_name} &#8226; {m.replied_on}
          <div >
          	{ReactHtmlParser(m.body)}
          </div>          
        </div>
        {m.attachment_url != '' ?
        	<div className="text-end">
  					<a className="btn btn-primary" href={m.attachment_url} target="_blank" rel="noopener noreferrer" >Download Attachment</a>
  				</div>
  				:
  				null
  			}		        
      </div>
  	);
  })

  return (
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">
        	<div className="card mt-4 ">
        		<div className="d-flex align-items-center">
		          <div className="col-md-11 text-start  m-2">
		            <h4 className="card-title">Subject : </h4>
		            <h5 className="">{my_data.conversation_subject}</h5>
		          </div>
		          <div className="col-md-1 text-start">
		        		<Link  type="submit"
			              className="btn btn-primary font-weight-bold text-end"
			              to={"/email-message"}
			            >Back
			          </Link>  		          
		          </div>
		        </div>	        
        	</div>
        	{ messageAttachment != '' ?
        		<div className="text-end mb-3">
							<a className="btn btn-primary" href={messageAttachment} target="_blank" rel="noopener noreferrer" >Download Attachment</a>
						</div>
						:
						null
        	}
        		
        	
		      <div className="card mb-3">
		      	<div className="card-body row g-2">	
		      	{
	      		 	senderDetails.user_type != 'no-reply'  ?
			      		<div className="col-md-12">
									<div className="text-start">
										<label htmlFor="message">Message</label>
									</div>
									<div className="text-start form-floating">
										<CustomEditor placeholder={"Type message here"} className="form-control" id="message" value={messageBody} onChange={handleChange} />
										<div className="invalid-feedback"></div>
									</div>
									{error == true && messageBody.replace(/<\/?[^>]+(>|$)/g, "") == "" ?
				          	<p className="text-danger text-start">Message can't be blank</p>
				          	:
				          	null
				          }
				          <div className="col-md-12 mt-1">
				            <div className="mb-3 text-start">
				              <label htmlFor="attachment" className="form-label">Select Attachment (Optional)</label>
				              <input className="form-control" name="attachment" type="file" id="attachment" onChange={(e) => uploadAttachment(e)}  />
				              <div className="invalid-feedback"></div>
				            </div>
				          </div>
									<div className="col-md-12 text-end mt-2 ">
										<button  type="submit"
					              className="btn btn-primary font-weight-bold text-end"
					            >Send Reply
					          </button>
			            </div>
								</div>
							:
								null

		      	}
		      		
							{OneTOnemessages_list}
		      	</div>	      	
						
		      </div>          
        </div>
				
      </form>
  );
}

export default ReplyMessage;
