import React, { useContext } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';

import { store } from './store.js';

import PublicLayout from './layouts/PublicLayout'
import PrivateLayout from './layouts/PrivateLayout'

import Page404 from './Page404';
import Login from './views/authentication/Login';
import Signup from './views/authentication/Signup';
import ClientSignup from './views/authentication/ClientSignup';
import ForgotPassword from './views/authentication/ForgotPassword';
import ResetPassword from './views/authentication/ResetPassword';
import PasswordUpdate from './views/authentication/PasswordUpdate';
import CompleteSignup from './views/CompleteSignup';
import Congratulations from './views/Congratulations';
import MessageBoard from './views/MessageBoard';
import ViewConsultantProfile from './views/ViewConsultantProfile';
import CompanyProfile from './views/CompanyProfile';
import CompanyPublicProfile from './views/CompanyPublicProfile';
import PublicProfile from './views/PublicProfile';
import SearchWithName from './views/SearchWithName';
import SavedConsultants from './views/SavedConsultants';
import AccountSetting from './views/AccountSetting';
import Search from './views/Search';
import EmailMessage from './views/EmailMessage';
import ReplyMessage from './views/onetoneMessage/ReplyMessage';
import CompanyProfileView from './views/profileSettings/CompanyProfileView';
import ClientPublicProfile from './views/profileSettings/ClientPublicProfile';
import NewProject from './views/postProject/NewProject';
import SearchForProject from './views/postProject/SearchForProject';
import SearchByName from './views/postProject/SearchByName';
import SendRequirements from './views/postProject/SendRequirements';
import ProjectDetailsView from './views/postProject/ProjectDetailsView';
import ProjectsPosted from './views/postProject/ProjectsPosted';
import ProjectsSaved from './views/postProject/ProjectsSaved';
import EditProject from './views/postProject/EditProject';
import ProjectInformation from './views/postProject/ProjectInformation';
import NewProposals from './views/projectProposals/NewProposals';
import EditProposals from './views/projectProposals/EditProposals';
import PropsalPreview from './views/projectProposals/PropsalPreview';
import ProposalWIP from './views/projectProposals/ProposalWIP';
import ProposalSubmitted from './views/projectProposals/ProposalSubmitted';
import ProjectRFP from './views/projectProposals/ProjectRFP';
import ProjectPreview from './views/postProject/ProjectPreview';
import WorkOrder from './views/commissionProject/WorkOrder';
import WorkOrderList from './views/commissionProject/WorkOrderList';
import ReceiveWorkOrderList from './views/consultantWorkOrder/ReceiveWorkOrderList';
import ReceiveActiveProjectList from './views/consultantWorkOrder/ReceiveActiveProjectList';
import CompletedProjectsView from './views/consultantWorkOrder/CompletedProjectsView';
import ReceiveArchivedProjectList from './views/consultantWorkOrder/ReceiveArchivedProjectList';
import WorkOrderView from './views/consultantWorkOrder/WorkOrderView';
import ProjectDeliveryPage from './views/consultantWorkOrder/ProjectDeliveryPage';
import ActiveProjectList from './views/commissionProject/ActiveProjectList';
import ArchivedProjectList from './views/commissionProject/ArchivedProjectList';
import WorkOrderPreview from './views/commissionProject/WorkOrderPreview';
import MyProfile from './views/profileSettings/MyProfile';
import SetupManageUsers from './views/SetupManageUsers';
import DataListing from './views/DataListing';
import Dashboard from './views/Dashboard';
import ConsultantDashboard from './views/ConsultantDashboard';
import ConsultantProfileForPrint from './components/ConsultantProfileForPrint';
import InvoiceList from './views/invoiceSchedule/InvoiceList';
import InvoiceView from './views/invoiceSchedule/InvoiceView';
import Resources from './views/Resources';

function Routes() {

  const globalState = useContext(store);
  const PrivateRoute = ({ children, layout: Layout, ...props }) => {
    if (!globalState.state.loggedIn) return <Redirect to="/login" />
    return (
      <Route {...props}>
        <Layout>
          {children}
        </Layout>
      </Route>
    );
  };

  const root = () => {
    if (globalState.state.user_type == "consultant" || globalState.state.user_type == "consultant_demo"){
        if (globalState.state.completed) {
          return(
            <PrivateRoute exact={true} path="/" layout={PrivateLayout}>
              <ViewConsultantProfile />
            </PrivateRoute>
          );
        } else {
          return(
            <PrivateRoute exact={true} path="/" layout={PublicLayout}>
              <CompleteSignup />
            </PrivateRoute>
          );
        }
    }
    if (globalState.state.user_type == "client"){
      return(
        <PrivateRoute exact={true} path="/" layout={PublicLayout}>
          <CompanyProfile />
        </PrivateRoute>
      );
    }
    
    return(
      <PrivateRoute exact={true} path="/" layout={PublicLayout}>
        <CompleteSignup />
      </PrivateRoute>
    );

  }

  return (
    <Switch>
      <Route exact={true} path="/login">
        <PublicLayout>
          <Login />
        </PublicLayout>
      </Route>
      <Route exact={true} path="/signup">
        <PublicLayout>
          <Signup />
        </PublicLayout>
      </Route>
      <Route exact={true} path="/client-signup">
        <PublicLayout>
          <ClientSignup />
        </PublicLayout>
      </Route>
      <Route exact={true} path="/company-profile">
        <PublicLayout>
          <CompanyProfile />
        </PublicLayout>
      </Route>
      <Route exact={true} path="/forgot-password">
        <PublicLayout>
          <ForgotPassword />
        </PublicLayout>
      </Route>
      <Route exact={true} path="/reset-password">
        <PublicLayout>
          <ResetPassword />
        </PublicLayout>
      </Route>

      {root()}
      <PrivateRoute exact={true} path="/complete-signup" layout={PublicLayout}>
        <CompleteSignup />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/congratulations" layout={PublicLayout}>
        <Congratulations />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/password-update" layout={PublicLayout}>
        <PasswordUpdate />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/message-board" layout={PrivateLayout}>
        <MessageBoard />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/view-consultant-profile" layout={PrivateLayout}>
        <ViewConsultantProfile />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/public-profile" layout={PrivateLayout}>
        <PublicProfile />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/search-with-name" layout={PrivateLayout}>
        <SearchWithName />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/account-setting" layout={PrivateLayout}>
        <AccountSetting />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/search" layout={PrivateLayout}>
        <Search />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/email-message" layout={PrivateLayout}>
        <EmailMessage />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/rply-message" layout={PrivateLayout}>
        <ReplyMessage />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/setupmanageusers" layout={PrivateLayout}>
        <SetupManageUsers />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/manage-users" layout={PrivateLayout}>
        <DataListing />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/dashboard" layout={PrivateLayout}>
        <Dashboard />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/consultant-dashboard" layout={PrivateLayout}>
        <ConsultantDashboard />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/company-profile-view" layout={PrivateLayout}>
        <CompanyProfileView />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/company-public-profile" layout={PrivateLayout}>
        <CompanyPublicProfile />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/client-public-profile" layout={PrivateLayout}>
        <ClientPublicProfile />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/my-profile" layout={PrivateLayout}>
        <MyProfile />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/saved-consultant" layout={PrivateLayout}>
        <SavedConsultants />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/new-project" layout={PrivateLayout}>
        <NewProject />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/search-consultant-project" layout={PrivateLayout}>
        <SearchForProject />
      </PrivateRoute> 
      <PrivateRoute exact={true} path="/search-by-name" layout={PrivateLayout}>
        <SearchByName />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/send-requirements" layout={PrivateLayout}>
        <SendRequirements />
      </PrivateRoute> 
      <PrivateRoute exact={true} path="/project-details-view" layout={PrivateLayout}>
        <ProjectDetailsView />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/project-saved" layout={PrivateLayout}>
        <ProjectsSaved />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/project-posted" layout={PrivateLayout}>
        <ProjectsPosted />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/project-view" layout={PrivateLayout}>
        <ProjectPreview />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/edit-project" layout={PrivateLayout}>
        <EditProject />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/project-info" layout={PrivateLayout}>
        <ProjectInformation />
      </PrivateRoute>  
      <PrivateRoute exact={true} path="/new-proposal" layout={PrivateLayout}>
        <NewProposals />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/edit-proposal" layout={PrivateLayout}>
        <EditProposals />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/project-rfp" layout={PrivateLayout}>
        <ProjectRFP />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/proposal-preview" layout={PrivateLayout}>
        <PropsalPreview />
      </PrivateRoute> 
      <PrivateRoute exact={true} path="/proposal-wip" layout={PrivateLayout}>
        <ProposalWIP />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/proposal-submited" layout={PrivateLayout}>
        <ProposalSubmitted />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/work-order" layout={PrivateLayout}>
        <WorkOrder />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/work-order-list" layout={PrivateLayout}>
        <WorkOrderList />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/active-project-list" layout={PrivateLayout}>
        <ActiveProjectList />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/archived-project-list" layout={PrivateLayout}>
        <ArchivedProjectList />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/work-order-preview" layout={PrivateLayout}>
        <WorkOrderPreview />
      </PrivateRoute>

      <PrivateRoute exact={true} path="/receive-work-order-list" layout={PrivateLayout}>
        <ReceiveWorkOrderList />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/receive-active-project-list" layout={PrivateLayout}>
        <ReceiveActiveProjectList />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/receive-archived-project-list" layout={PrivateLayout}>
        <ReceiveArchivedProjectList />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/work-order-view" layout={PrivateLayout}>
        <WorkOrderView />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/project-delivery-page" layout={PrivateLayout}>
        <ProjectDeliveryPage />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/complete-project-view" layout={PrivateLayout}>
        <CompletedProjectsView />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/consultant-profile-for-print" layout={PrivateLayout}>
        <ConsultantProfileForPrint />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/invoice-list" layout={PrivateLayout}>
        <InvoiceList />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/invoice-detail" layout={PrivateLayout}>
        <InvoiceView />
      </PrivateRoute>
      <PrivateRoute exact={true} path="/resources" layout={PrivateLayout}>
        <Resources />
      </PrivateRoute>

      <Route component={Page404} />
    </Switch>
  );
}

export default Routes;
