import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store'
import { Link } from 'react-router-dom';

import FloatingSelect from '../components/FloatingSelect'
import SearchProfileCard from '../components/SearchProfileCard'

const Search = (props) => {

  const globalState = useContext(store);
  const [ allServices, setAllServices ] = useState([])
  const [ service, setService ] = useState('')
  const [ subService, setSubService ] = useState('')
  const [ therapeutics, setTherapeutics ] = useState([])
  const [ therapeutic, setTherapeutic ] = useState('')
  const [ products, setProducts ] = useState([])
  const [ product, setProduct ] = useState('')
  const [ users, setUsers ] = useState([])
  const [ fetched, setFetched ] = useState(false)
  const [ loading, setLoading ] = useState(false)

  const fetchData = async () => {
    const response = await fetch('/api/v1/search', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

    if (response.ok) {
      setAllServices(responseJson.services)
      setTherapeutics(responseJson.therapeutics)
      setProducts(responseJson.products)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);


  const services = allServices.map( data => {
    return { value: data.id, text: data.name }
  });

  let SubServices = []
  if (service){
    let subServicesOptions = allServices.filter( s => service == s.id )
    SubServices = subServicesOptions[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const formData = {
      service: event.target.service.value,
      sub_service: event.target.sub_service.value,
      therapeutic: event.target.therapeutic.value,
      product: event.target.product.value,
    }
    setLoading( true )
    const response = await fetch( '/api/v1/search', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const profiles = await response.json()
    if (response.ok) {
      setUsers(profiles)
      setLoading( false )
      setFetched( true )
    } else {
      setLoading( false )
    }
  }

  const usersData = users.map( u => {
    return (
      <SearchProfileCard
        key={u.id}
        name={u.user.first_name + ' ' + u.user.last_name.substring(0, 1)}
        image_url={u.user?.image_url}
        city={u.user.city}
        country={u.user.country_name}
        service={u.primary_service}
        service_exp={u.primary_service_exp}
        education={u.main_education}
        summary={u.summary}
        userData={u}
      />
    )
  })

  const totalCount = () => {
    if (loading) return 'searching...';
    if (fetched) return `${users.length} consultant${users.length > 1  ? 's' : '' } found`
    return ''
  }

  return (
    <div className="">
      <form onSubmit={handleFormSubmit} className="row g-2 border-bottom">
        <div className="row g-2">
          <div className="col-md g-2 text-start">
            <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="address-book" /> Search Consultants </h2>
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            <FloatingSelect data={services} name='service'
              placeholder='All' label='Service Offering' required={false}
              onChange={(e) => setService( e.target.value )} />
          </div>
          <div className="col-md">
            <FloatingSelect data={SubServices} name='sub_service'
              placeholder='All' label='Sub-service Offering' required={false} canHaveCategory={true}
              onChange={(e) => setSubService( e.target.value )} />
          </div>
          <div className="col-md">
            <FloatingSelect data={therapeutics} name='therapeutic'
              placeholder='All' label='Therapeutic Specialisation' required={false}
              onChange={(e) => setTherapeutic( e.target.value )} />
          </div>
          <div className="col-md">
            <FloatingSelect data={products} name='product'
              placeholder='All' label='Product Specialisation' required={false}
              onChange={(e) => setProduct( e.target.value )} />
          </div>
        </div>

        <div className="row g-2">
          <div className="text-end">
            <button type="submit" className="btn btn-primary btn-block mb-2">Search</button>
          </div>
        </div>
        <div className="row g-2">
          <div className="text-end">
            <Link to={"/search-with-name"}><p><small>Click here to search by name</small></p></Link>
          </div>
        </div>
        <div className="row g-2">
          <div className="text-center m-0 mb-2">
            <h5 className="fw-bolder">{totalCount()}</h5>
          </div>
        </div>
      </form>
      <div className="row d-flex align-items-center overflow-search-scorll">
        {usersData}
      </div>

    </div>
  );
}

export default Search;
