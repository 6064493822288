import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../../store'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import Service from '../steps/Service';
import SubServices from '../steps/SubServices';
import CustomEditor from '../../components/CustomEditor'


const ProjectDetails = (props) => {

  const experiences = [
    { value: '4', text: '1-4' },
    { value: '9', text: '5-9' },
    { value: '20', text: '10-20' },
    { value: '21', text: '>20' },
  ]

  const formEl = useRef();
  const globalState = useContext(store);
  const [ allServices, setAllServices ] = useState([])
  const [ errors, setErrors ] = useState({})

  const [ primaryServiceOffering, setPrimaryServiceOffering ] = useState({ service_id: '', experience: '' })
  const [ primarySubServiceOffering, setPrimarySubServiceOfferings ] = useState([{ id: 1, service_id: '', experience: '' }])
  const [ secondaryServiceOffering, setSecondaryServiceOffering ] = useState({ service_id: '', experience: '' })
  const [ secondarySubServiceOffering, setSecondarySubServiceOfferings ] = useState([{ id: 1, service_id: '', experience: '' }])
  const [ selectedAllSubServices, setSelectedAllSubServices ]  = useState([])
  const [ projctTitle, setProjectTitle ]  = useState("")
  const [ projectDescription, setProjectDescription] = useState('')
  const [ descriptionError, setDescriptionError] = useState('')
  const formId = `project_create`

  const fetchData = async () => {

  	var existing_offerings = {}

  	const response = await fetch('/api/v1/services', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

    responseJson.map((service)=>
    {
    	if(existing_offerings.service_offering == service.name)
    	{
    		setPrimaryServiceOffering({service_id:service.id.toString() , experience: "" })
    	}

    })

    if (response.ok) {
      setAllServices(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }

  	if(!props.create_project)
  	{
  		const response_project = await fetch('/api/v1/project_details/' + props.edit_project_id, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })

		  const responseJson = await response_project.json()
		  if (response_project.ok) {
		    setProjectTitle(responseJson.title)
		    setProjectDescription(responseJson.description)
		    props.setProjectId(props.edit_project_id)
		    props.setProjectDetails(responseJson)
		  } else {
		    alert.show("Server is down.")
		    return false
		  }

		  if(responseJson.project_detail_services.length != 0)
		  {

		  	const service_offering_data =
				{
					project_detail_id: props.edit_project_id,
				}

				const service_offering_response = await fetch( '/api/v1/show_project_detail_services', {
				  headers: {
				    'Content-Type': 'application/json',
				    'Authorization': globalState.state.Authentication
				  },
				  method: 'post',
				  body: JSON.stringify(service_offering_data)
				})

				const service_responseJson = await service_offering_response.json()

				if(service_responseJson != {}){
					setPrimaryServiceOffering(service_responseJson.primary_offering.service)
					setPrimarySubServiceOfferings(service_responseJson.primary_offering.sub_services)
				}
		  }

  	}
  	else if(props.create_project && props.retriveDataProject)
  	{
  		if(props.projectDetails.id == undefined || ( props.projectDetails.id == props.copyProjectDetails.id))
  		{
  			const response_project = await fetch('/api/v1/project_details/' + globalState.state.project_id, {
			    headers: {
			      'Content-Type': 'application/json',
			      'Authorization': globalState.state.Authentication
			    },
			    method: 'get'
			  })

			  const responseJson = await response_project.json()

			  if (response_project.ok) {
			  	setProjectTitle(responseJson.title + " - copy ")
			  	setProjectDescription(responseJson.description)
			    props.setCopyProjectDetails(responseJson)
			    props.setProjectDetails(responseJson)
			  } else {
			    alert.show("Server is down.")
			    return false
			  }

			  if(responseJson.project_detail_services.length != 0)
			  {

			  	const service_offering_data =
					{
						project_detail_id: globalState.state.project_id,
					}

					const service_offering_response = await fetch( '/api/v1/show_project_detail_services', {
					  headers: {
					    'Content-Type': 'application/json',
					    'Authorization': globalState.state.Authentication
					  },
					  method: 'post',
					  body: JSON.stringify(service_offering_data)
					})

					const service_responseJson = await service_offering_response.json()


					if(service_responseJson != {}){
						setPrimaryServiceOffering(service_responseJson.primary_offering.service)
						setPrimarySubServiceOfferings(service_responseJson.primary_offering.sub_services)
					}
			  }
  		}
  		else
  		{
  			setProjectTitle(props.projectDetails.title)
		  	setProjectDescription(props.projectDetails.description)
		    props.setProjectId(props.projectDetails.id)

		  	const service_offering_data =
				{
					project_detail_id: props.projectDetails.id,
				}

				const service_offering_response = await fetch( '/api/v1/show_project_detail_services', {
				  headers: {
				    'Content-Type': 'application/json',
				    'Authorization': globalState.state.Authentication
				  },
				  method: 'post',
				  body: JSON.stringify(service_offering_data)
				})

				const service_responseJson = await service_offering_response.json()


				if(service_responseJson != {}){
					setPrimaryServiceOffering(service_responseJson.primary_offering.service)
					setPrimarySubServiceOfferings(service_responseJson.primary_offering.sub_services)
				}

		  	// else if(props.copyProjectDetails.project_detail_services.length != 0)
		  	// {
		  	// 	const service_offering_data =
					// {
					// 	project_detail_id: globalState.state.project_id,
					// }

					// const service_offering_response = await fetch( '/api/v1/show_project_detail_services', {
					//   headers: {
					//     'Content-Type': 'application/json',
					//     'Authorization': globalState.state.Authentication
					//   },
					//   method: 'post',
					//   body: JSON.stringify(service_offering_data)
					// })

					// const service_responseJson = await service_offering_response.json()


					// if(service_responseJson != {}){
					// 	setPrimaryServiceOffering(service_responseJson.primary_offering.service)
					// 	setPrimarySubServiceOfferings(service_responseJson.primary_offering.sub_services)
					// }
		  	// }
  		}

  	}
  	else
	  {
	  	if(props.projectDetails.description != undefined)
	  	{
	  		setProjectDescription(props.projectDetails.description)
	  	}
	  	setProjectTitle(props.projectDetails.title)

	  	if(props.projectDetails.project_detail_services != undefined )
		  {
		  	const service_offering_data =
				{
					project_detail_id: props.projectDetails.id,
				}

				const service_offering_response = await fetch( '/api/v1/show_project_detail_services', {
				  headers: {
				    'Content-Type': 'application/json',
				    'Authorization': globalState.state.Authentication
				  },
				  method: 'post',
				  body: JSON.stringify(service_offering_data)
				})

				const service_responseJson = await service_offering_response.json()


				if(service_responseJson != {}){
					setPrimaryServiceOffering(service_responseJson.primary_offering.service)
					setPrimarySubServiceOfferings(service_responseJson.primary_offering.sub_services)
				}
		  }

	  }

  }

  useEffect( () => {
    fetchData();
  }, []);

  const services = allServices.map( data => {
    return { value: data.id, text: data.name }
  });

  let primarySubServices = []
  if (primaryServiceOffering.service_id){
    let service = allServices.filter( s => primaryServiceOffering.service_id == s.id )
    if(service.length != 0)
    {
    	primarySubServices = service[0].secondary_services.map( data => {
	      return { value: data.id, text: data.name, category: data.category }
	    });
    }
  }

  let secondarySubServices = []
  if (secondaryServiceOffering.service_id){
    let service = allServices.filter( s => secondaryServiceOffering.service_id == s.id )
    secondarySubServices = service[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

  const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
			project_detail:
			{
				title: event.target.title.value,
				description: projectDescription,
			}
    }

    if(props.create_project && props.projectId == null)
    {
  		const response = await fetch( '/api/v1/project_details', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'post',
	      body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()

	    if (response.ok) {
	    	formEl.current.reset();
	      props.setProjectId(responseJson.id)
	      props.setProjectDetails(responseJson)
	      props.setActiveId(props.activeId + 1)

	      const service_offering_data =
				{
					project_detail_id: responseJson.id,
					primary_offering: {
					  service: primaryServiceOffering,
					  sub_services: primarySubServiceOffering
					}
				}

				const service_offering_response = await fetch( '/api/v1/project_detail_services', {
				  headers: {
				    'Content-Type': 'application/json',
				    'Authorization': globalState.state.Authentication
				  },
				  method: 'post',
				  body: JSON.stringify(service_offering_data)
				})

				const service_responseJson = await service_offering_response.json()

	    } else {
	      setDescriptionError(responseJson.description[0])
	    }


    }
    else
    {
    	const response = await fetch( '/api/v1/project_details/' +props.projectDetails.id, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })

	    const responseJson = await response.json()

	    if (response.ok) {
	    	formEl.current.reset();
	    	props.setProjectDetails(responseJson)
	      props.setProjectId(responseJson.id)
	      props.setActiveId(props.activeId + 1)

	      const service_offering_data =
				{
					project_detail_id: responseJson.id,
					primary_offering: {
					  service: primaryServiceOffering,
					  sub_services: primarySubServiceOffering
					}
				}

				const service_offering_response = await fetch( '/api/v1/project_detail_services', {
				  headers: {
				    'Content-Type': 'application/json',
				    'Authorization': globalState.state.Authentication
				  },
				  method: 'post',
				  body: JSON.stringify(service_offering_data)
				})

				const service_responseJson = await service_offering_response.json()

	    } else {
	      // setErrors(responseJson.errors)
	    }
    }

  }

  function handleChange(newValue) {
    setProjectDescription(newValue);
  }

  let errorsHtml = ''

  const updateSelectedServices =() => {
    let srvs = []
    srvs = srvs.concat(primarySubServiceOffering.map( item => parseInt(item.service_id) ))
    srvs = srvs.concat(secondarySubServiceOffering.map( item => parseInt(item.service_id) ))
    setSelectedAllSubServices(srvs)

  }

  return (
    <form id={formId} ref={formEl} onSubmit={handleFormSubmit}  className="row g-2 justify-content-center">
      <div className="row g-2 text-start align-items-center">
        <div className="col-md">
        	<div className="text-start form-group required">
            <label className="control-label" htmlFor="projectDescription">Project Title</label>
	        </div>
          <div className="form-floating">
            <input type="text" defaultValue={projctTitle} className="form-control" name="title" id="title" placeholder="John" required />
            <label htmlFor="title">Project title</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>
        <div className="col-md-12">
          <div className="text-start form-group required">
            <label className="control-label" htmlFor="projectDescription">Project Description (include objectives and scope)</label>
	        </div>
		      <div className="text-start">
		      	<CustomEditor placeholder={"Project Description"} className="form-control " type="text" id="projectDescription" value={projectDescription} onChange={handleChange} required/>
		      	{descriptionError != '' ?
			      	<div className="text-danger text-start"> Description {descriptionError}</div>
			      	:
			      	null
			      }
		      </div>
	      </div>

        <h4><b className="mt-2">Service Offering and Experience required</b></h4>
        <div className="text-start form-group required">
          <label className="control-label" htmlFor="projectDescription">Primary Service and Experience</label>
        </div>
        < Service services={services} experiences={experiences} value={ primaryServiceOffering } setValue={setPrimaryServiceOffering} type='Primary' isRequired={true}/>
	      {
			  	primarySubServiceOffering.length != 0 ?
			  	<p className="text-danger text-start mt-3">If no Primary Sub-service is required then click Remove.</p>
			  	:
			  	null
			  }
	      < SubServices services={ primarySubServices } page={"project"} add_button={true} updateSelectedServices={updateSelectedServices} experiences={experiences}
	        disabledvalues={selectedAllSubServices} value={primarySubServiceOffering} setValue={setPrimarySubServiceOfferings} type='Primary' />
			</div>
      <div className="row g-2">
        <div className="col-md text-end">
          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
        </div>
      </div>
    </form>
  );
}

export default ProjectDetails;
