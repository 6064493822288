import React from 'react';
import ReactDOM from 'react-dom';

import 'bootstrap';

import App from '../react/App';
import ErrorHandler from '../react/ErrorHandler';
import { BrowserRouter } from 'react-router-dom';
import { StateProvider } from '../react/store.js';

document.addEventListener("DOMContentLoaded", () => {
  let parentDiv = document.createElement("div");
  parentDiv.setAttribute("class", "parent-div cointainer");
  ReactDOM.render(
    <StateProvider>
      <BrowserRouter>
        <ErrorHandler>
          <App />
        </ErrorHandler>
      </BrowserRouter>
    </StateProvider>
    , document.body.appendChild( parentDiv )
  );
});
