import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'



const InvoiceList = (props) => {

	const globalState = useContext(store);
	const { dispatch } = globalState;
	let history = useHistory()


  const [ invoiceList, setInvoiceList] = useState([])

	const fetchData = async () => {

	  const response = await fetch('/api/v1/invoice_schedules_list', {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()
	  console.log("responseJson")
	  console.log(responseJson)

	  if (response.ok) {
	    setInvoiceList(responseJson)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	useEffect( () => {
	  fetchData();
	}, []);


	

	const invoice_list = invoiceList.map( invoice => {
		return (
    	<tr key={invoice.id}>
	      <td>{invoice.title}</td>
	      <td>{invoice.name}</td>
	      <td>{invoice.company.name}</td>
	      <td>{invoice.consultant_name.first_name} {invoice.consultant_name.last_name}</td>	      
	      <td>{invoice.total_fees_with_tax} {invoice.currency}</td>
	      <td><Link className="link" to={{pathname:"/invoice-detail", state:{ invoice_id: invoice.id }}}>View</Link></td>
	    </tr>
  	)

  })

  return (
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="list" /> Invoices</h2>
        </div>
    	</div>
    	<div className="col-md mt-4">
    		<table className="table table-bordered table-striped table-sm text-start">
				  <thead>
				    <tr>
				      <th scope="col">Project Title</th>
				      <th scope="col">Invoice Name</th>
				      <th scope="col">Company</th>
				      <th scope="col">Consultant Name</th>
				      <th scope="col">Total Fees with Tax</th>
				      <th scope="col"></th>

				    </tr>
				  </thead>
				  <tbody>
			    	{invoice_list}
				  </tbody>
				</table>

    	</div>
    </div>
  );
}

export default InvoiceList
;
