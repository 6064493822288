import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store'
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom';
import FloatingSelect from '../components/FloatingSelect'
import TextAreaAutoResize from '../components/TextAreaAutoResize'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import Modal from '../components/Modal';

const CompanyPublicProfile = (props) => {

	let history = useHistory();
  const [ created, setCreated ] = useState(false)
  const [countryCodes, setCountryCodes] = useState([])
  const [services, setServices] = useState([])
  const [country, setCountry] = useState('')
  const [turnover, setTurnover] = useState('')
  const [employee_count, setEmloyeeCount] = useState('')
  const [service, setService] = useState('')
  const [email, setEmail] = useState('')
  const [email_confirmation, setEmailConfirmation] = useState('')
  const [service_locations, setServiceLocations] = useState([{id:0,city:'',country:''}])
  const [ logo, setLogo ] = useState('')
  const [ company_data, setCompanyData ] = useState({})
  const globalState = useContext(store);



  const company_turnover = [
    { value: '< $10 M', text: '< $10 M' },
    { value: '$10 – 100 M', text: '$10 – 100 M' },
    { value: '$100 M – 1 B', text: '$100 M – 1 B' },
    { value: '> $1 B', text: '> $1 B' },
  ]

  const employees_number = [
    { value: '< 25', text: '< 25' },
    { value: '25 – 100', text: '25 – 100' },
    { value: '100 – 1000', text: '100 – 1000' },
    { value: '> 1000', text: '> 1000' },
  ]

  function scrollToUp() {
		window.scrollTo(0, 0);
	}

  const fetchData = async () => {
  	if(props.show)
  	{
  		const response_company = await fetch('/api/v1/company_public_profile/' + props.companyDataId, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'get'
	    })
	    const responseJson_company = await response_company.json()
	 		
	    if(response_company.ok)
	    {
	    	setCompanyData(responseJson_company)
	    	setTurnover(responseJson_company.turnover)
	    	setServiceLocations(responseJson_company.locations)
	    	setEmloyeeCount(responseJson_company.no_of_employees)
	    }

	    const response = await fetch('/api/v1/signup_data', {
	      headers: {
	        'Content-Type': 'application/json'
	      },
	      method: 'get'
	    })
	    const responseJson = await response.json()

	    if (response.ok) {
	      setCountryCodes( responseJson.country_codes )
	      const allServices = responseJson.services.map( data => {
	        return { value: data.name, text: data.name }
	      });
	      setServices( allServices )
	    } else {
	      alert.show("Server is down.")
	      return false
	    }
  	}    
  }


  useEffect( () => {
    fetchData();
    scrollToUp()
  }, [props.show]);


  const handleCompanyProfileSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const formData = {
      company: {
        website: event.target.website.value,
        turnover: event.target.turnover.value,
        no_of_employees: event.target.no_of_employees.value,
        profile: event.target.profile.value,
        department_name: event.target.department_name.value,
        locations_attributes: service_locations
      }
    }

    if (logo){
      formData.company.logo = logo
      formData.company.logo_name = `profile_pic.${event.target.logo.files[0].type.split('/')[1]}`
    }

    const response = await fetch( '/api/v1/companies', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const company_data = await response.json()
    
    if (response.ok) {
    	fetchData();
    } else {
      resetErrors( event.target.elements );
      showErrors( event.target.elements, userData );
    }
  }

	const locations_of_service = service_locations.map((data,index)=>
  {
  	return(  		
			<li key={data.id}>{data.city}, {data.country_name}</li>
       
  	)
  });

	const addLocation = () => {
   	let count = service_locations.length;
    let id = service_locations[count-1].id + 1;
    setServiceLocations( [...service_locations, {id:id, city:'',country:''} ]  )

  }

  const removeLocation = (id) => {
    const new_service_locations =  service_locations.filter( data => data.id != id )
    setServiceLocations( new_service_locations )
  }

  const uploadLogo = (e) => {
  	e.target.classList.remove("is-invalid");
    const file = e.target.files[0];
    const reader = new FileReader();
    reader.onloadend = () => setLogo( reader.result ) ;
    reader.readAsDataURL(file);
  }

  const handleCityChange = (event, id )=> {

    const newData = service_locations.map( data => {
      if (id == data.id)
        data.city = event.target.value
      return data
    });
    setServiceLocations( newData )
  }

  const handleCountryChange = (country, id )=> {

    const newData = service_locations.map( data => {
      if (id == data.id)
        data.country = country
      return data
    });
    setServiceLocations( newData )
  }

  return (   
  	<Modal setModal={props.setModal} setShow={props.setShow} id="profile" title='Company Profile' project_consultant={true} >
     	<div className="mb-4">
    		<div className="card mt-1">
          <div className="row g-0">
            <div className="col-md-3 mt-2">
              <img src={company_data.image_url} alt="BlankProfileImg" className="img-responsive" style={{width:"150px"}}/>
            </div>
            <div className="col-md-8 text-start ms-2">
              <div className="card-body">                
                <h3 className="card-title"><b>  {company_data.name} </b></h3>                
                <h5 className="card-title"><b>  {company_data.department_name} </b></h5>                
                <hr/>                
                <p className="card-text">  {ReactHtmlParser(company_data.profile)}</p>
              </div>
            </div>
          </div>
        </div>
        <div className="card mt-3">        	
          <h4 className="card-header d-flex align-items-center justify-content-between"><b>About the Company</b></h4>
          <div className="card-body text-start">
	          <div className="row g-0 text-start mt-1">
	            <strong className="col-md-3"> Company Website : </strong>
	            <a href={company_data.website} target="_blank" rel="noopener noreferrer" className="col-md-9"><h6>{company_data.website}</h6></a>              
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Company Turnover : </strong>
	            <h6 className="col-md-9">{company_data.turnover}</h6>          
	          </div>
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Number Of Employees : </strong>
	            <h6 className="col-md-9">{company_data.no_of_employees}</h6>             
	          </div>
	        </div>
        </div>        

          
          <div className="card mt-3">
            <div className="card-header d-flex align-items-center justify-content-between">
              <h4 className="text-start"><b>Company locations where Pharmalancers services will be required</b></h4>
            </div>
            <div className="card-body">
	            <ul className="text-start">
		         		{locations_of_service} 
		         	</ul>
		        </div>
          </div>        
      </div>
  	</Modal>
  );
}

export default CompanyPublicProfile;
