import React, { useContext, useEffect, useState } from 'react';
import { store } from '../../store'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';

const SpecialisationStep = (props) => {

  const globalState = useContext(store);
  const [ therapeutic, setTherapeutic ] = useState([])
  const [ allowTherapeuticOthers, setAllowTherapeuticOthers ] = useState(false)
  const [ therapeuticOthers, setTherapeuticOthers ] = useState([])
  const [ product, setProduct ] = useState([])
  const [languages, setLanguages ] = useState([])
  const [ allowProductOthers, setAllowProductOthers ] = useState(false)
  const [ productOthers, setProductOthers ] = useState([])
  const [ service, setService ] = useState([])
  const [ options, setOptions ] = useState({
    therapeutic: [],
    product: [],
    service: []
  })

  const fetchData = async () => {
    const response = await fetch('/api/v1/consultant_specialisations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()   
   
    if (response.ok) {
      setOptions(responseJson.options)
      setTherapeutic(responseJson.therapeutic)
      setLanguages(responseJson.languages)
      if (responseJson.therapeutic_others.length){
        setAllowTherapeuticOthers(true)
        setTherapeuticOthers(responseJson.therapeutic_others)
      }
      setProduct(responseJson.product)
      if (responseJson.product_others.length){
        setAllowProductOthers(true)
        setProductOthers(responseJson.product_others)
      }
      setService(responseJson.service)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const components = {
    DropdownIndicator: null,
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
    const therapeuticValues = therapeutic.map( data => data.value )
    const therapeuticOthersValues = therapeuticOthers.map( data => data.value )
    const productValues = product.map( data => data.value )
    const productOthersValues = productOthers.map( data => data.value )
    const serviceValues = service.map( data => data.value )
    const languageValue = languages.map(data=>data.value)
    const formData = {
      consultant_specialisations: {
        therapeutic: therapeuticValues,
        therapeutic_others: allowTherapeuticOthers ? therapeuticOthersValues : [],
        product: productValues,
        product_others: allowProductOthers ? productOthersValues : [],
        languages: languageValue,
        service: serviceValues,
        
      }
    }

    const response = await fetch( '/api/v1/consultant_specialisations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()
    if (response.ok) {
      props.setActiveId(props.activeId + 1)
    } else {

    }
  }

  const check_from_array =(value,array,key)=>
  {
  	var num = value.length
  	if(value.length == 0)
  	{
  		if(key == 'therapeutic')
  		{
  			setTherapeuticOthers([]);
  		}
  		else
  		{
  			setProductOthers([]);
  		}
  	}
  	else
  	{
  		var array_value = [];
	  	array.map((single)=>
	  	{
	  		array_value.push(single.label.toLowerCase()) ;
	  	});

			var lower_case_value = value[num-1].label.toLowerCase();

	  	if(array_value.includes(lower_case_value))
	  	{
	  		if(key == 'therapeutic')
	  		{
	  			alert('This specialisation already present in above dropdown,Add any specialisation not listed above')
	  		}
	  		else
	  		{
	  			alert('This product specialisations already present in above dropdown,Add any specialisation not listed above.')
	  		}
	  	}
	  	else
	  	{
	  		if(key == 'therapeutic')
	  		{
	  			setTherapeuticOthers(value);
	  		}
	  		else
	  		{
	  			setProductOthers(value);
	  		}
	  	}
  	}
  }


  const therapeuticOthersHTML = () => {
    if (allowTherapeuticOthers){
      return(
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <CreatableSelect
              components={components}
              value={therapeuticOthers}
              isClearable
              isMulti
              onChange={ newValue => check_from_array( newValue,options.therapeutic,"therapeutic")}
              id='therapeuticOthers'
              placeholder='Add any specialisation not listed above'
            />
          </div>
        </div>
      )
    }
    return ''
  }

  const productOthersHTML = () => {
    if (allowProductOthers){
      return(
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <CreatableSelect
              components={components}
              value={productOthers}
              isClearable
              isMulti
              onChange={ newValue => check_from_array( newValue, options.product, 'product')}
              id='productOthers'
              placeholder='Add any specialisation not listed above'
            />
          </div>
        </div>
      )
    }
    return ''
  }

  return (
    <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center">

      <div className="row g-3">
        <div className="col-md text-start">
          <div className="alert alert-info">
            Specialisations provide further details on your experience in 3 areas. These fields are optional.<br/>
            <ul>
              <li><b>Therapeutic Areas</b>: Select as many as you have experience in.</li>
              <li><b>Product Types</b>: Select as many as you have experience in.</li>
              <li><b>Specific to Service offering</b>: Add any specialisations specific to your Primary Service Offering which has not been provided so far.</li>
            </ul>
          </div>
        </div>
      </div>

      <fieldset>
        <legend>Therapeutic Areas</legend>
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <Select
              value={therapeutic}
              options={options.therapeutic}
              isClearable
              isMulti
              onChange={ newValue => setTherapeutic( newValue )}
              id='therapeutic'
            />
          </div>
          <div className="col-sm-3 d-flex align-items-center">
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" checked={allowTherapeuticOthers}
               onChange={() => setAllowTherapeuticOthers(!allowTherapeuticOthers)}
               id="therapeutic-others" />
              <label className="form-check-label" htmlFor="therapeutic-others">Add Others</label>
            </div>
          </div>
        </div>
        {therapeuticOthersHTML()}
      </fieldset>

      <fieldset>
        <legend>Product Specialisations</legend>
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <Select
              value={product}
              options={options.product}
              isClearable
              isMulti
              onChange={ newValue => setProduct( newValue )}
              id='product'
            />
          </div>
          <div className="col-sm-3 d-flex align-items-center">
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" checked={allowProductOthers}
               onChange={() => setAllowProductOthers(!allowProductOthers)}
               id="therapeutic-others" />
              <label className="form-check-label" htmlFor="therapeutic-others">Add Others</label>
            </div>
          </div>
        </div>
        {productOthersHTML()}
      </fieldset>

      <fieldset>
        <legend>Service Offering Specialisations</legend>
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <CreatableSelect
              components={components}
              value={service}
              isClearable
              isMulti
              onChange={ newValue => setService( newValue )}
              id='service'
              placeholder='Add any other specialisation specific to your Service offering'
            />
          </div>
        </div>
      </fieldset>

      <fieldset>
        <legend>Languages Known (in addition to English)</legend>
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-3 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <Select
              value={languages}
              options={options.languages}
              isClearable
              isMulti
              onChange={newValue => setLanguages(newValue)}
              id='language'
            />
          </div>
          {/* <div className="col-sm-3 d-flex align-items-center">
            <div className="form-check form-switch">
              <input className="form-check-input" type="checkbox" checked={allowProductOthers}
                onChange={() => setAllowProductOthers(!allowProductOthers)}
                id="therapeutic-others" />
              <label className="form-check-label" htmlFor="therapeutic-others">Add Others</label>
            </div>
          </div> */}
        </div>
      </fieldset>

      <div className="row g-2">
        <div className="col-md text-end">
          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
        </div>
      </div>
    </form>
  );
}

export default SpecialisationStep;
