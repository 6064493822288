import React, { useContext, useState } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';

import Errors from '../../components/Errors';
import { store } from '../../store';

const ResetPassword = (props) => {
	const [ created, setCreated ] = useState(false)
  const [ message, setMessage ] = useState('');
  const globalState = useContext(store);
  const { dispatch } = globalState;
  let history = useHistory();

  const location = useLocation();

  var url_token = window.location.href.split("=");

	
  const handleLoginSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }


    if(event.target.password.value != event.target.confirm_password.value)
    {
    	setMessage( 'Passwords did not match' )
    }
    else
    {
    	const data = {
	      user:
	      	{
	      		password: event.target.password.value,
  	      	password_confirmation: event.target.confirm_password.value,
  	      },
	      reset_password_token:url_token[1]
	    }

	   
	    const response = await fetch('/api/v1/update-password', {
	      headers: {
	        'Content-Type': 'application/json'
	      },
	      method: 'post',
	      body: JSON.stringify(data)
	    })
	    const userData = await response.json()
	    if (response.ok) {
	      setCreated(true)
	    } else {
	      if (userData.error)
	        setMessage( userData.errors )
	    }
    }
  }

  if ( created ) {
    return(
      <div className="auth-wrapper">
        <div className="auth-inner signup-success">
          <h3 className="fw-bold p-1">Your password has been changed Successfully. </h3>        
          <h3 className="fw-bold p-1">You can now sign in with your new password.</h3>          
        </div>
      </div>
    );
  }

  return (
    <div className="auth-wrapper">
      <h4 className="mb-4"><b className="primary-text">Work Smart</b> &#8226; <b className="secondary-text">Work Efficient</b> &#8226; <b className="primary-text">Be Flexible</b></h4>
      <div className="auth-inner">
        <form onSubmit={handleLoginSubmit} className="row g-2">
          <h3>Reset Password</h3>
          <div className="row g-2">
          <Errors message={message} />
            <div className="col-md">
              <div className="form-floating">
                <input type="password" className="form-control" name="password" id="password" placeholder="password" required />
                <label htmlFor="password">Password</label>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="password" className="form-control" name="confirm_password" id="confirm_password" placeholder="Confirm Password" required />
                <label htmlFor="password">Confirm Password</label>
              </div>
            </div>
          </div>
          <div className="row g-2">
            <div className="col-md">
              <button type="submit" className="btn btn-primary btn-block w-100">Submit</button>
            </div>
          </div>
        </form>
      </div>
    </div>
  );
}

export default ResetPassword;
