import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import SendMessage from './onetoneMessage/SendMessage';
import MessageList from './onetoneMessage/MessageList';
import moment from "moment";
require('moment-timezone');


const EmailMessage = (props) => {
  const globalState = useContext(store);
  const [showModalSendMessage, setShowModalSendMessage] = useState( false )
  const [showArchive, setShowArchive] = useState( false )
  const [modalSendMessage, setModalSendMessage] = useState(null)
  const formEl = useRef();
  const [messages, setMessages] = useState( [] )
  const [value, setValue] = useState('checking value...');
  const [timeZone, setTimeZone] = useState('checking value...');


	const fetchData = async () => {

		var tz = Intl.DateTimeFormat().resolvedOptions().timeZone
		
		setTimeZone(tz);

    const response = await fetch('/api/v1/conversations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()


    if (response.ok) {
      setMessages(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  const handleArchive = async (value,id) => {	

		if(value == 'archive')
		{
			const formData ={
	     	conversation_id:id,
	     	status:'archive'

	     }

	    const response = await fetch('/api/v1/archive_conversation', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	     	body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()

	    if (response.ok) {
	      fetchData();
	    } else {
	      alert.show("Server is down.")
	      return false
	    }
		}
		else
		{
			const formData ={
	     	conversation_id:id,
	     	status:'unarchive'

	     }

	    const response = await fetch('/api/v1/archive_conversation', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	     	body: JSON.stringify(formData)
	    })
	    const responseJson = await response.json()

	    if (response.ok) {
	      fetchData();
	    } else {
	      alert.show("Server is down.")
	      return false
	    }
		}
		
  }

  useEffect( () => {
    let isMounted = true;
		fetchData().then(() => {
      if(isMounted ){
      setValue("done!"); // no more error
      } 
    });
   	return () => {
    isMounted = false;
    };
  }, []);


  useEffect(() => {
    if (modalSendMessage)
      showModalSendMessage ? modalSendMessage.show() : modalSendMessage.hide()
      fetchData();		  
  }, [modalSendMessage, showModalSendMessage])

  const message_list = messages.map( m => { 

  	var date_show = moment.tz(m.last_replied_on, timeZone);

  	if(m.is_archive == null || m.is_archive == false)
  	{ 	
			return (
	      <MessageList key={m.id}
	      	conversation_id={m.id}
	        name={m.display_name}
	        message_subject={m.subject}
	        date={date_show.tz(timeZone).format('YYYY-MM-DD HH:mm')}
	        messages_count={m.messages_count}
	        unread={m.unread}
	        is_archive={m.is_archive}
	        showArchive={showArchive}
	        handleArchive={handleArchive}
	        timeZone={timeZone}
	        conversation={m}
	      />
	    )
		}
  	
    
  })

  const archive_message_list = messages.map( m => {

  	var date_show = moment.tz(m.last_replied_on, timeZone);

  	if(m.is_archive)
  	{
  		return (
	      <MessageList key={m.id}
	      	conversation_id={m.id}
	        name={m.display_name}
	        message_subject={m.subject}
	        date={date_show.tz(timeZone).format('YYYY-MM-DD HH:mm')}
	        messages_count={m.messages_count}
	        unread={m.unread}
	        is_archive={m.is_archive}
	        showArchive={showArchive}
	        handleArchive={handleArchive}
	        conversation={m}
	      />
	    )
  	}  	
    
  })

  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3">
      <div className="container">
          <div className="row g-2 align-items-center">
	          <div className="col-md-6 text-start">
	            <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="comments" /> Messages</h2>
	          </div>
	          {	showArchive?
	          	<div className="col-md-3 text-end">
	          		<button onClick={() => setShowArchive( !showArchive )} className="btn bg-transparent shadow-none text-primary fw-bold">
	          			<u>Show Messages</u>
          			</button>
		            
		          </div>
		          :
		          <div className="col-md-3 text-end">
		          	<button onClick={() => setShowArchive( !showArchive )} className="btn bg-transparent shadow-none text-primary fw-bold">
	          			<u>Show Archived Messages</u>
          			</button>		           
		          </div>

	          }
	          
	          <div className="col-md-3 text-end">
	            <button type="button"
	              className="btn btn-primary font-weight-bold action-button"
	              onClick={ () => setShowModalSendMessage( !showModalSendMessage ) }
	            >Write a New Message</button>
	          </div>
        	</div>

	        {showArchive?
	        	archive_message_list
	        	:
	        	message_list
	        }
	        <div className="row g-2 justify-content-start">
	          <SendMessage show={showModalSendMessage} setShow={setShowModalSendMessage} setModal={setModalSendMessage}/>
	        </div>
	      </div>
    </div>

  );
}

export default EmailMessage;
