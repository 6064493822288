import React, { useContext, useState, useEffect, forwardRef, useRef } from 'react';
import FloatingSelect from '../../components/FloatingSelect';
import DatePicker from "react-datepicker";
import CustomEditor from '../../components/CustomEditor'
import { confirmAlert } from 'react-confirm-alert';



import { store } from '../../store'

const AddJob = (props) => {

  const globalState = useContext(store);
	const formEl = useRef();
  const formId = 'add-job-form'

  const [countries, setCountries] = useState([])
  const [allServices, setAllServices] = useState([])
  const [country, setCountry] = useState('')
  const [service, setService] = useState({ service: '', sub_service: '' })
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [value, setValue] = useState('');
  const [descriptionError, setDescriptionError] = useState('');

  const fetchData = async () => {
    const response = await fetch('/api/v1/services', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setAllServices(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }

    const countriesResponse = await fetch('/api/v1/countries', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const countries = await countriesResponse.json()
    if (countriesResponse.ok) {
      setCountries( countries )
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    if(errors.description != undefined)
    {
	    setDescriptionError(errors.description[0]);
    }

    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
    });
  }

  const handleFormSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }

    if(value.replace(/<\/?[^>]+(>|$)/g, "") != "")
    {
    	const formData = {
	      job_role: {
	        start_date: event.target.start_date.value,
	        end_date: event.target.end_date.value,
	        company: event.target.company.value,
	        city: event.target.city.value,
	        country: event.target.country.value,
	        title: event.target.title.value,
	        description: value,
	        primary_service_id: event.target.primary_service_id.value,
	        secondary_service_id: event.target.secondary_service_id.value,
	      }
	    }
	    const response = await fetch( '/api/v1/job_roles', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'post',
	      body: JSON.stringify(formData)
	    })
	    const jobRole = await response.json()
	    if (response.ok) {
				event.target.reset();
				setStartDate(null)
				setEndDate(null)
				setCountry('')
				setService({ service: '', sub_service: '' })
				setValue('')
				setDescriptionError('')
	      props.setFetch(true)
	    } else {
	      resetErrors( event.target.elements );
	      showErrors( event.target.elements, jobRole );
	    }
    }
    else
    {
    	setDescriptionError("can't be blank ")
    }

  }

  const services = allServices.map( data => {
    return { value: data.id, text: data.name }
  });

  let sub_services = []
  if (service.service){
    let all_sub_services = allServices.filter( s => service.service == s.id )
    sub_services = all_sub_services[0].secondary_services.map( data => {
      return { value: data.id, text: data.name, category: data.category }
    });
  }

 //  const handleNextClick = async () => {
 //   	const check_next =
 //   		formEl.current.elements.title.value != '' ||
 //   		formEl.current.elements.start_date.value != '' ||
 //   		formEl.current.elements.end_date.value != '' ||
 //   		formEl.current.elements.company.value != '' ||
 //   		formEl.current.elements.city.value != '' ||
 //   		formEl.current.elements.primary_service_id.value != '' ||
 //   		formEl.current.elements.country.value != '' ||
 //   		formEl.current.elements.secondary_service_id.value != '' ||
 //   		value != '' ;

	// 	if(check_next)
	// 	{
	// 		submit()
	// 	}
	// 	else{
	// 		props.setActiveId(props.activeId + 1)
	// 	}

	// }


	// const submit = () => {
 //    confirmAlert({
	//   	customUI: ({ onClose }) => {
	// 	    return (
	// 	      <div className='p-5 custom-ui border border-primary rounded'>

	// 	        <h5>Do You Want to save entered data?</h5>
	// 	        <div className="row g-2 mt-3">
	// 		        <div className="col-md g-2 text-start">
	// 		        	<button className="btn btn-danger" onClick={()=> {onClose(),props.setActiveId(props.activeId + 1)}}>No</button>
	// 		        </div>
	// 		        <div className="col-md g-2 text-end">
	// 			        <button className="btn btn-primary"
	// 			          onClick={() => {
	// 			          	onClose();
	// 			          }}
	// 			        >
	// 			          Yes
	// 			        </button>
	// 			      </div>
	// 			    </div>
	// 	      </div>
	// 	    );
	// 	  }
	// 	});
 //  };


  const nextButton = () => {
    if (props.roleCount){
      return(
              <button type="button" className="btn btn-primary btn-block ms-3"
              onClick={() =>  props.setActiveId(props.activeId + 1)}>Next</button>
      );
    }
    return ''
  }

  function handleChange(newValue) {
    setValue(newValue);
  }

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text" defaultValue={value} onClick={onClick} ref={ref}
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  return (
      <form ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
         <div className="row g-2">
          <div className="col-md g-2">
            <h5 className="card-title">Add Job Roles</h5>
            <small className="lead fs-6 m-0">You can add multiple job roles for your profile.</small>
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" name="title" id="title" placeholder="Job Title" required />
              <label htmlFor="title">Job Title</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" name="company" id="company" placeholder="title" required />
              <label htmlFor="company">Company Name</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>

        </div>

        <div className="row g-2">
          <div className="col-md">
            <DatePicker
              selected={startDate}
              onChange={date => setStartDate(date)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              autoComplete='nope'
              customInput={<CustomInput identify='start_date' label='From'/>}
            />
          </div>
          <div className="col-md">
            < DatePicker
              selected={endDate}
              onChange={date => setEndDate(date)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              autoComplete='nope'
              customInput={<CustomInput identify='end_date' label='To'/>}
            />
          </div>
        </div>

        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" name="city" id="city" placeholder="title" required />
              <label htmlFor="city">City</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
           <div className="col-md">
            <FloatingSelect data={countries} name='country' required={true} onChange={e => setCountry( e.target.value )}
              label='Country' value={country} placeholder='Select Country' withError='true'/>
          </div>
        </div>
        <div className="row g-2">
          <div className="col-md">
            <FloatingSelect data={services} name='primary_service_id' required={true}
              onChange={e => setService( { service: e.target.value, sub_service: service.sub_service } )}
              label='Service Offering' value={service.service} placeholder='Select Service' withError='true'/>
          </div>
          <div className="col-md">
            <FloatingSelect data={sub_services} name='secondary_service_id'
              onChange={e => setService( { service: service.service, sub_service: e.target.value } )} canHaveCategory={true}              
              label='Sub Service Offering' value={service.sub_service} placeholder='Select Sub Service' withError='true'/>
          </div>
        </div>

        {/*<div className="row g-2">
          <div className="form-floating">
            <textarea
              className="form-control"
              maxLength='500'
              name="description"
              id="description"
              placeholder="Role Description"
              required
            ></textarea>
            <label htmlFor="description">Role Description</label>
            <div className="invalid-feedback"></div>
          </div>
        </div>*/}

        <div className="text-start">
		      {/* <textarea className="form-control" name="message" id="message" placeholder="Message" required /> */}
		      <CustomEditor id="project" placeholder={"Role Description...."} value={value} onChange={handleChange} />
		      {descriptionError != '' ?
		      	<div className="text-danger text-start"> Description {descriptionError}</div>
		      	:
		      	null
		      }
		    </div>

        <div className="row g-2">
          <div className="col-md g-2 text-end">
            <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
            {nextButton()}
          </div>
        </div>

      </form>
  );
}

export default AddJob;
