import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../components/Modal'
import CustomEditor from '../../components/CustomEditor'
import Select from 'react-select';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';



const SendMessage = (props) => {

  const globalState = useContext(store);
  const formEl = useRef();
  const formId = 'send-message'
  const [ messageBody, setMessageBody] = useState('')
  const [ users, setUsers] = useState([])
  const [ selectedUser, setSelectedUser] = useState([])
  const [ attachment, setAttachment ] = useState('')
  const [ subject, setSubject ] = useState('')
  const [ error, setError ] = useState(false)
  const [ subjectError, setSubjectError ] = useState(false)
 


  const fetchData = async () => {

    const response = await fetch('/api/v1/all_users', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()

    if (response.ok) {

      setUsers(responseJson)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  var messageText = messageBody.replace(/<\/?[^>]+(>|$)/g, "");

  useEffect( () => {
    fetchData();
  }, []);

  const handleFormSubmit = async (event) => {
    if (event) event.preventDefault();  

    if(messageText != "" && selectedUser.length != 0 && event.target.title.value != '')
    {
    	const formData = {
	      conversation: {
	        participant_id: selectedUser.value,
	        subject: event.target.title.value,
	        chat_message_attributes:[
	          { body: messageBody.replace(/\<br\>/g,'') },
	        ]
	      }
	    }

	    if (event.target.attachment.files[0]){
	      formData.conversation.attachment = attachment
	      formData.conversation.attachment_name = `${event.target.attachment.files[0].name}`
	    }

	    if(event.target.attachment.value == '' || event.target.attachment.files[0].size < 1000000)
	    {
	    	const response = await fetch( '/api/v1/conversation', {
	        headers: {
	          'Content-Type': 'application/json',
	          'Authorization': globalState.state.Authentication
	        },
	        method: 'post',
	        body: JSON.stringify(formData)
	      })
	      const conversation = await response.json()
	      if (response.ok) {    	
	        props.setShow(false);
	        setSelectedUser([])
	        setMessageBody('')
	        formEl.current.reset();
	      } else {        
	        showErrors( event.target.elements, message );
	      }
	    }    	
    }
    else
    {
    	setError(true)
    	if(event.target.title.value)
    	{
    		setSubjectError(true)
    	}    	
    }
  }

  const uploadAttachment = (e) => {  	
  	let message = "File size is too big max 10 MB" ;
		if( e.target.files[0].size > 1000000){
      e.target.classList.add("is-invalid");
      e.target.parentNode.querySelector('.invalid-feedback').innerText = message
      const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }
    else
    {
    	e.target.classList.remove("is-invalid");
    	const file = e.target.files[0];
	    const reader = new FileReader();
	    reader.onloadend = () => setAttachment( reader.result ) ;
	    reader.readAsDataURL(file);
    }    
  }

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    resetErrors( elements )
    Array.prototype.forEach.call(elements, function(el) {
      if( errors[el.name] ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = errors[el.name]
      }
    });
  }

  function handleChange(newValue) {
    setMessageBody(newValue);
  } 

  function resetForm() {
    document.getElementById(formId).reset();
  }

  useEffect( () => {
    resetForm();
    setSelectedUser('')
    setMessageBody('') 
    setError(false)   
  }, [props.show]);


  const onSelectValue = async(newValue)=>
  {
  	if(newValue == null)
  	{
  		setSelectedUser([]);
  		setError(true)
  	}
  	else
  	{
  		setSelectedUser(newValue)
  	}
  	
  }

  return (
    <Modal setModal={props.setModal} setShow={props.setShow} id="Add-Message-Modal" title='New Message' formId={formId} ActionButton={"Send"}>
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        <div className="row g-2">
        	<div className="d-flex align-items-center">
        		<div className="col-sm-6 ">
	            <Select	            	
	              value={selectedUser}
	              options={users}
	              isClearable
	              onChange={ newValue => onSelectValue( newValue )}
	              id='user'
	              required
	            />	            
	          </div>
	          <div>
            	<h6 className="text-start ms-4">Select User</h6>
	          </div>
          </div>
          {error == true && selectedUser.length == 0 ?
          	<p className="text-danger text-start">User can't be blank</p>
          	:
          	null
          }
          <div className="col-md-12">
            <div className="form-floating">
              <input onChange={(e)=>setSubject(e.target.value)} type="text" className="form-control" name="title" id="title" placeholder="Title" maxLength="100"  />
              <label htmlFor="title">Message Subject</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          {error == true && subject == '' ?
          	<p className="text-danger text-start">Subject can't be blank</p>
          	:
          	null
          }

          <div className="col-md-12">
            <div className="text-start">
              <label htmlFor="message">Message</label>
            </div>
            <div className="text-start">
              <CustomEditor placeholder={"Type message here"} className="form-control" type="text" id="message" value={messageBody} onChange={handleChange} required/>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          {error == true && messageBody.replace(/<\/?[^>]+(>|$)/g, "") == "" ?
          	<p className="text-danger text-start">Message can't be blank</p>
          	:
          	null
          }
          <div className="col-md-12">
            <div className="mb-3 text-start">
              <label htmlFor="attachment" className="form-label">Select Attachment (Optional)</label>
              <input className="form-control" name="attachment" type="file" id="attachment" onChange={(e) => uploadAttachment(e)}  />
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>
      </form>
    </Modal>
  );
}

export default SendMessage;
