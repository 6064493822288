import React, { useContext } from 'react';
import NavBar from '../components/NavBar'
import SideBar from '../components/SideBar'
import ClientSidebar from '../components/ClientSidebar'
import { store } from '../store.js';


function PrivateLayout({children}) {

	const globalState = useContext(store);

  return (
    <div>
      <div className="container-fluid">
        <div className="row">
          <NavBar />
          {
          	globalState.state.user_type == "client" ?
          	<ClientSidebar />
          	:
          	<SideBar />
          }
          
          <main className="col-md-9 ms-sm-auto col-lg-10 px-md-4">
            {children}
          </main>
        </div>
      </div>
    </div>
  );
}

export default PrivateLayout;
