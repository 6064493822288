import React, { useContext, useState } from 'react';
import { Link } from 'react-router-dom';
import { store } from '../store'


const Congratulations = (props) => {

	const globalState = useContext(store);

  return (
    <div>
      <div className="text-center mt-5">
        <h3><b>Congratulations! Your Account has been set up.</b></h3>
				{
					globalState.state.user_type == "client" ?
					<h3><b>Please complete your Client Profile</b></h3>
					:
					<h3><b>Please complete your Consultant Profile</b></h3>
				}
				{
					globalState.state.completed ?
					<div className="col-md">
            <Link className="btn btn-primary btn-block" to={"/dashboard"}>My Dashboard</Link>
          </div>
					:
					<div>
					{
						globalState.state.user_type == "client" ?
						<div className="mt-5">
		          <div className="col-md">
		            <Link className="btn btn-primary btn-block" to={"/company-profile"}>Complete Profile</Link>
		          </div>
		        </div>
						:
						<div className="mt-5">
		        	{
		        		globalState.state.user_type == "consultant_demo" ?
		        		<div className="col-md">
			            <Link className="btn btn-primary btn-block" to={"/view-consultant-profile"}>View Profile</Link>
			          </div>
			          :
			          <div className="col-md">
			            <Link className="btn btn-primary btn-block" to={"/complete-signup"}>Complete Profile</Link>
			          </div>
		        	}
		        </div>
					}
					</div>
				}
				
      </div>
    </div>
  );
}

export default Congratulations;
