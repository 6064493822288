import React, { useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store'
import { Link } from 'react-router-dom';
import SetupUser from './manageusers/SetupUser';

const SetupManageUsers = (props) => {

const globalState = useContext(store);
const [showModal, setShow] = useState( false )
const [modal, setModal] = useState(null)
const formEl = useRef();


useEffect(() => {
    if (modal)
      showModal ? modal.show() : modal.hide()
      //fetchData();
  }, [modal, showModal])

return(
	<div className="">
		<div className="row g-2">
        <div className="col-md g-2 text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="users" /> Set up and Manage Users </h2>
        </div>
    </div>

    <div className="row g-2">
      <div className="d-flex align-items-center justify-content-between">
        <button type="button"
                className="btn btn-primary font-weight-bold action-button"
                onClick={ () => setShowModalSetupUser( !showModalSetupUser ) }
              >Set up User Account</button>
      </div>
    </div>

    <div>
      <div className="card mb-3  mt-3">
        <div className="d-flex justify-content-between g-1 m-1">
          <div className="">
            <p className="font-weight-bold">Nikhil Paranjape</p>
          </div>
         
          <div className="">
            <button type="button" className="btn btn-primary" style={{width:'80px',marginRight:'5px'}}>Edit</button>
            <button type="button" className="btn btn-danger" style={{width:'80px'}}>Delete</button>
          </div>
        </div>
      </div>
      <div className="card mb-3  mt-3">
        <div className="d-flex justify-content-between g-1 m-1">
          <div className="">
            <p className="font-weight-bold">Aruna Ghosh</p>
          </div>
         
          <div className="">
            <button type="button" className="btn btn-primary" style={{width:'80px',marginRight:'5px'}}>Edit</button>
            <button type="button" className="btn btn-danger" style={{width:'80px'}}>Delete</button>
          </div>
        </div>
      </div>
      <div className="card mb-3  mt-3">
        <div className="d-flex justify-content-between g-1 m-1">
          <div className="">
            <p className="font-weight-bold">Rohit Panigrahi</p>
          </div>
         
          <div className="">
            <button type="button" className="btn btn-primary" style={{width:'80px',marginRight:'5px'}}>Edit</button>
            <button type="button" className="btn btn-danger" style={{width:'80px'}}>Delete</button>
          </div>
        </div>
      </div>
    </div>

    <div className="row g-2 justify-content-start">
        <SetupUser show={showModal} setShow={setShow} setModal={setModal}/>
    </div>

	</div>
	);

}

export default SetupManageUsers;
