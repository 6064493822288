import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import AddMessage from './messages/AddMessage';
import MessageCard from './messages/MessageCard';


const MessageBoard = (props) => {

  const globalState = useContext(store);
  const [ messages, setMessages ] = useState([])
  const [ messageScope, setMessageScope ] = useState(false)
  const [ fetched, setFetched ] = useState(false)
  const [ loading, setLoading ] = useState(false)
  const [showModalAddMessage, setShowModalAddMessage] = useState( false )
  const [modalAddMessage, setModalAddMessage] = useState(null)
  const formEl = useRef();

  const searchMessages = async (event) => {
		event?.preventDefault();

		const formData = {
      search_text: event?.target.search_text.value,
      message_scope: messageScope,
    }

		const response_promoted = await fetch( '/api/v1/promoted-message', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })

    const promoted_messages = await response_promoted.json()

    setLoading( true )
    const response = await fetch( '/api/v1/search_messages', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const messages = await response.json()

    var array_new = promoted_messages;

    array_new = array_new.concat(messages);


    if (response.ok && response_promoted.ok) {
      setMessages(array_new)
      setLoading( false )
      setFetched( true )
    } else {
      setLoading( false )
    }

  }

  useEffect(() => {
	  searchMessages()
	}, []);

  const totalCount = () => {
    if (loading) return 'searching messages...';
    if (fetched) return `${messages.length} message${messages.length > 1  ? 's' : '' } found`
    return ''
  }

  useEffect(() => {
    if (modalAddMessage)
      showModalAddMessage ? modalAddMessage.show() : modalAddMessage.hide()
		  searchMessages()
  }, [modalAddMessage, showModalAddMessage])


   const DeleteMessage = async(id) =>
  {
  	const formData = {
      message_id: id,
    }
    const response = await fetch( '/api/v1/delete_message', {
			headers: {
			  'Content-Type': 'application/json',
			  'Authorization': globalState.state.Authentication
			},
			method: 'post',
			body: JSON.stringify(formData)
			})

	    if (response.ok) {
	    	setLoading( true )
	      searchMessages()
    	}
  }

  const searchResults = messages.map( m => {
  	if(m.from_admin)
  	{
  		return (
	      <MessageCard
	        key={m.id}
	        message_id={m.id}
	        user_id={m.admin_user.id}
	        title={m.title}
	        message={m.message}
	        promoted_at={m.promoted_at}
	        // attachment_title={m.attachment_fileName}
	        // attachment_url={m.attachment_url}
	        display_name={m.admin_user.name}
	        from_admin={m.from_admin}
	        //primary_service={m.consultant_profile.primary_service}
	        image_url={m.admin_user.image_url}
	        posted_on={m.posted_on}
	        comments={m.comments}
	        // DeleteMessage={DeleteMessage}
	        // allow_delete={m.allow_delete}
	      />
	    )
  	}
  	else
  	{
  		return (
	      <MessageCard
	        key={m.id}
	        message_id={m.id}
	        user_id={m.consultant_profile.id}
	        title={m.title}
	        promoted_at={m.promoted_at}
	        message={m.message}
	        attachment_title={m.attachment_fileName}
	        attachment_url={m.attachment_url}
	        display_name={m.consultant_profile.display_name}
	        primary_service={m.consultant_profile.primary_service}
	        image_url={m.consultant_profile.image_url}
	        posted_on={m.posted_on}
	        comments={m.comments}
	        DeleteMessage={DeleteMessage}
	        allow_delete={m.allow_delete}
	        consultant_profile={m.consultant_profile}
	        user_type={globalState.state.user_type}
	      />
	    )
  	}
    
  })

  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 border-bottom">
      <div className="container">
        <div className="row g-2 align-items-center">
          <div className="col-md text-start">
            <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="copy" /> Message Board</h2>
          </div>
          <div className="col-md ms-4 text-end">
            <button type="button"
              className="btn btn-primary font-weight-bold action-button"
              onClick={ () => setShowModalAddMessage( !showModalAddMessage ) }
            >Post New Message</button>
          </div>
        </div>

        <div className="row g-2 justify-content-start">

          <AddMessage show={showModalAddMessage} setShow={setShowModalAddMessage} setModal={setModalAddMessage}/>
        </div>

        <form onSubmit={searchMessages} className="row g-2">
          <div className="row g-2 text-start align-items-center">
            <div className="col-md-8">
              <div className="form-floating">
                <input type="text" className="form-control" name="search_text" id="search_text" placeholder="Title" />
                <label htmlFor="search_text">Search Messages</label>
                <div className="invalid-feedback"></div>
              </div>
            </div>
            <div className="col-md-3">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" checked={messageScope}
                onChange={() => setMessageScope(!messageScope)}
                id="message_scope" />
                <label className="form-check-label" htmlFor="message_scope">{messageScope ? "Only My Messages" : "All Messages" }</label>
              </div>
            </div>
            <div className="col-md-1">
              <button type="submit" className="btn btn-primary btn-block mb-2">Search</button>
            </div>
          </div>
          <div className="row g-2">
            <div className="text-center m-2 mb-2">
              <h5 className="fw-bolder">{totalCount()}</h5>
            </div>
          </div>
        </form>

        <div className="row g-2">
          <div className="col-md g-2 text-start">
            {searchResults}
          </div>
        </div>

      </div>
    </div>

  );
}

export default MessageBoard;
