import React, { useContext, useRef, useState, useEffect } from 'react';
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import { store } from '../../store'
import { confirmAlert } from 'react-confirm-alert';


import Modal from '../../components/Modal';

const EditSpecialisation = (props) => {

  const formEl = useRef();

  const globalState = useContext(store);
  const [ therapeutic, setTherapeutic ] = useState([])
  const [ allowTherapeuticOthers, setAllowTherapeuticOthers ] = useState(false)
  const [ therapeuticOthers, setTherapeuticOthers ] = useState([])
  const [ product, setProduct ] = useState([])
  const [ allowProductOthers, setAllowProductOthers ] = useState(false)
  const [ productOthers, setProductOthers ] = useState([])
	const [languages, setLanguages ] = useState([])
  const [ service, setService ] = useState([])
  const [ options, setOptions ] = useState({
    therapeutic: [],
    product: [],
    service: []
  })

  const fetchData = async () => {
    const response = await fetch('/api/v1/consultant_specialisations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setOptions(responseJson.options)
      setTherapeutic(responseJson.therapeutic)
      setLanguages(responseJson.languages)
      if (responseJson.therapeutic_others.length){
        setAllowTherapeuticOthers(true)
        setTherapeuticOthers(responseJson.therapeutic_others)
      }
      setProduct(responseJson.product)
      if (responseJson.product_others.length){
        setAllowProductOthers(true)
        setProductOthers(responseJson.product_others)
      }
      setService(responseJson.service)
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, [props.show]);


  const handleFormSubmit = async () => {
    const therapeuticValues = therapeutic.map( data => data.value )
    const therapeuticOthersValues = therapeuticOthers.map( data => data.value )
    const productValues = product.map( data => data.value )
    const productOthersValues = productOthers.map( data => data.value )
    const serviceValues = service.map( data => data.value )
    const languageValue = languages.map(data=>data.value)

    const formData = {
      consultant_specialisations: {
        therapeutic: therapeuticValues,
        therapeutic_others: allowTherapeuticOthers ? therapeuticOthersValues : [],
        product: productValues,
        product_others: allowProductOthers ? productOthersValues : [],
        service: serviceValues,
        languages: languageValue,
      }
    }

    const response = await fetch( '/api/v1/consultant_specialisations', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()
    if (response.ok) {
      props.setShow(false)
    } else {

    }
  }

  const check_from_array =(value,array,key)=>
  {
  	var num = value.length
  	if(value.length == 0)
  	{
  		if(key == 'therapeutic')
  		{
  			setTherapeuticOthers([]);
  		}
  		else
  		{
  			setProductOthers([]);
  		}
  	}
  	else
  	{
  		var array_value = [];
	  	array.map((single)=>
	  	{
	  		array_value.push(single.label.toLowerCase()) ;
	  	});

			var lower_case_value = value[num-1].label.toLowerCase();

	  	if(array_value.includes(lower_case_value))
	  	{
	  		if(key == 'therapeutic')
	  		{
	  			alert('This specialisation already present in above dropdown,Add any specialisation not listed above')
	  		}
	  		else
	  		{
	  			alert('This product specialisations already present in above dropdown,Add any specialisation not listed above.')
	  		}
	  	}
	  	else
	  	{
	  		if(key == 'therapeutic')
	  		{
	  			setTherapeuticOthers(value);
	  		}
	  		else
	  		{
	  			setProductOthers(value);
	  		}
	  	}
  	}
  }

  const therapeuticOthersHTML = () => {
    if (allowTherapeuticOthers){
      return(
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-2 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <CreatableSelect
              components={components}
              value={therapeuticOthers}
              isClearable
              isMulti
              onChange={ newValue => check_from_array( newValue,options.therapeutic,"therapeutic")}
              placeholder='Add any specialisation not listed above'
              id='therapeuticOthers'
            />
          </div>
        </div>
      )
    }
    return ''
  }

  const productOthersHTML = () => {
    if (allowProductOthers){
      return(
        <div className="mb-3 row">
          <label htmlFor="service" className="col-sm-2 col-form-label text-end fw-bold"></label>
          <div className="col-sm-6">
            <CreatableSelect
              components={components}
              value={productOthers}
              isClearable
              isMulti
              onChange={ newValue => check_from_array( newValue, options.product, 'product')}
              placeholder='Add any specialisation not listed above'
              id='productOthers'
            />
          </div>
        </div>
      )
    }
    return ''
  }

  const performSubmit =() => { handleFormSubmit(); }

  const components = {
    DropdownIndicator: null,
  };

  return(
    <Modal setModal={props.setModal} setShow={props.setShow} id="modalEditSpecialisations" title='Edit Specialisations' triggerSumbit={performSubmit}>
      <form ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">

        <fieldset>
          
          <div className="mb-3 row">
            <label htmlFor="service" className="col-sm-2 col-form-label text-end fw-bold"></label>
            <div className="col-sm-6">
            	<legend className="text-start">Therapeutic Areas</legend>
              <Select
                value={therapeutic}
                options={options.therapeutic}
                isClearable
                isMulti
                onChange={ newValue => setTherapeutic( newValue )}
                id='therapeutic'
              />
            </div>
            <div className="col-sm-3 d-flex align-items-center">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" checked={allowTherapeuticOthers}
                onChange={() => setAllowTherapeuticOthers(!allowTherapeuticOthers)}
                id="therapeutic-others" />
                <label className="form-check-label" htmlFor="therapeutic-others">Add Others</label>
              </div>
            </div>
          </div>

          {therapeuticOthersHTML()}
        </fieldset>

        <fieldset>
          <div className="mb-3 row">
            <label htmlFor="service" className="col-sm-2 col-form-label text-end fw-bold"></label>
            <div className="col-sm-6">
            <legend className="text-start">Product Specialisations</legend>
              <Select
                value={product}
                options={options.product}
                isClearable
                isMulti
                onChange={ newValue => setProduct( newValue )}
                id='product'
              />
            </div>
            <div className="col-sm-3 d-flex align-items-center">
              <div className="form-check form-switch">
                <input className="form-check-input" type="checkbox" checked={allowProductOthers}
                onChange={() => setAllowProductOthers(!allowProductOthers)}
                id="product-others" />
                <label className="form-check-label" htmlFor="product-others">Add Others</label>
              </div>
            </div>
          </div>

          {productOthersHTML()}
        </fieldset>

        <fieldset>          
          <div className="mb-3 row">
            <label htmlFor="service" className="col-sm-2 col-form-label text-end fw-bold"></label>
            <div className="col-sm-6">
            	<legend className="text-start">Service Offering Specialisations</legend>
              <CreatableSelect
                components={components}
                value={service}
                isClearable
                isMulti
                onChange={ newValue => setService( newValue )}
                placeholder='Add any other specialisation specific to your Service offering'
                id='service'
              />
            </div>
          </div>
        </fieldset>
        <fieldset>
          <div className="mb-3 row">
            <label htmlFor="service" className="col-sm-2 col-form-label text-end fw-bold"></label>
            <div className="col-sm-6">
  	          <legend className="text-start">Languages Known (in addition to English)</legend>
              <Select
	              value={languages}
	              options={options.languages}
	              isClearable
	              isMulti
	              onChange={newValue => setLanguages(newValue)}
	              id='language'
	            />
            </div>            
          </div>         
        </fieldset>
      </form>
    </Modal>
  )

}

export default EditSpecialisation;
