import React,{ useContext, useState, useEffect } from 'react';
import { store } from '../../store'
import View from './View';

const List = (props) => {

  const globalState = useContext(store);
  
  const [projects, setProjects] = useState([]);

  const fetchData = async () => {
    const response = await fetch('/api/v1/projects', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson = await response.json()
   
    if (response.ok) {
      setProjects(responseJson)
      props.setFetch( false )
      if (props.setProjectCount) { props.setProjectCount( responseJson.length ) }
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

  if (props.fetch)
    fetchData();

  const listItems = projects.map((data, index) =>{
  	if(props.display_public_profile == true && data.is_public == true)
  	{
	    return <View display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} />
  	}
  	else if(props.display_public_profile == false || props.display_public_profile == undefined)
  	{
	    return <View display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} />
  	}
  });

  return (
    <div className="accordion step-form my-4 mx-1" id="accordionProjects">
      {listItems}
    </div>
  );
}

export default List;
