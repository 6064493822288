import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import TermAndConditions from '../../components/TermAndConditions'
import DatePicker from "react-datepicker";
import ViewJob from '../jobs/ViewJob';
import View from '../projects/View';
import FloatingSelect from '../../components/FloatingSelect';




const WorkOrder = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
	const [ workOrder, setWorkOrder ] = useState({})
	const [ userData, setUserData ] = useState({})
  const [ milestones, setMilestones] = useState([{id:0,name:'',milestone_date: null}])
  const [ timeBasedMilestone, setTimeBasedMilestone] = useState([{id:0,description:'',date: null, rateperhour:'', currency:''}])
  const [ invoice_Milestones, setInvoice_Milestones] = useState([{id:0,name:'',milestone_date: null, amount_percent:'',amount:''}])
	const [agreeConsultantCountry, setAgreeConsultantCountry] = useState(true)
  const [agreeLocation, setAgreeLocation] = useState(true)
  const [agreeOnLocation, setAgreeOnLocation] = useState(true)
  const [startDateComment, setStartDateComment] = useState('')
  const [estimatedDurationComment, setEstimatedDurationComment] = useState('')
  const [onsiteComment, setOnsiteComment] = useState('')
  const [consultantCountryComment, setConsultantCountryComment] = useState('')
  const [locationComment, setLocationComment] = useState('')
  const [invoiceFrequencyType, setInvoiceFrequencyType] = useState('')
  const [additionalTerms, setAdditionalTerms] = useState('')
  const [totalPercentage, setTotalPercentage] = useState(0)
  const [percentageLimit, setPercentageLimit] = useState(0)


	const invoicingfrequency = [
    { value: 'Weekly', text: 'Weekly' },
    { value: 'Fortnightly', text: 'Fortnightly' },
    { value: 'Monthly', text: 'Monthly' },

  ]


	let history = useHistory()

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	function handleChange(newValue) {
    setValue(newValue);
  }

	const fetchData = async () => {
		const response1 = await fetch('/api/v1/work_orders/'+ my_data.work_order_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })

	  const responseJsonProposal = await response1.json()

	  console.log("responseJsonProposal")
	  console.log(responseJsonProposal)

	  if(response1.ok)
	  {
	  	setProjectDetails(responseJsonProposal.project_detail)
		  setWorkOrder(responseJsonProposal)
		  setUserData(responseJsonProposal.user)
		  setMilestones(responseJsonProposal.work_order_milestones)
		  setStartDateComment(responseJsonProposal.comment_start_date)
		  setEstimatedDurationComment(responseJsonProposal.comment_estimated_duration)
		  setOnsiteComment(responseJsonProposal.comment_onsite_location)
		  setLocationComment(responseJsonProposal.comment_location_type)
		  setConsultantCountryComment(responseJsonProposal.comment_consultant_country)
		  setAgreeConsultantCountry(responseJsonProposal.agree_consultant_country)
		  setAgreeLocation(responseJsonProposal.agree_location_type)
		  setAgreeOnLocation(responseJsonProposal.agree_onsite_location)
		  setAdditionalTerms(responseJsonProposal.additional_terms)
		  
		  if(responseJsonProposal.work_order_invoice_schedules.length == 0 )
		  {
		  	if(responseJsonProposal.project_detail.fees_type == "time-based")
		  	{
		  		var time_based_milestones = []
			  	responseJsonProposal.work_order_milestones.map((milestone, index)=>
			  	{
			  		var milestone = {id: index+1, name: milestone.name, milestone_date:milestone.milestone_date }
			  		time_based_milestones.push(milestone);
			  	})
			  	setTimeBasedMilestone(time_based_milestones);
		  	}
		  	else
		  	{
		  		var invoice_Milestone = []
			  	responseJsonProposal.work_order_milestones.map((milestone, index)=>
			  	{
			  		var milestone = {id: index+1, name: milestone.name, milestone_date:milestone.milestone_date, amount_percent:'', amount:''}
			  		invoice_Milestone.push(milestone);
			  	})
			  	setInvoice_Milestones(invoice_Milestone);
		  	}		  	
		  }
		  else
		  {
		  	if(responseJsonProposal.project_detail.fees_type == "time-based")
		  	{
		  		setTimeBasedMilestone(responseJsonProposal.work_order_invoice_schedules)
		  	}
		  	else
		  	{
		  		setInvoice_Milestones(responseJsonProposal.work_order_invoice_schedules)

			  	var total_percentage = 0;
			    const newData = responseJsonProposal.work_order_invoice_schedules.map( data => {
			    	if(data.amount_percent != '')
			    	{
			    		var percentage = parseInt(data.amount_percent)
				    	total_percentage = total_percentage + percentage ;
			    	}

			    });
			    setTotalPercentage(total_percentage)
			    setPercentageLimit(100 - totalPercentage)
		  	}
		  	
		  }

	  }

	}

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	}, [props.show]);

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const addMilestones = () => {
   	let count = milestones.length;
    let id = milestones[count-1].id + 1;
    setMilestones( [...milestones, {id: id,name:'',milestone_date: null} ]  )
  }



  const removeMilestones = (id) => {
    const new_milestones =  milestones.filter( data => data.id != id )
    setMilestones( new_milestones )
  }

  const removeInMilestones = (id) => {
    const new_milestones =  invoice_Milestones.filter( data => data.id != id )
    setInvoice_Milestones( new_milestones )
  }

  const removeTimeMilestones = (id) => {
    const new_milestones =  timeBasedMilestone.filter( data => data.id != id )
    setTimeBasedMilestone( new_milestones )
  }


  const handleFormSubmit = async (event) => {
    event.preventDefault();

    var invoice_schedules_attributes = []

    if(projectDetails.fees_type == 'time-based')
    {
    	invoice_schedules_attributes = timeBasedMilestone
    }
    else
    {
    	invoice_schedules_attributes = invoice_Milestones
    }    

    const formData = {
			work_order:
			{
				comment_start_date: startDateComment,
				invoice_frequency: invoiceFrequencyType,
				comment_estimated_duration: estimatedDurationComment,
				comment_consultant_country: consultantCountryComment,
				comment_location_type: locationComment,
				comment_onsite_location: onsiteComment,
				work_order_milestones_attributes:milestones,
				work_order_invoice_schedules_attributes:invoice_schedules_attributes,
				additional_terms:additionalTerms
			}
    }

    console.log("formData")
    console.log(formData)

		const response = await fetch( '/api/v1/update_work_order/' + my_data.work_order_id, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()

    if (response.ok) {
    	history.push({pathname:"/work-order-preview", state:{ work_order_id: workOrder.id,}});
    }
    else
    {
    	setErrorMessage(responseJson.effort_frequency[0])
    }
  }

  const handleMilestoneChange = (event, id )=> {

    const newData = milestones.map( data => {
      if (id == data.id)
        data.name = event.target.value
      return data
    });
    setMilestones( newData )
  }

  const handleDateChange = (date, id )=> {

  	let formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    const newData = milestones.map( data => {
      if (id == data.id)
        data.milestone_date = formattedDate
      return data
    });
    setMilestones( newData )
  }

  const handleInvoiveDate = (date, id )=> {

  	let formattedDate = `${date.getFullYear()}-${
      date.getMonth() + 1
    }-${date.getDate()}`;

    if(projectDetails.fees_type == "time-based")
    {
    	const newData = timeBasedMilestone.map( data => {
	      if (id == data.id)
	        data.milestone_date = formattedDate
	      return data
	    });
	    setTimeBasedMilestone( newData )
    }
    else
    {
    	const newData = invoice_Milestones.map( data => {
	      if (id == data.id)
	        data.milestone_date = formattedDate
	      return data
	    });
	    setInvoice_Milestones( newData )
    }

    
  }

  const handleInvoiceMile = (event, id )=> {
  	if(projectDetails.fees_type == "time-based")
    {
    	const newData = timeBasedMilestone.map( data => {
	      if (id == data.id)
	        data.name = event.target.value
	      return data
	    });
	    setTimeBasedMilestone( newData )
    }
    else
    {
    	const newData = invoice_Milestones.map( data => {
	      if (id == data.id)
	        data.name = event.target.value
	      return data
	    });
	    setInvoice_Milestones( newData )
    }
    
  }

  const percentageLimitError = () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Total percentage should be 100% ?</h5>
		        <div className="row g-2 mt-3">

			        <div className="col-md g-2 text-center">
				        <button className="btn btn-primary"
				          onClick={() => {
				            onClose();
				          }}
				        >
				          Ok
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

  const handleMilestonePercentage = (event, id)=> {

  	var event_value = parseInt(event.target.value)

		const newData = invoice_Milestones.map( data => {
      if (id == data.id){
      	var value = event.target.value
      	const amount = (value/100) * workOrder.total_fees
        data.amount_percent = event.target.value
        data.amount = amount.toFixed(2)
      }
      return data

    });
    setInvoice_Milestones( newData )


    var	total_percentage = 0;

    const percentage_data = invoice_Milestones.map( data => {
    	if(data.amount_percent != '')
    	{
    		var percentage = parseInt(data.amount_percent)
	    	total_percentage = total_percentage + percentage ;
    	}
    });

    setTotalPercentage(total_percentage)

    if(total_percentage > 100)
    {
    	const newData = invoice_Milestones.map( data => {
	      if (id == data.id){
	      	var value = event.target.value
	      	const amount = (value/100) * workOrder.total_fees

	        data.amount_percent = ''
	        data.amount = ''
	      }
	      return data
	    });
	    setInvoice_Milestones( newData )
	    total_percentage = (totalPercentage - event_value)

	    setTotalPercentage(total_percentage )
    	percentageLimitError();
    }

  }

  const addInMilestones = () => {
  	if(projectDetails.fees_type_dv == "Time-based")
  	{
      let count = timeBasedMilestone.length;
      let id = timeBasedMilestone[count - 1].id + 1;
      setTimeBasedMilestone([...timeBasedMilestone, { id: id, description: '', date: null }])
  	}
  	else
  	{
  		var total_percentage = 0;
	    const newData = invoice_Milestones.map( data => {
	    	if(data.amount_percent != '')
	    	{
	    		var percentage = parseInt(data.amount_percent)
		    	total_percentage = total_percentage + percentage ;
	    	}
	    });
	    setTotalPercentage(total_percentage)

  		let count = invoice_Milestones.length;
	    let id = invoice_Milestones[count-1].id + 1;
	    setInvoice_Milestones( [...invoice_Milestones, {id: id,name:'',milestone_date: null, amount_percent:'',amount:''} ]  )
  	}

  }

  const Milestones = milestones.map((data,index)=>
  {
  	var milestone_date = null;

  	if(data.milestone_date != null)
  	{
  		milestone_date = new Date(data.milestone_date)
  	}

  	var date_id = (index - 1)

  	var minimum_date = null ;

  	if(index > 0 && milestones[date_id].milestone_date != null)
  	{
  		minimum_date = new Date(milestones[date_id].milestone_date)
  		minimum_date.setDate(minimum_date.getDate() + 1)
  	}
	

		return(
			<div key={index} className="col-md-12 text-start mt-3 mb-3">
				<div className="row">
					<div className="col-md-6 text-center">
						<div className="form-floating">
		          <input type="text" className="form-control" value={data.name} onChange={(event)=>handleMilestoneChange(event, data.id)} name="milestone_name" id="milestone_name" placeholder="Milestone" required />
		          <label htmlFor="milestone_name">Milestone</label>
		        </div>
					</div>
					<div className="col-md-4 text-center">
						< DatePicker
		          selected={milestone_date}
		          onChange={date => handleDateChange(date, data.id)}
		          dateFormat="dd/MM/yyyy"
		          minDate={minimum_date}
		          customInput={<CustomInput identify='milestone_date' label='Date'/>}
		        />
					</div>
					{
		      	index > 0 ?
		      	<div className="col d-flex align-items-center justify-content-center">
		          <button type="button" onClick={() => removeMilestones(data.id)}
		            className="btn btn-danger btn-block"><FontAwesomeIcon icon="times" /></button>
		        </div>
		        :
		        null

		      }

				</div>
			</div>
		);
  })

  const TimeBasedMilestone = timeBasedMilestone.map((data,index)=>
  {
  	var milestone_date = null;

  	if(data.milestone_date != null)
  	{
  		milestone_date = new Date(data.milestone_date)
  	}

		return(
			<div key={index} className="col-md-12 text-start mt-3 mb-3">
				<div className="row">
					<div className="col-md-5 text-center">
						<div className="form-floating">
		          <input type="text" className="form-control" value={data.name} onChange={(event)=>handleInvoiceMile(event, data.id)} name="milestone_name" id="milestone_name" placeholder="Milestone" required />
		          <label htmlFor="title">Description</label>
		        </div>
					</div>
					<div className="col-md-2 text-center">
						< DatePicker
		          selected={milestone_date}
		          onChange={date => handleInvoiveDate(date, data.id)}
		          dateFormat="dd/MM/yyyy"
		          minDate={new Date()}
		          customInput={<CustomInput identify='milestone_date' label='Date'/>}
		        />
					</div>
					<div className="col-md-2 text-center">
						<div className="form-floating">
		          <input type="text" className="form-control" defaultValue={workOrder.rate_per_hour}   name="rate/hour" id="rate/hour" placeholder="Milestone" required />
		          <label htmlFor="title">Rate/Hour*</label>
		        </div>
					</div>
					<div className="col-md-2 text-center">
						<div className="form-floating">
		          <input type="text" className="form-control" defaultValue={projectDetails.currency}  name="currency" id="currency" placeholder="Milestone" required />
		          <label htmlFor="title">Currency</label>
		        </div>
					</div>
					{
		      	index > 0 ?
		      	<div className="col d-flex align-items-center justify-content-center">
		          <button type="button" onClick={() => removeTimeMilestones(data.id)}
		            className="btn btn-danger btn-block"><FontAwesomeIcon icon="times" /></button>
		        </div>
		        :
		        null

		      }

				</div>
			</div>
		);
  })

  const Invoice_Milestones = invoice_Milestones.map((data,index)=>
  {
  	var milestone_date = null;

  	if(data.milestone_date != null)
  	{
  		milestone_date = new Date(data.milestone_date)
  	}

  	var date_id = (index - 1)

  	var minimum_date = null ;

  	if(index > 0 && invoice_Milestones[date_id].milestone_date != null)
  	{
  		var minimum_date = new Date(invoice_Milestones[index - 1].milestone_date)
  		minimum_date.setDate(minimum_date.getDate() + 1)
  	}
  	if(data.amount != '')
  	{
  		var amount_value = ''

  		if(projectDetails.currency == 'Other')
  		{
  			amount_value =  data.amount+" "+projectDetails.other_currency
  		}
  		else
  		{
  			amount_value =  data.amount+" "+projectDetails.currency
  		}
  		
  	}  	

		return(
			<div key={index} className="col-md-12 text-start mt-3 mb-3">
				<div className="row align-items-center">
					<div className="col-md-4 text-center">
						<div className="form-floating">
		          <input type="text" className="form-control" value={data.name} onChange={(event)=>handleInvoiceMile(event, data.id)} name="invoice_milestone_name" id="invoice_milestone_name" placeholder="InvoiceMilestone" required />
		          <label htmlFor="invoice_milestone_name">Invoice Milestone</label>
		        </div>
					</div>
					<div className="col-md-2 text-center">
						< DatePicker
		          selected={milestone_date}
		          onChange={date => handleInvoiveDate(date, data.id)}
		          dateFormat="dd/MM/yyyy"
		          minDate={minimum_date}
		          customInput={<CustomInput identify='invoice_milestone_date' label='Date'/>}
		        />
					</div>
					<div className="col-md-2 text-center">
						<div className="form-floating">
		          <input type="number" className="form-control" value={data.amount_percent} onChange={(event)=>handleMilestonePercentage(event, data.id, 0)} name="amount_percent" id="amount_percent" placeholder="percentage" required />
		          <label htmlFor="amount_percent">% of total</label>
		        </div>
					</div>
					<div className="col-md-3 text-center">
						<div className="form-floating">
		          <input type="text" className="form-control" value={amount_value}  name="amount_percent" id="amount_percent" placeholder="percentage" disabled/>
		          <label htmlFor="amount_percent">Amount*</label>
		        </div>
					</div>
					{/*<div className="col-md-2 text-center">
						<label>{data.amount} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</label>
					</div>*/}

					{
		      	index > 0 ?
		      	<div className="col d-flex align-items-center justify-content-center">
		          <button type="button" onClick={() => removeInMilestones(data.id)}
		            className="btn btn-danger btn-block"><FontAwesomeIcon icon="times" /></button>
		        </div>
		        :
		        null

		      }
				</div>
			</div>
		);
  })








	function handleChange(newValue) {
    setProjectObjective(newValue);
  }
  function handleChangeInScope(newValue) {
    setProjectInScope(newValue);
  }
  function handleChangeOutScope(newValue) {
    setProjectOutScope(newValue);
  }

  function handleAdditionalTerms(newValue) {
    setAdditionalTerms(newValue);
  }



  return (
    <div className="container mb-5">
    	<div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="file-contract" /> Work Order</h2>
        </div>
    	</div>
    	<form onSubmit={handleFormSubmit} className="row g-2 justify-content-center mt-3 mb-3">
		    <div className="row g-2 mt-2">
	      	<div className="col-md-12">
	      		<div className="row g-0 text-start">
		      		<h4 className="col-md-5"><b>Project Title: </b></h4>
		      		<h4 className="col-md-7  text-start"><b>{projectDetails.title} </b></h4>
		      	</div>
	      		<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-5"><b>Company Name (“Client”): </b></h5>
		      		<h5 className="col-md-7">{projectDetails.company_name}</h5>
		      	</div>
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-5"><b>Consultant Name (“Consultant”): </b></h5>
		      		<h5 className="col-md-7">{userData.first_name} {userData.last_name}</h5>
		      	</div>
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-5"><b>Client User Name: </b></h5>
		      		<h5 className="col-md-7">{projectDetails.creator}</h5>
		      	</div>		      	
		      	<div className="row g-0 text-start mt-2">
		      		<h5 className="col-md-5"><b>Work Order Date: </b></h5>
		      		<h5 className="col-md-7">{workOrder.update_date}</h5>
		      	</div>
	      	</div>
	    	</div>
	    	<div className="col-md text-start mt-3 mb-4">
	        <div className="alert alert-info">
	          <ul>
	            This Work Order is an Agreement between the Client and the Consultant for the 
							delivery and completion of the Project of which the details are specified in this 
							Work Order. The Work Order is created under and is subject to the terms listed 
							below. It is noted that there are separate Master Agreements between the Client 
							and Pharmalancers Ltd and between the Consultant and Pharmalancers Ltd for 
							the usage of this online platform. The Work Order Agreement will be deemed 
							complete when the Client clicks on the ‘Submit Work Order’ button and then the 
							Consultant accepts it by clicking on the ‘Accept Work Order’ button at the 
							bottom of their respective Work Order screens.
	          </ul>
	        </div>
	      </div>
	    	<hr/>
	    	<h5 className="text-start mt-4 mb-3"><b>Project Scope</b></h5>
	    	<div className="text-start">
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Project Objectives : </strong>
	          <h6 className="col-md-9 ">{ReactHtmlParser(workOrder.project_objective)}</h6>
	        </div>
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Project In Scope : </strong>
	          <h6 className="col-md-8">{ReactHtmlParser(workOrder.project_in_scope)}</h6>
	        </div>
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Project Out Of Scope : </strong>
	          <h6 className="col-md-9">{ReactHtmlParser(workOrder.project_out_scope)}</h6>
	        </div>
	      </div>
	      <hr/>
	      <h5 className="text-start mt-4"><b>Project Timeline</b></h5>
	    	<div className="row g-0 mt-3 align-items-center">
					<div className="col-md-4 text-start">
						<h6><b>Proposed Start Date :</b> {workOrder.proposed_start_date}</h6>
					</div>

					<div className="col-md-8 text-center">
						<div className="form-floating">
	            <input type="text" defaultValue={startDateComment} onChange={()=>setStartDateComment(event.target.value)}  className="form-control" name="comment_start_date" id="comment_start_date" placeholder="abc"  />
	            <label htmlFor="title">Comment</label>
	          </div>
					</div>
				</div>
				<div className="row g-0 mt-3 align-items-center">
					<div className="col-md-4 text-start">
						<h6><b>Estimated project duration :</b> {projectDetails.estimated_duration_display}</h6>
					</div>

					<div className="col-md-8 text-center">
						<div className="form-floating">
	            <input type="text"  className="form-control" defaultValue={estimatedDurationComment} onChange={()=>setEstimatedDurationComment(event.target.value)} name="comment_start_date" id="comment_start_date" placeholder="abc"  />
	            <label htmlFor="title">Comment</label>
	          </div>
					</div>
				</div>
				<hr/>
				<div className="col-md form-group required">
	    		<h5 className="text-start mt-3"><b className=" ">Project Milestones </b></h5>
	    	</div>
	  		{Milestones}
	  		<div className="text-start ms-3">
	        <button type="button" onClick={addMilestones} className="btn btn-primary btn-block"><FontAwesomeIcon icon="plus" /></button>
	      </div>
	      <hr/>

	  		<div className="col-md form-group required">
					<h5 className="text-start mt-3"><b className=" ">Project Logistics</b></h5>
				</div>
					{
						projectDetails.consultant_country != '' ?
							<div className="row g-0 align-items-center mt-3">
								<div className="col-md-5 text-start">
									<h6><b>Consultant Country :</b> {projectDetails.display_consultant_country}</h6>
								</div>
								<div className="col-md-1 text-center form-check ">
							  	<input className="form-check-input" type="checkbox"  checked={agreeConsultantCountry} id="agree_consultant_country"/>
							  	<label htmlFor="title">{agreeConsultantCountry ? 'Yes' : 'No'}</label>
							  </div>
								<div className="col-md-6 text-center">
									<div className="form-floating">
				            <input type="text" className="form-control" defaultValue={consultantCountryComment} onChange={()=>setConsultantCountryComment(event.target.value)}  name="comment_consultant_country" id="comment_consultant_country" placeholder="John" />
				            <label htmlFor="title">Comment</label>
				          </div>
								</div>
							</div>
							:
							null
						}


					<div className="row g-0 align-items-center mt-3">
						<div className="col-md-5 text-start">
							<h6><b>Project Location :</b> {projectDetails.location_type_dv}</h6>
						</div>
						<div className="col-md-1 text-center form-check ">
					  	<input className="form-check-input" type="checkbox"  checked={agreeLocation} id="agree_location_type"/>
					  	<label htmlFor="title">{agreeLocation ? 'Yes' : 'No'}</label>
					  </div>
						<div className="col-md-6 text-center">
							<div className="form-floating">
		            <input type="text" className="form-control" defaultValue={locationComment} onChange={()=>setLocationComment(event.target.value)}  name="comment_location_type" id="comment_location_type" placeholder="John" />
		            <label htmlFor="title">Comment</label>
		          </div>
						</div>
					</div>
					{
						projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
						<div className="row g-0 align-items-center mt-3">
							<div className="col-md-5 text-center">
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.onsite_city} className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Onsite City</label>
			          </div>
							</div>
							<div className="col-md-1 text-center form-check ">
						  	<input className="form-check-input" type="checkbox"  checked={agreeOnLocation} id="agree_onsite_location"/>
						  	<label htmlFor="title">{agreeOnLocation ? 'Yes' : 'No'}</label>
						  </div>
							<div className="col-md-6 text-center">
								<div className="form-floating">
			            <input type="text" className="form-control" defaultValue={onsiteComment} onChange={()=>setOnsiteComment(event.target.value)}  name="comment_onsite_location" id="comment_onsite_location" placeholder="John" />
			            <label htmlFor="title">Comment</label>
			          </div>
							</div>
						</div>
						:
						null
					}
					{
						projectDetails.location_type == "mix" || projectDetails.location_type == 'on-site' ?
						<div className="row g-0 mt-3 mb-2">
							<div className="col-md-5 text-center">
								<div className="form-floating">
			            <input type="text" defaultValue={projectDetails.display_onsite_country } className="form-control" name="title" id="title" placeholder="John" disabled />
			            <label htmlFor="title">Onsite Country</label>
			          </div>
							</div>
						</div>
						:
						null
					}

				<hr/>

				<div className="col-md form-group required">
					<h5 className="text-start mt-3"><b className=" ">Project Fees *</b></h5>
				</div>

				<div className="text-start">
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Type of project : </strong>
	          <h6 className="col-md-9 ">{projectDetails.fees_type_dv}</h6>
	        </div>
	        {
	        	workOrder.total_fees != null &&  projectDetails.currency == 'Other' ?
	        	<div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Total Fees : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.total_fees} {projectDetails.other_currency}</h6>
	          </div>
	          :workOrder.total_fees != null && projectDetails.currency != 'Other'?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Total Fees : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.total_fees} {projectDetails.currency} </h6>
	          </div>
	          :
	          null
	        }

	        {
	        	workOrder.rate_per_hour != null &&  projectDetails.currency == 'Other' ?
	        	<div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Rate/Hour : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.rate_per_hour} {projectDetails.other_currency}</h6>
	          </div>
	          :workOrder.rate_per_hour != null &&  projectDetails.currency != 'Other' ?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Rate/Hour : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.rate_per_hour} {projectDetails.currency} </h6>
	          </div>
	          :
	          null
	        }
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Effort Frequency Type : </strong>
	          <h6 className="col-md-9">{workOrder.effort_frequency_type}</h6>
	        </div>
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Effort Frequency Value : </strong>
	          <h6 className="col-md-9">{workOrder.effort_frequency}</h6>
	        </div>
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Estimated Effort Type : </strong>
	          <h6 className="col-md-9">{workOrder.total_effort_type}</h6>
	        </div>
	        <div className="row g-0 text-start mt-2">
	          <strong className="col-md-3">Estimated Effort Value : </strong>
	          <h6 className="col-md-9">{workOrder.total_effort}</h6>
	        </div>
	      </div>

				<hr/>

				<div className="col-md form-group required">
					<h5 className="text-start mt-3"><b className=" ">Invoicing Schedule</b></h5>
				</div>
				{
					projectDetails.fees_type_dv == "Time-based" ?
					<div>
						<div className="row align-items-center mt-3 mb-3">
							<div className="col-md-4 text-center">
								<div className={"text-end form-group "}>
				          <label className=" " htmlFor="projectDescription"><b>Select invoicing frequency</b></label>
				        </div>
							</div>
							<div className="col-md-4 form-floating">
		            <label htmlFor="title" className="card-title ">Invoicing frequency</label>
		            <FloatingSelect data={invoicingfrequency}  onChange={e => setInvoiceFrequencyType( e.target.value )} name={'invoicing_frequency' }
						     	placeholder={'Effort Type'}
							    label={'Select One'}/>
		          </div>
	          </div>
						{TimeBasedMilestone}
					</div>

					:
					Invoice_Milestones
				}

				<div className="text-start ms-3">
	        <button type="button" onClick={addInMilestones} className="btn btn-primary btn-block"><FontAwesomeIcon icon="plus" /></button>
	      </div>
	      <div className="col-md text-start no-print">
	        <div className="alert alert-info">
	          <h6><b>
	          	* Consultant Fees only, does not include Pharmalancers Fees or any applicable taxes.
	          </b></h6>
	        </div>
	      </div>
	      <hr/>
	      <div className="col-md form-group required">
					<h5 className="text-start mt-3"><b className=" ">Terms of the Work Order</b></h5>
				</div>
				<div className="text-start">
					<TermAndConditions/>
				</div>
				<div className="text-start form-floating">
					<div className="text-start">
            <label htmlFor="message"><b>Add Additional Terms</b></label>
          </div>
					<CustomEditor placeholder={"Additional Terms"} className="form-control" id="additional_terms" value={additionalTerms} onChange={handleAdditionalTerms} />
				</div>
				<hr/>

	      <div className="row g-2 mt-3">
	      	<div className="col-md text-start">
	          <button type="button" onClick={()=> history.push({pathname:"/work-order-preview", state:{ work_order_id: workOrder.id,}})} className="btn btn-primary btn-block ml-auto">Display Work Order</button>
	        </div>
	        <div className="col-md text-end">
	          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
	        </div>
	      </div>
	    </form>

    </div>

  );
}

export default WorkOrder;
