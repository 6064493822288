import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../../store'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from '../../components/Modal'
import CustomEditor from '../../components/CustomEditor'
import Select from 'react-select';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link } from 'react-router-dom';
import FloatingSelect from '../../components/FloatingSelect';
import TextAreaAutoResize from '../../components/TextAreaAutoResize';


const SetupUser = (props) => {

  const globalState = useContext(store);
  const formEl = useRef();
  const formId = 'setup-user'
  const [title,setTitle]=useState('')
  const [ created, setCreated ] = useState(false)
  const [ editData, setEditData ] = useState(true)
  const [countryCodes, setCountryCodes] = useState([])
  const [country, setCountry] = useState('')

  const [userTypes, setUserTypes] = useState([])
  const [userType, setUserType] = useState('')

  const [email, setEmail] = useState('')
  const [email_confirmation, setEmailConfirmation] = useState('')
	const [ company_data, setCompanyData ] = useState({})



  const types = [
	  {value:'admin',text:'Admin'},
	  {value:'manager',text:'Manager'},
	  {value:'reviewer',text:'Reviewer'},
	  {value:'accountant',text:'Accountant'},
  ];

  const fetchData = async () => {
  	const response_company = await fetch('/api/v1/companies', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responseJson_company = await response_company.json()

		if(response_company.ok)
		{
			setCompanyData(responseJson_company)
		}


    const response = await fetch('/api/v1/signup_data', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get'
    })
    const responseJson = await response.json()
    if (response.ok) {
      setCountryCodes( responseJson.country_codes )
    } else {
      alert.show("Server is down.")
      return false
    }
  }

  useEffect( () => {
    fetchData();
  }, []);

 

  const resetErrors = ( elements ) => {
    Array.prototype.forEach.call(elements, function(el) {
      el.classList.remove("is-invalid");
    });
  }

  const showErrors = ( elements, errors ) => {
    if ( !errors ) return;

    Array.prototype.forEach.call(elements, function(el) {
      let message = errors.user[el.name] 
      if( message ){
        el.classList.add("is-invalid");
        el.parentNode.querySelector('.invalid-feedback').innerText = message
      }
    });
  }

  const handleBlur = async (event) =>{
    if(email != email_confirmation )
    {
      let message = "doesn't match Email" ;
      if( message ){
        event.target.classList.add("is-invalid");
        event.target.parentNode.querySelector('.invalid-feedback').innerText = message
      }
    }
    else
    {
      event.target.classList.remove("is-invalid");
    }
  }

  const handleFormSubmit = async (event) => {

  	if (event) {
      event.preventDefault();
    }
    if(props.isEdit)
    {
    	const formData = {
	      user: {	        
          first_name: event.target.first_name.value,
	        last_name: event.target.last_name.value,
	        phone_number: event.target.phone_number.value,
	        country: event.target.country.value,        
	        job_title: event.target.job_title.value,
	        user_id: props.data.id,
	        role: event.target.user_type.value,
	        job_role_description: event.target.job_role_description.value
	      }
	    }

	    const response = await fetch( '/api/v1/edit_user', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'put',
	      body: JSON.stringify(formData)
	    })

	    const userData = await response.json()

	    if (response.ok) {
	    	setCountry('')
	    	setUserType('') 
	    	event.target.reset();   	
	      props.setShow(false)
	    } else {
	      resetErrors( event.target.elements );
	      showErrors( event.target.elements, userData );
	    }    
    }
    else
    {
    	const formData = {
	      user: {
	        first_name: event.target.first_name.value,
	        last_name: event.target.last_name.value,
	        email: event.target.email.value,
	        email_confirmation: event.target.email_confirmation.value,
	        phone_number: event.target.phone_number.value,
	        country: event.target.country.value,        
	        role: event.target.user_type.value,
	        user_type: 'client',
	        terms_of_service: true,
	        company_name: company_data.name,
	        job_title: event.target.job_title.value,
	        job_role_description: event.target.job_role_description.value
	      }
	    }

	    const response = await fetch( '/api/v1/create_user', {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'post',
	      body: JSON.stringify(formData)
	    })

	    const userData = await response.json()

	    if (response.ok) {
	    	setCountry('')
	    	setUserType('')  
	    	event.target.reset();   	
	      props.setShow(false)
	    } else {
	      resetErrors( event.target.elements );
	      showErrors( event.target.elements, userData );
	    }    

    }   
    
  }

  const Oncancel = (current_state) =>
  {  	
		formEl.current.reset();
		props.setUserType('') 
		props.setCountry('')
		resetErrors(formEl.current.elements);		
  }
 

  return (
    <Modal setModal={props.setModal} setShow={props.setShow} isEdit={props.isEdit} setData={props.setData} id="Setup-User-Modal" title={props.title} formId={formId} onCancel={Oncancel}>
      <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
        
        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">              
              <input type="text" defaultValue={props.data.first_name} className="form-control" name="first_name" id="first_name" placeholder="John" required />
              <label htmlFor="first_name">First Name</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md">
            <div className="form-floating">              
              <input type="text" defaultValue={props.data.last_name} className="form-control" name="last_name" id="last_name" placeholder="Smith" required /> 
              <label htmlFor="last_name">Last Name</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>  
             
        <div className="row g-2">
          <div className="col-md">
            <FloatingSelect data={types} name='user_type' required={true} onChange={e => props.setUserType( e.target.value )}
              label='User Type' value={props.userType} placeholder='Select User Type' withError='true'/>
          </div>
        </div>  	      
        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="text" defaultValue={props.data.job_title} className="form-control" name="job_title" id="job_title" placeholder="Job Title" required />
              <label htmlFor="job_title">Job Title</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>
        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="text" className="form-control" defaultValue={props.data.job_role_description} name="job_role_description" id="job_role_description" placeholder="name@example.com"   required />
              <label htmlFor="job_role_description">Job Role Description</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>
        
        <div className="row g-2">
          <div className="col-md">
            <div className="form-floating">
              <input type="email" defaultValue={props.data.email} disabled={props.data.email} className="form-control" name="email" id="email" placeholder="name@example.com" onChange={(e)=> setEmail(e.target.value)}   required />
              <label htmlFor="email">Email</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>
          

        {props.isEdit?
          <div/>
          :
          <div className="row g-2">
	          <div className="col-md">
	            <div className="form-floating">
	              <input type="email" className="form-control" name="email_confirmation" id="email_confirmation" placeholder="name@example.com" onChange={(e)=> setEmailConfirmation(e.target.value)} onBlur={handleBlur} required />
	              <label htmlFor="email_confirmation">Confirm Email</label>
	              <div className="invalid-feedback"></div>
	            </div>
	          </div>
	        </div>
        }       

        <div className="row g-2">
          <div className="col-md-6">
            <FloatingSelect data={countryCodes} name='country' required={true} onChange={e => props.setCountry( e.target.value )}
              label='Country Code' value={props.country} placeholder='Select Code' withError='true'/>
          </div>
          <div className="col-md-6">
            <div className="form-floating">
              <input type="tel" defaultValue={props.data.phone_number} className="form-control" name="phone_number" id="phone_number" placeholder="+123214124412" required />
              <label htmlFor="phone_number">Phone Number</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
        </div>

      </form>
    </Modal>
  );
}

export default SetupUser;
