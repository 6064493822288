import React, { useContext,useState,useEffect } from 'react';
import { Link} from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { store } from '../store.js';
import Modal from './NewModal'


function NavBar() {

  const globalState = useContext(store);
  const { dispatch } = globalState;
  const [ showmodal, setShow ] = useState(false);
  const [ newSetModal, setModal ] = useState(null);

  useEffect(() => {
    if (newSetModal)
      showmodal ? newSetModal.show() : newSetModal.hide()
  }, [newSetModal, showmodal])


  const logoutHandler = () =>{
    dispatch({ type: 'logout', newState: null });
    return false
  }

  if(showmodal == false	)
  {
  	return (
	    <nav className="navbar sticky-top navbar-expand navbar-dark navbar-customclass no-print">
	      <div className="container">
	        <img src={require('./pharmalancers-full-logo.png')} className="img-responsive" style={{width:"15%"}}/>
	        <div className="collapse navbar-collapse" id="navbarTogglerDemo02">
	          {(globalState.state.loggedIn) ? <ul className="navbar-nav ms-auto">
	            <li className="nav-item">
							<div className="nav-link page-title fw-bold cursor_default"><FontAwesomeIcon icon="user" />&nbsp;&nbsp; {globalState.state.firstName} {globalState.state.lastName} </div>
	            </li>
	            {(globalState.state.user_type == "client")?
	            	<li className="nav-item">
  	              <div className="nav-link page-title fw-bold cursor_default"><FontAwesomeIcon icon="building" />&nbsp;&nbsp; {globalState.state.company_name} </div>
  	            </li>
  	            :
  	            null
  	          }
	            <li className="nav-item">
	              <a className="nav-link fw-bold navbar-text" onClick={logoutHandler}><FontAwesomeIcon icon="power-off" />&nbsp;&nbsp;Log out </a>
	            </li>
	          </ul> : <ul className="navbar-nav ms-auto">
	            <li className="nav-item">
	              <Link className="nav-link fw-bold navbar-text" to={"/login"}>Log in</Link>
	            </li>
	            <li className="nav-item">
	              <Link className="nav-link fw-bold navbar-text" onClick={()=>setShow(true)}>Sign up</Link>
	            </li>
	          </ul>}
	        </div>
	        {/*<div>
	        	<Modal setModal={setModal} setShow={setShow} />
	        </div>*/}
	      </div>
	    </nav>
	  );
  }
  else
  {
  	return (
  		<Modal setModal={setModal} setShow={setShow} />
		);
  }
}

export default NavBar;
