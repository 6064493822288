import React, { useContext } from 'react';
import { NavLink } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { store } from '../store.js';

function SideBar() {

  const globalState = useContext(store);
  const { dispatch } = globalState;

  const logoutHandler = () =>{
    dispatch({ type: 'logout', newState: null });
    return false
  }

  return (

    <nav className="col-md-3 col-lg-2 d-md-block bg-light sidebar collapse overflow-auto vh-100">
      <div className="position-sticky pt-3">
        <ul className="nav flex-column mt-4">
          <li className="nav-item">
          	<NavLink activeClassName="nav-link active" className="nav-link" to={"/consultant-dashboard"}>
              <FontAwesomeIcon icon="tachometer-alt" />
              &nbsp;&nbsp;My Dashboard
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/search"}>
              <FontAwesomeIcon icon="address-book" />
              &nbsp;&nbsp;Search Consultants
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/search-with-name"}>
              <FontAwesomeIcon icon="address-book" />
              &nbsp;&nbsp;Consultants By Name
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/email-message"}>
              <FontAwesomeIcon icon="comments" />
              &nbsp;&nbsp;Messages
            </NavLink>
          </li>                    
          <li className="nav-item">
            <a role="button" href="#"  className="nav-link btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#proposals-collapse" aria-expanded="false"><FontAwesomeIcon icon="briefcase" />&nbsp;&nbsp;Proposals </a>
          	<div className="collapse" id="proposals-collapse">
						  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
						    <li className="nav-item">
			            <NavLink activeClassName="nav-link active" className="nav-link" to={"/project-rfp"} >
			              <FontAwesomeIcon icon="briefcase-medical" />
			              &nbsp;&nbsp;Project RFPs
			            </NavLink>
			          </li>				        
						    <li className="nav-item">
			            <NavLink activeClassName="nav-link active" className="nav-link" to={"/proposal-wip"} >
			              <FontAwesomeIcon icon="bookmark" />
			              &nbsp;&nbsp;Proposals WIP
			            </NavLink>
						    </li>
			          <li className="nav-item">
			          	<NavLink activeClassName="nav-link active" className="nav-link" to={"/proposal-submited"} >
			              <FontAwesomeIcon icon="thumbs-up" />
			              &nbsp;&nbsp;Proposals Submitted
			            </NavLink>
			          </li>
						  </ul>
						</div>
          </li>
          <li className="nav-item">
            <a role="button" href="#"  className="nav-link btn-toggle collapsed" data-bs-toggle="collapse" data-bs-target="#project-collapse" aria-expanded="false"><FontAwesomeIcon icon="business-time" />&nbsp;&nbsp;Projects </a>
          	<div className="collapse" id="project-collapse">
						  <ul className="btn-toggle-nav list-unstyled fw-normal pb-1 small ms-3">
						    
						    <li className="nav-item">
			            <NavLink activeClassName="nav-link active" className="nav-link" to={{pathname:"/receive-work-order-list", state:{ copyProjectState: false }}}>
			              <FontAwesomeIcon icon="file-contract" />
			              &nbsp;&nbsp;Work Orders
			            </NavLink>
			          </li>
  					    <li className="nav-item">
						    	<NavLink activeClassName="nav-link active" className="nav-link" to={"/receive-active-project-list"}>
			              <FontAwesomeIcon icon="list" />
			              &nbsp;&nbsp;Active Projects
			            </NavLink>
						    </li>
							    
			          <li className="nav-item">
			            <NavLink activeClassName="nav-link active" className="nav-link" to={"/receive-archived-project-list"}>
			              <FontAwesomeIcon icon="box" />
			              &nbsp;&nbsp;Archived Projects
			            </NavLink>
			          </li>
						  </ul>
						</div>
          </li>
          
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/message-board"}>
              <FontAwesomeIcon icon="copy" />
              &nbsp;&nbsp;Message Board
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/view-consultant-profile"}>
              <FontAwesomeIcon icon="address-card" />
              &nbsp;&nbsp;My Profile
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/account-setting"}>
              <FontAwesomeIcon icon="cog" />
              &nbsp;&nbsp;Account Settings
            </NavLink>
          </li>
          <li className="nav-item">
            <NavLink activeClassName="nav-link active" className="nav-link" to={"/resources"}>
              <FontAwesomeIcon icon="book" />
              &nbsp;&nbsp;Resources
            </NavLink>
          </li>
          <li className="nav-item">
            <a className="nav-link" onClick={logoutHandler}>
              <FontAwesomeIcon icon="power-off" />
              &nbsp;&nbsp;Log out
            </a>
          </li>
        </ul>
      </div>
    </nav>

  );
}

export default SideBar;
