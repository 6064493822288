import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import TermAndConditions from '../../components/TermAndConditions'
import DatePicker from "react-datepicker";
import ViewJob from '../jobs/ViewJob';
import View from '../projects/View';






const WorkOrderPreview = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
	const [ workOrder, setWorkOrder ] = useState({})
	const [ userData, setUserData ] = useState({})
	const [ expRoles, setExpRoles ] = useState([])
	const [ projects, setProjects ] = useState([])
	const [ companyData, setCompanyData ] = useState({})
  const [ projectDescription, setProjectDescription] = useState('')
  const [ milestones, setMilestones] = useState([{id:0,milestone:'',date: null}])
  const [ invoice_Milestones, setInvoice_Milestones] = useState([{id:0,milestone:'',date: null}])
  const [startDate, setStartDate] = useState(null)
  const [showCompanyProfile, setShowCompanyProfile] = useState( false );
	const [modalCompanyProfile, setModalCompanyProfile] = useState(null);
	const [fetchEvent, setFetchEvent] = useState(false);
	const [showClientModal, setShowClientModal] = useState( false );
	const [modalClientPublicProfile, setModalClientPublicProfile] = useState(null);
	const [showProjectDetails, setShowProjectDetails] = useState( false );
	const [modalProjectDetails, setProjectDetailsModal] = useState(null);
	const [incompleteInvoices, setIncompleteInvoices] = useState( false );



	let history = useHistory()

	const [modal, setModal] = useState(null);

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	const fetchData = async () => {
	  const response = await fetch('/api/v1/work_orders/' + my_data.work_order_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()
	  console.log("responseJson")
	  console.log(responseJson)
	  if (response.ok) {
	    setProjectDetails(responseJson.project_detail)
		  setWorkOrder(responseJson)
		  setUserData(responseJson.user)
		  setMilestones(responseJson.work_order_milestones)
		  setInvoice_Milestones(responseJson.work_order_invoice_schedules)

		  var total_percentage = 0;
	    const newData = responseJson.work_order_invoice_schedules.map( data => {
	    	if(data.amount_percent != '')
	    	{
	    		var percentage = parseInt(data.amount_percent)
		    	total_percentage = total_percentage + percentage ;
	    	}

	    });

	    if(total_percentage < 100)
	    {
	    	setIncompleteInvoices(true);
	    }

	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	  scrollToUp();
	}, []);

	useEffect(() => {
    if (modalCompanyProfile)
      showCompanyProfile ? modalCompanyProfile.show() : modalCompanyProfile.hide()
  }, [showCompanyProfile, modalCompanyProfile])

  useEffect(() => {
    if (modalClientPublicProfile)
      showClientModal ? modalClientPublicProfile.show() : modalClientPublicProfile.hide()
  }, [modalClientPublicProfile, showClientModal])

  useEffect(() => {
    if (modalProjectDetails)
      showProjectDetails ? modalProjectDetails.show() : modalProjectDetails.hide()
  }, [modalProjectDetails, showProjectDetails])

	function handleChange(newValue) {
    setProjectDescription(newValue);
  }

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );

  const addMilestones = () => {
   	let count = milestones.length;
    let id = milestones[count-1].id + 1;
    setMilestones( [...milestones, {id: id,milestone:'',date: null} ]  )
  }

  const removeMilestones = (id) => {
    const new_milestones =  milestones.filter( data => data.id != id )
    setMilestones( new_milestones )
  }

  const Milestones = milestones.map((data,index)=>
  {
  	return(
			<tr className="d-flex" key={index}>
	      <th className="col-md-1">{index+1}</th>
	      <td className="col-md-9">{data.name}</td>
	      <td className="col-md-2">{data.milestone_date}</td>
      </tr>

  	);
  })

  const InvoiceMilestones = invoice_Milestones.map((data,index)=>
  {
  	return(
			<tr key={index}>
	      <th>{index+1}</th>
	      <td>{data.name}</td>
	      <td>{data.milestone_date}</td>
	      {
	      	projectDetails.fees_type == 'time-based' ? <td>{workOrder.rate_per_hour}</td> : <td>{data.amount_percent}</td>
	      }
	      {
	      	projectDetails.fees_type == 'time-based' ? <td>{projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</td> : <td>{(Math.round(data.amount * 100) / 100).toFixed(2)} {projectDetails.currency == 'Other' ? projectDetails.other_currency : projectDetails.currency}</td>
	      }
	      
      </tr>

  	);
  })



  const listItems = expRoles.map((data, index) =>{
    return <ViewJob display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} proposalView={true} />
  });

  const project = projects.map((data, index) =>{
    return <View display_public_profile={props.display_public_profile} data={data} key={data.id} setFetch={props.setFetch} proposalView={true} />
	});

  const WorkOrderConfirmation = () => {
  	if(incompleteInvoices)
  	{
  		showPopupError();
  	}
  	else
  	{
  		confirmAlert({
		  	customUI: ({ onClose }) => {
			    return (
			      <div className='p-5 custom-ui border border-primary rounded'>

			        <h5>Confirm Submit Work Order?</h5>
			        <div className="row g-2 mt-3">
				        <div className="col-md g-2 text-start">
				        	<button className="btn btn-danger" onClick={onClose}>No</button>
				        </div>
				        <div className="col-md g-2 text-end">
					        <button className="btn btn-primary"
					          onClick={() => {
					            submitWorkOrder();
					            onClose();
					          }}
					        >
					          Yes
					        </button>
					      </div>
					    </div>
			      </div>
			    );
			  }
			});
  	}
	  
	};

	const deleteConfirmation= () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Delete This Work Order?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            deleteWorkOrder();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const cancelConfirmation= () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Cancel This Work Order?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            cancelWorkOrder();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};

	const showPopupError= () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Your invoicing schedule is not complete.</h5>
		        <div className="row g-2 mt-3">
			        
			        <div className="col-md g-2 text-center">
				        <button className="btn btn-primary"
				          onClick={() => {				           
				            onClose();
				          }}
				        >
				          Ok
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};



	const submitWorkOrder = async () => {		
		const response = await fetch('/api/v1/submit_work_order/'+ my_data.work_order_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'put',
	  })

	  const responseJson = await response.json()

	  if (response.ok) {
	    history.push("/work-order-list");
	  } else {
	    postProposalError();
	    return false
	  }			

	}

	const deleteWorkOrder = async () => {

	  const response = await fetch('/api/v1/work_orders/'+ workOrder.id , {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'delete',
	  })

	  const responseJson = await response.json()

	  if (response.ok) {
	    history.push("/work-order-list");
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	const cancelWorkOrder = async () => {

	  const response = await fetch('/api/v1/cancel_work_order/'+ workOrder.id , {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'delete',
	  })

	  const responseJson = await response.json()

	  if (response.ok) {
	    history.push("/work-order-list");
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	const postProposalError = () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui-error border border-primary rounded '>

		        <h5>Some data is missing, please check all fields completed correctly or not?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            history.push({pathname:"/edit-proposal", state:{ project_details_id: projectDetails.id, proposal_id:workOrder.id}});
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};


  return (
    <div className="container mb-3">
			<img src={require('./pharmalancers-full-logo.png')} className="img-responsive print-only" style={{width:"15%"}}/>
    	<div className="text-center mt-2">
      	<h2>Work Order Preview</h2>
      </div>
      <div className="row g-2 mt-2">
      	<div className="col-md-10">
      		<div className="row g-0 text-start">
	      		<h4 className="col-md-5"><b>Project Title: </b></h4>
	      		<h4 className="col-md-7  text-start"><b>{projectDetails.title} </b></h4>
	      	</div>
      		<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-5"><b>Company Name (“Client”): </b></h5>
	      		<h5 className="col-md-7">{projectDetails.company_name}</h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-5"><b>Consultant Name (“Consultant”): </b></h5>
	      		<h5 className="col-md-7">{userData.first_name} {userData.last_name}</h5>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-5"><b>Client User Name: </b></h5>
	      		<h5 className="col-md-7">{projectDetails.creator}</h5>
	      	</div>	      	
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-5"><b>Work Order Date: </b></h5>
	      		<h5 className="col-md-7">{workOrder.update_date}</h5>
	      	</div>
      	</div>
      	<div className="col-md-2">
      		<div className="text-end">
	          <button  type="button" className="btn btn-primary btn-block ml-auto no-print" onClick={() => window.print()} >Print</button>
	        </div>
      	</div>
    	</div>
    	<div className="col-md text-start mt-3 mb-4 no-print">
        <div className="alert alert-info">
          <ul>
            This Work Order is an Agreement between the Client and the Consultant for the 
						delivery and completion of the Project of which the details are specified in this 
						Work Order. The Work Order is created under and is subject to the terms listed 
						below. It is noted that there are separate Master Agreements between the Client 
						and Pharmalancers Ltd and between the Consultant and Pharmalancers Ltd for 
						the usage of this online platform. The Work Order Agreement will be deemed 
						complete when the Client clicks on the ‘Submit Work Order’ button and then the 
						Consultant accepts it by clicking on the ‘Accept Work Order’ button at the 
						bottom of their respective Work Order screens.
          </ul>
        </div>
      </div>
    	<div className="card mt-4">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Scope</b></h4>
        <div className="card-body text-start">
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Objectives : </strong>
            <h6 className="col-md-9 ">{ReactHtmlParser(workOrder.project_objective)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project In Scope : </strong>
            <h6 className="col-md-8">{ReactHtmlParser(workOrder.project_in_scope)}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Project Out Of Scope : </strong>
            <h6 className="col-md-9">{ReactHtmlParser(workOrder.project_out_scope)}</h6>
          </div>
        </div>
      </div>

      <div className="card mt-3 pagebreak">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Timelines</b></h4>
        <div className="card-body text-start">
        	<h5><b>Project Dates : </b></h5>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Proposed Start Date : </strong>
            <h6 className="col-md-9">{workOrder.proposed_start_date}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Comment : </strong>
            <h6 className="col-md-9">{workOrder.comment_start_date ? workOrder.comment_start_date : 'None'}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Proposed Duration : </strong>
            <h6 className="col-md-9">{workOrder.proposed_estimated_duration} {workOrder.proposed_estimated_duration ? projectDetails.estimated_duration_category : ''}</h6>
          </div>
          <div className="row g-0 text-start mt-2">
            <strong className="col-md-3">Comment : </strong>
            <h6 className="col-md-9">{workOrder.comment_estimated_duration ? workOrder.comment_estimated_duration : 'None'}</h6>
          </div>
        </div>
				<div className="col-md-10 card-body text-start">
        	<h5><b>Project Milestones : </b></h5>
        	<table className="table table-bordered table-striped table-sm text-start">
					  <thead>
					    <tr className="d-flex">
					      <th className="col-md-1">Sr.No</th>
					      <th className="col-md-9">Milestone Name</th>
					      <th className="col-md-2">Date</th>
					    </tr>
					  </thead>
					  <tbody>
				    	{Milestones}
					  </tbody>
					</table>
        </div>
        <div className="card-body text-start">
        	<h5><b>Project Logistics : </b></h5>
        	<table class="table table-borderless">					  
					  <tbody>
					    <tr className="d-flex">
					      <td className="col-md-4"><b>Consultant Location:</b> {projectDetails.display_consultant_country}</td>					      
					      <td className="col-md-4"><b>Agree Consultant Country:</b> {workOrder.agree_consultant_country ? 'Yes' : 'No'}</td>					      
					      <td className="col-md-4"><b>Comment:</b> {workOrder.comment_consultant_country}</td>
					    </tr>
					    <tr className="d-flex">
					      <td className="col-md-4"><b>Project Location:</b> {projectDetails.location_type_dv}</td>					      
					      <td className="col-md-4"><b>Agree Project Location:</b> {workOrder.agree_location_type ? 'Yes' : 'No'}</td>					      
					      <td className="col-md-4"><b>Comment:</b> {workOrder.comment_location_type}</td>
					    </tr>
					    {
					    	projectDetails.location_type == 'on-site' || projectDetails.location_type == 'mix' ?
					    	<tr className="d-flex">
						      <td className="col-md-4"></td>					      
						      <td className="col-md-4"><b>On-site City:</b> {projectDetails.onsite_city}</td>					      
						      <td className="col-md-4"><b>Comment:</b> {workOrder.comment_onsite_location}</td>
						    </tr>
						    :
						    null
					    }
					    
					    {
					    	projectDetails.location_type == 'on-site' || projectDetails.location_type == 'mix' ?
					    	<tr className="d-flex">
						      <td className="col-md-4"></td>					      
						      <td className="col-md-4"><b>On-site Country:</b> {projectDetails.display_onsite_country}</td>					      
						      <td className="col-md-4"></td>
						    </tr>
						    :
						    null
					    }					    				    
					  </tbody>
					</table>

        </div>
      </div>
      <div className="card mt-3 pagebreak">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Project Fees*</b></h4>
        <div className="card-body text-start">
	        {
	        	workOrder.total_fees != null &&  projectDetails.currency == 'Other' ?
	        	<div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Total Fees : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.total_fees} {projectDetails.other_currency}</h6>
	          </div>
	          :workOrder.total_fees != null && projectDetails.currency != 'Other'?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Total Fees : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.total_fees} {projectDetails.currency} </h6>
	          </div>
	          :
	          null
	        }

	        {
	        	workOrder.rate_per_hour != null &&  projectDetails.currency == 'Other' ?
	        	<div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Rate/Hour : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.rate_per_hour} {projectDetails.other_currency}</h6>
	          </div>
	          :workOrder.rate_per_hour != null &&  projectDetails.currency != 'Other' ?
	          <div className="row g-0 text-start mt-2">
	            <strong className="col-md-3">Rate/Hour : </strong>
	            <h6 className="row g-0 col-md-9">{workOrder.rate_per_hour} {projectDetails.currency} </h6>
	          </div>
	          :
	          null
	        }

          {
	          	workOrder.effort_frequency_type != '' ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Effort Frequency Type : </strong>
		            <h6 className="col-md-9">{workOrder.effort_frequency_type}</h6>
		          </div>
		          :
		          null
	          }
	          {
	          	workOrder.effort_frequency != null ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Effort Frequency : </strong>
		            <h6 className="col-md-9">{workOrder.effort_frequency}</h6>
		          </div>
		          :
		          null
	          }

	          {
	          	workOrder.total_effort_type != '' ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Effort Type : </strong>
		            <h6 className="col-md-9">{workOrder.total_effort_type}</h6>
		          </div>
		          :
		          null

	          }

	          {
	          	workOrder.total_effort != null ?
	          	<div className="row g-0 text-start mt-2">
		            <strong className="col-md-3">Total Effort : </strong>
		            <h6 className="col-md-9">{workOrder.total_effort}</h6>
		          </div>
		          :
		          null
	          }
        </div>
      </div>
      {
      	projectDetails.fees_type == "time-based" ?
      	<div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Invoicing Schedule</b></h4>
	        <div className="card-body text-start">
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr>
						      <th scope="col">Sr.No</th>
						      <th scope="col">Invoice Milestone</th>
						      <th scope="col">Date</th>
						      <th scope="col">Rate/Hour*</th>
						      <th scope="col">Currency</th>
						    </tr>
						  </thead>
						  <tbody>
						  {InvoiceMilestones}
						  </tbody>
						</table>
						<div className="alert alert-info">
		          <h6><b>
		          	* Consultant Fees only, does not include Pharmalancers Fees or any applicable taxes.
		          </b></h6>
		        </div>
	        </div>	        
	      </div>
	      :
	      <div className="card mt-3 pagebreak">
	        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Invoicing Schedule</b></h4>
	        <div className="card-body text-start">
	        	<table className="table table-bordered table-striped table-sm text-start">
						  <thead>
						    <tr>
						      <th scope="col">Sr.No</th>
						      <th scope="col">Invoice Milestone</th>
						      <th scope="col">Date</th>
						      <th scope="col">% of total</th>
						      <th scope="col">Amount*</th>
						    </tr>
						  </thead>
						  <tbody>
						  {InvoiceMilestones}
						  </tbody>
						</table>
						<div className="alert alert-info">
		          <h6><b>
		          	* Consultant Fees only, does not include Pharmalancers Fees or any applicable taxes.
		          </b></h6>
		        </div>
	        </div>
	       
	      </div>

      }

      <div className="card mt-3 pagebreak">
        <h4 className="card-header d-flex align-items-center justify-content-between"><b>Terms of the Work Order</b></h4>
        <div className="card-body text-start">
        	<TermAndConditions/>
        	{
        		workOrder.additional_terms != null ?
        		<div>
		        	<h5><b>Additional Terms</b></h5>
		        	<div>
				      	{ReactHtmlParser(workOrder.additional_terms)}
				    	</div>       
		        </div>
		        :
		        null
        	}
        	
        </div>               
      </div>     

      <div className="row g-3 mt-3 mb-3 justify-content-between no-print">
    		<div className="col-md text-start">
          <button  type="button"  className="btn btn-primary btn-block ml-auto" onClick={()=> history.push("/work-order-list")}>Back</button>
        </div>
        {
        	workOrder.status == 'wip' ?
        	<div className="col-md text-center">
	          <button  type="button"  className="btn btn-danger btn-block ml-auto" onClick={() => deleteConfirmation()} >Delete</button>
	        </div>
	        :
	        null
        }
        {
        	workOrder.status == 'wip' ?
        	<div className="col-md text-end">
	          <button  type="submit" className="btn btn-primary btn-block ml-auto " onClick={()=> WorkOrderConfirmation()}>Submit Work Order</button>
	        </div>
	        :workOrder.status == 'submitted' ?
	        <div className="col-md text-end">
	          <button  type="button"  className="btn btn-danger btn-block ml-auto" onClick={() => cancelConfirmation()} >Cancel</button>
	        </div>
	        :
	        null
        }
        
    		
      </div>
    </div>
  );
}

export default WorkOrderPreview;
