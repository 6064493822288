import React, { useContext, useEffect, useState , forwardRef} from 'react';

import { store } from '../../store'
import DatePicker from "react-datepicker";


const EducationComp = (props) => {

  
 

  const [ errors, setErrors ] = useState({})
  
 
	const removeEducation = (id) => {
    const newServices = props.value.filter(data => data.id != id)
    props.setValue(newServices);
  }

  const handleQualificationChange = (event, id )=> {
    const newData = props.value.map( (data,index) => {
     	if (id == data.id)
        data.qualification = event.target.value
      	if(index == 0)
      	{
      		data.category = 'main'
      	}
      	else
      	{
      		data.category = ''
      	}
      return data
    });
    props.setValue( newData )
    //props.updateSelectedServices()
  }

  const handleCollegeChange = (event, id )=> {
    const newData = props.value.map( data => {
      if (id == data.id)
        data.institution = event.target.value
      return data
    });
    props.setValue( newData )
    //props.updateSelectedServices()
  }

  const handleStartdateChange = (date, id )=> {
  
    const newData = props.value.map( data => {    	
      if (id == data.id)
        data.start_date = date
      if (id == data.id)
        data.start_formatted = date
      return data
    });
    props.setValue( newData )
    //props.updateSelectedServices()
  }

  const handleEnddateChange = (date, id )=> {

    const newData = props.value.map( data => {    	
      if (id == data.id)
     		data.end_date = date
     	if (id == data.id)     	
				data.end_formatted = date     	
      return data
    });
    props.setValue( newData )
    //props.updateSelectedServices()
  }

   const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text"  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );  

 
  const addEducation = () => {
   	let count = props.value.length;
    let id = props.value[count-1].id + 1;
    props.setValue( [...props.value, {id:id, end_formatted:null, start_formatted:null, start_date: '', end_date: '', qualification: '', institution: '' , category:''} ]  )
    
  }

  const education_comp = props.value.map( (data,index) => {
  	
    return(
      <div className="row g-2" key={data.id} >
	        <div className="col-md-3">
	          <div className="form-floating">
	            <input type="text" className="form-control" name="main_qualification" id="main_qualification"
              placeholder={index == 0 ? "Main Qualification" : "Qualification"} defaultValue={data.qualification} required 
	              onInput={e =>handleQualificationChange(e,data.id)} required/>
              {index == 0 ?
	            	<label htmlFor="qualification">Main Qualification</label>
	            	:
	            	<label htmlFor="qualification">Qualification</label>
	            }
	            <div className="invalid-feedback"></div>
	          </div>
	        </div>
	        <div className="col-md-3">
	          <div className="form-floating">
	            <input type="text" className="form-control" name="main_institution" id="main_institution" 
              placeholder="College/University" defaultValue={data.institution} required 
	               onInput={e =>handleCollegeChange(e,data.id)} required/>
	            <label htmlFor="institution">College/University</label>
	            <div className="invalid-feedback"></div>
	          </div>
	        </div>
          <div className="col-md-2">
            < DatePicker
              selected={data.start_date}
              onChange={date => handleStartdateChange(date, data.id)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              customInput={<CustomInput identify='start_formatted' label='From' />}
            />
          </div>

          <div className="col-md-2">
            < DatePicker
              selected={data.end_date}
              onChange={date => handleEnddateChange(date, data.id)}
              dateFormat="MMMM yyyy"
              maxDate={new Date()}
              showMonthYearPicker
              customInput={<CustomInput identify='end_formatted' label='To' />}
            />
          </div>
					{index != 0 ?
						<div className="col d-flex align-items-center justify-content-center">
						  <button type="button" onClick={() => removeEducation(data.id)}
						    className="btn btn-danger btn-block">Remove</button>
						</div>
						:
						null
					}
	      </div>
	    )
  });

  const addButton = () => {
    //if ((props.type == 'Primary' && props.value.length >= 6) || (props.type == 'Secondary' && props.value.length >= 3)) { return '' }
    if(props.value.length < 6)
    {
    	return(
	      <div className="col d-flex">
	        <button type="button" onClick={addEducation} className="btn btn-primary btn-block">Add</button>
	      </div>
	    );
    }    
  }

  return (
    <>
      {education_comp}
      <div className="row g-2">
        {addButton()}
      </div>
    </>
  );
}

export default EducationComp;
