import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";




const RelevantExperience = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();
	const { dispatch } = globalState;
	const [ jobRoles, setJobRoles ] = useState([])
	const [ projects, setProjects ] = useState([])
	const [ projectIDs, setProjectIds ] = useState([])
	const [ projectSelected, setProjectSelected ] = useState([])
	const [ jobRolesIds, setJobRolesIds ] = useState([])
	const [ jobRolesSelected, setJobRolesSelected ] = useState([])
	const [ dataLoad, setDataLoad ] = useState(false)
	const [ relevant_exp_summary, setRelevant_exp_summary ] = useState('')


	let history = useHistory()

	const [modal, setModal] = useState(null);

	const fetchData = async () => {	

		const response = await fetch('/api/v1/relevant_experience/'+props.proposalId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })

    const responseJson = await response.json()

    console.log("props.proposalDetails")
    console.log(props.proposalDetails)
    
	  if (response.ok) {
			setJobRoles(responseJson.job_roles)
			setProjects(responseJson.projects)
			setRelevant_exp_summary(props.proposalDetails.relevant_exp_summary)
			if(!dataLoad)
			{
				setJobRolesIds(responseJson.selected_job_roles)
				setProjectIds(responseJson.selected_projects)
				if(props.proposalDetails.relevant_project != null)
				{
					setProjectSelected(props.proposalDetails.relevant_project)
				}
				if(props.proposalDetails.relevant_job_role != null)
				{
					setJobRolesSelected(props.proposalDetails.relevant_job_role)
				}				
			}			
			setDataLoad(true)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }


	}

	const handleFormSubmit = async (event) => {
    event.preventDefault();

    const formData = {
			proposal:
			{
				relevant_project: projectSelected,
				relevant_job_role: jobRolesSelected,
				relevant_exp_summary: relevant_exp_summary
			}
    }

    console.log(formData)

		const response = await fetch( '/api/v1/proposals/' + props.proposalId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()


    if (response.ok) {
    	props.setActiveId(props.activeId + 1)
    	props.setProposalId(responseJson.id)
    	props.setProposalDetails(responseJson)
    }
  }

	

	useEffect( () => {
	  fetchData();
	}, []);




  const handleProject = (data) => {
    if(projectIDs.includes(data.id))
  	{
  		var projectIds_array = projectIDs
			var projectSelected_array = projectSelected

			for( var i = 0; i < projectIds_array.length; i++){
      	if ( projectIds_array[i] === data.id) {
          projectIds_array.splice(i, 1);
					projectSelected_array.splice(i, 1);
        }
        setProjectIds(projectIds_array)
				setProjectSelected(projectSelected_array)
    	}
  	}
  	else
  	{
  		var projectIds_array = projectIDs
			var projectSelected_array = projectSelected
  		projectIds_array.push(data.id)
			projectSelected_array.push(data)
  		setProjectIds(projectIds_array)
			setProjectSelected(projectSelected_array)
  	}
  	
  	fetchData();
  }

  const handleRolesChange = (data) => {
    if(jobRolesIds.includes(data.id))
  	{
  		var jobRolesIds_array = jobRolesIds
			var jobRolesSelected_array = jobRolesSelected

			for( var i = 0; i < jobRolesIds_array.length; i++){
      	if ( jobRolesIds_array[i] === data.id) {
          jobRolesIds_array.splice(i, 1);
					jobRolesSelected_array.splice(i, 1);
        }
        setJobRolesIds(jobRolesIds_array)
				setJobRolesSelected(jobRolesSelected_array)
    	}
  	}
  	else
  	{
  		var jobRolesIds_array = jobRolesIds
			var jobRolesSelected_array = jobRolesSelected
  		jobRolesIds_array.push(data.id)
			jobRolesSelected_array.push(data)
  		setJobRolesIds(jobRolesIds_array);
			setJobRolesSelected(jobRolesSelected_array);
  	}
  	
  	fetchData();
  }

 function handleValueChange(newValue) {
    setRelevant_exp_summary(newValue);
  }

  return (
    <div className="container">

	    <form onSubmit={handleFormSubmit} className="row g-2 justify-content-center mt-3 mb-3">

	      <div className="col-md-12 text-start mt-2">
					<label className="card-title  "><b>Experience (Roles)</b></label>
					{
						jobRoles.map((data,index)=>
						{
							return(
								<div key={data.id} className="d-flex ">
							  	<div className="col-md-7">
								  	<label  htmlFor="is_estimation_required">{data.title} at {data.company}</label>
								  </div>
								  <div className="col-md-4 form-check ">
								  	<input className="form-check-input" type="checkbox" checked={jobRolesIds.includes(data.id)} onChange={()=>handleRolesChange(data)}  id={data.id}/>
								  </div>
								</div>
							)
						})
					}
				</div>

				<div className="col-md-12 text-start mt-3 mb-3">
					<label className="card-title  "><b>Projects</b></label>
					{
						projects.map((data,index)=>
						{
							return(
								<div key={data.id} className="d-flex ">
							  	<div className="col-md-7">
								  	<label  htmlFor="is_estimation_required">{data.title} at {data.client_name}</label>
								  </div>
								  <div className="col-md-4 form-check ">
								  	<input className="form-check-input" type="checkbox" checked={projectIDs.includes(data.id)} onChange={()=>handleProject(data)} id={data.id}/>
								  </div>
								</div>
							)
						})
					}
				</div>

				<div className="col-md-12 mt-2">
          <div className="text-start ">
            <label  htmlFor="project_in_scope">Summary of Relevant Experience</label>
	        </div>
		      <div className="text-start">
		      	<CustomEditor placeholder={"Relevant Experience Summary"} className="form-control" type="relevant_exp_summary" id="relevant_exp_summary" value={relevant_exp_summary} onChange={handleValueChange}/>
		      </div>        
	      </div>

				<div className="row g-2">
	        <div className="col-md text-end">
	          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
	        </div>
	      </div>
    	</form>

    </div>
  );
}

export default RelevantExperience;
