import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'



const ProposalSubmitted = (props) => {

	const globalState = useContext(store);
	const { dispatch } = globalState;
	let history = useHistory()


  const [ proposalList, setProposalList] = useState([])

	const fetchData = async () => {

	  const response = await fetch('/api/v1/proposals_submitted', {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()
	  console.log(responseJson)

	  if (response.ok) {
	    setProposalList(responseJson)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }	
	}

	useEffect( () => {
	  fetchData();
	}, []);

	

	const proposal_list = proposalList.map( propsal => {		
		return (
    	<tr key={propsal.id}>
	      <td><Link className="link" to={{pathname:"/proposal-preview", state:{ project_details_id: propsal.project_detail.id, proposal_id:propsal.id}}}  >{propsal.project_detail.title}</Link></td>
	      <td>{propsal.project_detail.company_name}</td>
	      <td>{propsal.project_detail.creator}</td>
	      <td>{propsal.project_detail.update_date}</td>
	      <td>{propsal.update_date}</td>
	      <td>{propsal.status== 'posted' ? 'Submitted' : propsal.status.charAt(0).toUpperCase() + propsal.status.slice(1)}</td>
	    </tr>
  	)		

  })

  return (
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="bookmark" /> Proposals Submitted</h2>
        </div>
    	</div>
    	<div className="col-md mt-4">
    		<table className="table table-bordered table-striped table-sm text-start">
				  <thead>
				    <tr>
				      <th scope="col">Project Title</th>
				      <th scope="col">Company</th>
				      <th scope="col">Posted by</th>
				      <th scope="col">Date Posted</th>
				      <th scope="col">Date Submitted</th>				      				      
				      <th scope="col">Status</th>				      				      
				    </tr>
				  </thead>
				  <tbody>
			    	{proposal_list}
				  </tbody>
				</table>

    	</div>
    </div>
  );
}

export default ProposalSubmitted;
