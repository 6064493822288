import React from 'react';

const Page404 = (props) => {

  return (
    <div>
      <h1>Page NOT Found</h1>
    </div>
  );
}

export default Page404;
