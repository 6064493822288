import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';

import Errors from '../../components/Errors';
import { store } from '../../store';

const Login = (props) => {

  const [ message, setMessage ] = useState('');
  const globalState = useContext(store);
  let history = useHistory();
  const { dispatch } = globalState;


  const handleLoginSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const formData = { user:{
      password: event.target.password.value,
      password_confirmation: event.target.confirmPassword.value
    }}
    const response = await fetch('/api/v1/password-update', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const userData = await response.json()

    if (response.ok) {
    	if(userData.user_type == 'client' && userData.company.completed)
    	{
    		dispatch({ type: 'completed', user_type: userData.user_type});
    	}
      history.push('/congratulations');
    } else {
      if (userData.error)
        setMessage( userData.errors[0] )
    }
  }

  return (
    <div className="auth-wrapper">
      <div className="auth-inner">
        <form onSubmit={handleLoginSubmit} className="row g-2">
          <h3>Create new password</h3>
          <Errors message={message} />
          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="password" className="form-control" name="password" id="password" placeholder="New Password" required />
                <label htmlFor="password">New password</label>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <div className="form-floating">
                <input type="password" className="form-control" name="confirmPassword" id="confirmPassword" placeholder="Confirm password" required />
                <label htmlFor="confirmPassword">Confirm password</label>
              </div>
            </div>
          </div>

          <div className="row g-2">
            <div className="col-md">
              <button type="submit" className="btn btn-primary btn-block w-100">Save</button>
            </div>
          </div>

        </form>
      </div>
    </div>
  );
}

export default Login;
