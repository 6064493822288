import React, { useState, useEffect, useContext } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'



const WorkOrderList = (props) => {

	const globalState = useContext(store);
	const { dispatch } = globalState;
	let history = useHistory()


  const [ workOrderList, setWorkOrderList] = useState([])

	const fetchData = async () => {

	  const response = await fetch('/api/v1/client_work_orders', {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  if (response.ok) {
	    setWorkOrderList(responseJson)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	useEffect( () => {
	  fetchData();
	}, []);



	const WorkOrderlist = workOrderList.map( workOrder => {		
		return (
    	<tr key={workOrder.id}>
	      <td><Link className="link" to={{pathname:"/work-order-preview", state:{ work_order_id: workOrder.id, workOrder:workOrder, }}}>{workOrder.project_detail.title}</Link></td>
	      <td>{workOrder.project_detail.creator}</td>
	      <td>{workOrder.user.first_name} {workOrder.user.last_name}</td>
	      <td>{workOrder.update_date}</td>
	      {workOrder.status == 'wip' ?
	      	<td>{workOrder.status.toUpperCase()}</td>
	      	:
	      	<td>{workOrder.status.charAt(0).toUpperCase() + workOrder.status.slice(1)}</td>
	      }
	      <td>{workOrder.status == 'wip' ?
	      	<Link className="link" to={{pathname:"/work-order", state:{ work_order_id: workOrder.id, workOrder:workOrder, }}}>Edit</Link>
	      	:
	      	null
	      }</td>
	    </tr>
  	)	

  })

  return (
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="file-contract" /> Work  Orders</h2>
        </div>
    	</div>
    	<div className="col-md mt-4">
    		<table className="table table-bordered table-striped table-sm text-start">
				  <thead>
				    <tr>
				      <th scope="col">Project Title/ Work Order</th>
				      <th scope="col">Project Posted by</th>
				      <th scope="col">Consultant Selected</th>
				      <th scope="col">Date of Work Order</th>
				      <th scope="col">Work Order Status</th>
				      <th scope="col"></th>

				    </tr>
				  </thead>
				  <tbody>
			    	{WorkOrderlist}
				  </tbody>
				</table>

    	</div>
    </div>
  );
}

export default WorkOrderList;
