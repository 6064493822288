import React, { useContext, useEffect, useState, forwardRef, useRef } from 'react';
import { store } from '../../store'
import Select from 'react-select';
import CreatableSelect from 'react-select/creatable';
import FloatingSelect from '../../components/FloatingSelect';
import DatePicker from "react-datepicker";


const ProjectLogistics = (props) => {

  const globalState = useContext(store);
  const [countryCodes, setCountryCodes] = useState([])
  const [onsiteCity, setOnsiteCity] = useState('')
  const [country, setCountry] = useState('')
  const [proposalDate, setProposalDate] = useState(null)
  const [selectionDate, setSelectionDate] = useState(null)
  const [startDate, setStartDate] = useState(null)
  const [durationType, setDurationType] = useState(null)
  const [durationValue, setDuration] = useState(null)
  const [projectLocation, setProjectLocation] = useState(null)
  const [consultantCountry, setConsultantCountry] = useState('')
  const [dateError, setDateError] = useState('')
  const [ options, setOptions ] = useState({
    therapeutic: [],
    product: [],
    service: []
  })

  const formEl = useRef();
  const formId = `project_logistics`

   const project_location = [
    { value: 'on-site', text: 'Fully On-site' },
    { value: 'mix', text: 'Mix of on-site/remote' },
    { value: 'remote', text: 'Fully Remote' },
    { value: 'no-pref', text: 'No preference' },
  ]

  const duration_type = [
    { value: 'months', text: 'Months' },
    { value: 'weeks', text: 'Weeks' },   
    { value: 'days', text: 'Days' },   
  ]


 const fetchData = async () => {
 		if(!props.create_project)
 		{
 			setProjectLocation(props.projectDetails.location_type)
 			
 			if(props.projectDetails.start_date != null)
 			{
 				setStartDate(new Date(props.projectDetails.start_date))
 			}
 			if(props.projectDetails.selection_date != null)
 			{
 				setSelectionDate(new Date(props.projectDetails.selection_date))
 			}
 			if(props.projectDetails.proposal_due_date != null)
 			{
 				setProposalDate(new Date(props.projectDetails.proposal_due_date))
 			}	
 			setCountry(props.projectDetails.onsite_country)
 			setOnsiteCity(props.projectDetails.onsite_city)
 			setDurationType(props.projectDetails.estimated_duration_category)
 			setDuration(props.projectDetails.estimated_duration)
 			setConsultantCountry(props.projectDetails.consultant_country)

 		}
 		else if(props.create_project && globalState.state.project_id != undefined)
 		{
 			setProjectLocation(props.copyProjectDetails.location_type)
 			if(props.copyProjectDetails.start_date != null)
 			{
 				setStartDate(new Date(props.copyProjectDetails.start_date))
 			}
 			if(props.copyProjectDetails.selection_date != null)
 			{
 				setSelectionDate(new Date(props.copyProjectDetails.selection_date))
 			}
 			if(props.copyProjectDetails.proposal_due_date != null)
 			{
 				setProposalDate(new Date(props.copyProjectDetails.proposal_due_date))
 			}			
 			setCountry(props.copyProjectDetails.onsite_country)
 			setOnsiteCity(props.copyProjectDetails.onsite_city)
 			setDurationType(props.copyProjectDetails.estimated_duration_category)
 			setDuration(props.copyProjectDetails.estimated_duration)
 			setConsultantCountry(props.copyProjectDetails.consultant_country)
 		}
 		else
 		{
 			setProjectLocation(props.projectDetails.location_type)
 			if(props.projectDetails.start_date != null)
 			{
 				setStartDate(new Date(props.projectDetails.start_date))
 			}
 			if(props.projectDetails.selection_date != null)
 			{
 				setSelectionDate(new Date(props.projectDetails.selection_date))
 			}
 			if(props.projectDetails.proposal_due_date != null)
 			{
 				setProposalDate(new Date(props.projectDetails.proposal_due_date))
 			}			
 			setCountry(props.projectDetails.onsite_country)
 			setOnsiteCity(props.projectDetails.onsite_city)
 			setDurationType(props.projectDetails.estimated_duration_category)
 			setDuration(props.projectDetails.estimated_duration)
 			setConsultantCountry(props.projectDetails.consultant_country)
 		}

    const response = await fetch('/api/v1/country_list', {
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'get'
    })
    const responseJson = await response.json()
    console.log(responseJson)
    if (response.ok) {
      setCountryCodes( responseJson.country_codes )
    } else {
      alert.show("Server is down.")
      return false
    }    
  }

  useEffect( () => {
    fetchData();
  }, []);

  const components = {
    DropdownIndicator: null,
  };

  const handleFormSubmit = async (event) => {
    event.preventDefault();
      
    const formData = {
      project_detail:
			{
				consultant_country: event.target.consultant_country.value,
				location_type: event.target.location_type.value,
				onsite_city: onsiteCity,
				onsite_country: country,
				proposal_due_date: event.target.proposal_due_date.value,
				selection_date: event.target.selection_date.value,
				start_date: event.target.start_date.value,
				estimated_duration: event.target.estimated_duration.value,
				estimated_duration_category: event.target.estimated_duration_category.value,
			}
    }

    const response = await fetch( '/api/v1/project_details/' + props.projectId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()


    if (response.ok) {
    	formEl.current.reset();
      props.setActiveId(props.activeId + 1)
      props.setProjectId(responseJson.id)
      props.setProjectDetails(responseJson)
    } else {
    	setDateError(responseJson.proposal_due_date)
    }
  }

  const onkeyChangeFunction = async (identify) => {
  	if(identify == 'proposal_due_date')
  	{
  		setProposalDate(null)
  	}
  	if(identify == 'selection_date')
  	{
  		setSelectionDate(null)
  	}
  	if(identify == 'start_date')
  	{
  		setStartDate(null)
  	}
  }

  const CustomInput = forwardRef(
    ({ value, onClick, label, identify }, ref) => (
      <div className="form-floating">
        <input type="text" onKeyDown={(e)=>onkeyChangeFunction(identify)}  onClick={onClick} ref={ref} defaultValue={value} autoComplete="off"
          className="form-control" name={identify} id={identify} required />
        <label htmlFor={identify}>{label}</label>
        <div className="invalid-feedback"></div>
      </div>
    ),
  );  

  
  return (
    <form id={formId} ref={formEl} onSubmit={handleFormSubmit} className="row g-2 justify-content-center">
     
      <div className="col-md">
        <FloatingSelect data={countryCodes} name='consultant_country' onChange={e => setConsultantCountry( e.target.value )}
          label='Consultant Country' value={consultantCountry} placeholder='Select Country' withError='true'/>
      </div>
      <div className="">
      	<div className="text-start form-group required">
          <label className="control-label" htmlFor="projectDescription"><b>Project Location</b></label>
        </div>
        <FloatingSelect  data={project_location} value={projectLocation} name='location_type' onChange={e=>setProjectLocation(e.target.value)}
           placeholder={'Select One'}
          label={'Project Location'} required/>
      </div>
      {projectLocation == 'on-site' || projectLocation == 'mix' ?
      	<div className="row g-2">
        	<div className="col-md">
        		<div className="text-start form-group required">
		          <label className="control-label" htmlFor="onsite_city"><b>Onsite City</b></label>
		        </div>
            <div className="form-floating">
              <input className="form-control" defaultValue={onsiteCity} name="onsite_city" id="onsite_city" onChange={e => setOnsiteCity( e.target.value )} placeholder="City" required />
              <label htmlFor="onsite_city">City</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md">
	          <div className="text-start form-group required">
		          <label className="control-label" htmlFor="onsite_country"><b>Onsite Country</b></label>
		        </div>
            <FloatingSelect data={countryCodes} name='onsite_country' onChange={e => setCountry( e.target.value )}
              label='Country' value={country} placeholder='Select country' withError='true' required/>
          </div>	            
        </div>
        :
        null
      }
      <div className="row g-2">
      	<div className="col-md">
          <div className="form-group required">
            <label className="card-title control-label" htmlFor="city"><b>Date for Receipt of Proposals</b></label>
          </div>
        </div>
        <div className="col-md">
          < DatePicker
            selected={proposalDate}
            onChange={date => setProposalDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            
            customInput={<CustomInput identify='proposal_due_date' label='Proposal Due Date'/>}
          />
        </div>	            
      </div>
      <div className="row g-2">
      	<div className="col-md">
          <div className="form-group required">
            <label className="card-title control-label" htmlFor="consultant_selection_date"><b>Date for Selection of Consultant</b></label>
          </div>
        </div>
        <div className="col-md">
          < DatePicker
            selected={selectionDate}
            onChange={date=>setSelectionDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            
            customInput={<CustomInput identify='selection_date' label='Selection Date'/>}
          />
        </div>	            
      </div>
      <div className="row g-2">
      	<div className="col-md">
          <div className="form-group required">
            <label className="card-title control-label" htmlFor="start_date"><b>Date for Project Start</b></label>
          </div>
        </div>
        <div className="col-md">
          < DatePicker
            selected={startDate}
            onChange={date=>setStartDate(date)}
            dateFormat="dd/MM/yyyy"
            minDate={new Date()}
            
            customInput={<CustomInput identify='start_date' label='Start Date'/>}
          />
        </div>	            
      </div>
      {
		  	dateError != ''?
		  	<p className="text-danger text-start">{dateError}</p>
		  	:
		  	null
		  }			          
      <div>					      	
        <div className="row g-2">
        	<div className="col-md-4">
            <div className="form-group required">
              <label className="card-title control-label" htmlFor="duration"><b>Estimated project duration </b></label>
            </div>
          </div>
	      	<div className="col-md-4">
            <div className="form-floating">
              <input type="text" pattern="[0-9]*" defaultValue={durationValue}  className="form-control" name="estimated_duration" id="estimated_duration"  placeholder="Duration" required />
              <label htmlFor="estimated_duration">Duration(input number)</label>
              <div className="invalid-feedback"></div>
            </div>
          </div>
          <div className="col-md-4">
            <FloatingSelect data={duration_type} name='estimated_duration_category'  onChange={e => setDurationType( e.target.value )}
              label='Duration Type' value={durationType}   placeholder={'Select months/weeks/days'} withError='true' required/>
          </div>	            
        </div>			          	            
      </div>
      <div className="row g-2">
        <div className="col-md text-end">
          <button type="submit" className="btn btn-primary btn-block ml-auto">Save</button>
        </div>
      </div>
    </form>
  );
}

export default ProjectLogistics;
