import React, { useState, useEffect, useContext, forwardRef, useRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";
import FloatingSelect from '../../components/FloatingSelect';





const ProposalAttachments = (props) => {

	const globalState = useContext(store);
  const [ refDocuments , setRefDocuments ] = useState([{id:0,attachment:'',attachment_name:null,show:false}])

  const [ isLoding, setIsLoding ] = useState(false)
  const [ proposalDocuments , setPropopsalDocuments ] = useState([{id:0,attachment:'',attachment_name:null,show:false}])
  const formEl = useRef();
	const formId = `proposalAttachments`
	let history = useHistory()

  
  const uploadAttachment = (event,id) => {
  	
  	setIsLoding(true)
  	const file = event.target.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () =>  handleAttachmentChange(event,id,reader.result);
   
  }

  const handleAttachmentChange = async (event,id,result) => {
  	console.log("result999999999999999999999999999999999999999999999999999999999999999")
  	console.log(result)

  	const newData = proposalDocuments.map( data => {
			console.log("QQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQQ")
	    if (id == data.id) 
	    {
	    	data.attachment = result		              
	      data.attachment_name = event.target.files[0].name	              
	      data.show = true		              
	    	
	    }  
	    return data   
	      
    }) 

    console.log("newData")
    console.log(newData)

    setPropopsalDocuments(newData);
    setIsLoding(false);

  }

 

  const addProposalDocuments = () => {
   	let count = proposalDocuments.length;
    let id = proposalDocuments[count-1].id + 1;
    if(proposalDocuments.length < 3)
    {
    	setPropopsalDocuments( [...proposalDocuments, {id:id, attachment:'',attachment_name: null, show:false} ]  )
    }
    
  }
 

  const proposal_documents_view = proposalDocuments.map((data,index)=>
  {
  	if(!isLoding)
  	{
			return(
				<div key={index} className="col-md-12 text-start mt-3 mb-3">					
					{
		      	data.show ?
		      	<div className="row mt-4 mb-4">
		  				<div className="col-md-9">
		  					<a href={data.attachment} target="_blank" rel="noopener noreferrer" >{data.attachment_name}</a>

		  				</div>
		  				<div className="col-md-3 text-end">
		  					<button  type="submit" className="btn btn-danger btn-block ml-auto" onClick={() => delete_proposal_document(data.id)} >Delete</button>
		  				</div>
	  				</div>
		      	:
		      	<div className="mb-3 text-start">
		          <label htmlFor="attachment_name" className="form-label">Document</label>
		          <input className="form-control" name="attachment_name" type="file" id="attachment_name" onChange={(e) => uploadAttachment(e, data.id)}  />
		          <div className="invalid-feedback"></div>
		        </div>


		      }		      
				</div>

			);
	  }
	  else
	  {
	  	return(
	  		<div key={index} className="col-md-12 text-start mt-3 mb-3">
			  	<div className="loading-spinner">
			    	Document Uploading......
		      </div>
	      </div>
      );
	  }
	})


  useEffect( () => {
	  fetchData();
	}, []);

	const fetchData = async () => {
		console.log("jjjjjjjjjjjjjjjjjjjjjjjjjjjj")
		const response = await fetch('/api/v1/proposals/'+props.proposalId, {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })

		  const responseJson = await response.json()

		  if(response.ok)
		  {		  	
		  	props.setProposalDetails(responseJson)
		  	setRefDocuments(responseJson.proposal_documents)

		  	if(responseJson.proposal_documents.length > 0) 
				{
					var fetchDocuments = [];
					props.proposalDetails.proposal_documents.map( data => {
						var new_document = {id:data.id,attachment:data.attachment,attachment_name:data.attachment_name,show:true}
						fetchDocuments.push(new_document)
					})

					setPropopsalDocuments(fetchDocuments);

				}
				else
				{
					setPropopsalDocuments([{id:0,attachment:'',attachment_name:null,show:false}])
				}
		  }
		  else {
		    alert.show("Server is down.")
		    return false
		  } 

		
	}

  const handleSubmitAttachments = async (event) => {
    if (event) event.preventDefault();
    // history.push({pathname:"/proposal-preview", state:{ proposal_id:props.proposalId, project_details_id: props.projectDetails.id }});
   	
 		const formData = {
      proposal: {
      	documents: proposalDocuments        
      }
    }

    console.log("formData")
    console.log(formData)
   
  	const response = await fetch( '/api/v1/proposals/' + props.proposalId, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'put',
      body: JSON.stringify(formData)
    })
    const responseJson = await response.json()

    history.push({pathname:"/proposal-preview", state:{ proposal_id:props.proposalId, project_details_id: props.projectDetails.id }});

    if (response.ok) {
    	
    } else {

    }        	

  }

  const delete_proposal_document = async (id) => {

  	const newProposalDocuments =  proposalDocuments.filter( data => data.id != id )
    setPropopsalDocuments( newProposalDocuments )

    console.log("proposalDocuments.length")
    console.log(proposalDocuments.length)
    
    if(proposalDocuments.length == 1)
    {
    	setPropopsalDocuments([{id:0,attachment:'',attachment_name:null,show:false}])
    }
    
    var check_id = false
    refDocuments.map( data => {
    	if(data.id == id)
    	{
    		check_id = true
    	}
    })

    if(check_id)
    {
    	const response = await fetch( '/api/v1/delete_proposal_document/' + id, {
	      headers: {
	        'Content-Type': 'application/json',
	        'Authorization': globalState.state.Authentication
	      },
	      method: 'delete',
	      
	    })

	    const responseJson = await response.json()		    

	    if (response.ok) {
	    	fetchData();
	    } else {
	    	console.log("responseJson")
	    }
    }

    

    
	}




  return (
    <div className="container">	
      <div className="col-md-12 mt-1">
	      {proposal_documents_view} 
	      {
	      	proposalDocuments.length < 3 ?
	      	<div className="text-start ms-3">
		        <button type="button" onClick={addProposalDocuments} className="btn btn-primary btn-block"><FontAwesomeIcon icon="plus" /></button>
		      </div>
		      :
		      null
	      }	      
      </div>
      
      
      <div className="row g-2 mt-3">
        <div className="col-md text-end">
          <button  type="submit" onClick={()=>handleSubmitAttachments()} className="btn btn-primary btn-block ml-auto">Save</button>
        </div>
      </div>  
    	

    </div>
  );
}

export default ProposalAttachments;
