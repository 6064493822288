import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'



const ProjectsSaved = (props) => {

	const globalState = useContext(store);
  const [ projectList, setProjectList] = useState([])



	const fetchData = async () => {

	  const response = await fetch('/api/v1/project_details', {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()

	  if (response.ok) {
	    setProjectList(responseJson)
	  } else {
	    alert.show("Server is down.")
	    return false
	  }
	}

	useEffect( () => {
	  fetchData();
	}, []);


	const ProjectList = projectList.map( (project) => {
		if(project.status != 'posted')
		{
			return (
      	<tr key={project.id}>
		      <td><Link className="link" to={{pathname:"/project-view", state:{ projectId:project.id, projectTitle:project.title,wip:true}}}>{project.title}</Link></td>
		      <td>{project.creator}</td>
		      <td>{project.update_date}</td>
		      <td><Link className="link" to={{pathname:"/edit-project", state:{ projectDetails: project}}}>Edit</Link></td>

		    </tr>

    	)
		}

  })



  return (
    <div className="container">
      <div className="row g-2 align-items-center">
        <div className="col-md text-start">
          <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="bookmark" /> Project RFPs WIP</h2>
        </div>
    	</div>
			<table className="table table-bordered table-striped table-sm text-start">
			  <thead>
			    <tr>
			      <th scope="col">Project Title</th>
			      <th scope="col">Created By</th>
			      <th scope="col">Date Saved</th>
			      <th scope="col"></th>
			    </tr>
			  </thead>
			  <tbody>
		    	{ProjectList}
			  </tbody>
			</table>

    </div>
  );
}

export default ProjectsSaved;
