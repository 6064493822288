import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';


const TextAreaAutoResize = (props) => {

	function expandTextarea(id) {
  	var el = document.getElementById(id);
		if(el){
		  el.addEventListener('keyup', function() {
        this.style.overflow = 'hidden';
        this.style.height = 0;
        this.style.height = this.scrollHeight + 'px';
    	}, false);
		}    
	}

	expandTextarea(props.Id);

	const handleChange = async (value) => 
  {
  	props.handleValueChange(value);
  }

	return (    
    <div className="col-md">
      <div className="form-floating">
        <textarea
        	disabled={props.disabled}
          className="form-control"          
          name="summary"
          id={props.Id}
          defaultValue={props.value}
          onChange={(e)=>handleChange(e.target.value)}
          placeholder="(You will be able to edit this in your Profile when your application is accepted)"
          required
        ></textarea>
        <label htmlFor={props.label_value}>{props.label_value}</label>          
      </div>
    </div>
        
  );
}

export default TextAreaAutoResize;