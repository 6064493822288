import React, { useState } from 'react';

import TabCards from '../components/TabCards';

import ProfileSummaryStep from './steps/ProfileSummaryStep';
import ServiceOfferingStep from './steps/ServiceOfferingStep';
import SpecialisationStep from './steps/SpecialisationStep';
import JobsStep from './steps/JobsStep';
import ProjectStep from './steps/ProjectStep';
import EducationStep from './steps/EducationStep';
import ContactDetailStep from './steps/ContactDetailStep';

const CompleteSignup = (props) => {
  const [activeId, setActiveId] = useState(1)

  const commonProps = { activeId: activeId, setActiveId: setActiveId }

  const tabs = [
    { id: 1, name: 'Profile Summary', component: ProfileSummaryStep, props: commonProps },
    { id: 2, name: 'Service Offerings', component: ServiceOfferingStep, props: commonProps },
    { id: 3, name: 'Specialisations', component: SpecialisationStep, props: commonProps },
    { id: 4, name: 'CV', component: JobsStep, props: commonProps },
    { id: 5, name: 'Projects', component: ProjectStep, props: commonProps },
    { id: 6, name: 'Education', component: EducationStep, props: commonProps },
    { id: 7, name: 'Contact Details', component: ContactDetailStep, props: commonProps }
  ]

  return (
    <TabCards
      tabs={tabs}
      activeId={activeId}
      setActiveId={setActiveId}
      heading='Complete your profile'
    />
  );
}

export default CompleteSignup;
