import React, { useState, useEffect, useContext, forwardRef } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ConsultantProfile from '../../components/ConsultantProfile';
import Modal from '../../components/Modal';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import CompanyPublicProfile from '../CompanyPublicProfile'
import ClientPublicProfile from '../profileSettings/ClientPublicProfile'
import CustomEditor from '../../components/CustomEditor'
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import DatePicker from "react-datepicker";
import ProposalSteps from './ProposalSteps';
import ProjectInformationPop from './ProjectInformationPop'






const NewProposals = (props) => {

	const globalState = useContext(store);
	const { state } = useLocation();	
	const { dispatch } = globalState;
	const [ projectDetails, setProjectDetails ] = useState({})
	const [ companyData, setCompanyData ] = useState({})
  const [startDate, setStartDate] = useState(null)
  const [showCompanyProfile, setShowCompanyProfile] = useState( false );
	const [modalCompanyProfile, setModalCompanyProfile] = useState(null);
	const [fetchEvent, setFetchEvent] = useState(false);
	const [showClientModal, setShowClientModal] = useState( false );
	const [modalClientPublicProfile, setModalClientPublicProfile] = useState(null);
	const [showProjectDetails, setShowProjectDetails] = useState( false );
	const [modalProjectDetails, setProjectDetailsModal] = useState(null);


	let history = useHistory()

	const [modal, setModal] = useState(null);

	if(state != undefined)
	{
		localStorage.setItem('document',JSON.stringify(state));
	}
	const my_data =  JSON.parse(localStorage.getItem('document'));

	const fetchData = async () => {

	  const response = await fetch('/api/v1/project_details/' + my_data.project_details_id, {
	    headers: {
	      'Content-Type': 'application/json',
	      'Authorization': globalState.state.Authentication
	    },
	    method: 'get'
	  })
	  const responseJson = await response.json()
	  
	  if (response.ok) {
	    setProjectDetails(responseJson)
	    setCompanyData(responseJson.company)  
	    setStartDate(new Date(responseJson.start_date)) 
	  } else {
	    alert.show("Server is down.")
	    return false
	  }	 
	}

	function scrollToUp() {
		window.scrollTo(0, 0);
	}

	useEffect( () => {
	  fetchData();
	  scrollToUp();
	}, []);

	useEffect(() => {
    if (modalCompanyProfile)
      showCompanyProfile ? modalCompanyProfile.show() : modalCompanyProfile.hide()
  }, [showCompanyProfile, modalCompanyProfile])

  useEffect(() => {
    if (modalClientPublicProfile)
      showClientModal ? modalClientPublicProfile.show() : modalClientPublicProfile.hide()
  }, [modalClientPublicProfile, showClientModal])

  useEffect(() => {
    if (modalProjectDetails)
      showProjectDetails ? modalProjectDetails.show() : modalProjectDetails.hide()
  }, [modalProjectDetails, showProjectDetails])
 

  return (
    <div className="container mb-3">
      <div className="row g-2 mt-2">
      	<div className="col-md-12">	      	
	      	<div className="row g-0 text-start mt-2">
	        	<h5 className="col-md-4"><b>Company Name: </b></h5>
	        	<button type="button" className="col-md-8 text-start btn btn-link p-0" onClick={() => setShowCompanyProfile(!showCompanyProfile)} ><h5>{companyData.name}</h5></button>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-4"><b>Posted by: </b></h5>
	      		<button type="button" className="col-md-8 text-start btn btn-link p-0" onClick={() => setShowClientModal(!showClientModal)} ><h5>{projectDetails.creator}</h5></button>
	      	</div>
	      	<div className="row g-0 text-start mt-2">
	      		<h5 className="col-md-4"><b>Project Title: </b></h5>
	      		<h5 className="col-md-8 text-start">{projectDetails.title}    [ <button type="button" className="text-start btn btn-link p-0 mt-1" onClick={() => setShowProjectDetails(!showProjectDetails)} ><h6>Show Requirements</h6></button> ]</h5>
	      	</div>	      		      	
      	</div>      	
    	</div>
    	<ClientPublicProfile clientDataId={projectDetails.creator_id} show={showClientModal} setShow={setShowClientModal} setModal={setModalClientPublicProfile} setFetch={setFetchEvent}/>
      <CompanyPublicProfile companyDataId={companyData.id} show={showCompanyProfile} setShow={setShowCompanyProfile} setModal={setModalCompanyProfile} setFetch={setFetchEvent}/>
    	<ProjectInformationPop project_detail_id={projectDetails.id} show={showProjectDetails} setShow={setShowProjectDetails} setModal={setProjectDetailsModal} setFetch={setFetchEvent}/>    	
    	<ProposalSteps  projectDetails={projectDetails}  headings={"Create Proposal"}/>     	
    </div>
  );
}

export default NewProposals;
