import React, { useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../store'
import { Link, useHistory } from 'react-router-dom';
import SetupUser from './manageusers/SetupUser';
import BtnCellRenderer from './manageusers/BtnCellRenderer';

import {AgGridColumn, AgGridReact} from 'ag-grid-react';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';


const Resources = (props) => {

const globalState = useContext(store);
const { dispatch } = globalState;
let history = useHistory()
const [guideList, setGuidelist] = useState([]);



const fetchData = async () => {
	if(globalState.state.user_type == "client")
	{
		const response = await fetch('/api/v1/client_guide_list', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responsejson = await response.json()

    if(response.ok)
    {
    	setGuidelist(responsejson)
    }
    else {     
      return false
    }
	}
	
	else
	{
		const response = await fetch('/api/v1/consultant_guide_list', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'get'
    })
    const responsejson = await response.json()

    if(response.ok)
    {
    	setGuidelist(responsejson)
    }
    else {     
      return false
    }
	}
}

   	


  useEffect( () => {
    fetchData();    
  }, []);


  const guides_list = guideList.map( g => {  	 	
		return (
      <div key={g.id} className="col-md-6 mt-2">
		    <div className="card">
		    	<div className="card-body text-start">
		    		<h4 className="card-title">{g.resource_title}</h4>	
		    		<div className="card-text">{ReactHtmlParser(g.resource_description)}</div>
		 				<a href={g.resource_url_link} className="card-link" target="_blank" rel="noopener noreferrer" >Open Resource</a>
		    	</div>
		    </div>
	    </div>
    )  	
	});

 

return(
	<div className="container">		
    <div className="col-md g-2 text-start">
      <h2 className="card-title page-title my-3"><FontAwesomeIcon icon="book" />  Resources </h2>
    </div>    
	 	<div className="row">	    
	    {guides_list}
		</div>
			
	</div>
	);

}

export default Resources;
