import React, { useContext, useState } from 'react';
import { Link, useHistory } from 'react-router-dom';

import Errors from '../../components/Errors';
import { store } from '../../store';

const ForgotPassword = (props) => {

  const [ message, setMessage ] = useState('');
  const [ created, setCreated ] = useState(false);
  const globalState = useContext(store);
  const { dispatch } = globalState;
  let history = useHistory();

  const handleLoginSubmit = async (event) => {
    if (event) {
      event.preventDefault();
    }
    const formData = {
      email: event.target.email.value,
    }
    const response = await fetch('/api/v1/reset-password', {
      headers: {
        'Content-Type': 'application/json',
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const userData = await response.json()
    if (response.ok) {
      setCreated(true)
    } else {
      if (userData.error)
        setMessage( userData.errors )
    }
  }

  if ( created ) {
    return(
      <div className="auth-wrapper">
        <div className="auth-inner signup-success">
          <h3 className="fw-bold">
            Please check your email for instructions on how to reset your password.
          </h3>
          <div>
				    <Link className="fw-bold" to={"/login"}>Log in</Link>
				  </div>
        </div>

      </div>
    );
  }

  return (
    <div className="auth-wrapper">
      <h4 className="mb-4"><b className="primary-text">Work Smart</b> &#8226; <b className="secondary-text">Work Efficient</b> &#8226; <b className="primary-text">Be Flexible</b></h4>
      <div className="auth-inner">
        <form onSubmit={handleLoginSubmit} className="row g-2">
          <h3>Forgot Password</h3>
          <div className="row g-2">
          <Errors message={message} />
            <div className="col-md">
              <div className="form-floating">
                <input type="email" className="form-control" name="email" id="email" placeholder="name@example.com" required />
                <label htmlFor="email">Email</label>
              </div>
            </div>
          </div>
          <div className="row g-2">
						<div className="col-md">
						  <div className="form-group text-end">
						    <Link className="forgot-password" to={"/login"}>Log in</Link>
						  </div>
						</div>
					</div>
          <div className="row g-2">
            <div className="col-md">
              <button type="submit" className="btn btn-primary btn-block w-100">Submit</button>
            </div>
          </div>




        </form>
      </div>
    </div>
  );
}

export default ForgotPassword;
