import React, { useContext, useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { store } from '../../store'
import { confirmAlert } from 'react-confirm-alert';
import CustomEditor from '../../components/CustomEditor'
import SearchProfileCard from '../../components/SearchProfileCard'

const SendRequirements = (props) => {

  const globalState = useContext(store);
  const [ users, setUsers ] = useState([])
  const [ nameStarts, setNameStarts ] = useState('abcd')
  const [ projectDescription, setProjectDescription ] = useState('')
  const [ projectName, setProjectName ] = useState('')
 	let history = useHistory()


  const handleFormSubmit = async () => {
    
    const formData = {
    	project_details:
    	{
    		project_name: projectName,
      	project_description: projectDescription,
      	email: 'concierge@pharmalancers.com',
    	}
      
    }
    const response = await fetch( '/api/v1/ask_pharmalancers', {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': globalState.state.Authentication
      },
      method: 'post',
      body: JSON.stringify(formData)
    })
    const responsejson = await response.json()
    
    if (response.ok) {
      history.goBack();
    } else {
    }
  }

  const confirmSend = () => {
	  confirmAlert({
	  	customUI: ({ onClose }) => {
		    return (
		      <div className='p-5 custom-ui border border-primary rounded'>

		        <h5>Send message to Pharmalancers Concierge?</h5>
		        <div className="row g-2 mt-3">
			        <div className="col-md g-2 text-start">
			        	<button className="btn btn-danger" onClick={onClose}>No</button>
			        </div>
			        <div className="col-md g-2 text-end">
				        <button className="btn btn-primary"
				          onClick={() => {
				            handleFormSubmit();
				            onClose();
				          }}
				        >
				          Yes
				        </button>
				      </div>
				    </div>
		      </div>
		    );
		  }
		});
	};


 

  function handleChange(newValue) {
    setProjectDescription(newValue);
  }

  const usersData = users.map( u => {
    return (
      <SearchProfileCard
        key={u.id}
        name={u.user.first_name + ' ' + u.user.last_name.substring(0, 1)}
        image_url={u.user?.image_url}
        city={u.user.city}
        country={u.user.country_name}
        service={u.primary_service}
        service_exp={u.primary_service_exp}
        education={u.main_education}
        summary={u.summary}        
        usersData={u}
      />
    )
  })

  const totalCount = () => {
    return `${users.length} consultant${ users.length > 1 ? 's' : '' } found`
  }
  return (
    <div className="container">
    	<div className="text-start mt-4">	     
	      <div>
	      	<h5 className="mt-2">To: Pharmalancers Concierge</h5>
	      	<h5 className="mt-2">Company Name: {globalState.state.company_name}</h5>
	      </div>
	      <div>
	      	<h5 className="mt-2">User Name: {globalState.state.firstName} {globalState.state.lastName}</h5>
	      </div>
	      <div>
	      	<h5 className="mt-2">I would like you to create a new project for us on the Pharmalancers platform with your Concierge service.</h5>
	      </div>
	      <div className="row align-items-center mt-3">
	      	<div className="col-md-3">
	      		<h5>Project Title : </h5>
	      	</div>
	      	<div className="col-md-9">
		      	<div className="form-floating">
		          <input type="text" className="form-control" name="first_name" id="first_name" placeholder="John" onChange={(e) => setProjectName(e.target.value)} required />
		          <label htmlFor="first_name">Project title</label>
		          <div className="invalid-feedback"></div>
		        </div>
	      	</div>
	      	
	      </div>
	      <div className="row align-items-center mt-4">
	      	<div className="col-md-3">
	      		<h5>Project Description : </h5>
	      	</div>
	      	<div className="col-md-9">
		      	<CustomEditor placeholder={"Project Description"} className="form-control " type="text" id="projectDescription" value={projectDescription} onChange={handleChange} required/>
	      	</div>	      	
	      </div>	      
      </div>
      {/*console.log(<div className="col-md-12 mt-3">
              <div className="text-start">
                <label className="control-label" htmlFor="projectDescription">Project Description (include objectives and scope)</label>
              </div>
      	      <div className="text-start">
      	      	
      	      </div>
            </div>
      )*/}
     

      {/*<div className="row d-flex align-items-center overflow-search-scorll search-with-name">
        {usersData}
      </div>*/}
         		
  		<div className="mt-3">
  			<button type="button" onClick={()=>confirmSend()} className="btn btn-primary text-end">Send</button>
    	</div>
    </div>
  );
}

export default SendRequirements;
