import React, { useContext, useEffect, useState, useRef } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { store } from '../../store'
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';
import { Link, useHistory ,Redirect} from 'react-router-dom';
import TextAreaAutoResize from '../../components/TextAreaAutoResize'
import ReplyMessage from './ReplyMessage'


const MessageList = (props) => {

	const handle_Archive = async (type,id) => {
	
		props.handleArchive(type,id)
	}

	return (
		<div>
		 	<div className="card mb-3  mt-3">
	    	<div className="row g-2 m-1 align-items-center">
	      	<div className="col-md-2 text-start">
						<h6 className={"text-start "+ (props.unread ? 'fw-bold' : '')}>{props.name}<small className="text-end muted"> ({props.messages_count})</small></h6>
					</div>
					<div className="col-md-8 d-flex justify-content-between align-items-center ">
						<h6 className={"text-start "+ (props.unread ? 'fw-bold' : '')}>{props.message_subject}</h6>
						<h6 className={"text-end "+ (props.unread ? 'fw-bold' : '')}>{props.date}</h6>
					</div>					
					{
						!props.is_archive?
						<div className="col-md-1 text-end">
							<button
		            className="btn button-custom-style btn-sm text-center"
		            onClick={()=>handle_Archive('archive',props.conversation_id)}
			          >  Archive  
			        </button>
						</div>
						:
						<div className="col-md-1 text-end">
							<button
		            className="btn button-custom-style btn-sm text-center"
		            onClick={()=>handle_Archive('unarchive',props.conversation_id)}
			          >Unarchive
			        </button>
						</div>

					}
					<div className="col-md-1 text-end">
						<Link
	            className="btn button-custom-style btn-sm text-center"
	            to={{pathname:"/rply-message",state:{conversation_id:props.conversation_id,conversation_subject:props.message_subject,conversation:props.conversation}}}
		          >Read
		        </Link>
					</div>
					
				</div>
    	</div>
    	{/*{
    		showModalReplyMessage == true ?
    		<div className="row g-2 justify-content-start">
	        <ReplyMessage  show={showModalReplyMessage} setShow={setShowModalReplyMessage} setModal={setModalReplyMessage} conversation_id={props.conversation_id} message_subject={props.message_subject}/>
	      </div>
	      :
	      null
    	}
    	*/}
    </div>
	);
}

export default MessageList;
