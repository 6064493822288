import React, { useContext, useEffect, useState, useRef } from 'react';
import { store } from '../store';
import Modal from './Modal'
import PublicProfile from '../views/PublicProfile';
import ViewJob from '../views/jobs/ViewJob';
import View from '../views/projects/View';
import ServicesDisplay from '../views/ServicesDisplay';
import ReactHtmlParser, { processNodes, convertNodeToElement, htmlparser2 } from 'react-html-parser';




const ConsultantProfileForPrint = React.forwardRef((props, ref) => {

	const globalState = useContext(store);
	const [consultantData, setConsultantData] = useState({} )
  const [modal, setModal] = useState(null);

	useEffect(() => {
    fetchData()
  }, [])


	const fetchData = async () => {
		if(props.print_from == 'consultant')
		{
			const response = await fetch('/api/v1/consultant_profiles', {
		    headers: {
		      'Content-Type': 'application/json',
		      'Authorization': globalState.state.Authentication
		    },
		    method: 'get'
		  })
		  const responseJson = await response.json()

		  console.log(responseJson);

		  if (response.ok) {
		    setConsultantData(responseJson)
		  } else {
		    alert.show("Server is down.")
		    return false
		  }
		}
		else
		{
			const response = await fetch('/api/v1/show_consultant_profile/' + props.consultant_id, {
  	    headers: {
  	      'Content-Type': 'application/json',
  	      'Authorization': globalState.state.Authentication
  	    },
  	    method: 'get'
  	  })
  	  const responseJson = await response.json()
  	  if (response.ok) {
  	    setConsultantData(responseJson)
  	  } else {
  	    alert.show("Server is down.")
  	    return false
  	  }
		}

	}

  const Listing = ( props ) => {
    if(props.list?.length < 1)
      return <div><ul>{props.noListMsg}</ul></div>;

    const list = props.list?.map( (name , i) => {
      return <li key={i}>{name}</li>
    })
    return <ul>{list}</ul>;
  };

  const educationsHTML = consultantData.educations?.map( (education) => {
    return(
      <li key={education.id}>
        {education.qualification}, {education.institution}, {education.start_formatted} - {education.end_formatted}
      </li>
    )
  });

  const listItems = consultantData.projects?.map((data, index) =>{
  	if(data.is_public == true)
  	{
  		return <View display_public_profile={true} data={data} key={data.id} setFetch={props.setFetch} />
  	}
  });


  const joblistItems = consultantData.job_roles?.map((data, index) =>{
    return <ViewJob display_public_profile={true} data={data} key={data.id} setFetch={props.setFetch} />
  });

  return (
    <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center pt-3 pb-2 mb-3 print-only" ref={ref} >

	  		<div className="container">
		  		<div className="d-flex card">
	          <div className="col-md-7">
	            <img src={consultantData.user?.image_url} alt="BlankProfileImg" className="img-responsive" style={{width:"100%"}}/>
	          </div>
	          <div className="col-md-6">
	            <div className="card-body">
	              <h3 className="card-title"><b>{consultantData.user?.first_name} {consultantData.user?.last_name}</b></h3>
	              <h5 className="card-title">{consultantData.primary_service} <i>({consultantData.total_experience})</i></h5>
	              <h5 className="card-title">{consultantData.main_education}</h5>
	              <h6 className="card-title"><i> {consultantData.user?.city}, {consultantData.user?.country_name}. </i></h6>
	              <p className="card-text">{ReactHtmlParser(consultantData.summary)}</p>
	            </div>
	          </div>
		      </div>

		      <div className="card mt-3 pagebreak">
		        <div className="card-header text-start">
		          <h4><b>Service Offerings</b></h4>
		        </div>
		        <div className="card-body text-start">
		          <div>
		            <p className="text-muted">The main pharmaceutical function(s) in which the consultant provides specialist services</p>
		          </div>
		          <ul>
		            <p><strong>Primary Service:</strong></p>
		            <strong>{consultantData.primary_service} <i>({consultantData.primary_service_exp})</i></strong>
		            <ul>
		              <ServicesDisplay services={consultantData.primary_sub_services}/>
		            </ul>
		            <hr/>
		            <p><strong>Secondary Service:</strong></p>
		            {
		            	consultantData.secondary_service != null ?
		            		<strong>{consultantData.secondary_service} <i>({consultantData.secondary_service_exp})</i></strong>
		            		:
		            		<strong>Not selected</strong>
		            }

		            <ul>
		              <ServicesDisplay services={consultantData.secondary_sub_services}/>
		            </ul>
		          </ul>
		        </div>
		      </div>

		      <div className="card mt-3">
		        <div className="card-header text-start">
		          <h4><b>Specialisations</b></h4>
		        </div>
		        <div className="card-body text-start">

		          <div>
		            <p className="text-muted">The Therapeutic Areas and Product types in which the Consultant has specific expertise or experience.</p>
		          </div>
		          <ul>
		            <strong>Therapeutic Areas</strong>
		            <Listing list={consultantData.therapeutics} noListMsg="None selected" />
		            <hr/>
		            <strong>Product Specialisations</strong>
		            <Listing list={consultantData.products} noListMsg="None selected" />
		            <hr/>
		            <strong>Service Offering Specialisations</strong>
		            <Listing list={consultantData.services} noListMsg="None selected" />
		            <hr/>
		            <strong>Languages Known (in addition to English)</strong>
		            <Listing list={consultantData.languages} noListMsg="None selected" />
		          </ul>
		        </div>
		      </div>


			    <div className="card mt-3 pagebreak">
		        <div className="card-header d-flex align-items-center justify-content-between">
		          <h4 className='m-0'><b>Experience (Roles)</b></h4>

		        </div>
		        <div className="card-body">
		          <div>
		            <p className="text-muted"></p>
		          </div>
		          <div className="step-form my-4 mx-1" >
					      {joblistItems}
					    </div>
		        </div>
		      </div>

		      <div className="card mt-3 pagebreak">
		        <div className="card-header d-flex align-items-center justify-content-between">
		          <h4 className='m-0'><b>Projects</b></h4>
		        </div>
		        <div className="card-body">
		          <div className="text-start">
		            <p className="text-muted">A selection of projects worked on by the Consultant</p>
		          </div>
		          <div className="step-form my-4 mx-1">
					      {listItems}
					    </div>
		        </div>
		      </div>


		      <div className="card mt-3 pagebreak">
			      <div className="card-header d-flex align-items-center justify-content-between">
			        <h4 className='m-0'><b>Education & Qualifications</b></h4>
			      </div>
			      <div className="card-body">
			        <div className="text-end">
			          <ul className="text-start">
			            {educationsHTML}
			          </ul>
			        </div>
			      </div>
			    </div>
		  	</div>

	  </div>
	);
});

export default ConsultantProfileForPrint;
